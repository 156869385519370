class GroupDetailsBalanceSheet {
  assets: number | null;

  propertyAtStartOfCycle: number | null;

  liabilities: number | null;

  netProfit: number | null;

  loanFundBalance: number | null;

  loansOutstanding: number | null;

  externalDebts: number | null;

  propertyNow: number | null;

  bankBalance: number | null;

  cumulativeSavings: number | null;

  equity: number | null;

  socialFundBalance: number | null;

  constructor() {
    this.assets = 0;
    this.propertyAtStartOfCycle = 0;
    this.liabilities = 0;
    this.netProfit = 0;
    this.loanFundBalance = 0;
    this.loansOutstanding = 0;
    this.externalDebts = 0;
    this.propertyNow = 0;
    this.bankBalance = 0;
    this.cumulativeSavings = 0;
    this.equity = 0;
    this.socialFundBalance = 0;
  }
}

export default GroupDetailsBalanceSheet;

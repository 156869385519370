/* eslint-disable react/no-unused-state */
import { Component } from 'react';

import axios from 'axios';
import isEqual from 'lodash/isEqual';
import * as intl from 'react-intl-universal';

import ApiError from 'api/common/ApiError';
import FinancesApiInstance from 'api/finances/FinancesApi';
import KeyMetricsDetails from 'api/finances/key-metrics-details/KeyMetricsDetails';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import LabelKeysGA from 'constants/ga/LabelKeysGA';
import { defaultGlobalFilters } from 'helpers/GlobalFilterUtils';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import setPageTitle from 'helpers/setPageTitle';
import FinancialMetricsChartContainer from 'modules/private/finances/components/financial-metrics-chart-container/FinancialMetricsChartContainer';
import FinancialMetricsChartFilter from 'modules/private/finances/components/financial-metrics-chart-container/FinancialMetricsChartFilter';
import FinancialMetricsChartTypes from 'modules/private/finances/components/financial-metrics-chart-container/FinancialMetricsChartTypes';
import KeyMetrics from 'modules/private/finances/components/key-metrics/KeyMetrics';
import FinancialMetricsViewProps from 'modules/private/finances/containers/FinancialMetricsViewProps';
import FinancialMetricsViewState from 'modules/private/finances/containers/FinancialMetricsViewState';
import GlobalFilters from 'shared/components/header-toolbar/GlobalFilters';
import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import ScrollToTopOnMount from 'shared/components/scroll-to-top-on-mount/ScrollToTopOnMount';
import EventKey from 'shared/enums/EventKey';
import FilterType from 'shared/enums/FilterType';
import HTTP_STATUS from 'shared/enums/HttpStatus';
import { EventBus } from 'shared/events/EventBus';
import { CustomErrorArgs } from 'shared/types/eventTypes';

class FinancialMetricsView extends Component<
  FinancialMetricsViewProps,
  FinancialMetricsViewState
> {
  constructor(props: FinancialMetricsViewProps) {
    super(props);

    this.state = {
      filters: defaultGlobalFilters,
      keyMetrics: {
        loading: false,
        error: null,
        data: new KeyMetricsDetails(),
      },
      financialMetricsChart: {
        chartType: FinancialMetricsChartTypes.CumulativeSavings,
        data: {},
        error: null,
        filters: new FinancialMetricsChartFilter(),
        loading: false,
      },
    };
  }

  componentDidMount(): void {
    const { appContext, location } = this.props;
    const { globalFilters, hideErrorToast } = appContext;
    hideErrorToast();
    setPageTitle(intl.get('BTN_FINANCES'));

    const { financialMetricsChart } = this.state;

    if (location.state) {
      const { chartType } = location.state;
      this.setState((state) => ({
        financialMetricsChart: {
          ...state.financialMetricsChart,
          chartType,
        },
      }));
    }

    this.loadFinancialData(
      financialMetricsChart.chartType,
      globalFilters,
      financialMetricsChart.filters
    );
    this.loadKeyMetrics(globalFilters);
  }

  componentDidUpdate(
    prevProps: FinancialMetricsViewProps,
    prevState: FinancialMetricsViewState
  ): void {
    const { filters, financialMetricsChart } = this.state;

    const { appContext } = this.props;
    const { globalFilters } = appContext;

    if (!isEqual(filters, globalFilters)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ filters: globalFilters });
      const { fromDate, toDate } = globalFilters;
      if ((!fromDate && !toDate) || (fromDate && toDate)) {
        this.loadFinancialData(
          financialMetricsChart.chartType,
          globalFilters,
          financialMetricsChart.filters
        );
        this.loadKeyMetrics(globalFilters);
      }
    }

    // prettier-ignore
    if (!isEqual(prevState.financialMetricsChart.filters, financialMetricsChart.filters)) {
      this.loadFinancialData(financialMetricsChart.chartType, globalFilters, financialMetricsChart.filters);
    }

    /* chart type change */
    // prettier-ignore
    if(prevState.financialMetricsChart.chartType !== financialMetricsChart.chartType) {
      this.loadFinancialData(financialMetricsChart.chartType, globalFilters, financialMetricsChart.filters);
    }
  }

  componentWillUnmount(): void {
    this.source.cancel();
  }

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  /**
   * Fetch key metrics data and update state
   *
   * @param globalFilters Current global filter configuration
   */
  private async loadKeyMetrics(globalFilters: GlobalFilters): Promise<void> {
    const { appContext } = this.props;
    this.setState((state) => ({
      keyMetrics: {
        ...state.keyMetrics,
        loading: true,
      },
    }));

    try {
      const result = await FinancesApiInstance.GetKeyMetrics(
        globalFilters,
        this.source
      );

      this.setState((state) => ({
        keyMetrics: {
          ...state.keyMetrics,
          loading: false,
          error: null,
          data: result,
        },
      }));
    } catch (error) {
      if (error instanceof ApiError) {
        this.setState((state) => ({
          keyMetrics: {
            ...state.keyMetrics,
            loading: false,
            error: error as ApiError,
            data: new KeyMetricsDetails(),
          },
        }));
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  }

  /**
   * Fetch financial metrics data and update state
   *
   * @param chartType Type of chart selected
   * @param globalFilters Current global filter configuration
   * @param financialFilters Selected financial filters configuration
   */
  private async loadFinancialData(
    chartType: FinancialMetricsChartTypes,
    globalFilters: GlobalFilters,
    financialFilters: FinancialMetricsChartFilter
  ): Promise<void> {
    const { appContext } = this.props;
    try {
      this.setState((state) => ({
        financialMetricsChart: {
          ...state.financialMetricsChart,
          loading: true,
        },
      }));
      const result = await FinancesApiInstance.GetFinancesChartData(
        chartType,
        globalFilters,
        financialFilters,
        this.source
      );
      this.setState((state) => ({
        financialMetricsChart: {
          ...state.financialMetricsChart,
          loading: false,
          error: null,
          data: { ...state.financialMetricsChart.data, [result.type]: result },
        },
      }));
    } catch (error) {
      if (error instanceof ApiError) {
        this.setState((state) => ({
          financialMetricsChart: {
            ...state.financialMetricsChart,
            loading: false,
            error: error as ApiError,
            data: {},
          },
        }));
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  }

  /**
   * Updating the state when a filter is changed. The `type` param
   * provide the name of the update filter and the `filter` param
   * provides the selected value in the Select Controller.
   *
   * @param filter Selected filter option
   * @param type Filter type
   */
  private handleFinancialMetricFilterChange = (
    filter: SelectFieldOption,
    type: FilterType
  ): void => {
    this.setState((state) => ({
      financialMetricsChart: {
        ...state.financialMetricsChart,
        filters: {
          ...state.financialMetricsChart.filters,
          [type]: { ...filter },
        },
      },
    }));

    const { financialMetricsChart } = this.state;

    const eventCategory = `${CategoryKeysGA.FinancesFinancialMetrics}/${String(
      LabelKeysGA[`FINANCES_${financialMetricsChart.chartType}`]
    )}`;
    sendEventGA(
      eventCategory,
      ActionKeysGA.ChangeChartFilter,
      `${String(LabelKeysGA[type])}:${String(LabelKeysGA[filter.value])}`
    );
  };

  /**
   * Handles change event for chart type
   *
   * @param type Financial metrics chart type
   */
  private handleFinancialMetricChartTypeChange = (
    type: FinancialMetricsChartTypes
  ): void => {
    this.setState((state) => ({
      financialMetricsChart: {
        ...state.financialMetricsChart,
        chartType: type,
      },
    }));

    sendEventGA(
      CategoryKeysGA.FinancesFinancialMetrics,
      ActionKeysGA.ChangeChartType,
      LabelKeysGA[`FINANCES_${type}`]
    );
  };

  /**
   * Updating the state when a filter is changed. The `type` param
   * provide the name of the update filter and the `filter` param
   * provides the selected value in the Select Controller.
   */
  render(): JSX.Element {
    const { financialMetricsChart, keyMetrics } = this.state;
    const data = Object.values(financialMetricsChart.data);
    return (
      <div className="content-container">
        <ScrollToTopOnMount />
        <FinancialMetricsChartContainer
          data={data}
          filters={financialMetricsChart.filters}
          loading={financialMetricsChart.loading}
          chartType={financialMetricsChart.chartType}
          onFilterChange={this.handleFinancialMetricFilterChange}
          onChartTypeChange={this.handleFinancialMetricChartTypeChange}
        />
        <KeyMetrics
          loading={keyMetrics.loading}
          data={keyMetrics.data}
          error={keyMetrics.error}
        />
      </div>
    );
  }
}

export default FinancialMetricsView;

import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import {
  Button,
  Col,
  FormGroup,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import VerificationError from 'shared/enums/VerificationError';
import emailSentAnim from 'shared/static/img/animations/mail-sent.gif';

import RegisterEmailSentProps from './RegisterEmailSentProps';

const RegisterEmailSent: ReactFC<RegisterEmailSentProps> = (
  props: RegisterEmailSentProps
) => {
  const { loading, resendEmail, error, expiryTimeString } = props;

  const alreadyConfirmed = Boolean(
    error && error === VerificationError.EMAIL_ALREADY_VERIFIED
  );

  return (
    <>
      <ModalHeader className="d-flex increase-font justify-content-center text-center">
        {intl.get('LBL_CA_WE_SENT_VERIFICATION_CODE')}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs="12" className="d-flex justify-content-center">
            <img alt="Email Sent" src={emailSentAnim} />
          </Col>
          <Col xs="12" className="mb-3 pb-3">
            <p className="form-title w-50 text-center d-block m-auto">
              {intl.get('LBL_CA_SENT_VERIFICATION_DESCRIPTION', {
                expiry: expiryTimeString,
              })}
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto" className="mb-3 pb-3">
            <Button
              color="primary"
              disabled={loading || alreadyConfirmed}
              onClick={resendEmail}
            >
              {intl.get('BTN_CA_RESEND_EMAIL')}
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <FormGroup className="mb-1">
              {alreadyConfirmed && (
                <label className="form-error">
                  {intl.get('ERR_CA_VERIFY_ACCOUNT_EMAIL_ALREADY_VERIFIED')}
                </label>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="text-center mt-3 pt-3">
            <small>
              {intl.get('LBL_CA_NEED_ASSISTANCE')}{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.dreamstartlabs.com/"
              >
                {intl.get('LBL_CA_CONTACT_SUPPORT')}
              </a>
            </small>
          </Col>
        </Row>
      </ModalBody>
    </>
  );
};

export default RegisterEmailSent;

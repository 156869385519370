import { ChangeEvent, Component, createRef } from 'react';

import axios from 'axios';
import { Location } from 'history';
import { isEmpty } from 'lodash';
import { DebounceInput } from 'react-debounce-input';
import * as intl from 'react-intl-universal';
import { Button, Col, Row } from 'reactstrap';

import ApiError from 'api/common/ApiError';
import SurveyFiltersRequest from 'api/surveys/filters/SurveyFiltersRequest';
import RecipientsStatusRequestBody from 'api/surveys/RecipientsStatusRequestBody';
import SendSurveyRequestBody from 'api/surveys/SendSurveyRequestBody';
import SurveysApiInstance from 'api/surveys/SurveysApi';
import ErrorCodes from 'constants/ErrorCodes';
import Constraints from 'constants/forms/Constraints';
import ModulePaths from 'constants/ModulePaths';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import TIMEOUTS from 'constants/Timeouts';
import PermissionUtil from 'helpers/PermissionUtil';
import setPageTitle from 'helpers/setPageTitle';
import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import RouteChangeGuard from 'shared/components/route-change-guard/RouteChangeGuard';
import ScrollToTopOnMount from 'shared/components/scroll-to-top-on-mount/ScrollToTopOnMount';
import EventKey from 'shared/enums/EventKey';
import HTTP_STATUS from 'shared/enums/HttpStatus';
import Status from 'shared/enums/Status';
import SurveyPopoverType from 'shared/enums/SurveyPopoverType';
import SurveyRecipientType from 'shared/enums/SurveyRecipientType';
import SurveysFilter from 'shared/enums/SurveysFilter';
import SurveyType from 'shared/enums/SurveyType';
import { EventBus, Registry } from 'shared/events/EventBus';
import { CustomErrorArgs } from 'shared/types/eventTypes';

import DraftedSurveyGuidePopover from '../../components/drafted-surveys-list/drafted-survey-guide-popover/DraftedSurveyGuidePopover';
import DraftedSurveysList from '../../components/drafted-surveys-list/DraftedSurveysList';
import SurveyChangePopup from '../../components/surveys-popups/survey-change-popup/SurveyChangePopup';
import SurveySendSuccessPopup from '../../components/surveys-popups/survey-send-success-popup/SurveySendSuccessPopup';
import SurveySendWarningPopup from '../../components/surveys-popups/survey-send-warning-popup/SurveySendWarningPopup';
import { SurveySendViewPoint } from '../../components/surveys-popups/survey-send-warning-popup/SurveySendWarningPopupProps';
import {
  allMembers,
  MappedViewpoint,
  memberAges,
  memberEducation,
  memberGenders,
} from '../SurveyConstants';
import styles from './surveysDraftView.module.scss';
import SurveysDraftViewProps from './SurveysDraftViewProps';
import SurveysDraftViewState, {
  FilteredRecipients,
  SurveyChangeViewPoint,
} from './SurveysDraftViewState';

class SurveysDraftView extends Component<
  SurveysDraftViewProps,
  SurveysDraftViewState
> {
  constructor(props: SurveysDraftViewProps) {
    super(props);
    const { appContext } = props;
    const { permissionsData } = appContext;
    const canCreateSurveys = PermissionUtil.Can(
      permissionsData.claims,
      ResourceKeys.SurveysCreate
    );
    const canCollectIDs = PermissionUtil.Can(
      permissionsData.claims,
      ResourceKeys.SurveysCollectAdditionalID
    );
    this.state = {
      status: Status.Idle,
      canCreateSurveys,
      canCollectIDs,
      draftedSurveys: {
        data: [],
        status: Status.Idle,
        searchTerm: '',
        selectedSurveyTypes: {},
        selectedRecipientTypes: {},
        total: 0,
        displaySurveyChangeWarningPopup: false,
        surveyChangeViewpoint: null,
        next: null,
        previous: null,
        page: 0,
        pageSize: Constraints.LazyLoadingPageSizeExtra,
      },
      focusedSurvey: {
        status: Status.Idle,
        countriesFilter: {
          data: [],
          status: Status.Idle,
          error: null,
        },
        projectsFilter: {
          data: [],
          status: Status.Idle,
          error: null,
        },
        facilitatorsFilter: {
          data: [],
          status: Status.Idle,
          error: null,
        },
        selectedFilters: {
          countries: [],
          projects: [],
          facilitators: [],
          groupStatus: [],
          groupAge: [],
          allMembers: [],
          memberGender: [],
          memberAge: [],
          memberEducation: [],
        },
        appliedFilters: {
          data: {
            filteredRecipients: [],
            totalGroupCount: 0,
            totalMemberCount: 0,
          },
          status: Status.Idle,
          error: null,
        },
        popupDetails: {
          sendCount: 0,
          excludedMemberCount: 0,
          excludedGroups: [],
          isDuplicateSurvey: false,
          surveyType: SurveyType.Survey,
          recipientType: SurveyRecipientType.Groups,
          existingCollectIds: [],
        },
        finalRecipients: [],
        lastUpdatedFilter: null,
        displayWarningPopup: false,
        displaySuccessPopup: false,
        isSurveySendClicked: false,
        isItemExpanded: false,
        expandedRowId: '',
        tempRowId: '',
        tempRowExpanded: false,
        collapsingOwnSurveyWithFilters: false,
        isFilterSelectionDone: false,
      },
      surveyStats: { item: { tooltipStatus: [] } },
    };
  }

  componentDidMount(): void {
    const { appContext } = this.props;
    appContext.hideErrorToast();
    setPageTitle(intl.get('LBL_SURVEYS_CREATE_TITLE'));
    window.scrollTo(0, 0);
    this.loadDraftedSurveysPaginated(false, true);
    this.fetchCountriesForFilter();
    this.fetchGroupsStatuses();
    this.countryRegistry = EventBus.getInstance().register(
      SurveysFilter.Countries,
      (eventArgs) => {
        this.setFiltersChanged(SurveysFilter.Countries, eventArgs);
      }
    );
    this.projectsRegistry = EventBus.getInstance().register(
      SurveysFilter.Projects,
      (eventArgs) => {
        this.setFiltersChanged(SurveysFilter.Projects, eventArgs);
      }
    );
    this.facilitatorsRegistry = EventBus.getInstance().register(
      SurveysFilter.Facilitators,
      (eventArgs) => {
        this.setFiltersChanged(SurveysFilter.Facilitators, eventArgs);
      }
    );
    this.groupStatusRegistry = EventBus.getInstance().register(
      SurveysFilter.GroupStatus,
      (eventArgs) => {
        this.setFiltersChanged(SurveysFilter.GroupStatus, eventArgs);
      }
    );
    this.groupAgeRegistry = EventBus.getInstance().register(
      SurveysFilter.GroupAge,
      (eventArgs) => {
        this.setFiltersChanged(SurveysFilter.GroupAge, eventArgs);
      }
    );
    this.allMemberRegistry = EventBus.getInstance().register(
      SurveysFilter.AllMembers,
      (eventArgs) => {
        this.setFiltersChanged(SurveysFilter.AllMembers, eventArgs);
      }
    );
    this.memberGenderRegistry = EventBus.getInstance().register(
      SurveysFilter.MemberGender,
      (eventArgs) => {
        this.setFiltersChanged(SurveysFilter.MemberGender, eventArgs);
      }
    );
    this.memberAgeRegistry = EventBus.getInstance().register(
      SurveysFilter.MemberAge,
      (eventArgs) => {
        this.setFiltersChanged(SurveysFilter.MemberAge, eventArgs);
      }
    );
    this.memberEducationRegistry = EventBus.getInstance().register(
      SurveysFilter.MemberEducation,
      (eventArgs) => {
        this.setFiltersChanged(SurveysFilter.MemberEducation, eventArgs);
      }
    );
  }

  componentDidUpdate(
    prevProps: SurveysDraftViewProps,
    prevState: SurveysDraftViewState
  ): void {
    const {
      appContext: { groupStatusData, setErrorToastText },
    } = this.props;
    const {
      focusedSurvey: { isFilterSelectionDone, expandedRowId },
    } = this.state;
    if (
      groupStatusData.status === Status.Error &&
      prevProps.appContext.groupStatusData.status === Status.Loading
    ) {
      setErrorToastText(
        intl.get('ERR_TOAST_SURVEYS_GROUP_STATUS_FILTER_ERRORS')
      );
    }

    if (
      prevState.focusedSurvey.isFilterSelectionDone !== isFilterSelectionDone &&
      !isEmpty(expandedRowId)
    ) {
      this.fetchSelectedRecipients();
    }
  }

  componentWillUnmount(): void {
    if (this.countryRegistry) this.countryRegistry.unregister();
    if (this.projectsRegistry) this.projectsRegistry.unregister();
    if (this.facilitatorsRegistry) this.facilitatorsRegistry.unregister();
    if (this.groupStatusRegistry) this.groupStatusRegistry.unregister();
    if (this.groupAgeRegistry) this.groupAgeRegistry.unregister();
    if (this.allMemberRegistry) this.allMemberRegistry.unregister();
    if (this.memberGenderRegistry) this.memberGenderRegistry.unregister();
    if (this.memberAgeRegistry) this.memberAgeRegistry.unregister();
    if (this.memberEducationRegistry) this.memberEducationRegistry.unregister();
    if (this.sendSurveyUndoTimeout) clearTimeout(this.sendSurveyUndoTimeout);

    this.source.cancel();
  }

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  searchInputRef = createRef<HTMLInputElement>();

  createSurveyGuideRef = createRef<HTMLDivElement>();

  private countryRegistry: Registry | undefined;

  private projectsRegistry: Registry | undefined;

  private facilitatorsRegistry: Registry | undefined;

  private groupStatusRegistry: Registry | undefined;

  private groupAgeRegistry: Registry | undefined;

  private allMemberRegistry: Registry | undefined;

  private memberGenderRegistry: Registry | undefined;

  private memberAgeRegistry: Registry | undefined;

  private memberEducationRegistry: Registry | undefined;

  private sendSurveyUndoTimeout?: NodeJS.Timeout;

  /**
   * Set/Update component state in the root slice
   * @param updateState Updated state as an object
   */
  setStateAttribute = (
    updateState: Partial<SurveysDraftViewState>,
    callback?: () => void
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        ...updateState,
      }),
      callback
    );

  /**
   * Set/Update component state in the draftedSurveys slice
   * @param updateState Updated state as an object
   */
  setDraftedSurveysStateAttribute = (
    updateState: Partial<SurveysDraftViewState['draftedSurveys']>,
    callback?: () => void
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        draftedSurveys: { ...state.draftedSurveys, ...updateState },
      }),
      callback
    );

  /**
   * Set/Update component state in the focusedSurvey slice
   * @param updateState Updated state as an object
   */
  setFocusedSurveyStateAttribute = (
    updateState: Partial<SurveysDraftViewState['focusedSurvey']>,
    callback?: () => void
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        focusedSurvey: { ...state.focusedSurvey, ...updateState },
      }),
      callback
    );

  /**
   * Set/Update component state in the countriesFilter slice
   * @param updateState Updated state as an object
   */
  setCountriesFilterStateAttribute = (
    updateState: Partial<
      SurveysDraftViewState['focusedSurvey']['countriesFilter']
    >,
    callback?: () => void
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        focusedSurvey: {
          ...state.focusedSurvey,
          countriesFilter: {
            ...state.focusedSurvey.countriesFilter,
            ...updateState,
          },
        },
      }),
      callback
    );

  /**
   * Set/Update component state in the projectsFilter slice
   * @param updateState Updated state as an object
   */
  setProjectsFilterStateAttribute = (
    updateState: Partial<
      SurveysDraftViewState['focusedSurvey']['projectsFilter']
    >,
    callback?: () => void
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        focusedSurvey: {
          ...state.focusedSurvey,
          projectsFilter: {
            ...state.focusedSurvey.projectsFilter,
            ...updateState,
          },
        },
      }),
      callback
    );

  /**
   * Set/Update component state in the facilitatorsFilter slice
   * @param updateState Updated state as an object
   */
  setFacilitatorsFilterStateAttribute = (
    updateState: Partial<
      SurveysDraftViewState['focusedSurvey']['facilitatorsFilter']
    >,
    callback?: () => void
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        focusedSurvey: {
          ...state.focusedSurvey,
          facilitatorsFilter: {
            ...state.focusedSurvey.facilitatorsFilter,
            ...updateState,
          },
        },
      }),
      callback
    );

  /**
   * Set/Update component state in the selectedFilters slice
   * @param updateState Updated state as an object
   */
  setSelectedFiltersStateAttribute = (
    updateState: Partial<
      SurveysDraftViewState['focusedSurvey']['selectedFilters']
    >,
    callback?: () => void
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        focusedSurvey: {
          ...state.focusedSurvey,
          selectedFilters: {
            ...state.focusedSurvey.selectedFilters,
            ...updateState,
          },
        },
      }),
      callback
    );

  /**
   * Set/Update component state in the appliedFilters slice
   * @param groupFilters State object
   * @param filteredGroups State object
   */
  setAppliedFiltersStateAttribute = (
    appliedFilters: Partial<
      SurveysDraftViewState['focusedSurvey']['appliedFilters']
    >,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback?: () => void
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        focusedSurvey: {
          ...state.focusedSurvey,
          appliedFilters: {
            ...state.focusedSurvey.appliedFilters,
            ...appliedFilters,
          },
        },
      }),
      callback
    );

  /**
   * Handle filter change event
   *
   * @param filter Updated filter
   * @param eventArgs Event attributes
   */
  setFiltersChanged = (
    filter: SurveysFilter,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventArgs: any
  ): void => {
    const {
      focusedSurvey: { selectedFilters },
    } = this.state;

    const { event, label } = eventArgs;
    const { checked, value } = event.target;

    this.setFocusedSurveyStateAttribute({
      lastUpdatedFilter: filter,
    });
    const filterTyped = filter as string;

    if (filter === SurveysFilter.AllMembers) {
      if (checked) {
        this.setSelectedFiltersStateAttribute(
          {
            allMembers,
            memberGender: memberGenders,
            memberAge: memberAges,
            memberEducation,
          },
          this.onFiltersStateUpdatedCallback
        );
      } else {
        this.resetMemberFilters(this.onFiltersStateUpdatedCallback);
      }
    } else if (checked) {
      this.setSelectedFiltersStateAttribute(
        {
          [filterTyped]: [...selectedFilters[filterTyped], { value, label }],
        },
        this.onFiltersStateUpdatedCallback
      );
    } else {
      const updatedFilter = selectedFilters[filterTyped].filter(
        (selected) => selected.value !== value
      );
      this.setSelectedFiltersStateAttribute(
        {
          [filterTyped]: [...updatedFilter],
        },
        this.onFiltersStateUpdatedCallback
      );
    }
  };

  /**
   * Callback to be fired after setting selected filters state
   */
  // eslint-disable-next-line react/sort-comp
  onFiltersStateUpdatedCallback = (): void => {
    const {
      focusedSurvey: {
        selectedFilters,
        lastUpdatedFilter,
        isFilterSelectionDone,
      },
    } = this.state;
    if (lastUpdatedFilter === SurveysFilter.Countries) {
      // If no countries are selected, reset all member filters
      if (isEmpty(selectedFilters.countries)) {
        this.resetMemberFilters();
        this.resetGroupFilters();

        this.setProjectsFilterStateAttribute({
          data: [],
          status: Status.Idle,
          error: null,
        });
        this.setFacilitatorsFilterStateAttribute(
          {
            data: [],
            status: Status.Idle,
            error: null,
          },
          () =>
            this.setFocusedSurveyStateAttribute({
              isFilterSelectionDone: !isFilterSelectionDone,
            })
        );
      } else {
        this.fetchProjectsForFilter(
          selectedFilters[SurveysFilter.Countries as string]
        );
      }
    } else if (lastUpdatedFilter === SurveysFilter.Projects) {
      const selectedProjects = selectedFilters[SurveysFilter.Projects];
      if (isEmpty(selectedProjects)) {
        this.setFacilitatorsFilterStateAttribute({
          data: [],
          status: Status.Idle,
          error: null,
        });
        this.setSelectedFiltersStateAttribute({ facilitators: [] }, () =>
          this.setFocusedSurveyStateAttribute({
            isFilterSelectionDone: !isFilterSelectionDone,
          })
        );
      } else {
        this.fetchFacilitatorsForFilter(
          selectedFilters[SurveysFilter.Projects as string]
        ).then(() =>
          this.setFocusedSurveyStateAttribute({
            isFilterSelectionDone: !isFilterSelectionDone,
          })
        );
      }
    } else if (
      !isEmpty(selectedFilters.allMembers) &&
      (lastUpdatedFilter === SurveysFilter.MemberGender ||
        lastUpdatedFilter === SurveysFilter.MemberAge ||
        lastUpdatedFilter === SurveysFilter.MemberEducation)
    ) {
      // Unselect "All Members" option if at least one other member filter is unchecked
      this.setSelectedFiltersStateAttribute(
        {
          allMembers: [],
        },
        () =>
          this.setFocusedSurveyStateAttribute({
            isFilterSelectionDone: !isFilterSelectionDone,
          })
      );
    } else if (
      isEmpty(selectedFilters.allMembers) &&
      (lastUpdatedFilter === SurveysFilter.MemberGender ||
        lastUpdatedFilter === SurveysFilter.MemberAge ||
        lastUpdatedFilter === SurveysFilter.MemberEducation) &&
      selectedFilters.memberAge.length >= 4 &&
      selectedFilters.memberGender.length >= 2 &&
      selectedFilters.memberEducation.length >= 4
    ) {
      // Select "All Members" option if all member filters are checked individually
      this.setSelectedFiltersStateAttribute(
        {
          allMembers: [...allMembers],
        },
        () =>
          this.setFocusedSurveyStateAttribute({
            isFilterSelectionDone: !isFilterSelectionDone,
          })
      );
    } else {
      this.setFocusedSurveyStateAttribute({
        isFilterSelectionDone: !isFilterSelectionDone,
      });
    }
  };

  /**
   * Reset group filters
   */
  resetGroupFilters = (): void => {
    this.setSelectedFiltersStateAttribute({
      projects: [],
      facilitators: [],
      groupStatus: [],
      groupAge: [],
    });
  };

  /**
   * Reset member filters
   */
  resetMemberFilters = (callback?: () => void): void => {
    this.setSelectedFiltersStateAttribute(
      {
        allMembers: [],
        memberGender: [],
        memberAge: [],
        memberEducation: [],
      },
      callback
    );
  };

  /**
   * Fetch paginated drafted surveys data
   *
   * @param updateSilent Whether to update drafted surveys silently
   * @param resetScroll Whether to reset scroll to initial position
   */
  loadDraftedSurveysPaginated = async (
    isNext: boolean,
    isInitial = false
  ): Promise<void> => {
    const { draftedSurveys } = this.state;
    try {
      this.setDraftedSurveysStateAttribute({
        status: Status.Loading,
      });
      let url: string | null = '';
      if (isInitial) {
        url = null;
      } else {
        url = isNext ? draftedSurveys.next : draftedSurveys.previous;
      }
      const result = await SurveysApiInstance.GetDraftedSurveysData(
        url,
        draftedSurveys.searchTerm,
        draftedSurveys.pageSize,
        this.source
      );
      if (result) {
        const { items, pagination } = result;

        this.setDraftedSurveysStateAttribute({
          data: items,
          status: Status.Success,
          total: pagination.total,
          next: pagination.next,
          previous: pagination.previous,
          page: pagination.page,
          pageSize: pagination.pageSize,
        });

        this.fetchSurveyStats();
      }
    } catch (error) {
      this.setDraftedSurveysStateAttribute({
        status: Status.Error,
        total: 0,
        next: null,
        previous: null,
        page: 0,
        pageSize: Constraints.LazyLoadingPageSizeExtra,
      });

      this.handleAPIErrors(error, intl.get('ERR_TOAST_SURVEYS_ERRORS'));
    }
  };

  /**
   * Fetch drafted surveys data for the next page
   */
  loadNextPage = (): Promise<void> => this.loadDraftedSurveysPaginated(true);

  /**
   * Fetch drafted surveys data for the next page
   */
  loadPreviousPage = (): Promise<void> =>
    this.loadDraftedSurveysPaginated(false);

  /**
   * Fetch surveys stats data
   * Ex: tooltips data
   */
  fetchSurveyStats = async (): Promise<void> => {
    try {
      this.setStateAttribute({
        status: Status.Loading,
      });
      const stats = await SurveysApiInstance.GetSurveysStatsData(this.source);
      this.setStateAttribute({ surveyStats: stats, status: Status.Success });
    } catch (error) {
      this.setStateAttribute({
        status: Status.Error,
      });
      this.handleAPIErrors(error, intl.get('ERR_TOAST_SURVEYS_ERRORS'));
    }
  };

  /**
   * Fetch countries to populate countries filter
   */
  fetchCountriesForFilter = async (): Promise<void> => {
    this.setCountriesFilterStateAttribute({
      status: Status.Loading,
    });
    try {
      const result = await SurveysApiInstance.GetCountries(this.source);
      const countriesFormatted = result.items.map((country) => ({
        value: country.code,
        label: country.name,
      }));
      this.setCountriesFilterStateAttribute({
        data: countriesFormatted,
        status: Status.Success,
      });
    } catch (error) {
      this.setCountriesFilterStateAttribute({
        data: [],
        status: Status.Error,
      });

      if (error instanceof ApiError) {
        this.setCountriesFilterStateAttribute({
          error,
        });
      }
      this.handleAPIErrors(
        error,
        intl.get('ERR_TOAST_SURVEYS_COUNTRY_FILTER_ERRORS')
      );
    }
  };

  /**
   * Fetch projects to populate projects filter
   * @param countryCodes Country codes to filter by
   */
  fetchProjectsForFilter = async (
    countryCodes: Array<SelectFieldOption>
  ): Promise<void> => {
    const {
      focusedSurvey: { selectedFilters, isFilterSelectionDone },
    } = this.state;

    this.setProjectsFilterStateAttribute({ status: Status.Loading });
    try {
      const countryValues = countryCodes.map((country) => country.value);
      const result = await SurveysApiInstance.GetProjectsForCountry(
        countryValues,
        this.source
      );
      const projectsFormatted = result.items.map((project) => ({
        value: project.projectId,
        label: project.name,
      }));
      this.setProjectsFilterStateAttribute({
        data: projectsFormatted,
        status: Status.Success,
      });
      const filteredProjects = selectedFilters.projects.filter((project) =>
        projectsFormatted.find(
          (updatedProject) => project.value === updatedProject.value
        )
      );

      this.setSelectedFiltersStateAttribute(
        { projects: filteredProjects },
        () => {
          if (isEmpty(filteredProjects)) {
            this.setFacilitatorsFilterStateAttribute({
              data: [],
              status: Status.Idle,
              error: null,
            });
            this.setSelectedFiltersStateAttribute(
              {
                facilitators: [],
              },
              () =>
                this.setFocusedSurveyStateAttribute({
                  isFilterSelectionDone: !isFilterSelectionDone,
                })
            );
          } else {
            this.fetchFacilitatorsForFilter(filteredProjects).then(() =>
              this.setFocusedSurveyStateAttribute({
                isFilterSelectionDone: !isFilterSelectionDone,
              })
            );
          }
        }
      );
    } catch (error) {
      this.setProjectsFilterStateAttribute({
        data: [],
        status: Status.Error,
      });

      if (error instanceof ApiError) {
        this.setProjectsFilterStateAttribute({
          error,
        });
      }
      this.handleAPIErrors(
        error,
        intl.get('ERR_TOAST_SURVEYS_PROJECT_FILTER_ERRORS')
      );
    }
  };

  /**
   * Fetch facilitators to populate facilitators filter
   * @param projectIds Project IDs to filter by
   */
  fetchFacilitatorsForFilter = async (
    projectIds: Array<SelectFieldOption>
  ): Promise<void> => {
    const {
      focusedSurvey: { selectedFilters },
    } = this.state;
    this.setFacilitatorsFilterStateAttribute({ status: Status.Loading });
    try {
      const projectValues = projectIds.map((project) => project.value);
      const result = await SurveysApiInstance.GetFacilitatorsForProjects(
        projectValues,
        this.source
      );
      const facilitatorsFormatted = result.items.map((facilitator) => ({
        value: facilitator.id,
        label: facilitator.name,
      }));
      this.setFacilitatorsFilterStateAttribute({
        data: facilitatorsFormatted,
        status: Status.Success,
      });
      this.setSelectedFiltersStateAttribute({
        facilitators: selectedFilters.facilitators.filter((facilitator) =>
          facilitatorsFormatted.find(
            (newFacilitator) => facilitator.value === newFacilitator.value
          )
        ),
      });
    } catch (error) {
      this.setFacilitatorsFilterStateAttribute({
        data: [],
        status: Status.Error,
      });
      if (error instanceof ApiError) {
        this.setFacilitatorsFilterStateAttribute({
          error,
        });
      }
      this.handleAPIErrors(
        error,
        intl.get('ERR_TOAST_SURVEYS_FACILITATOR_FILTER_ERRORS')
      );
    }
  };

  /**
   * Fetch group statuses
   */
  fetchGroupsStatuses = (): void => {
    const {
      appContext: { getGroupStatuses },
    } = this.props;

    getGroupStatuses();
  };

  /**
   * Fetch selected recipients
   */
  fetchSelectedRecipients = async (): Promise<void> => {
    const { appContext } = this.props;
    this.setAppliedFiltersStateAttribute({ status: Status.Loading });

    try {
      const surveyFilters = this.getMappedFilterData();
      const response = await SurveysApiInstance.GetSurveyRecipients(
        surveyFilters,
        this.source
      );

      this.setAppliedFiltersStateAttribute({ status: Status.Success });

      this.setAppliedFiltersStateAttribute({
        data: response,
        status: Status.Success,
        error: null,
      });
    } catch (error) {
      this.setAppliedFiltersStateAttribute({ status: Status.Error });
      if (error instanceof ApiError) {
        this.setAppliedFiltersStateAttribute({
          error,
        });
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_SURVEYS_ERRORS',
            genericCallback: () => undefined,
            customCallback: this.fetchFiltersOnAccessError,
          } as CustomErrorArgs);
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_SURVEYS_ERRORS'));
      }
    }
  };

  /**
   * Handle survey sending API
   */
  sendSurvey = async (): Promise<void> => {
    const { history, appContext } = this.props;
    const {
      focusedSurvey: { finalRecipients, expandedRowId },
      draftedSurveys: { selectedRecipientTypes, selectedSurveyTypes },
    } = this.state;
    this.setFocusedSurveyStateAttribute({ status: Status.Loading });

    try {
      const surveyFilters = this.getMappedFilterData();
      const sendSurveyRequest: SendSurveyRequestBody = {
        id: expandedRowId,
        filters: surveyFilters,
        recipients: finalRecipients,
        recipientsType: selectedRecipientTypes[
          expandedRowId
        ] as SurveyRecipientType,
        surveyType: selectedSurveyTypes[expandedRowId] as SurveyType,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        viewPoint: MappedViewpoint.get(
          selectedRecipientTypes[expandedRowId] as SurveyRecipientType
        )!,
      };

      await SurveysApiInstance.SendSurvey(sendSurveyRequest, this.source);
      this.setFocusedSurveyStateAttribute({ status: Status.Success });
      this.handleResetFocusedSurvey((): void =>
        history.push(
          `${ModulePaths.SurveysPath}${ModulePaths.SurveysHistoryPath}`,
          {
            recipientType: selectedRecipientTypes[
              expandedRowId
            ] as SurveyRecipientType,
            surveyType: selectedSurveyTypes[expandedRowId] as SurveyType,
          }
        )
      );
    } catch (error) {
      this.setFocusedSurveyStateAttribute({ status: Status.Error });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_SURVEYS_ERRORS',
            genericCallback: () => undefined,
            customCallback: this.fetchFiltersOnAccessError,
          } as CustomErrorArgs);
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_SURVEYS_ERRORS'));
      }
    }
  };

  /**
   * Get all filters mapped data
   * @returns {SurveyFiltersRequest}  Survey filter data
   */
  getMappedFilterData = (): SurveyFiltersRequest => {
    const {
      focusedSurvey: {
        selectedFilters: {
          countries,
          projects,
          facilitators,
          groupStatus,
          groupAge,
          memberAge,
          memberEducation: MemberEducation,
          memberGender,
          allMembers: AllMembers,
        },
        expandedRowId,
      },
      draftedSurveys: { selectedRecipientTypes },
    } = this.state;

    const countryValues = countries.map((country) => country.value);
    const projectValues = projects.map((project) => project.value);
    const facilitatorValues = facilitators.map(
      (facilitator) => facilitator.value
    );
    const groupStatusValues = groupStatus.map((status) => status.value);
    const groupAgeValues = groupAge.map((age) => age.value);
    let memberAgeValues = memberAge.map((age) => age.value);
    let memberGenderValues = memberGender.map((gender) => gender.value);
    let memberEducationValues = MemberEducation.map(
      (education) => education.value
    );
    const allMembersSelected = AllMembers.length > 0;
    if (allMembersSelected) {
      memberAgeValues = [];
      memberGenderValues = [];
      memberEducationValues = [];
    }

    return {
      countries: countryValues,
      facilitatorIds: facilitatorValues,
      groupAges: groupAgeValues,
      groupStatuses: groupStatusValues,
      projectIds: projectValues,
      educationLevels: memberEducationValues,
      gender: memberGenderValues,
      memberAges: memberAgeValues,
      allMember: allMembersSelected,
      recipientsType: selectedRecipientTypes[
        expandedRowId
      ] as SurveyRecipientType,
    };
  };

  /**
   * Handle API errors
   */
  handleAPIErrors = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any,
    errorMessage?: string
  ): void => {
    const { appContext } = this.props;
    if (error.status !== HTTP_STATUS.FORBIDDEN) {
      appContext.setErrorToastText(errorMessage);
    }
  };

  /**
   * Validate if any filters are selected
   */
  handleCheckFiltersSelected = (): boolean => {
    const {
      focusedSurvey: { selectedFilters },
    } = this.state;
    const filters = Object.keys(selectedFilters);
    const filtersSelected = filters.some(
      (filter) => !isEmpty(selectedFilters[filter])
    );
    return filtersSelected;
  };

  /**
   * Event handler for 'Get New Surveys' button
   */
  onGetNewSurveysClicked = (): void => {
    const filtersSelected = this.handleCheckFiltersSelected();
    if (filtersSelected) {
      this.setDraftedSurveysStateAttribute({
        displaySurveyChangeWarningPopup: true,
        surveyChangeViewpoint: SurveyChangeViewPoint.GetNewSurveysButton,
      });
      this.setFocusedSurveyStateAttribute({
        collapsingOwnSurveyWithFilters: true,
      });
    } else {
      this.setDraftedSurveysStateAttribute({
        displaySurveyChangeWarningPopup: false,
        surveyChangeViewpoint: null,
      });
      this.handleRefreshDraftedSurveys();
    }
  };

  /**
   * Refresh drafted surveys list data
   */
  handleRefreshDraftedSurveys = async (): Promise<void> => {
    try {
      this.setDraftedSurveysStateAttribute({
        status: Status.Loading,
        searchTerm: '',
      });
      // Reset focused survey on refresh
      this.resetFocusedSurveyAndCollapse();
      const result = await SurveysApiInstance.FetchLatestSurveys(this.source);
      if (result) {
        await this.loadDraftedSurveysPaginated(false, true);
        this.setDraftedSurveysStateAttribute({
          status: Status.Success,
        });
      }
    } catch (error) {
      this.setDraftedSurveysStateAttribute({
        status: Status.Error,
      });
      this.handleAPIErrors(error, intl.get('ERR_TOAST_SURVEYS_ERRORS'));
    }
  };

  /**
   * Handle search term change event
   * @param searchTerm Current search value
   */
  handleSearchTermChange = (searchTerm = ''): void => {
    // Reset focused survey on search term change
    this.resetFocusedSurveyAndCollapse();
    this.setDraftedSurveysStateAttribute({ searchTerm }, () => {
      this.loadDraftedSurveysPaginated(false, true);
    });
  };

  /**
   * Resets the focused survey and also collapses the row
   */
  resetFocusedSurveyAndCollapse = (): void => {
    this.setFocusedSurveyStateAttribute(
      {
        isItemExpanded: false,
        expandedRowId: '',
      },
      this.handleResetFocusedSurvey
    );
  };

  /**
   * Handle survey type change event
   * @param e Change event
   * @param surveyId ID of the focused survey
   */
  handleSurveyTypeChange = (
    e: ChangeEvent<HTMLInputElement>,
    surveyId: string,
    close: () => void
  ): void => {
    const {
      draftedSurveys: { selectedSurveyTypes },
      focusedSurvey: { isFilterSelectionDone },
    } = this.state;
    const { value } = e.target;
    const interactedSurveys = Object.keys(selectedSurveyTypes);
    const surveyRowExists = interactedSurveys.includes(surveyId);
    let newSelectedSurveyTypes = { ...selectedSurveyTypes };
    if (surveyRowExists) {
      newSelectedSurveyTypes[surveyId] = value;
    } else {
      newSelectedSurveyTypes = {
        ...newSelectedSurveyTypes,
        [surveyId]: value,
      };
    }
    /* If selected value is 'Collect ID', reset the Group Age 
    filter and re-fetch recipients from BE */
    if (value === (SurveyType.CollectId as string)) {
      this.setSelectedFiltersStateAttribute(
        {
          groupAge: [],
        },
        () =>
          this.setFocusedSurveyStateAttribute({
            isFilterSelectionDone: !isFilterSelectionDone,
          })
      );
    }
    this.setDraftedSurveysStateAttribute(
      {
        selectedSurveyTypes: newSelectedSurveyTypes,
      },
      close
    );
  };

  /**
   * Handle recipient type change event
   * @param e Change event
   * @param surveyId ID of the focused survey
   */
  handleRecipientTypeChange = (
    e: ChangeEvent<HTMLInputElement>,
    surveyId: string,
    close: () => void
  ): void => {
    const {
      draftedSurveys: { selectedRecipientTypes },
      focusedSurvey: { isFilterSelectionDone },
    } = this.state;
    const { value } = e.target;
    const interactedSurveys = Object.keys(selectedRecipientTypes);
    const surveyRowExists = interactedSurveys.includes(surveyId);
    let newSelectedRecipientTypes = { ...selectedRecipientTypes };
    if (surveyRowExists) {
      newSelectedRecipientTypes[surveyId] = value;
    } else {
      newSelectedRecipientTypes = {
        ...newSelectedRecipientTypes,
        [surveyId]: value,
      };
    }

    /* Reset all member filters and re-fetch recipients from BE */
    this.resetMemberFilters(() => {
      this.setFocusedSurveyStateAttribute({
        isFilterSelectionDone: !isFilterSelectionDone,
      });
    });

    this.setDraftedSurveysStateAttribute(
      {
        selectedRecipientTypes: newSelectedRecipientTypes,
      },
      close
    );
  };

  /**
   * Fetch filters on access error
   */
  fetchFiltersOnAccessError = (errorCode: number): void => {
    const {
      focusedSurvey: {
        selectedFilters: { countries, projects },
      },
    } = this.state;
    switch (errorCode) {
      case ErrorCodes.InvalidCountryAccess:
        this.fetchCountriesForFilter();
        break;
      case ErrorCodes.InvalidProjectsAccess:
        this.fetchProjectsForFilter(countries);
        break;
      case ErrorCodes.InvalidFacilitatorAccess:
        this.fetchFacilitatorsForFilter(projects);
        break;
      default:
        break;
    }
  };

  /**
   * Get send count for recipient type
   * @returns {number} Send count
   */
  getSendCount = (
    recipientType: SurveyRecipientType,
    recipients: Array<FilteredRecipients>
  ): number => {
    let count = 0;
    if (recipientType === SurveyRecipientType.Groups) {
      count = recipients.length;
    } else {
      recipients.forEach(({ members }) => {
        count += members.length;
      });
    }
    return count;
  };

  /**
   * Handles sending the survey/collect ID request
   * @param surveyType Type of survey
   * @param recipientType Type of recipient
   */
  handleSendSurvey = async (
    surveyType: SurveyType | null,
    recipientType: SurveyRecipientType | null
  ): Promise<void> => {
    const {
      focusedSurvey: { appliedFilters },
    } = this.state;
    const { appContext } = this.props;

    try {
      if (surveyType !== null && recipientType !== null) {
        this.setFocusedSurveyStateAttribute({ status: Status.Loading });
        const surveyFilters = this.getMappedFilterData();
        const recipients: RecipientsStatusRequestBody = {
          filters: surveyFilters,
          recipients: appliedFilters.data.filteredRecipients,
          surveyType,
        };
        const result = await SurveysApiInstance.FetchRecipientsStatus(
          recipients,
          this.source
        );
        if (result) {
          const {
            excludedMemberCount,
            excludedGroups,
            isDuplicateSurvey,
            finalRecipients,
            existingCollectIds,
          } = result;
          const sendCount = this.getSendCount(recipientType, finalRecipients);
          this.setFocusedSurveyStateAttribute({
            status: Status.Success,
            isSurveySendClicked: true,
            popupDetails: {
              sendCount,
              excludedMemberCount,
              excludedGroups,
              isDuplicateSurvey,
              surveyType,
              recipientType,
              existingCollectIds,
            },
            finalRecipients,
            displayWarningPopup: true,
            displaySuccessPopup: false,
          });
        }
      } else {
        throw new Error(intl.get('ERR_TOAST_SURVEYS_ERRORS'));
      }
    } catch (error) {
      this.setFocusedSurveyStateAttribute({
        status: Status.Error,
        isSurveySendClicked: false,
        popupDetails: {
          sendCount: 0,
          excludedMemberCount: 0,
          excludedGroups: [],
          isDuplicateSurvey: false,
          surveyType: SurveyType.Survey,
          recipientType: SurveyRecipientType.Groups,
          existingCollectIds: [],
        },
        finalRecipients: [],
        displayWarningPopup: false,
        displaySuccessPopup: false,
      });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_SURVEYS_ERRORS',
            genericCallback: () => undefined,
            customCallback: this.fetchFiltersOnAccessError,
          } as CustomErrorArgs);
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_SURVEYS_ERRORS'));
      }
    }
  };

  /**
   * Reset focused survey values in state
   */
  handleResetFocusedSurvey = (callback?: () => void): void => {
    this.setFocusedSurveyStateAttribute(
      {
        status: Status.Idle,
        projectsFilter: {
          data: [],
          status: Status.Idle,
          error: null,
        },
        facilitatorsFilter: {
          data: [],
          status: Status.Idle,
          error: null,
        },
        selectedFilters: {
          countries: [],
          projects: [],
          facilitators: [],
          groupStatus: [],
          groupAge: [],
          allMembers: [],
          memberGender: [],
          memberAge: [],
          memberEducation: [],
        },
        appliedFilters: {
          data: {
            filteredRecipients: [],
            totalGroupCount: 0,
            totalMemberCount: 0,
          },
          status: Status.Idle,
          error: null,
        },
        popupDetails: {
          sendCount: 0,
          excludedMemberCount: 0,
          excludedGroups: [],
          isDuplicateSurvey: false,
          surveyType: SurveyType.Survey,
          recipientType: SurveyRecipientType.Groups,
          existingCollectIds: [],
        },
        finalRecipients: [],
        lastUpdatedFilter: null,
        isSurveySendClicked: false,
        displayWarningPopup: false,
        displaySuccessPopup: false,
        isFilterSelectionDone: false,
        tempRowId: '',
        tempRowExpanded: false,
      },
      callback
    );
  };

  /**
   * Handle table page size change
   */
  handlePageSizeChange = (size: number): void => {
    // Reset focused survey and collapse row on page size change
    this.resetFocusedSurveyAndCollapse();
    this.setDraftedSurveysStateAttribute({ pageSize: size }, () => {
      this.loadDraftedSurveysPaginated(false, true);
    });
  };

  /**
   * Handles on continue event for the survey change warning popup
   */
  handleOnContinueSurveyChangeWarning = (): void => {
    const {
      focusedSurvey: { tempRowId, tempRowExpanded },
      draftedSurveys: { surveyChangeViewpoint },
    } = this.state;
    this.setDraftedSurveysStateAttribute({
      displaySurveyChangeWarningPopup: false,
    });
    if (surveyChangeViewpoint === SurveyChangeViewPoint.NewSurvey) {
      this.setFocusedSurveyStateAttribute(
        {
          isItemExpanded: tempRowExpanded,
          expandedRowId: tempRowId,
        },
        /* Reset focused survey data if any row gets collapsed/expanded */
        this.handleResetFocusedSurvey
      );
      this.setDraftedSurveysStateAttribute({ surveyChangeViewpoint: null });
    }
    if (surveyChangeViewpoint === SurveyChangeViewPoint.GetNewSurveysButton) {
      this.handleRefreshDraftedSurveys();
      this.setFocusedSurveyStateAttribute({
        collapsingOwnSurveyWithFilters: false,
      });
      this.setDraftedSurveysStateAttribute({ surveyChangeViewpoint: null });
    }
  };

  /**
   * Handle warning popup continue
   */
  handleOnWarningContinue = (): void => {
    this.setFocusedSurveyStateAttribute({
      displayWarningPopup: false,
      displaySuccessPopup: true,
    });
    this.handleSurveySendingTimeout();
  };

  /**
   * Handle survey sending timeout
   */
  handleSurveySendingTimeout = (): void => {
    this.sendSurveyUndoTimeout = setTimeout(() => {
      this.sendSurvey();
      this.setFocusedSurveyStateAttribute({ displaySuccessPopup: false });
    }, TIMEOUTS.SEND_SURVEY_UNDO_TIMEOUT);
  };

  /**
   * Handles on cancel event for the survey change warning popup
   */
  handleOnCancelSurveyChangeWarning = (): void => {
    this.setDraftedSurveysStateAttribute({
      displaySurveyChangeWarningPopup: false,
      surveyChangeViewpoint: null,
    });
    this.setFocusedSurveyStateAttribute({
      tempRowId: '',
      tempRowExpanded: false,
    });
  };

  /**
   * Handles close success popup
   */
  handleSurveySendingInterrupt = (): void => {
    if (this.sendSurveyUndoTimeout) clearTimeout(this.sendSurveyUndoTimeout);
    this.setFocusedSurveyStateAttribute({
      displaySuccessPopup: false,
      isSurveySendClicked: false,
    });
  };

  /**
   * Handles survey item collapsed
   */
  handleSurveyItemExpanded = (isExpanded: boolean, rowId: string): void => {
    const {
      focusedSurvey: { expandedRowId },
    } = this.state;
    const filtersSelected = this.handleCheckFiltersSelected();
    /* If any filters are selected */
    if (filtersSelected) {
      /* Display survey change warning */
      this.setDraftedSurveysStateAttribute({
        displaySurveyChangeWarningPopup: true,
        surveyChangeViewpoint: SurveyChangeViewPoint.NewSurvey,
      });
      if (expandedRowId && expandedRowId !== rowId) {
        /* If any row is currently expanded, and the row to 
        be collapsed/expanded is different to the currently 
        expanded row */
        this.setFocusedSurveyStateAttribute({
          tempRowId: rowId,
          tempRowExpanded: isExpanded,
          collapsingOwnSurveyWithFilters: false,
        });
      } else {
        /* If any row is currently expanded, and the row to 
        be collapsed is the currently expanded row */
        this.setFocusedSurveyStateAttribute({
          tempRowId: '',
          tempRowExpanded: isExpanded,
          collapsingOwnSurveyWithFilters: true,
        });
      }
    } else {
      /* If no filters are selected, any row can be 
      collapsed/expanded without warning */
      this.setFocusedSurveyStateAttribute({
        isItemExpanded: isExpanded,
        expandedRowId: isExpanded ? rowId : '',
        tempRowId: '',
        tempRowExpanded: false,
      });
    }
  };

  /* Handles hiding the survey send warning popup
   */
  handleOnCancelSurveySendWarning = (): void => {
    this.setFocusedSurveyStateAttribute({
      displayWarningPopup: false,
    });
  };

  /**
   * Handles blocking navigation out of the focused survey
   *
   * @param location Location to navigate to
   * @returns {boolean} Form navigation blocking status
   */
  handleBlockingNavigation = (nextLocation: Location): boolean => {
    const { location } = this.props;
    const {
      focusedSurvey: { selectedFilters },
    } = this.state;
    const filters = Object.keys(selectedFilters);
    const filtersSelected = filters.some(
      (filter) => !isEmpty(selectedFilters[filter])
    );
    if (
      nextLocation.pathname !==
      `${ModulePaths.SurveysPath}${ModulePaths.SurveysDraftPath}`
    ) {
      if (nextLocation.pathname !== location.pathname) {
        if (filtersSelected) {
          return true;
        }
      }
    }
    return false;
  };

  render(): JSX.Element {
    const { history } = this.props;
    const {
      canCreateSurveys,
      canCollectIDs,
      draftedSurveys,
      focusedSurvey,
      status,
      surveyStats: {
        item: { tooltipStatus },
      },
    } = this.state;
    const { popupDetails } = focusedSurvey;

    const hideDraftedSurveyGuidePopovers =
      !!tooltipStatus.find(
        (tooltip) => tooltip.tooltip === SurveyPopoverType.SEND_SURVEY
      )?.status ||
      draftedSurveys.data.length === 0 ||
      draftedSurveys.status === Status.Loading ||
      status === Status.Loading;

    const showCreateSurveyGuidePopover =
      draftedSurveys.data.length === 0 &&
      canCreateSurveys &&
      !draftedSurveys.searchTerm &&
      status !== Status.Error &&
      status !== Status.Loading &&
      draftedSurveys.status !== Status.Loading;

    return (
      <div className={`content-container ${styles.surveysDraftContainer}`}>
        <ScrollToTopOnMount />
        <Row>
          <Col xs="6">
            <DebounceInput
              minLength={2}
              debounceTimeout={TIMEOUTS.SEARCH_DEBOUNCE_TIMEOUT}
              value={draftedSurveys.searchTerm}
              onChange={(e): void =>
                this.handleSearchTermChange(e.target.value)
              }
              className={styles.search}
              placeholder={intl.get('LBL_SURVEYS_SEARCH_PLACEHOLDER')}
              disabled={draftedSurveys.status === Status.Loading}
              type="search"
              inputRef={this.searchInputRef}
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col md={6} className="d-inline-flex align-items-center">
            <h3 className="text-18-semibold mb-0">
              {intl.get('LBL_SURVEYS_DRAFTED_SURVEYS_TITLE')}
            </h3>
            <Button
              color="secondary"
              size="sm"
              className="ml-3"
              disabled={draftedSurveys.status === Status.Loading}
              onClick={this.onGetNewSurveysClicked}
            >
              <i className="icon-refresh" />
              {intl.get('BTN_SURVEYS_GET_NEW_SURVEYS')}
            </Button>
          </Col>
          <Col md={6} className="d-inline-flex justify-content-end">
            <div ref={this.createSurveyGuideRef}>
              <a
                target="_blank"
                className="btn btn-secondary"
                rel="noopener noreferrer"
                href={`${String(process.env.REACT_APP_KOBO_URL)}`}
                style={
                  !canCreateSurveys && !canCollectIDs ? { display: 'none' } : {}
                }
              >
                <i className="icon-plus" />
                {intl.get('BTN_SURVEYS_CREATE')}
              </a>
            </div>
            {showCreateSurveyGuidePopover && (
              <DraftedSurveyGuidePopover
                elementRef={this.createSurveyGuideRef}
                popoverPlacement="top"
                popoverBodyText="LBL_SURVEYS_CREATE_SURVEY_GUIDE"
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <hr className="divider" />
          </Col>
        </Row>
        <Row className="ml-0">
          <Col xs="12">
            <DraftedSurveysList
              data={draftedSurveys.data}
              status={draftedSurveys.status}
              tooltipStatus={status}
              total={draftedSurveys.total}
              page={draftedSurveys.page}
              pageSize={draftedSurveys.pageSize}
              hasNext={draftedSurveys.next !== null}
              hasPrevious={draftedSurveys.previous !== null}
              selectedSurveyTypes={draftedSurveys.selectedSurveyTypes}
              selectedRecipientTypes={draftedSurveys.selectedRecipientTypes}
              showDraftedSurveyGuidePopovers={!hideDraftedSurveyGuidePopovers}
              focusedSurvey={focusedSurvey}
              setPageSize={this.handlePageSizeChange}
              loadNextPage={this.loadNextPage}
              loadPreviousPage={this.loadPreviousPage}
              refreshList={this.handleRefreshDraftedSurveys}
              onSurveyTypeChange={this.handleSurveyTypeChange}
              onRecipientTypeChange={this.handleRecipientTypeChange}
              onItemExpanded={this.handleSurveyItemExpanded}
              onSendSurvey={this.handleSendSurvey}
            />
          </Col>
        </Row>
        <RouteChangeGuard
          when={focusedSurvey.selectedFilters.countries.length > 0}
          navigate={(nextLocation): void => history.push(nextLocation)}
          shouldBlockNavigation={(loc: Location): boolean =>
            this.handleBlockingNavigation(loc)
          }
        />
        <SurveyChangePopup
          display={draftedSurveys.displaySurveyChangeWarningPopup}
          collapsingOwnSurvey={focusedSurvey.collapsingOwnSurveyWithFilters}
          viewPoint={draftedSurveys.surveyChangeViewpoint}
          onCancel={this.handleOnCancelSurveyChangeWarning}
          onContinue={this.handleOnContinueSurveyChangeWarning}
        />
        <SurveySendWarningPopup
          display={focusedSurvey.displayWarningPopup}
          sendCount={popupDetails.sendCount}
          excludedMemberCount={popupDetails.excludedMemberCount}
          excludedGroups={popupDetails.excludedGroups}
          surveyType={popupDetails.surveyType}
          recipientType={popupDetails.recipientType}
          isDuplicateSurvey={popupDetails.isDuplicateSurvey}
          viewPoint={SurveySendViewPoint.Drafts}
          conflictingRequests={popupDetails.existingCollectIds}
          onCancel={this.handleOnCancelSurveySendWarning}
          onContinue={this.handleOnWarningContinue}
        />
        <SurveySendSuccessPopup
          display={focusedSurvey.displaySuccessPopup}
          displayWarningPopup={focusedSurvey.displayWarningPopup}
          sendCount={popupDetails.sendCount}
          surveyType={popupDetails.surveyType}
          recipientType={popupDetails.recipientType}
          onClose={this.handleSurveySendingInterrupt}
          onUndo={this.handleSurveySendingInterrupt}
        />
      </div>
    );
  }
}

export default SurveysDraftView;

import { Component, ReactNode, createRef } from 'react';

import axios from 'axios';
import * as localStorage from 'local-storage';
import * as intl from 'react-intl-universal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Button, Col, Popover, Row } from 'reactstrap';

import AuthApiInstance from 'api/auth/AuthApi';
import ApiError from 'api/common/ApiError';
import SettingsApiInstance from 'api/settings/SettingsApi';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import StorageKeys from 'constants/StorageKeys';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import setPageTitle from 'helpers/setPageTitle';
import HttpStatus from 'shared/enums/HttpStatus';
import PermissionsViewMode from 'shared/enums/PermissionsViewMode';
import Status from 'shared/enums/Status';
import CreatePermissionItem from 'shared/permissions/components/permissions-list/create-permission-item/CreatePermissionItem';
import EditPermissionsListItem from 'shared/permissions/components/permissions-list/edit-permissions-list-item/EditPermissionsListItem';
import clearIcon from 'shared/static/img/ic_x.svg';

import CategoryKeysGA from '../../../../constants/ga/CategoryKeysGA';
import styles from './permissionsListView.module.scss';
import PermissionsListViewProps from './PermissionsListViewProps';
import PermissionsListViewState from './PermissionsListViewState';

class PermissionsListView extends Component<
  PermissionsListViewProps,
  PermissionsListViewState
> {
  constructor(props: PermissionsListViewProps) {
    super(props);
    const orgId = localStorage.get<string>(StorageKeys.OrganizationId);
    this.state = {
      organizationId: orgId,
      permissions: [],
      defaultClaims: [],
      active: '',
      showNewPermission: false,
      dummyItemCollapsed: true,
      permissionStatus: Status.Idle,
      infoPopoverOpen: false,
    };
    this.addNewButtonRef = createRef<HTMLDivElement>();
  }

  componentDidMount(): void {
    const { appContext } = this.props;
    appContext.hideErrorToast();
    setPageTitle(intl.get('BTN_SETTINGS'));
    this.fetchPermissions();
  }

  componentWillUnmount(): void {
    this.source.cancel();
  }

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  addNewButtonRef: React.RefObject<HTMLDivElement>;

  /**
   * Fetch existing permission levels for a given organization
   * and default claims for adding new permission levels.
   */
  fetchPermissions = async (): Promise<void> => {
    const { organizationId } = this.state;
    const { appContext } = this.props;
    this.setState({ permissionStatus: Status.Loading });
    try {
      let orgId = organizationId;
      if (!organizationId) {
        const userInfo = await AuthApiInstance.GetUserInfo();
        orgId = userInfo.organizationId;
      }

      const permissionLevelsResponse =
        await SettingsApiInstance.GetPermissionLevels(
          orgId,
          PermissionsViewMode.VIEW,
          this.source
        );
      const defaultClaimsResponse = await SettingsApiInstance.GetDefaultClaims(
        orgId,
        this.source
      );
      this.setState({
        permissions: permissionLevelsResponse.permissions,
        defaultClaims: defaultClaimsResponse.claims,
      });
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HttpStatus.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    } finally {
      this.setState({
        permissionStatus: Status.Done,
        infoPopoverOpen: true,
      });
    }
  };

  /**
   * Handle remove dummy item
   */
  removeDummyItem = (): void => {
    this.setState({ showNewPermission: false });
  };

  /**
   * Handle edit click on a existing permission level.
   *
   * @param activeKey Active key
   */
  handleEdit = (activeKey): void => {
    this.setState({
      active: activeKey,
      dummyItemCollapsed: true,
    });
  };

  /**
   * Handle edit click on the newly added dummy permission.
   */
  handleDummyItemEdit = (): void => {
    this.setState({
      active: '',
      dummyItemCollapsed: false,
    });
  };

  /**
   * Handle cancel click on on the edit view of a existing permission level.
   *
   * @param activeKey Active key
   */
  handleCancel = (): void => {
    this.setState({
      active: '',
    });
  };

  /**
   * Handle cancel click on the edit view of newly added dummy permission.
   */
  handleDummyItemCancel = (): void => {
    this.setState({
      dummyItemCollapsed: true,
    });
  };

  /**
   * Adding new dummy permission level in to the list.
   */
  handleAddClick = (): void => {
    this.setState({
      showNewPermission: true,
    });

    sendEventGA(
      CategoryKeysGA.SettingsPermissions,
      ActionKeysGA.AddNewPermission
    );
  };

  /**
   * Handle hide info popover
   */
  handleInfoHide = (): void => {
    this.setState({
      infoPopoverOpen: false,
    });
  };

  /**
   * Fetch updated permission levels update app context user permissions
   *
   * @param permissionKey key of the permission that was updated
   */
  handleEditSuccess = (/* permissionKey: string */): void => {
    const { appContext } = this.props;
    const { getPermissions } = appContext;
    // here use permissionKey to check if we should update context?
    getPermissions(true);
    this.fetchPermissions();
  };

  /**
   * Render popover
   *
   * @returns {ReactNode} JSX snippet containing popover
   */
  renderPopover = (): ReactNode => {
    const { infoPopoverOpen, showNewPermission, active } = this.state;
    const { isModalView, togglePermissionsModal, isModalOpen } = this.props;

    const isOpen =
      isModalOpen === true &&
      !showNewPermission &&
      active === '' &&
      infoPopoverOpen &&
      isModalView;

    return (
      <Popover
        isOpen={isOpen}
        placement="left"
        boundariesElement="window"
        container={this.addNewButtonRef}
        popperClassName="go-back-helper"
        hideArrow
        modifiers={{
          preventOverflow: { enabled: false },
          flip: { behavior: ['left'] },
          hide: { enabled: false },
        }}
        target="btnAddPermission"
        delay={{ show: 500, hide: 100 }}
      >
        <div>
          <div className="close-container">
            <button
              className="insight-close-button"
              type="button"
              onClick={this.handleInfoHide}
            >
              <img src={clearIcon} alt="Close" />
            </button>
          </div>
          <span>
            {intl.get('LBL_SETTINGS_PERMISSION_GO_BACK_HINT_PART1')}{' '}
            <button className="go-back" onClick={togglePermissionsModal}>
              {intl.get('BTN_GO_BACK')}
            </button>{' '}
            {intl.get('LBL_SETTINGS_PERMISSION_GO_BACK_HINT_PART2')}
          </span>
        </div>
      </Popover>
    );
  };

  render(): JSX.Element {
    const {
      showNewPermission,
      active,
      permissionStatus,
      permissions,
      defaultClaims,
      organizationId,
      dummyItemCollapsed,
    } = this.state;
    const {
      appContext,
      isModalView,
      canAddPermissions,
      canEditPermissions,
      isFirstTime,
      getGettingStartedState,
    } = this.props;

    return (
      <>
        <Row>
          <Col className="d-flex align-items-center">
            <h3
              className={`main-title text-18-semibold mb-0 ${
                isModalView ? 'd-none' : ''
              }`}
            >
              {intl.get('LBL_SETTINGS_PERMISSION_LEVELS')}
            </h3>
          </Col>
          <Col xs="4" className="d-flex justify-content-end">
            {canAddPermissions && (
              <div ref={this.addNewButtonRef}>
                <Button
                  className={isModalView ? 'mb-3' : ''}
                  id="btnAddPermission"
                  onClick={this.handleAddClick}
                  disabled={showNewPermission || active !== ''}
                >
                  <i className="icon-plus" />
                  {intl.get('BTN_SETTINGS_PERMISSIONS_CREATE_CUSTOM_LEVEL')}
                </Button>
                {this.renderPopover()}
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <hr
              className={`${styles.divider} ${isModalView ? 'd-none' : ''}`}
            />
          </Col>
        </Row>
        <div className="ds-collapse">
          {permissionStatus === Status.Loading ? (
            <SkeletonTheme color="#fafaf5" highlightColor="#ffffff">
              <Skeleton height={40} />
              <Skeleton height={62} count={10} />
            </SkeletonTheme>
          ) : (
            <Row>
              {permissions.length > 0 &&
                permissions.map((permission) => (
                  <EditPermissionsListItem
                    key={permission.key}
                    isModalView={isModalView}
                    active={active}
                    handleEdit={this.handleEdit}
                    handleCancel={this.handleCancel}
                    permission={permission}
                    canEditPermissions={canEditPermissions}
                    defaultClaims={defaultClaims}
                    organizationId={organizationId}
                    onError={appContext.setErrorToastText}
                    onEditSuccess={this.handleEditSuccess}
                    removeDummyItem={this.removeDummyItem}
                  />
                ))}
            </Row>
          )}
          <Row>
            {showNewPermission && (
              <CreatePermissionItem
                isModalView={isModalView}
                defaultClaims={defaultClaims}
                dummyItemCollapsed={dummyItemCollapsed}
                handleDummyItemEdit={this.handleDummyItemEdit}
                handleDummyItemCancel={this.handleDummyItemCancel}
                organizationId={organizationId}
                onError={appContext.setErrorToastText}
                isFirstTime={isFirstTime}
                fetchPermissions={this.fetchPermissions}
                removeDummyItem={this.removeDummyItem}
                getGettingStartedState={getGettingStartedState}
              />
            )}
          </Row>
        </div>
      </>
    );
  }
}

export default PermissionsListView;

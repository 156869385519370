/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
import { useMemo, FC as ReactFC } from 'react';

import kebabCase from 'lodash/kebabCase';
import * as intl from 'react-intl-universal';
import { Row, Col } from 'reactstrap';

import ModulePaths from 'constants/ModulePaths';
import { formatDate } from 'helpers/DateFormat';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import { getFormattedNumber } from 'helpers/NumberFormat';
import BackupStatusTag from 'modules/private/group/components/backup-status-tag/BackupStatusTag';
import history from 'router-history';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import DateFormatType from 'shared/enums/DateFormatType';
import Status from 'shared/enums/Status';

import GroupListProps from './GroupListProps';
import GroupsDataTable from './groups-data-table/GroupsDataTable';
import IndeterminateCheckbox from './indeterminate-checkbox/IndeterminateCheckbox';
import ManageColumnsModal from './manage-columns-modal/ManageColumnsModal';
import TableHeaderCell from './table-header-cell/TableHeaderCell';

const GroupList: ReactFC<GroupListProps> = (props: GroupListProps) => {
  const {
    data,
    pagination,
    pageCount,
    hiddenColumns,
    facilitators,
    facilitatorsStatus,
    facilitatorsError,
    unselectAllRows,
    selectedRows,
    columnOrder,
    showManageColsModal,
    pageSize,
    pageIndex,
    sortBy,
    loading,
    error,
    assignToProjectStatus,
    assignFacilitatorsStatus,
    assignToProjectError,
    assignGroupsStatusStatus,
    assignFacilitatorsError,
    notificationStatus,
    assignGroupsStatusError,
    location,
    groupStatGroupsAssigned,
    fetchData,
    fetchFacilitators,
    setHiddenColumns,
    putTablePreferences,
    setUnselectAll,
    setSelectAll,
    setSelectedRows,
    toggleColOrderModal,
    postAssignGroupsToProject,
    postAssignGroupsFacilitators,
    postAssignGroupsStatus,
    setAssignGroupsStatusStatus,
    setAssignToProjectStatus,
    setAssignFacilitatorsStatus,
  } = props;

  /**
   * Renders project name along with tooltip wrapper
   *
   * @returns {JSX.Element | string} Project name
   */
  const renderProject = ({ value }): JSX.Element | string => {
    if (value) {
      return (
        <EllipsisTooltip
          tag="span"
          data-place="bottom"
          data-for="insTooltip"
          data-tip={`${String(value.name)} #${String(value.code)}`}
          data-class="overflow-wrap text-uppercase"
          className="table-content ml-3"
        >
          {`${String(value.name)} #${String(value.code)}`}
        </EllipsisTooltip>
      );
    }
    return '—';
  };

  /**
   * Renders facilitator name along with tooltip wrapper
   *
   * @returns {JSX.Element | string} Facilitator name
   */
  const renderFacilitator = ({ value }): JSX.Element | string => {
    if (value) {
      return (
        <EllipsisTooltip
          tag="span"
          data-place="bottom"
          data-for="insTooltip"
          data-tip={value.name}
          data-class="overflow-wrap text-uppercase"
          className="table-content ml-3"
        >
          {value.name}
        </EllipsisTooltip>
      );
    }
    return '—';
  };

  /**
   * Renders group status along with tooltip wrapper
   *
   * @returns {JSX.Element | string} Group status
   */
  const renderGroupStatus = ({ value }): JSX.Element | string => {
    if (value) {
      return (
        <EllipsisTooltip
          tag="span"
          data-place="bottom"
          data-for="insTooltip"
          data-tip={value.status}
          data-class="overflow-wrap text-uppercase"
          className="table-content ml-3"
        >
          {value.status}
        </EllipsisTooltip>
      );
    }
    return '—';
  };

  /**
   * Renders formatted percentage
   *
   * @returns {string} Formatted value
   */
  const renderPercentage = ({ value }): string =>
    value !== undefined && value !== null
      ? `${String(getFormattedNumber(Number(value)))}%`
      : '—';

  /**
   * Renders formatted number
   *
   * @returns {string} Formatted value
   */
  const renderFormatted = ({ value }): string =>
    value ? getFormattedNumber(value) : '—';

  /**
   * Renders formatted meeting frequency
   *
   * @returns {string} Formatted value
   */
  const renderMeetingFreq = ({ value }): string =>
    value ? intl.get(`LBL_${String(value)}`) : '—';

  /**
   * Renders formatted date
   *
   * @returns {string} Formatted value
   */
  const renderFormattedDate = ({ value }): string =>
    value !== undefined && value !== null
      ? formatDate(value, DateFormatType.ShortYear)
      : '—';

  /**
   * Renders backup status tag
   *
   * @returns {JSX.Element} Backup status
   */
  const renderBackupStatus = ({ value }): JSX.Element => (
    <BackupStatusTag type={value} />
  );

  /**
   * Renders localized string
   *
   * @returns {string} Localized string
   */
  const renderLocalizedString = ({ value }): string =>
    value ? intl.get(`LBL_${String(value)}`) : '—';

  const columns = useMemo(
    () => [
      {
        dataTitle: intl.get('LBL_TITLE_GROUPS'), // 'Groups',
        id: 'name', // required if accessor is a fn (used for sorting)
        Header: TableHeaderCell,
        accessor: (row): string => row.name,
        className: 'text-bold truncate-long-words',
        disableSortBy: data.length === 0,
        // sortDescFirst: true,
      },
      {
        dataTitle: intl.get('LBL_TITLE_GROUP_ID') /* 'Group Id' */,
        Header: TableHeaderCell,
        accessor: 'number',
        className: 'align-right',
        helpText: intl.get('LBL_HELP_TEXT_GROUP_ID'),
        disableSortBy: data.length === 0,
        // sortDescFirst: true,
      },
      {
        dataTitle: intl.get('LBL_TITLE_CM_SAVINGS') /* 'Cumulative Savings' */,
        Header: TableHeaderCell,
        Cell: renderFormatted,
        accessor: 'cumulativeSavings',
        className: 'align-right',
        helpText: intl.get('LBL_HELP_TEXT_CM_SAVINGS'),
        disableSortBy: data.length === 0,
        sortDescFirst: true,
      },
      {
        dataTitle: intl.get('LBL_TITLE_ATTEN_RATE') /* 'Attendance Rate' */,
        Cell: renderPercentage,
        Header: TableHeaderCell,
        accessor: 'attendanceRate',
        className: 'align-right',
        helpText: intl.get('LBL_HELP_TEXT_ATTEN_RATE'),
        disableSortBy: data.length === 0,
        sortDescFirst: true,
      },
      {
        dataTitle: intl.get('LBL_TITLE_NOF_MEMBERS') /* 'Number of Members' */,
        Header: TableHeaderCell,
        Cell: renderFormatted,
        accessor: 'numberOfMembers',
        className: 'align-right',
        helpText: intl.get('LBL_HELP_TEXT_NOF_MEMBERS'),
        disableSortBy: data.length === 0,
        sortDescFirst: true,
      },
      {
        dataTitle: intl.get('LBL_TITLE_LAST_MEETINGS') /* 'Last Meetings' */,
        Header: TableHeaderCell,
        Cell: renderFormattedDate,
        accessor: 'lastMeeting',
        className: 'align-right',
        helpText: intl.get('LBL_HELP_TEXT_LAST_MEETINGS'),
        disableSortBy: data.length === 0,
        sortDescFirst: true,
      },
      {
        dataTitle: intl.get('LBL_TITLE_BACKUP_STATUS') /* 'Backup Status' */,
        Header: TableHeaderCell,
        Cell: renderBackupStatus,
        accessor: 'backupStatus',
        // headerClassName: '',
        className: 'align-right',
        helpText: intl.get('LBL_HELP_TEXT_BACKUP_STATUS'),
        disableSortBy: data.length === 0,
        // sortDescFirst: true,
      },
      {
        dataTitle: intl.get('LBL_TITLE_DROPOUT_RATE') /* 'Dropout Rate' */,
        Cell: renderPercentage,
        Header: TableHeaderCell,
        accessor: 'dropoutRate',
        className: 'align-right',
        helpText: intl.get('LBL_HELP_TEXT_DROPOUT_RATE'),
        disableSortBy: data.length === 0,
        sortDescFirst: true,
      }, // default
      {
        dataTitle: intl.get(
          'LBL_TITLE_MEM_GROWTH_RATE'
        ) /* 'Membership Growth Rate' */,
        Header: TableHeaderCell,
        Cell: renderPercentage,
        accessor: 'membershipGrowthRate',
        className: 'align-right',
        helpText: intl.get('LBL_HELP_TEXT_MEM_GROWTH_RATE'),
        disableSortBy: data.length === 0,
        sortDescFirst: true,
      },
      {
        dataTitle: intl.get('LBL_TITLE_MEETING_FREQ') /* 'Meeting Frequency' */,
        Header: TableHeaderCell,
        Cell: renderMeetingFreq,
        accessor: 'meetingFrequency',
        className: 'align-right',
        helpText: intl.get('LBL_HELP_TEXT_MEETING_FREQ'),
        disableSortBy: data.length === 0,
        sortDescFirst: true,
      },
      {
        dataTitle: intl.get(
          'LBL_TITLE_ATTEN_WITH_REP'
        ) /* 'Attendance Rate with Representative' */,
        Header: TableHeaderCell,
        Cell: renderPercentage,
        accessor: 'attendanceRateWithRep',
        className: 'align-right',
        helpText: intl.get('LBL_HELP_TEXT_ATTEN_WITH_REP'),
        disableSortBy: data.length === 0,
        sortDescFirst: true,
      },
      {
        dataTitle:
          intl.get('LBL_TITLE_GROUP_GA') /* 'Group Goal Achievement' */,
        Header: TableHeaderCell,
        Cell: renderLocalizedString,
        accessor: 'groupGoalAchievement',
        className: 'align-right',
        helpText: intl.get('LBL_HELP_TEXT_GROUP_GA'),
        disableSortBy: data.length === 0,
        sortDescFirst: true,
      },
      {
        dataTitle: intl.get(
          'LBL_TITLE_INACTIVITY_RATE'
        ) /* 'Inactivity Rate' */,
        Header: TableHeaderCell,
        Cell: renderPercentage,
        accessor: 'inactivityRate',
        className: 'align-right',
        helpText: intl.get('LBL_HELP_TEXT_INACTIVITY_RATE'),
        disableSortBy: data.length === 0,
        sortDescFirst: true,
      },
      {
        dataTitle: intl.get('LBL_TITLE_GROUP_STATUS') /* 'Group Status' */,
        Header: TableHeaderCell,
        Cell: renderGroupStatus,
        accessor: 'groupStatus',
        className:
          'align-right text-uppercase truncate-long-words shorten-length',
        helpText: intl.get('LBL_HELP_TEXT_GROUP_STATUS'),
        disableSortBy: data.length === 0,
        sortDescFirst: true,
      },
      {
        dataTitle: intl.get('LBL_TITLE_FACILITATOR') /* 'Project' */,
        Header: TableHeaderCell,
        Cell: renderFacilitator,
        accessor: 'facilitator',
        className: 'align-right truncate-long-words shorten-length',
        helpText: intl.get('LBL_HELP_TEXT_FACILITATOR'),
        disableSortBy: data.length === 0,
        sortDescFirst: true,
      },
      {
        dataTitle: intl.get('LBL_TITLE_PROJECT') /* 'Project' */,
        Header: TableHeaderCell,
        Cell: renderProject,
        accessor: 'project',
        className:
          'align-right text-uppercase truncate-long-words shorten-length',
        helpText: intl.get('LBL_HELP_TEXT_PROJECT'),
        disableSortBy: data.length === 0,
        sortDescFirst: true,
      },
    ],
    [data]
  );

  /**
   * Adds checkboxes column to data table
   *
   * @param hooks React table hooks
   */
  const useCheckboxSelection = (hooks): void => {
    hooks.visibleColumns.push((cols) => [
      {
        id: 'selection',
        // Make this column a groupByBoundary. This ensures that groupBy columns
        // are placed after it
        groupByBoundary: true,
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        className: 'no-bg checkbox-td',
        Cell: ({ row }): JSX.Element => (
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        ),
      },
      ...cols,
    ]);
  };

  /**
   * Handles row click event
   *
   * @param row Row that was clicked on
   */
  const handleRowClick = (row): void => {
    history.push({
      pathname: `${ModulePaths.GroupsPath}/${kebabCase(
        row.values.number
      )}/details`,
      state: location.pathname + location.search,
      search: getGlobalFiltersQuery(location.search),
    });
  };

  /**
   * Handles column change event success state
   *
   * @param hiddenCols Hidden columns
   */
  const handleSuccessColumnOptions = (hiddenCols: string[]): void => {
    setHiddenColumns(hiddenCols);
    toggleColOrderModal(false);
  };

  const tableLoading = loading || notificationStatus === Status.Loading;
  const tableError =
    notificationStatus === Status.Error
      ? intl.get('OOPS_SOMETHING_WENT_WRONG')
      : error;

  return (
    <>
      <Row className="dashboard-row">
        <Col>
          <GroupsDataTable
            columns={columns}
            data={data}
            fetchData={fetchData}
            columnOrder={columnOrder}
            hiddenColumns={hiddenColumns}
            pagination={pagination}
            controlledPageCount={pageCount}
            initialPageSize={pageSize}
            initialPageIndex={pageIndex}
            initialSortBy={sortBy}
            selectedRows={selectedRows}
            unselectAll={unselectAllRows}
            loading={tableLoading}
            error={tableError}
            facilitators={facilitators}
            facilitatorsStatus={facilitatorsStatus}
            facilitatorsError={facilitatorsError}
            groupStatGroupsAssigned={groupStatGroupsAssigned}
            assignToProjectStatus={assignToProjectStatus}
            assignToProjectError={assignToProjectError}
            assignFacilitatorsStatus={assignFacilitatorsStatus}
            assignFacilitatorsError={assignFacilitatorsError}
            assignGroupsStatusStatus={assignGroupsStatusStatus}
            assignGroupsStatusError={assignGroupsStatusError}
            fetchFacilitators={fetchFacilitators}
            setAssignGroupsStatusStatus={setAssignGroupsStatusStatus}
            setAssignToProjectStatus={setAssignToProjectStatus}
            setAssignFacilitatorsStatus={setAssignFacilitatorsStatus}
            setSelectedRows={setSelectedRows}
            customHooks={[useCheckboxSelection]}
            onRowClick={handleRowClick}
            setUnselectAll={setUnselectAll}
            setSelectAll={setSelectAll}
            onAssignToProject={postAssignGroupsToProject}
            onAssignGroupsFacilitators={postAssignGroupsFacilitators}
            onAssignGroupsStatus={postAssignGroupsStatus}
          />
        </Col>
      </Row>
      <ManageColumnsModal
        columns={columns}
        hiddenColumns={hiddenColumns}
        columnOrder={columnOrder}
        toggle={toggleColOrderModal}
        show={showManageColsModal}
        onSuccess={handleSuccessColumnOptions}
        save={putTablePreferences}
      />
    </>
  );
};

export default GroupList;

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMemo, FC as ReactFC } from 'react';

import { ResponsiveBar as NivoResponsiveBar } from '@nivo/bar';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

import { insightsColors } from 'helpers/ColorHelper';
import {
  getCompactNumber,
  getFormattedNumberWithDecimals,
} from 'helpers/NumberFormat';
import BarChartEmpty from 'modules/private/dashboard/components/bar-chart/bar-chart-empty/BarChartEmpty';
import useDimension from 'shared/hooks/use-dimensions/UseDimension';

import GroupMetricsChartProps from './GroupMetricsChartProps';

const GroupMetricsChart: ReactFC<GroupMetricsChartProps> = (
  props: GroupMetricsChartProps
) => {
  const theme = {
    background: 'white',
    axis: {
      legend: {
        text: {
          fontSize: '11px',
          fontFamily: 'Montserrat',
          fontWeight: 600,
          fill: insightsColors.insightPrimary,
        },
      },
      ticks: {
        text: {
          fontSize: '11px',
          fontFamily: 'Montserrat',
          fontWeight: 600,
          fill: insightsColors.insightGray,
          fontStyle: 'normal',
          lineHeight: '18px',
        },
        line: {
          stroke: insightsColors.insightLightGray,
        },
      },
    },
    tooltip: {
      container: {
        background: '#ffffff',
        border: `solid 1px ${insightsColors.insightLightGray}`,
        borderRadius: '4px',
        fontFamily: 'Montserrat',
        color: insightsColors.insightGray,
        padding: '8px 24px',
        fontSize: '11px',
        lineHeight: '18px',
      },
    },
    grid: {
      line: {
        stroke: insightsColors.insightLightGray,
        strokeWidth: 1,
        strokeDasharray: '4 4',
      },
    },
  };

  const containerDimension = {
    minHeight: 334,
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    aspectRatioWidth: 1,
    aspectRatioHeight: 0.4,
  };

  const { data, meta, error, loading } = props;
  const { ref, computedDimension } = useDimension(containerDimension);
  const { height } = computedDimension;

  /** this calculation holds bar width to 25px(@1920x1080) according
   * to number of bars : does not take resolution into consideration */
  const getPadding = (bars: number): number => 0.97 - 0.03 * bars;

  const formatNumberIntl = (number): string => getCompactNumber(number);

  /**
   * Renders the tooltip for each bar
   *
   * @param properties Tooltip properties
   * @returns {JSX.Element} JSX snippet containing the tooltip
   */
  const renderTooltip = (properties): JSX.Element => {
    const label = getFormattedNumberWithDecimals(Number(properties.data.key));
    const value = getFormattedNumberWithDecimals(properties.data.value);
    return (
      <div className="item">
        <span style={{ fontWeight: 800 }}>{label}</span>
        :&nbsp;
        <span
          style={{
            fontWeight: 800,
            color: properties.color,
          }}
        >
          {value}
        </span>
      </div>
    );
  };

  /**
   * Limits the number of grid-lines and tick values on y axis to prevent
   * clutter following large number of ticks, specifically limits the number of
   * ticks to 6 including 0
   *
   * @param maxY Largest value on the y-axis
   * @param minY Smallest value on the y-axis
   * @returns {number[] | undefined} Array of y-axis tick values
   */
  const tickValues = (maxY?: number, minY?: number): number[] | undefined => {
    if (!isNil(maxY) && !isNil(minY)) {
      if (minY === maxY) return [maxY];
      const gap = (maxY - minY) / 5;
      const numberOfValues = 5;
      const yTickValues: number[] = minY < 0 ? [minY] : [0];
      for (let index = 1; index < numberOfValues; index += 1) {
        yTickValues.push(yTickValues[index - 1] + gap);
      }
      yTickValues.push(maxY);
      return yTickValues;
    }
    return undefined;
  };

  const yTickValues = useMemo(
    () => tickValues(meta.maxY, meta.minY),
    [meta.maxY, meta.minY]
  );

  const dataTransformed = data.map((item) => ({
    ...item,
    key: item.key.toString(),
  }));

  /**
   * Validate whether to display empty state
   *
   * @returns {boolean} Specifies whether the empty state is displayed or not
   */
  const showEmptyState = (): boolean =>
    loading === false &&
    !!(get(dataTransformed, 'length', 0) <= 0 || Boolean(error));

  return (
    <div
      className="ins-custom-chart-wrapper chart-container line-area-char"
      ref={ref}
      style={{ height: `${height}px` }}
    >
      {showEmptyState() ? (
        <BarChartEmpty />
      ) : (
        <NivoResponsiveBar
          // @ts-ignore
          data={dataTransformed}
          keys={['value']}
          maxValue={meta.maxY}
          indexBy="key"
          margin={{ top: 30, right: 0, bottom: 70, left: 75 }}
          padding={getPadding(data.length)}
          colors={insightsColors.insightSecondary}
          axisTop={null}
          axisRight={null}
          enableGridX
          // @ts-ignore
          gridYValues={yTickValues}
          gridXValues={meta.xAxisGridValues}
          axisBottom={{
            tickRotation: 0,
            format: formatNumberIntl,
            legend: meta.xAxisLabel || '',
            legendPosition: 'middle',
            legendOffset: 60,
          }}
          axisLeft={{
            tickRotation: 0,
            format: formatNumberIntl,
            legend: meta.yAxisLabel || '',
            legendPosition: 'middle',
            legendOffset: -70,
            tickValues: yTickValues,
          }}
          isInteractive
          tooltip={renderTooltip}
          enableLabel={false}
          /* in order for the ReactTooltip to work animation must be disabled */
          animate
          motionStiffness={90}
          motionDamping={15}
          theme={theme}
        />
      )}
    </div>
  );
};

export default GroupMetricsChart;

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import history from 'router-history';
import { useEffect, useRef, useState, FC as ReactFC } from 'react';

import { ResizeObserverEntry } from '@juggle/resize-observer';
import { ResizeObserverCallback } from '@juggle/resize-observer/lib/ResizeObserverCallback';
import noop from 'lodash/noop';
import * as intl from 'react-intl-universal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactTooltip from 'react-tooltip';
import { Popover } from 'reactstrap';

import ModulePaths from 'constants/ModulePaths';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import FeatureValidationUtil from 'helpers/FeatureValidationUtil';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import PermissionUtil from 'helpers/PermissionUtil';
import InsNavLink from 'shared/components/ins-nav-link/InsNavLink';
import LeftNavigationProps from 'shared/components/left-navigation/LeftNavigationProps';
import Status from 'shared/enums/Status';
import primaryLogo from 'shared/static/img/primary-logo.svg';

import clearIcon from '../../static/img/ic_x.svg';
import PopperResizeContent from '../hoc/popper-resize-content/PopperResizeContent';
import InsLink from '../ins-link/InsLink';
import BackupStatusGraph from './backup-status-graph/BackupStatusGraph';

const LeftNavigation: ReactFC<LeftNavigationProps> = (props) => {
  const { context, history, location } = props;
  const {
    globalFilters,
    dashboardSetupInProgress,
    groupDetailsSetupInProgress,
    gettingStartedStates,
    guideOpen,
    permissionsData: { claims },
    userInfoData: { features },
  } = context;

  const [gsInfoOpen, setGsInfoOpen] = useState<boolean>(false);
  const [closeGsInfo, setCloseGsInfo] = useState<boolean>(false);

  const sideBarRef = useRef<HTMLDivElement>(null);
  const trackPointer = useRef<number | null>(null);

  const navigationDisabled =
    dashboardSetupInProgress || groupDetailsSetupInProgress;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const toggleGsInfo = (): void => setGsInfoOpen((open) => !open);
  const closeGsInfoForSession = (): void => {
    setCloseGsInfo(true);
    setGsInfoOpen(false);
  };

  useEffect(() => {
    if (closeGsInfo === false) {
      if (navigationDisabled === false) {
        if (guideOpen) {
          setGsInfoOpen(false);
        } else if (gettingStartedStates.status !== Status.Loading) {
          if (gettingStartedStates.data?.completed === false) {
            setGsInfoOpen(true);
          }
        }
      } else {
        setGsInfoOpen(false);
      }
    }
  }, [
    gettingStartedStates.data,
    gettingStartedStates.status,
    guideOpen,
    navigationDisabled,
    closeGsInfo,
  ]);

  /**
   * Curried function returns resize callback for ResizeObserver
   *
   * @param scheduleUpdate scheduleUpdate function param passed
   * to children function used to calculate popover position manually
   */
  const getGsResizeCallback =
    (scheduleUpdate: () => void): ResizeObserverCallback =>
    (entries: ResizeObserverEntry[]): void => {
      if (entries && entries[0]) {
        const { contentBoxSize } = entries[0];
        if (contentBoxSize[0] && contentBoxSize[0].inlineSize) {
          const width = contentBoxSize[0].inlineSize;
          /* left navigation media breakpoint widths from src\shared\static\styles\partials\_sidebar.scss */
          if (width > 80 && width < 160) {
            scheduleUpdate();
          }
        }
      }
    };

  /**
   * Handle navigation to dashboard
   */
  const goToDashboard = (): void =>
    navigationDisabled
      ? noop()
      : history.push({
          pathname: ModulePaths.DashboardPath,
          search: getGlobalFiltersQuery(location.search),
        });

  /**
   * Sets the pointer v position (clientY) to ref
   * to be used by the widget save warning tooltip
   *
   * @param event MouseEvent Synthetic Event
   */
  const handleAsideMouseEnterLeave = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    if (navigationDisabled === false) {
      trackPointer.current = null;
      return;
    }
    if (event.type === 'mouseenter') {
      trackPointer.current = event.clientY;
    } else if (event.type === 'mouseleave') {
      trackPointer.current = null;
    }
  };

  /**
   * Check whether the feature is enabled before accessing
   *
   * @param pathName Accessing location path
   * @returns {boolean} Whether the user can access the feature or not
   */
  const checkFeatureEnabled = (pathName: string): boolean => {
    const routeResource = `/${pathName.split('/')[1]}`;
    const isFeatureAvailable = FeatureValidationUtil.Can(
      features || [],
      routeResource
    );
    return isFeatureAvailable;
  };

  const dataTip = navigationDisabled ? intl.get('ERR_SAVE_WIDGETS') : '';
  const canViewMessaging = PermissionUtil.Can(
    claims,
    ResourceKeys.MessagingCompose
  );
  const canViewSurveys = PermissionUtil.Can(claims, ResourceKeys.SurveysCreate);
  const canCollectIDs = PermissionUtil.Can(
    claims,
    ResourceKeys.SurveysCollectAdditionalID
  );

  return (
    <aside
      key={dataTip}
      ref={sideBarRef}
      className="main-sidebar"
      data-for="insTooltip"
      data-tip={dataTip}
      data-class="error place-right"
      data-place="right"
      onMouseEnter={handleAsideMouseEnterLeave}
      onMouseLeave={handleAsideMouseEnterLeave}
    >
      <section className="sidebar-section logo">
        <div
          role="button"
          tabIndex={0}
          className={`logo-img ${navigationDisabled ? 'disabled' : ''}`}
          onKeyPress={goToDashboard}
          onClick={goToDashboard}
          style={{ cursor: 'pointer' }}
        >
          <img src={primaryLogo} alt="Logo" />
        </div>
      </section>
      <PerfectScrollbar>
        <div
          className={`sidebar-scroll-container ${
            !checkFeatureEnabled(ModulePaths.SurveysPath) &&
            !checkFeatureEnabled(ModulePaths.MessagingPath)
              ? 'reduce-my-height'
              : ''
          }`}
        >
          <nav className="sidebar-section main-navigation">
            <ul className="sidebar-nav">
              <li>
                <InsNavLink
                  to={ModulePaths.DashboardPath}
                  disabled={navigationDisabled}
                  disabledClass="disabled-nav-link"
                >
                  <i className="icon-dashboard" />
                  <span>{intl.get('BTN_DASHBOARD')}</span>
                </InsNavLink>
              </li>
              <li>
                <InsNavLink
                  to={ModulePaths.FinancesPath}
                  disabled={navigationDisabled}
                  disabledClass="disabled-nav-link"
                >
                  <i className="icon-money" />
                  <span>{intl.get('BTN_FINANCES')}</span>
                </InsNavLink>
              </li>
              <li>
                <InsNavLink
                  to={ModulePaths.GroupsPath}
                  disabled={navigationDisabled}
                  disabledClass="disabled-nav-link"
                >
                  <i className="icon-group" />
                  <span>{intl.get('BTN_GROUPS')}</span>
                </InsNavLink>
              </li>
              {/* Enable when features are available */}
              {/* <li>
                <InsNavLink
                  disabled={navigationDisabled || true}
                  to="/facilitators"
                  disabledClass="disabled-nav-link"
                >
                  <i className="icon-user" />
                  <span>{intl.get('BTN_FACILITATORS')}</span>
                </InsNavLink>
              </li> */}
              <li>
                <InsNavLink
                  to={ModulePaths.ProjectsPath}
                  disabled={navigationDisabled}
                  disabledClass="disabled-nav-link"
                >
                  <i className="icon-book" />
                  <span>{intl.get('BTN_PROJECTS')}</span>
                </InsNavLink>
              </li>
              {checkFeatureEnabled(ModulePaths.MessagingPath) && (
                <li>
                  <InsNavLink
                    to={ModulePaths.MessagingPath}
                    disabled={navigationDisabled || !canViewMessaging}
                    disabledClass="disabled-nav-link"
                  >
                    <i className="icon-messaging" />
                    <span>{intl.get('BTN_MESSAGING')}</span>
                  </InsNavLink>
                </li>
              )}
              {checkFeatureEnabled(ModulePaths.SurveysPath) && (
                <li>
                  <InsNavLink
                    to={`${ModulePaths.SurveysPath}`}
                    disabled={
                      navigationDisabled || (!canViewSurveys && !canCollectIDs)
                    }
                    disabledClass="disabled-nav-link"
                  >
                    <i className="icon-check-list" />
                    <span>{intl.get('BTN_SURVEYS')}</span>
                  </InsNavLink>
                </li>
              )}
              <li>
                <InsNavLink
                  disabled={navigationDisabled}
                  to={ModulePaths.SettingsPath}
                  disabledClass="disabled-nav-link"
                >
                  <i className="icon-settings" />
                  <span>{intl.get('BTN_SETTINGS')}</span>
                </InsNavLink>
              </li>
              <li id="gettingStartedLi">
                <Popover
                  isOpen={gsInfoOpen}
                  placement="left-start"
                  container={sideBarRef}
                  hideArrow
                  modifiers={{
                    preventOverflow: { enabled: false },
                    hide: { enabled: false },
                  }}
                  toggle={toggleGsInfo}
                  trigger="manual"
                  popperClassName="getting-started"
                  target="gettingStartedLi"
                  delay={{ show: 500, hide: 100 }}
                >
                  {({ scheduleUpdate }): JSX.Element => (
                    <PopperResizeContent
                      observeElement={sideBarRef}
                      onResize={getGsResizeCallback(scheduleUpdate)}
                    >
                      <div>
                        <div className="close-container">
                          <button
                            className="insight-close-button"
                            type="button"
                            onClick={closeGsInfoForSession}
                          >
                            <img src={clearIcon} alt="Close" />
                          </button>
                        </div>
                        {intl.getHTML(
                          'LBL_HTML_GSG_CONTINUE_TO_GETTING_STARTED'
                        )}
                      </div>
                    </PopperResizeContent>
                  )}
                </Popover>
                <InsLink
                  disabledClass="disabled"
                  disabled={navigationDisabled}
                  to={`${ModulePaths.DashboardPath}${ModulePaths.DashboardGettingStartedPath}`}
                  className="btn side-bar-button btn-sm m-auto"
                  data-cy="gs-link"
                >
                  {intl.get('BTN_GS_GETTING_STARTED')}
                </InsLink>
              </li>
            </ul>
          </nav>
          <BackupStatusGraph
            limitedAccessUser={
              !checkFeatureEnabled(ModulePaths.SurveysPath) &&
              !checkFeatureEnabled(ModulePaths.MessagingPath)
            }
            globalFilters={globalFilters}
            navigationDisabled={navigationDisabled}
            location={location}
            history={history}
          />
        </div>
      </PerfectScrollbar>
    </aside>
  );
};

export default LeftNavigation;

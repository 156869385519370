/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, createRef } from 'react';

import axios from 'axios';
import * as download from 'downloadjs';
import { FormikHelpers } from 'formik';
import { Location } from 'history';
import * as localStorage from 'local-storage';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import * as intl from 'react-intl-universal';
import { Col, Row, TabContent, TabPane } from 'reactstrap';

import AuthApiInstance from 'api/auth/AuthApi';
import ApiError from 'api/common/ApiError';
import GroupFiltersResponse, {
  MessagingGroup,
} from 'api/messaging/group-filters/GroupFiltersResponse';
import { PostMessageRequestBody } from 'api/messaging/message/PostMessageRequestBody';
import MessagingApiInstance from 'api/messaging/MessagingApi';
import SettingsApiInstance from 'api/settings/SettingsApi';
import ErrorCodes from 'constants/ErrorCodes';
import ModulePaths from 'constants/ModulePaths';
import StorageKeys from 'constants/StorageKeys';
import Tables from 'constants/Tables';
import TIMEOUTS from 'constants/Timeouts';
import { formatDate } from 'helpers/DateFormat';
import setPageTitle from 'helpers/setPageTitle';
import ComposeMessage from 'modules/private/messaging/components/compose-message/ComposeMessage';
import ComposeMessageFormValues from 'modules/private/messaging/components/compose-message/ComposeMessageFormValues';
import TableConfig from 'modules/private/messaging/components/message-history/message-history-data-table/TableConfig';
import MessageHistory from 'modules/private/messaging/components/message-history/MessageHistory';
import InsNavLink from 'shared/components/ins-nav-link/InsNavLink';
import DateFormatType from 'shared/enums/DateFormatType';
import EventKey from 'shared/enums/EventKey';
import HTTP_STATUS from 'shared/enums/HttpStatus';
import MessagingFilter from 'shared/enums/MessagingFilter';
import MessagingReceiverType from 'shared/enums/MessagingReceiverType';
import MessagingViewpoint from 'shared/enums/MessagingViewpoint';
import Status from 'shared/enums/Status';
import { EventBus } from 'shared/events/EventBus';
import { CustomErrorArgs } from 'shared/types/eventTypes';

import MessageSuccessPopup from '../../components/compose-message/messaging-popups/success-popup/MessageSuccessPopup';
import MessageWarningPopup from '../../components/compose-message/messaging-popups/warning-popup/MessageWarningPopup';
import MessageHistoryViewModel from '../../components/message-history/MessagingHistoryViewModel';
import { filterTypesMap } from './MessagingConstants';
import styles from './messagingView.module.scss';
import MessagingViewProps from './MessagingViewProps';
import MessagingViewState, {
  Filter,
  MessagingSearchValues,
  SortByItem,
  MessagingSearchValuesHistory,
  MessagingTab,
  MessagingSearchValuesCompose,
  MessageHistoryType,
  FilterDataItem,
} from './MessagingViewState';

class MessagingView extends Component<MessagingViewProps, MessagingViewState> {
  constructor(props: MessagingViewProps) {
    super(props);
    this.innerFormikRef = createRef<FormikHelpers<ComposeMessageFormValues>>();
    const orgId = localStorage.get<string>(StorageKeys.OrganizationId);
    this.state = {
      organizationId: orgId,
      organizationName: '',
      status: Status.Idle,
      showTranslationTooltip: false,
      compose: {
        title: '',
        body: '',
        countriesFilter: {
          data: [],
          status: Status.Idle,
          error: null,
        },
        projectsFilter: {
          data: [],
          status: Status.Idle,
          error: null,
        },
        facilitatorsFilter: {
          data: [],
          status: Status.Idle,
          error: null,
        },
        selectedFilters: {
          countries: [],
          projects: [],
          facilitators: [],
          groupStatus: [],
          groupAge: [],
          lastMessaged: [],
        },
        groupFilters: {
          data: [],
          status: Status.Idle,
          error: null,
        },
        filteredGroups: [],
        lastUpdatedFilter: null,
        displayWarningPopup: false,
        displaySuccessPopup: false,
        isMessageSendClicked: false,
      },
      messageHistory: {
        data: [],
        pagination: { total: 100, page: 0 },
        searchTerm: '',
        status: Status.Idle,
        statusReloadRow: null,
        messageDetails: {},
        messageDetailsStatus: Status.Idle,
        pageCount: 5,
      },
      didFiltersUpdate: false,
    };
  }

  componentDidMount(): void {
    const { appContext, location } = this.props;
    const {
      messageHistory: { searchTerm },
    } = this.state;
    appContext.hideErrorToast();
    setPageTitle(intl.get('LBL_MESSAGING_TITLE'));

    window.scrollTo(0, 0);
    const { tab } = this.getTablePreferencesFromSearch(location);
    this.setStateAttribute({
      showTranslationTooltip: tab === MessagingTab.Compose,
    });
    this.loadOrganizationName();
    this.fetchCountriesForFilter();
    const locationInfo = this.getTablePreferencesFromSearch(location);
    if (locationInfo.tab === MessagingTab.History) {
      const { size, page, sortBy } =
        locationInfo as MessagingSearchValuesHistory &
          MessagingSearchValuesCompose;
      this.fetchMessageHistory({ size, page, sortBy, searchTerm }, false);
    }
  }

  componentDidUpdate(
    prevProps: MessagingViewProps,
    prevState: MessagingViewState
  ): void {
    const { location } = this.props;
    const {
      messageHistory: { searchTerm },
    } = this.state;
    const { location: prevLocation } = prevProps;

    const { tab, ...currentSearch } =
      this.getTablePreferencesFromSearch(location);
    const prevSearch = this.getTablePreferencesFromSearch(prevLocation);
    const { page, size, sortBy } =
      currentSearch as MessagingSearchValuesHistory;

    const {
      messageHistory: { data: messageHistoryData },
      didFiltersUpdate,
    } = this.state;
    if (messageHistoryData.length === 0) {
      this.updatePage(0);
    }

    if (location.search !== prevLocation.search) {
      window.scrollTo(0, 0);

      if (tab !== prevSearch.tab) {
        this.setMessageHistoryStateAttribute({
          searchTerm: '',
        });
      }
      if (
        tab !== MessagingTab.Compose &&
        (prevSearch.size !== size ||
          prevSearch.page !== page ||
          !isEqual(prevSearch.sortBy, sortBy))
      ) {
        this.fetchMessageHistory({ size, page, sortBy, searchTerm }, false);
      }

      this.setStateAttribute({
        showTranslationTooltip: tab === MessagingTab.Compose,
      });

      if (this.innerFormikRef && this.innerFormikRef.current) {
        if (location.state && tab === MessagingTab.Compose) {
          const { title: copyTitle, body: copyBody } = location.state;
          if (copyTitle && copyBody) {
            this.innerFormikRef.current.setValues({
              title: copyTitle,
              body: copyBody,
            });
          }
        } else if (tab === MessagingTab.History) {
          this.setComposeSelectedFiltersStateAttribute({
            countries: [],
            projects: [],
            facilitators: [],
            groupStatus: [],
            groupAge: [],
            lastMessaged: [],
          });
          this.setComposeStateAttribute({
            lastUpdatedFilter: null,
            groupFilters: { data: [], error: null, status: Status.Idle },
            filteredGroups: [],
            displaySuccessPopup: false,
            displayWarningPopup: false,
          });
          this.setComposeCountriesFilterStateAttribute({
            data: [],
            status: Status.Idle,
            error: null,
          });
          this.setComposeProjectsFilterStateAttribute({
            data: [],
            status: Status.Idle,
            error: null,
          });
          this.setComposeFacilitatorsFilterStateAttribute({
            data: [],
            status: Status.Idle,
            error: null,
          });
          this.innerFormikRef.current.resetForm();
          this.fetchCountriesForFilter();
        }
      }
    }

    if (prevState.didFiltersUpdate !== didFiltersUpdate) {
      this.postGroupFilters();
    }
  }

  componentWillUnmount(): void {
    this.source.cancel();

    if (this.sendMessageUndoTimeout) clearTimeout(this.sendMessageUndoTimeout);
  }

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  innerFormikRef: React.RefObject<FormikHelpers<ComposeMessageFormValues>>;

  private sendMessageUndoTimeout?: NodeJS.Timeout;

  /**
   * Extract and return preferences from location.search string
   * @param location History.Location from react-router props
   * @returns {UsersSearchValues} Formatted preferences
   */
  getTablePreferencesFromSearch = (
    location: Location
  ): MessagingSearchValues => {
    let preferences = { tab: MessagingTab.Compose };
    const tabs = [MessagingTab.Compose, MessagingTab.History];
    let { tab } = queryString.parse(location.search);
    tab = tab && Array.isArray(tab) ? null : tab;
    const newTab = (
      tabs.includes(tab as MessagingTab) ? tab : MessagingTab.Compose
    ) as MessagingTab;

    preferences = { ...preferences, tab: newTab };

    if (newTab === MessagingTab.History) {
      let { size, page, by, desc } = queryString.parse(location.search);
      size = size && Array.isArray(size) ? null : size;
      page = page && Array.isArray(page) ? null : page;
      by = by && Array.isArray(by) ? null : by;
      desc = desc && Array.isArray(desc) ? null : desc;

      const sizeNumber = size ? parseInt(size, 10) : -1;
      const newSize = Tables.PageSizes.includes(sizeNumber)
        ? sizeNumber
        : Tables.PageSizes[0];
      const descending = desc ? desc !== 'false' : true;
      const sortColumn =
        by && TableConfig.sortCols.has(by) ? by : TableConfig.defaultSort;
      const sortBy = [{ id: sortColumn, desc: descending }];
      const pageNumber = page ? parseInt(page, 10) : -1;
      const newPage =
        pageNumber !== undefined &&
        pageNumber !== null &&
        !Number.isNaN(pageNumber) &&
        pageNumber >= 0
          ? pageNumber
          : 0;

      preferences = {
        ...preferences,
        size: newSize,
        sortBy,
        page: newPage,
      } as MessagingSearchValues;
    }
    return preferences;
  };

  /**
   * Set/Update component state in the root slice
   * @param updateState Updated state as an object
   */
  setStateAttribute = (updateState: Partial<MessagingViewState>): void =>
    this.setState((state) => ({
      ...state,
      ...updateState,
    }));

  /**
   * Set/Update component state in the compose slice
   * @param updateState Updated state as an object
   */
  setComposeStateAttribute = (
    updateState: Partial<MessagingViewState['compose']>
  ): void =>
    this.setState((state) => ({
      ...state,
      compose: { ...state.compose, ...updateState },
    }));

  /**
   * Set/Update component state in the compose countriesFilter slice
   * @param updateState Updated state as an object
   */
  setComposeCountriesFilterStateAttribute = (
    updateState: Partial<MessagingViewState['compose']['countriesFilter']>
  ): void =>
    this.setState((state) => ({
      ...state,
      compose: {
        ...state.compose,
        countriesFilter: { ...state.compose.countriesFilter, ...updateState },
      },
    }));

  /**
   * Set/Update component state in the compose projectsFilter slice
   * @param updateState Updated state as an object
   */
  setComposeProjectsFilterStateAttribute = (
    updateState: Partial<MessagingViewState['compose']['projectsFilter']>
  ): void =>
    this.setState((state) => ({
      ...state,
      compose: {
        ...state.compose,
        projectsFilter: { ...state.compose.projectsFilter, ...updateState },
      },
    }));

  /**
   * Set/Update component state in the compose facilitatorsFilter slice
   * @param updateState Updated state as an object
   */
  setComposeFacilitatorsFilterStateAttribute = (
    updateState: Partial<MessagingViewState['compose']['facilitatorsFilter']>
  ): void =>
    this.setState((state) => ({
      ...state,
      compose: {
        ...state.compose,
        facilitatorsFilter: {
          ...state.compose.facilitatorsFilter,
          ...updateState,
        },
      },
    }));

  /**
   * Set/Update component state in the compose selectedFilters slice
   * @param updateState Updated state as an object
   */
  setComposeSelectedFiltersStateAttribute = (
    updateState: Partial<MessagingViewState['compose']['selectedFilters']>,
    callback?: () => void
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        compose: {
          ...state.compose,
          selectedFilters: { ...state.compose.selectedFilters, ...updateState },
        },
      }),
      callback
    );

  /**
   * Set/Update component state in the messageHistory slice
   * @param groupFilters State object
   * @param filteredGroups State object
   */
  setGroupFiltersStateAttribute = (
    groupFilters: Partial<MessagingViewState['compose']['groupFilters']>,
    filteredGroups: Array<MessagingGroup>
  ): void =>
    this.setState((state) => ({
      ...state,
      compose: {
        ...state.compose,
        groupFilters: { ...state.compose.groupFilters, ...groupFilters },
        filteredGroups,
      },
    }));

  /**
   * Set/Update component state in the messageHistory slice
   * @param updateState Updated state as an object
   */
  setMessageHistoryStateAttribute = (
    updateState: Partial<MessagingViewState['messageHistory']>,
    callback?: () => void
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        messageHistory: { ...state.messageHistory, ...updateState },
      }),
      callback
    );

  /**
   * Fetch message history list
   * @param tableQuery Table preferences config
   * @param resetPageIndex Whether to reset page index on fetch
   */
  fetchMessageHistory = async (
    tableQuery: {
      size: number;
      sortBy: SortByItem[];
      page: number;
      searchTerm: string;
    },
    resetPageIndex: boolean
  ): Promise<void> => {
    const { appContext } = this.props;
    this.setMessageHistoryStateAttribute({ status: Status.Loading });
    const Direction = Tables.SortDirection;
    try {
      const sortByAttribute = tableQuery.sortBy[0].id;
      const sortDirection = tableQuery.sortBy[0].desc
        ? Direction.Descending
        : Direction.Ascending;
      const pageIndexAltered = resetPageIndex ? 0 : tableQuery.page;
      const response = await MessagingApiInstance.GetMessageListData(
        {
          pageSize: tableQuery.size,
          page: pageIndexAltered,
          sortBy: sortByAttribute,
          sort: sortDirection,
          search: tableQuery.searchTerm,
        },
        this.source
      );

      const pageCount = Math.ceil(response.pagination.total / tableQuery.size);
      this.setMessageHistoryStateAttribute({
        data: response.items,
        pageCount,
        pagination: response.pagination,
        status: Status.Success,
      });
    } catch (error) {
      this.setMessageHistoryStateAttribute({
        status: Status.Error,
      });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(
            intl.get('ERR_TOAST_MESSAGING_HISTORY_ERRORS')
          );
        }
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_MESSAGING_HISTORY_ERRORS')
        );
      }
    }
  };

  /**
   * Map response model to state model in order to populate the labels in group tags
   * @param groupFilters Group filters response object
   */
  mapGroupFiltersResponse = (
    groupFilters: GroupFiltersResponse
  ): Array<Filter> => {
    const {
      compose: { selectedFilters },
    } = this.state;
    const { filterResults } = groupFilters;
    const selectedFilterValues = [
      ...selectedFilters.countries,
      ...selectedFilters.facilitators,
      ...selectedFilters.projects,
      ...selectedFilters.groupStatus,
      ...selectedFilters.groupAge,
      ...selectedFilters.lastMessaged,
    ];
    const filtersMap = new Map(
      selectedFilterValues.map((filter) => [filter.value, filter.label])
    );
    const groupFilterValues: Array<Filter> = [];
    filterResults.forEach((filter) => {
      filter.results.forEach((result) => {
        if (filtersMap.get(result.value)) {
          const group: Filter = {
            name: intl.get(filterTypesMap.get(filter.type)!),
            value: filtersMap.get(result.value)!,
            count: result.groups,
          };
          groupFilterValues.push(group);
        }
      });
    });

    return groupFilterValues;
  };

  /**
   * Fetch organization data
   */
  loadOrganizationName = async (): Promise<void> => {
    let { organizationId } = this.state;
    const { appContext } = this.props;
    this.setState({ status: Status.Loading });
    try {
      if (!organizationId) {
        const { organizationId: orgId } = await AuthApiInstance.GetUserInfo();
        organizationId = orgId;
        this.setState({ organizationId });
      }
      const response = await SettingsApiInstance.GetOrganizationInfo(
        organizationId,
        this.source
      );
      this.setState({
        status: Status.Success,
        organizationName: response.name,
      });
    } catch (error) {
      this.setState({
        status: Status.Error,
      });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(
            intl.get('ERR_TOAST_MESSAGING_HISTORY_ERRORS')
          );
        }
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_MESSAGING_HISTORY_ERRORS')
        );
      }
    }
  };

  /**
   * Fetch countries to populate countries filter
   */
  fetchCountriesForFilter = async (): Promise<void> => {
    const { appContext } = this.props;
    this.setComposeCountriesFilterStateAttribute({
      status: Status.Loading,
    });
    try {
      const result = await MessagingApiInstance.GetCountries(this.source);
      const countriesFormatted = result.items.map((country) => ({
        value: country.code,
        label: country.name,
      }));
      this.setComposeCountriesFilterStateAttribute({
        data: countriesFormatted,
        status: Status.Success,
      });
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(
            intl.get('ERR_TOAST_MESSAGING_COUNTRY_FILTER_ERRORS')
          );
        }
        this.setComposeCountriesFilterStateAttribute({
          data: [],
          status: Status.Error,
          error,
        });
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_MESSAGING_COUNTRY_FILTER_ERRORS')
        );
        this.setComposeCountriesFilterStateAttribute({
          data: [],
          status: Status.Error,
          error: new Error(
            intl.get('ERR_TOAST_MESSAGING_COUNTRY_FILTER_ERRORS')
          ),
        });
      }
    }
  };

  /**
   * Fetch projects to populate projects filter
   * @param countryCodes Country codes to filter by
   */
  fetchProjectsForFilter = async (
    countryCodes: Array<FilterDataItem>
  ): Promise<void> => {
    const {
      compose: { selectedFilters },
      didFiltersUpdate: filtersUpdated,
    } = this.state;
    const { appContext } = this.props;
    this.setComposeProjectsFilterStateAttribute({ status: Status.Loading });
    try {
      const countryValues = countryCodes.map((country) => country.value);
      const result = await MessagingApiInstance.GetProjectsForCountry(
        countryValues,
        this.source
      );
      const projectsFormatted = result.items.map((project) => ({
        value: project.projectId,
        label: project.name,
      }));
      this.setComposeProjectsFilterStateAttribute({
        data: projectsFormatted,
        status: Status.Success,
      });
      const filteredProjects = selectedFilters.projects.filter((project) =>
        projectsFormatted.find(
          (updatedProject) => project.value === updatedProject.value
        )
      );
      this.setComposeSelectedFiltersStateAttribute(
        {
          projects: filteredProjects,
        },
        () => {
          if (isEmpty(filteredProjects)) {
            this.setComposeFacilitatorsFilterStateAttribute({
              data: [],
              status: Status.Idle,
              error: null,
            });
            this.setComposeSelectedFiltersStateAttribute(
              {
                facilitators: [],
              },
              () =>
                this.setStateAttribute({ didFiltersUpdate: !filtersUpdated })
            );
          } else {
            this.fetchFacilitatorsForFilter(filteredProjects).then(() =>
              this.setStateAttribute({ didFiltersUpdate: !filtersUpdated })
            );
          }
        }
      );
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(
            intl.get('ERR_TOAST_MESSAGING_PROJECT_FILTER_ERRORS')
          );
        }
        this.setComposeProjectsFilterStateAttribute({
          data: [],
          status: Status.Error,
          error,
        });
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_MESSAGING_PROJECT_FILTER_ERRORS')
        );
        this.setComposeProjectsFilterStateAttribute({
          data: [],
          status: Status.Error,
          error: new Error(
            intl.get('ERR_TOAST_MESSAGING_PROJECT_FILTER_ERRORS')
          ),
        });
      }
    }
  };

  /**
   * Fetch facilitators to populate facilitators filter
   * @param projectIds Project IDs to filter by
   */
  fetchFacilitatorsForFilter = async (
    projectIds: Array<FilterDataItem>
  ): Promise<void> => {
    const {
      compose: { selectedFilters },
    } = this.state;
    const { appContext } = this.props;
    this.setComposeFacilitatorsFilterStateAttribute({ status: Status.Loading });
    try {
      const projectValues = projectIds.map((project) => project.value);
      const result = await MessagingApiInstance.GetFacilitatorsForProjects(
        projectValues,
        this.source
      );
      const facilitatorsFormatted = result.items.map((facilitator) => ({
        value: facilitator.id,
        label: facilitator.name,
      }));
      this.setComposeFacilitatorsFilterStateAttribute({
        data: facilitatorsFormatted,
        status: Status.Success,
      });
      this.setComposeSelectedFiltersStateAttribute({
        facilitators: selectedFilters.facilitators.filter((facilitator) =>
          facilitatorsFormatted.find(
            (newFacilitator) => facilitator.value === newFacilitator.value
          )
        ),
      });
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(
            intl.get('ERR_TOAST_MESSAGING_FACILITATOR_FILTER_ERRORS')
          );
        }
        this.setComposeFacilitatorsFilterStateAttribute({
          data: [],
          status: Status.Error,
          error,
        });
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_MESSAGING_FACILITATOR_FILTER_ERRORS')
        );
        this.setComposeFacilitatorsFilterStateAttribute({
          data: [],
          status: Status.Error,
          error: new Error(
            intl.get('ERR_TOAST_MESSAGING_FACILITATOR_FILTER_ERRORS')
          ),
        });
      }
    }
  };

  /**
   * Fetch message details
   * @param id Message ID
   */
  fetchMessageDetails = async (id: string): Promise<void> => {
    const { messageHistory } = this.state;
    const { appContext } = this.props;
    this.setMessageHistoryStateAttribute({
      messageDetails: {
        ...messageHistory.messageDetails,
        [id]: {
          ...messageHistory.messageDetails[id],
          loadingStatus: Status.Loading,
        },
      },
    });
    try {
      const result = await MessagingApiInstance.GetMessage(id, this.source);
      const messageDetails = {
        ...messageHistory.messageDetails,
        [result.id]: { ...result, loadingStatus: Status.Success },
      };
      this.setMessageHistoryStateAttribute({
        messageDetailsStatus: Status.Success,
        messageDetails,
      });
    } catch (error) {
      this.setMessageHistoryStateAttribute({
        messageDetails: {
          ...messageHistory.messageDetails,
          [id]: {
            ...messageHistory.messageDetails[id],
            loadingStatus: Status.Error,
          },
        },
      });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(
            intl.get('ERR_TOAST_MESSAGING_HISTORY_ERRORS')
          );
        }
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_MESSAGING_HISTORY_ERRORS')
        );
      }
    }
  };

  /**
   * Post group filter data
   */
  postGroupFilters = async (): Promise<void> => {
    const { appContext } = this.props;
    const {
      compose: {
        selectedFilters: {
          countries,
          facilitators,
          groupAge,
          groupStatus,
          lastMessaged,
          projects,
        },
      },
    } = this.state;

    this.setGroupFiltersStateAttribute({ status: Status.Loading }, []);
    try {
      const countryValues = countries.map((country) => country.value);
      const projectValues = projects.map((project) => project.value);
      const facilitatorValues = facilitators.map(
        (facilitator) => facilitator.value
      );
      const groupStatusValues = groupStatus.map((status) => status.value);
      const groupAgeValues = groupAge.map((age) => age.value);
      const lastMessagedValues = lastMessaged.map((date) => date.value);
      const response = await MessagingApiInstance.PostGroupFilters(
        {
          countries: countryValues,
          facilitatorIds: facilitatorValues,
          groupAges: groupAgeValues,
          groupStatuses: groupStatusValues,
          lastMessaged: lastMessagedValues,
          projectIds: projectValues,
        },
        this.source
      );
      const mappedResponse = this.mapGroupFiltersResponse(response);
      this.setGroupFiltersStateAttribute(
        {
          data: mappedResponse,
          status: Status.Success,
        },
        response.filteredGroups
      );
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_MESSAGING_HISTORY_ERRORS',
            genericCallback: () => undefined,
            customCallback: this.fetchFiltersOnAccessError,
          } as CustomErrorArgs);
        }
        this.setGroupFiltersStateAttribute(
          {
            data: [],
            status: Status.Error,
            error,
          },
          []
        );
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_MESSAGING_HISTORY_ERRORS')
        );
        this.setGroupFiltersStateAttribute(
          {
            data: [],
            status: Status.Error,
            error: new Error(intl.get('ERR_TOAST_MESSAGING_HISTORY_ERRORS')),
          },
          []
        );
      }
    }
  };

  /**
   * Fetch filters on access error
   */
  fetchFiltersOnAccessError = (errorCode: number): void => {
    const {
      compose: {
        selectedFilters: { countries, projects },
      },
    } = this.state;
    switch (errorCode) {
      case ErrorCodes.InvalidCountryAccess:
        this.fetchCountriesForFilter();
        break;
      case ErrorCodes.InvalidProjectsAccess:
        this.fetchProjectsForFilter(countries);
        break;
      case ErrorCodes.InvalidFacilitatorAccess:
        this.fetchFacilitatorsForFilter(projects);
        break;
      default:
        break;
    }
  };

  /**
   * Create message API call and handle redirect to message history tab on success
   */
  postMessageCreate = async (): Promise<void> => {
    const { history, appContext } = this.props;
    const {
      compose: {
        body,
        title,
        filteredGroups,
        selectedFilters: {
          countries,
          facilitators,
          groupAge,
          groupStatus,
          lastMessaged,
          projects,
        },
      },
    } = this.state;
    try {
      this.setStateAttribute({ status: Status.Loading });
      const countryValues = countries.map((country) => country.value);
      const projectValues = projects.map((project) => project.value);
      const facilitatorValues = facilitators.map(
        (facilitator) => facilitator.value
      );
      const groupStatusValues = groupStatus.map((status) => status.value);
      const groupAgeValues = groupAge.map((age) => age.value);
      const lastMessagedValues = lastMessaged.map((date) => date.value);
      const messageCreateBody: PostMessageRequestBody = {
        body,
        title,
        receivers: filteredGroups.map((filter) => ({
          groupId: filter.id,
          members: [],
          type: MessagingReceiverType.GROUP,
        })),
        status: 'SENT',
        viewPoint: MessagingViewpoint.MEETING_CREATE,
        filters: {
          countries: countryValues,
          facilitatorIds: facilitatorValues,
          groupAges: groupAgeValues,
          groupStatuses: groupStatusValues,
          lastMessaged: lastMessagedValues,
          projectIds: projectValues,
        },
      };
      await MessagingApiInstance.CreateMessage(messageCreateBody, this.source);
      this.setStateAttribute({ status: Status.Success });
      this.setComposeStateAttribute({
        displaySuccessPopup: false,
        filteredGroups: [],
        groupFilters: { data: [], status: Status.Idle, error: null },
        isMessageSendClicked: false,
      });
      if (this.innerFormikRef && this.innerFormikRef.current) {
        this.innerFormikRef.current.resetForm();
      }
      history.push(this.getTabNavigateUrl(MessagingTab.History));
    } catch (error) {
      this.setStateAttribute({ status: Status.Error });
      this.setComposeStateAttribute({
        displaySuccessPopup: false,
        isMessageSendClicked: false,
      });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_MESSAGING_HISTORY_ERRORS',
            genericCallback: () => undefined,
            customCallback: this.fetchFiltersOnAccessError,
          } as CustomErrorArgs);
        }
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_MESSAGING_HISTORY_ERRORS')
        );
      }
    }
  };

  /**
   * Handle group filters change event
   * @param event Change event
   * @param filter Type of updated filter
   */
  setFiltersChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    filter: MessagingFilter,
    label: string
  ): void => {
    const {
      compose: { selectedFilters },
    } = this.state;
    const { checked, value } = event.target;
    this.setComposeStateAttribute({ lastUpdatedFilter: filter });
    if (checked) {
      this.setComposeSelectedFiltersStateAttribute(
        {
          [filter as string]: [...selectedFilters[filter], { value, label }],
        },
        this.updateGroupFiltersState
      );
    } else {
      const updatedFilter = selectedFilters[filter].filter(
        (selected) => selected.value !== value
      );
      this.setComposeSelectedFiltersStateAttribute(
        {
          [filter as string]: [...updatedFilter],
        },
        this.updateGroupFiltersState
      );
    }
  };

  /**
   * Update group filter data based on the selection of filters
   */
  updateGroupFiltersState = (): void => {
    const {
      compose: { selectedFilters, lastUpdatedFilter },
      didFiltersUpdate: filtersUpdated,
    } = this.state;
    if (lastUpdatedFilter === MessagingFilter.Countries) {
      const selectedCountries = selectedFilters[MessagingFilter.Countries];
      if (isEmpty(selectedCountries)) {
        this.setComposeProjectsFilterStateAttribute({
          data: [],
          status: Status.Idle,
          error: null,
        });
        this.setComposeFacilitatorsFilterStateAttribute({
          data: [],
          status: Status.Idle,
          error: null,
        });
        this.setComposeSelectedFiltersStateAttribute(
          {
            projects: [],
            facilitators: [],
            groupAge: [],
            groupStatus: [],
            lastMessaged: [],
          },
          () => this.setStateAttribute({ didFiltersUpdate: !filtersUpdated })
        );
      } else {
        this.fetchProjectsForFilter(
          selectedFilters[MessagingFilter.Countries as string]
        );
      }
    } else if (lastUpdatedFilter === MessagingFilter.Projects) {
      const selectedProjects = selectedFilters[MessagingFilter.Projects];
      if (isEmpty(selectedProjects)) {
        this.setComposeFacilitatorsFilterStateAttribute({
          data: [],
          status: Status.Idle,
          error: null,
        });
        this.setComposeSelectedFiltersStateAttribute(
          {
            facilitators: [],
          },
          () => this.setStateAttribute({ didFiltersUpdate: !filtersUpdated })
        );
      } else {
        this.fetchFacilitatorsForFilter(
          selectedFilters[MessagingFilter.Projects as string]
        ).then(() =>
          this.setStateAttribute({ didFiltersUpdate: !filtersUpdated })
        );
      }
    } else {
      this.setStateAttribute({ didFiltersUpdate: !filtersUpdated });
    }
  };

  /**
   * Get full url with search parameters for given tab
   * @param tab Tab name
   * @returns {string} URL
   */
  getTabNavigateUrl = (tab: MessagingTab): string => {
    const { location } = this.props;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let query: any = { ...queryString.parse(location.search), tab };
    if (tab === MessagingTab.History) {
      query = {
        ...query,
        by: undefined,
        desc: undefined,
        size: undefined,
        page: 0,
      };
    } else {
      query = {
        ...query,
        by: undefined,
        desc: undefined,
        size: undefined,
        page: undefined,
      };
    }
    const url = queryString.stringifyUrl({
      url: `${ModulePaths.MessagingPath}`,
      query,
    });
    return url;
  };

  /**
   * Push sortBy update to url; additionally sets page to 0
   * @param sortBy sortBy object of shape { id: string, desc: boolean }
   */
  updateSortBy = (sortBy: SortByItem[]): void => {
    const { history, location } = this.props;
    const searchParsed = queryString.parse(location.search);
    const { id: by, desc } = sortBy[0];
    const { tab } = this.getTablePreferencesFromSearch(location);
    if (
      (by !== searchParsed.by || desc.toString() !== searchParsed.desc) &&
      tab === MessagingTab.History
    ) {
      const query = { ...searchParsed, by, desc, page: 0 };
      const url = queryString.stringifyUrl({
        url: `${ModulePaths.MessagingPath}`,
        query,
      });
      history.push(url);
    }
  };

  /**
   * Push size update to url
   * @param size current page size
   */
  updatePageSize = (size: number): void => {
    const { history, location } = this.props;
    const searchParsed = queryString.parse(location.search);
    const { tab } = this.getTablePreferencesFromSearch(location);
    if (size.toString() !== searchParsed.size && tab === MessagingTab.History) {
      const query = { ...searchParsed, size, page: 0 };
      const url = queryString.stringifyUrl({
        url: `${ModulePaths.MessagingPath}`,
        query,
      });
      history.push(url);
    }
  };

  /**
   * Push page update to url
   * @param page current page
   */
  updatePage = (page: number): void => {
    const { history, location } = this.props;
    const searchParsed = queryString.parse(location.search);
    const { tab } = this.getTablePreferencesFromSearch(location);
    if (page.toString() !== searchParsed.page && tab === MessagingTab.History) {
      const query = { ...searchParsed, page };
      const url = queryString.stringifyUrl({
        url: `${ModulePaths.MessagingPath}`,
        query,
      });
      history.push(url);
    }
  };

  /**
   * Handle message sending, checking the groups message history and show warning popup
   * @param title Title of the message
   * @param body Content of the message
   */
  handleSendMessage = async (title: string, body: string): Promise<void> => {
    const { appContext } = this.props;
    const { compose } = this.state;
    if (compose.filteredGroups.length === 0) {
      this.setComposeStateAttribute({ isMessageSendClicked: true });
      return;
    }
    try {
      this.setStateAttribute({
        status: Status.Loading,
      });
      const groupItems = await MessagingApiInstance.CheckGroupStatus(
        compose.filteredGroups.map((group) => group.id),
        this.source
      );
      const showWarningPopup = groupItems.items.some(
        (group) => group.messagedRecently
      );
      this.setStateAttribute({
        status: Status.Success,
        compose: {
          ...compose,
          filteredGroups: groupItems.items,
          title,
          body,
          isMessageSendClicked: true,
        },
      });
      if (showWarningPopup) {
        this.setComposeStateAttribute({ displayWarningPopup: true });
      } else {
        this.setComposeStateAttribute({
          displaySuccessPopup: true,
        });
        this.handleMessageSendingTimeout();
      }
    } catch (error) {
      this.setStateAttribute({
        status: Status.Error,
        compose: {
          ...compose,
          isMessageSendClicked: false,
          displayWarningPopup: false,
        },
      });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_MESSAGING_HISTORY_ERRORS',
            genericCallback: () => undefined,
            customCallback: this.fetchFiltersOnAccessError,
          } as CustomErrorArgs);
        }
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_MESSAGING_HISTORY_ERRORS')
        );
      }
    }
  };

  /**
   * Handle message sending timeout
   */
  handleMessageSendingTimeout = (): void => {
    this.sendMessageUndoTimeout = setTimeout(() => {
      this.postMessageCreate();
      this.setComposeStateAttribute({ displaySuccessPopup: false });
    }, TIMEOUTS.SEND_MESSAGE_UNDO_TIMEOUT);
  };

  /**
   * Copies the message row to compose message
   * @param event Click event
   */
  handleCopy = (event: React.MouseEvent<HTMLButtonElement>, row): void => {
    const { history } = this.props;
    const {
      original: { title, body },
    } = row;
    history.push(this.getTabNavigateUrl(MessagingTab.Compose), { title, body });
  };

  /**
   * Reload delivery status for message
   * @param event Click event
   */
  handleReloadStatus = async (
    event: React.MouseEvent<HTMLButtonElement>,
    row
  ): Promise<void> => {
    const { appContext } = this.props;
    const {
      messageHistory: { data },
    } = this.state;
    const { id } = row;
    this.setMessageHistoryStateAttribute({
      statusReloadRow: id,
    });
    try {
      const result = await MessagingApiInstance.GetMessageProcessedStatus(
        id,
        this.source
      );
      const updatedData = data.map((messageItem: MessageHistoryViewModel) => {
        if (messageItem.id === id) {
          return {
            ...messageItem,
            status: result.status,
          };
        }
        return messageItem;
      });
      this.setMessageHistoryStateAttribute({
        data: updatedData,
      });
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(
            intl.get('ERR_TOAST_MESSAGING_HISTORY_ERRORS')
          );
        }
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_MESSAGING_HISTORY_ERRORS')
        );
      }
    } finally {
      this.setMessageHistoryStateAttribute({
        statusReloadRow: null,
      });
    }
  };

  /**
   * Handle search term change event
   * @param searchTerm Current search value
   */
  handleSearchTermChange = (searchTerm = ''): void => {
    const { location } = this.props;
    const locationInfo = this.getTablePreferencesFromSearch(location);
    const { size, page, sortBy } =
      locationInfo as MessagingSearchValuesHistory &
        MessagingSearchValuesCompose;
    this.setMessageHistoryStateAttribute({ searchTerm }, () => {
      if (page === 0) {
        this.fetchMessageHistory({ size, page, sortBy, searchTerm }, false);
      } else {
        this.updatePage(0);
      }
    });
  };

  /**
   * Send export message request download excel
   * @param event click event
   */
  handleExportRow = async (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ): Promise<void> => {
    const { appContext } = this.props;
    try {
      const blob = await MessagingApiInstance.GetMessagingExcel(
        id,
        this.source
      );
      const dateTime = formatDate(new Date(), DateFormatType.ExcelFilename);
      const fileName = `DreamSaveInsight-Messaging-${id}_${dateTime}.xlsx`;
      download(blob, fileName);
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(
            intl.get('ERR_TOAST_MESSAGING_HISTORY_ERRORS')
          );
        }
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_MESSAGING_HISTORY_ERRORS')
        );
      }
    }
  };

  /**
   * Handle click event on row
   */
  handleRowClick = (row): void => {
    if (row.id) {
      this.fetchMessageDetails(row.id);
    }
  };

  /**
   * Handles close success popup
   */
  handleSuccessPopupClose = (): void => {
    if (this.sendMessageUndoTimeout) clearTimeout(this.sendMessageUndoTimeout);
    this.setComposeStateAttribute({
      displaySuccessPopup: false,
      isMessageSendClicked: false,
    });
  };

  /**
   * Handle cancel warning popup
   */
  onWarningCancel = (): void => {
    this.setComposeStateAttribute({
      displayWarningPopup: false,
      isMessageSendClicked: false,
    });
  };

  /**
   * Handle continue warning popup
   */
  onWarningContinue = (): void => {
    this.setComposeStateAttribute({
      displayWarningPopup: false,
      displaySuccessPopup: true,
    });
    this.handleMessageSendingTimeout();
  };

  /**
   * Handle message undo
   */
  onSuccessUndo = (): void => {
    this.setComposeStateAttribute({
      displaySuccessPopup: false,
      isMessageSendClicked: false,
    });
    if (this.sendMessageUndoTimeout) clearTimeout(this.sendMessageUndoTimeout);
  };

  render(): JSX.Element {
    const { location, appContext } = this.props;
    const {
      messageHistory,
      organizationName,
      status,
      showTranslationTooltip,
      compose,
    } = this.state;
    const {
      selectedFilters,
      countriesFilter,
      facilitatorsFilter,
      groupFilters,
      projectsFilter,
      filteredGroups,
      isMessageSendClicked,
    } = compose;

    const { tab, ...rest } = this.getTablePreferencesFromSearch(location);

    return (
      <div
        className={`content-container ${styles.containerMargin} ${
          tab === MessagingTab.Compose ? styles.filterPanelPadding : ''
        }`}
      >
        <Row className="justify-content-start mb-2 pb-4">
          <Col xs="auto" className="pl-0">
            <div className="insight-tab smaller">
              <ul>
                <li>
                  <InsNavLink
                    className={`btn btn-secondary bg-transparent text-14-semibold ${styles.tab}`}
                    to={this.getTabNavigateUrl(MessagingTab.Compose)}
                    isActive={(): boolean => tab === MessagingTab.Compose}
                  >
                    {intl.get('LBL_MESSAGING_TAB_CREATE_NEW_MESSAGE')}
                  </InsNavLink>
                </li>
                <li>
                  <InsNavLink
                    className={`btn btn-secondary text-14-semibold bg-transparent ${styles.tab}`}
                    to={this.getTabNavigateUrl(MessagingTab.History)}
                    isActive={(): boolean => tab === MessagingTab.History}
                    data-cy="history-tab"
                  >
                    {intl.get('LBL_MESSAGING_TAB_MESSAGE_HISTORY')}
                  </InsNavLink>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <TabContent activeTab={tab}>
          <TabPane tabId={MessagingTab.Compose}>
            <ComposeMessage
              orgName={organizationName}
              status={status}
              groupFiltersStatus={compose.groupFilters.status}
              countriesData={countriesFilter}
              projectsData={projectsFilter}
              facilitatorsData={facilitatorsFilter}
              groupFilters={groupFilters.data}
              innerFormikRef={this.innerFormikRef}
              onFiltersChanged={this.setFiltersChanged}
              handleSendMessage={this.handleSendMessage}
              showTranslationTooltip={showTranslationTooltip}
              selectedFilters={selectedFilters}
              location={location}
              tab={tab}
              selectedGroupsCount={filteredGroups.length}
              appliedFiltersCount={groupFilters.data.length}
              handleSetErrorToast={appContext.setErrorToastText}
              onNavigationChange={(): void => {
                this.handleSuccessPopupClose();
                this.onWarningCancel();
              }}
              isMessageSendClicked={isMessageSendClicked}
              getUrlConfig={this.getTablePreferencesFromSearch}
            />
          </TabPane>
          {tab === MessagingTab.History && (
            <TabPane tabId={MessagingTab.History}>
              <MessageHistory
                data={messageHistory.data}
                orgName={organizationName}
                messageDetails={messageHistory.messageDetails}
                controlledPageCount={messageHistory.pageCount}
                pagination={messageHistory.pagination}
                initialPageSize={(rest as MessagingSearchValuesHistory).size}
                initialPageIndex={(rest as MessagingSearchValuesHistory).page}
                initialSortBy={(rest as MessagingSearchValuesHistory).sortBy}
                status={messageHistory.status}
                searchTerm={messageHistory.searchTerm}
                onRowClick={this.handleRowClick}
                onCopy={this.handleCopy}
                onExportRow={this.handleExportRow}
                onReloadStatus={this.handleReloadStatus}
                onSearchTermChange={this.handleSearchTermChange}
                updateSortBy={this.updateSortBy}
                updatePageSize={this.updatePageSize}
                updatePage={this.updatePage}
                type={MessageHistoryType.Sent}
                statusReloadRow={messageHistory.statusReloadRow}
              />
            </TabPane>
          )}
        </TabContent>
        <MessageWarningPopup
          display={compose.displayWarningPopup}
          filteredGroups={compose.filteredGroups}
          onCancel={this.onWarningCancel}
          onContinue={this.onWarningContinue}
        />
        <MessageSuccessPopup
          display={compose.displaySuccessPopup}
          displayWarningPopup={compose.displayWarningPopup}
          filteredGroups={compose.filteredGroups}
          onClose={this.handleSuccessPopupClose}
          onUndo={this.onSuccessUndo}
        />
      </div>
    );
  }
}

export default MessagingView;

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, FC as ReactFC } from 'react';

import { AxisProps } from '@nivo/axes';
import { Serie } from '@nivo/line';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import {
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import Meta from 'api/common/Meta';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import LabelKeysGA from 'constants/ga/LabelKeysGA';
import { getColorFor } from 'helpers/ColorHelper';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import { getRoundedNumber } from 'helpers/NumberFormat';
import AverageSavings from 'modules/private/dashboard/components/financial-metrics/average-savings/AverageSavings';
import CumulativeSavings from 'modules/private/dashboard/components/financial-metrics/cumulative-savings/CumulativeSavings';
import FinancialMetricContainerProps from 'modules/private/dashboard/components/financial-metrics/financial-metric-container/FinancialMetricContainerProps';
import FinancialMetricType from 'modules/private/dashboard/components/financial-metrics/FinancialMetricType';
import LoanFundUtilization from 'modules/private/dashboard/components/financial-metrics/loan-fund-utilization/LoanFundUtilization';
import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import CyclePhase from 'shared/enums/CyclePhase';
import FilterType from 'shared/enums/FilterType';
import Frequency from 'shared/enums/Frequency';
import TimeUnit from 'shared/enums/TimeUnit';

type ChartData = {
  [K in FinancialMetricType]: { data: Serie[]; meta: Partial<Meta> };
};

const FinancialMetricContainer: ReactFC<FinancialMetricContainerProps> = (
  props
) => {
  const defaultBottomAxis: AxisProps = {
    format: '%Y, %b',
    // tickValues: 'every 1 month',
  };

  const defaultChartData: ChartData = Object.keys(FinancialMetricType).reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [FinancialMetricType[currentValue]]: { data: Array<Serie>(), meta: {} },
    }),
    {} as ChartData
  );

  const defaultTab = FinancialMetricType.CumulativeSavings;

  const { data, filters, loading, onFilterChange } = props;
  const averageSavings = data.find(
    (i) => i.type === FinancialMetricType.AverageSavings
  );
  const cumulativeSavings = data.find(
    (i) => i.type === FinancialMetricType.CumulativeSavings
  );
  const loanFundUtilization = data.find(
    (i) => i.type === FinancialMetricType.LoanFundUtilization
  );

  const [xAxisConfig, setxAxisConfig] = useState<AxisProps>(defaultBottomAxis);
  const [activeTab, setActiveTab] = useState<FinancialMetricType>(defaultTab);
  const [timeUnits, setTimeUnits] = useState<SelectFieldOption[]>([]);
  const [frequencies, setFrequency] = useState<SelectFieldOption[]>([]);
  const [cyclePhases, setCyclePhases] = useState<SelectFieldOption[]>([]);
  const [chartData, setChartData] = useState<ChartData>(defaultChartData);

  useEffect(() => {
    const timeUnitOptions = Object.values(TimeUnit).map((value) => ({
      value,
      label: intl.get(`BTN_${value}`),
    }));
    const frequencyOptions = Object.values(Frequency).map((value) => ({
      value,
      label: intl.get(`BTN_${value}`),
    }));
    const cyclePhaseOptions = Object.values(CyclePhase).map((value) => ({
      value,
      label: intl.get(`LBL_CP_${value}`),
    }));

    setTimeUnits(timeUnitOptions);
    setFrequency(frequencyOptions);
    setCyclePhases(cyclePhaseOptions);
  }, []);

  useEffect(() => {
    const classifiedChartData: ChartData = defaultChartData;
    data.forEach((d) => {
      const dataPoints = d.dataPoints.map((dp) => ({
        x: dp.key,
        y: dp.value || 0,
      }));
      const comparisonPoints = d.comparisonDataPoints.map((cp) => ({
        x: cp.key,
        y: cp.value || 0,
        comparisonKey: cp.comparisonKey,
      }));

      const dataPointsSerie: Serie = {
        id: d.type,
        data: dataPoints,
        color: getColorFor(d.type),
      };

      const comparisonPointsSerie: Serie = {
        id: `${d.type}_COMP`,
        data: comparisonPoints,
        color: getColorFor(`${d.type}_COMP`),
      };

      classifiedChartData[d.type] = {
        data: [comparisonPointsSerie, dataPointsSerie],
        meta: d.meta,
      };
    });
    setChartData(classifiedChartData);
  }, [data]);

  useEffect(() => {
    switch (filters.frequency.value) {
      case Frequency.Yearly:
        setxAxisConfig({
          format: '%Y',
          tickValues: 'every year',
        });
        break;
      case Frequency.Quarterly:
        setxAxisConfig({
          format: '%Y, Q%q',
          tickValues: cumulativeSavings?.dataPoints.length,
        });
        break;
      default:
        setxAxisConfig({
          format: '%Y, %b',
          tickValues: 'every month',
        });
        break;
    }
  }, [filters.frequency, cumulativeSavings?.dataPoints.length]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  /**
   * Handles changing the financial metrics tab
   *
   * @param tab Financial metrics chart type
   */
  const changeActiveTab = (tab: FinancialMetricType): void => {
    setActiveTab(tab);
    sendEventGA(
      CategoryKeysGA.DashboardFinancialMetrics,
      ActionKeysGA.ChangeChartTab,
      LabelKeysGA[tab]
    );
  };

  /**
   * Get classnames for active/inactive tabs
   *
   * @param tab Financial metrics chart type
   * @returns {string} Classnames for active/inactive tabs
   */
  const isActive = (tab: FinancialMetricType): string => {
    const activeTabClasses = 'tab active';
    const idleTabClasses = 'tab idle';

    return activeTab === tab ? activeTabClasses : idleTabClasses;
  };

  /**
   * Handles the change event for the financial metric filters
   *
   * @param filter Selected filter
   * @param type Selected filter type
   */
  const handleFilterChange = (
    filter: SelectFieldOption,
    type: FilterType
  ): void => {
    onFilterChange(filter, type);

    sendEventGA(
      `${CategoryKeysGA.DashboardFinancialMetrics}/${LabelKeysGA[activeTab]}`,
      ActionKeysGA.ChangeChartFilter,
      `${String(LabelKeysGA[type])}:${String(LabelKeysGA[filter.value])}`
    );
  };

  return (
    <>
      <BlockUi tag="div" blocking={loading} className="row dashboard-row">
        <div className="col">
          <div className="content-box">
            <div className="content-box-title has-dropdowns pb-4 pt-2">
              <h3 className="main-title">
                {intl.get('LBL_FINANCIAL_METRICS')}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get('LBL_FINANCIAL_METRICS_HELP_TEXT')}
                  />
                </span>
              </h3>
              <div className="inline-form">
                <FormGroup className="insight-select-group">
                  <Label htmlFor="cyclePhase" className="chart-filter-label">
                    {intl.get('LBL_CHART_FILTER_CYCLE_PHASE')}
                  </Label>
                  <Select
                    name="cyclePhase"
                    value={filters.cyclePhase}
                    options={cyclePhases}
                    onChange={(value): void =>
                      handleFilterChange(
                        value as SelectFieldOption,
                        FilterType.CyclePhase
                      )
                    }
                    classNamePrefix="insight-select-no-border"
                  />
                </FormGroup>
                <FormGroup className="insight-select-group">
                  <Label
                    htmlFor="dateComparison"
                    className="chart-filter-label"
                  >
                    {intl.get('LBL_CHART_FILTER_DATE_COMPARISON')}
                  </Label>
                  <Select
                    name="dateComparison"
                    value={filters.timeUnit}
                    options={timeUnits}
                    onChange={(value): void =>
                      handleFilterChange(
                        value as SelectFieldOption,
                        FilterType.TimeUnit
                      )
                    }
                    classNamePrefix="insight-select-no-border"
                  />
                </FormGroup>
                <FormGroup className="insight-select-group">
                  <Label htmlFor="frequency" className="chart-filter-label">
                    {intl.get('LBL_CHART_FILTER_FREQUENCY')}
                  </Label>
                  <Select
                    name="frequency"
                    value={filters.frequency}
                    options={frequencies}
                    onChange={(value): void =>
                      handleFilterChange(
                        value as SelectFieldOption,
                        FilterType.Frequency
                      )
                    }
                    classNamePrefix="insight-select-no-border"
                  />
                </FormGroup>
              </div>
            </div>

            <Nav tabs className="content-box-tabs">
              <NavItem>
                <NavLink
                  className={isActive(FinancialMetricType.CumulativeSavings)}
                  onClick={(): void =>
                    changeActiveTab(FinancialMetricType.CumulativeSavings)
                  }
                >
                  <div className="chart-details">
                    <span className="sub-title">
                      {intl.get(`LBL_${FinancialMetricType.CumulativeSavings}`)}
                      <span className="help">
                        <i
                          className="icon-help"
                          data-for="insTooltip"
                          data-tip={intl.get(
                            'LBL_FINANCIAL_CUMULATIVE_SAVINGS_HELP_TEXT'
                          )}
                        />
                      </span>
                    </span>
                    <span className="value">
                      <NumberFormat
                        value={getRoundedNumber(
                          cumulativeSavings?.tabData || 0
                        )}
                        displayType="text"
                        thousandSeparator
                      />
                    </span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={isActive(FinancialMetricType.AverageSavings)}
                  onClick={(): void =>
                    changeActiveTab(FinancialMetricType.AverageSavings)
                  }
                >
                  <div className="chart-details">
                    <span className="sub-title">
                      {intl.get(`LBL_${FinancialMetricType.AverageSavings}`)}
                      <span className="help">
                        <i
                          className="icon-help"
                          data-for="insTooltip"
                          data-tip={intl.get(
                            'LBL_FINANCIAL_AVERAGE_SAVINGS_PER_MEMBER_HELP_TEXT'
                          )}
                        />
                      </span>
                    </span>
                    <span className="value">
                      <NumberFormat
                        value={getRoundedNumber(averageSavings?.tabData || 0)}
                        displayType="text"
                        thousandSeparator
                      />
                    </span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={isActive(FinancialMetricType.LoanFundUtilization)}
                  onClick={(): void =>
                    changeActiveTab(FinancialMetricType.LoanFundUtilization)
                  }
                >
                  <div className="chart-details">
                    <span className="sub-title">
                      {intl.get(
                        `LBL_${FinancialMetricType.LoanFundUtilization}`
                      )}
                      <span className="help">
                        <i
                          className="icon-help"
                          data-for="insTooltip"
                          data-tip={intl.get(
                            'LBL_FINANCIAL_LOAN_FUND_UTILIZATION_RATE_HELP_TEXT'
                          )}
                        />
                      </span>
                    </span>
                    <span className="value">
                      <NumberFormat
                        value={getRoundedNumber(
                          loanFundUtilization?.tabData || 0
                        )}
                        displayType="text"
                        suffix="%"
                      />
                    </span>
                  </div>
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId={FinancialMetricType.CumulativeSavings}>
                <CumulativeSavings
                  data={chartData.CUMULATIVE_SAVINGS.data}
                  meta={chartData.CUMULATIVE_SAVINGS.meta}
                  xAxisConfig={xAxisConfig}
                  filters={filters}
                />
              </TabPane>
              <TabPane tabId={FinancialMetricType.AverageSavings}>
                <AverageSavings
                  data={chartData.AVERAGE_SAVINGS_PER_MEMBER.data}
                  meta={chartData.AVERAGE_SAVINGS_PER_MEMBER.meta}
                  xAxisConfig={xAxisConfig}
                  filters={filters}
                />
              </TabPane>
              <TabPane tabId={FinancialMetricType.LoanFundUtilization}>
                <LoanFundUtilization
                  data={chartData.LOAN_FUND_UTILIZATION.data}
                  meta={chartData.LOAN_FUND_UTILIZATION.meta}
                  xAxisConfig={xAxisConfig}
                  filters={filters}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </BlockUi>
    </>
  );
};

export default FinancialMetricContainer;

import { ChangeEvent, Component, createRef } from 'react';

import axios from 'axios';
import * as download from 'downloadjs';
import { isEmpty } from 'lodash';
import { DebounceInput } from 'react-debounce-input';
import * as intl from 'react-intl-universal';
import { Col, Row } from 'reactstrap';

import ApiError from 'api/common/ApiError';
import SurveysApiInstance from 'api/surveys/SurveysApi';
import Constraints from 'constants/forms/Constraints';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import Tables from 'constants/Tables';
import TIMEOUTS from 'constants/Timeouts';
import { formatDate } from 'helpers/DateFormat';
import PermissionUtil from 'helpers/PermissionUtil';
import setPageTitle from 'helpers/setPageTitle';
import ScrollToTopOnMount from 'shared/components/scroll-to-top-on-mount/ScrollToTopOnMount';
import DateFormatType from 'shared/enums/DateFormatType';
import EventKey from 'shared/enums/EventKey';
import HTTP_STATUS from 'shared/enums/HttpStatus';
import Status from 'shared/enums/Status';
import SurveyPopoverType from 'shared/enums/SurveyPopoverType';
import SurveyRecipientType from 'shared/enums/SurveyRecipientType';
import SurveyType from 'shared/enums/SurveyType';
import { EventBus } from 'shared/events/EventBus';
import { CustomErrorArgs } from 'shared/types/eventTypes';

import CollectIdsHistoryDataTable from '../../components/collect-ids-history-data-table/CollectIdsHistoryDataTable';
import SurveysHistoryDataTable from '../../components/surveys-history-data-table/SurveysHistoryDataTable';
import SurveySendWarningPopup from '../../components/surveys-popups/survey-send-warning-popup/SurveySendWarningPopup';
import { SurveySendViewPoint } from '../../components/surveys-popups/survey-send-warning-popup/SurveySendWarningPopupProps';
import styles from './surveysHistoryView.module.scss';
import SurveysHistoryViewModel from './SurveysHistoryViewModel';
import SurveysHistoryViewProps from './SurveysHistoryViewProps';
import SurveysHistoryViewState, {
  FilteredRecipients,
  SortByItem,
} from './SurveysHistoryViewState';

class SurveysHistoryView extends Component<
  SurveysHistoryViewProps,
  SurveysHistoryViewState
> {
  constructor(props: SurveysHistoryViewProps) {
    super(props);
    const { appContext } = props;
    const { permissionsData } = appContext;
    const canCreateSurveys = PermissionUtil.Can(
      permissionsData.claims,
      ResourceKeys.SurveysCreate
    );
    const canCollectIDs = PermissionUtil.Can(
      permissionsData.claims,
      ResourceKeys.SurveysCollectAdditionalID
    );
    this.state = {
      searchTerm: '',
      selectedRecipientType: SurveyRecipientType.Groups,
      canCreateSurveys,
      canCollectIDs,
      displayWarningPopup: false,
      popupDetails: {
        sendCount: 0,
        excludedMemberCount: 0,
        excludedGroups: [],
        isDuplicateSurvey: false,
        finalRecipients: [],
        existingCollectIds: [],
      },
      surveysList: {
        data: [],
        status: Status.Idle,
        total: 0,
        next: null,
        previous: null,
        page: 0,
        pageSize: Constraints.LazyLoadingPageSizeExtra,
        sortBy: [{ id: 'sendDate', desc: true }],
        statusReloadRow: null,
      },
      collectIdsList: {
        data: [],
        status: Status.Idle,
        tooltipStatus: { item: { tooltipStatus: [] } },
        total: 0,
        next: null,
        previous: null,
        page: 0,
        pageSize: Constraints.LazyLoadingPageSizeExtra,
        sortBy: [{ id: 'sendDate', desc: true }],
        statusReloadRow: null,
        focusedStatusToggleRowId: '',
      },
    };
  }

  componentDidMount(): void {
    const {
      location: { state: historyState },
      history,
      appContext,
    } = this.props;
    appContext.hideErrorToast();
    setPageTitle(intl.get('LBL_SURVEYS_HISTORY_TITLE'));
    window.scrollTo(0, 0);

    if (historyState && historyState.recipientType) {
      this.setStateAttribute(
        {
          selectedRecipientType: historyState.recipientType,
        },
        () => {
          this.fetchListData();
          history.replace({
            state: { recipientType: SurveyRecipientType.Groups },
          });
        }
      );
    } else {
      this.fetchListData();
    }

    this.fetchSurveyStats();
  }

  componentWillUnmount(): void {
    this.source.cancel();
  }

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  groupsToggleButtonRef = createRef<HTMLButtonElement>();

  membersToggleButtonRef = createRef<HTMLButtonElement>();

  containerRef = createRef<HTMLDivElement>();

  collectIdWrapperRef = createRef<HTMLDivElement>();

  /**
   * Scroll to the collect id table
   *
   */
  scrollToCollectIdTable = (): void => {
    const HEADER_HEIGHT = 120;
    if (this.collectIdWrapperRef.current)
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: this.collectIdWrapperRef.current?.offsetTop - HEADER_HEIGHT,
      });
  };

  /**
   * Set/Update component state in the root slice
   * @param updateState Updated state as an object
   */
  setStateAttribute = (
    updateState: Partial<SurveysHistoryViewState>,
    callback?: () => void
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        ...updateState,
      }),
      callback
    );

  /**
   * Set/Update component state in the surveysList slice
   * @param updateState Updated state as an object
   */
  setSurveysListStateAttribute = (
    updateState: Partial<SurveysHistoryViewState['surveysList']>,
    callback?: () => void
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        surveysList: { ...state.surveysList, ...updateState },
      }),
      callback
    );

  /**
   * Set/Update component state in the collectIdsList slice
   * @param updateState Updated state as an object
   */
  setCollectIdsListStateAttribute = (
    updateState: Partial<SurveysHistoryViewState['collectIdsList']>,
    callback?: () => void
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        collectIdsList: { ...state.collectIdsList, ...updateState },
      }),
      callback
    );

  /**
   * Fetch list data based on available granted permissions
   */
  fetchListData = (): void => {
    const { canCreateSurveys, canCollectIDs } = this.state;
    if (canCreateSurveys) {
      this.loadSurveysHistoryData(false, true);
    }
    if (canCollectIDs) {
      this.loadCollectIdsHistoryData(false, true);
    }
  };

  /**
   * Handle API errors
   */
  // eslint-disable-next-line react/sort-comp
  handleAPIErrors = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any,
    errorMessage?: string
  ): Promise<void> => {
    const { appContext } = this.props;
    if (error.status === HTTP_STATUS.FORBIDDEN) {
      await this.handleForbiddenErrors();
    } else {
      appContext.setErrorToastText(errorMessage);
    }
  };

  /**
   * Handle 403 errors
   */
  handleForbiddenErrors = async (): Promise<void> => {
    const {
      appContext: { getPermissions },
    } = this.props;
    this.setSurveysListStateAttribute({ status: Status.Loading });
    this.setCollectIdsListStateAttribute({ status: Status.Loading });
    /**
      Permissions have to be fetched explicitly here because 
      the respective list that pertains to the lost permission 
      has to be removed from the page. 
      See @updatePermissionsOnForbiddenStatus
    */
    await getPermissions(true);
    this.updatePermissionsOnForbiddenStatus();
  };

  /**
   * Update permissions in container state on 403 error
   */
  updatePermissionsOnForbiddenStatus = (): void => {
    const { appContext } = this.props;
    const { permissionsData } = appContext;
    const canCreateSurveys = PermissionUtil.Can(
      permissionsData.claims,
      ResourceKeys.SurveysCreate
    );
    const canCollectIDs = PermissionUtil.Can(
      permissionsData.claims,
      ResourceKeys.SurveysCollectAdditionalID
    );
    this.setStateAttribute({ canCreateSurveys, canCollectIDs });
    this.setSurveysListStateAttribute({ status: Status.Idle });
    this.setCollectIdsListStateAttribute({ status: Status.Idle });
  };

  /**
   * Fetch surveys stats data
   * Ex: tooltips data
   */
  fetchSurveyStats = async (): Promise<void> => {
    try {
      const stats = await SurveysApiInstance.GetSurveysStatsData(this.source);
      this.setCollectIdsListStateAttribute({
        tooltipStatus: stats,
      });
    } catch (error) {
      this.handleAPIErrors(error, intl.get('ERR_TOAST_SURVEYS_ERRORS'));
    }
  };

  /**
   * Fetch survey history data
   *
   * @param next Whether to fetch the next/previous page
   * @param initialLoad Whether to load the initial page
   */
  loadSurveysHistoryData = async (
    next: boolean,
    initialLoad: boolean
  ): Promise<void> => {
    const { searchTerm, selectedRecipientType, surveysList } = this.state;
    const Direction = Tables.SortDirection;
    try {
      this.setSurveysListStateAttribute({ status: Status.Loading });
      let url: string | null = '';
      if (initialLoad) {
        url = null;
      } else {
        url = next ? surveysList.next : surveysList.previous;
      }
      const result = await SurveysApiInstance.GetSurveyHistoryList(
        {
          name: searchTerm,
          pageSize: surveysList.pageSize,
          recipientType: selectedRecipientType,
          sortBy: surveysList.sortBy[0].id,
          sort: surveysList.sortBy[0].desc
            ? Direction.Descending
            : Direction.Ascending,
          surveyType: SurveyType.Survey,
          url,
        },
        this.source
      );
      if (result) {
        const { items, pagination } = result;

        this.setSurveysListStateAttribute({
          data: items,
          status: Status.Success,
          total: pagination.total,
          next: pagination.next,
          previous: pagination.previous,
          page: pagination.page,
          pageSize: pagination.pageSize,
        });
      }
    } catch (error) {
      this.setSurveysListStateAttribute({
        status: Status.Error,
        total: 0,
        next: null,
        previous: null,
        page: 0,
        pageSize: Constraints.LazyLoadingPageSizeExtra,
      });
      this.handleAPIErrors(error, intl.get('ERR_TOAST_SURVEYS_ERRORS'));
    }
  };

  /**
   * Fetch collect IDs history data
   *
   * @param next Whether to fetch the next/previous page
   * @param initialLoad Whether to load the initial page
   */
  loadCollectIdsHistoryData = async (
    next: boolean,
    initialLoad: boolean
  ): Promise<void> => {
    const {
      location: { state: historyState },
      history,
    } = this.props;
    const { searchTerm, selectedRecipientType, collectIdsList } = this.state;
    const Direction = Tables.SortDirection;
    try {
      this.setCollectIdsListStateAttribute({ status: Status.Loading });
      let url: string | null = '';
      if (initialLoad) {
        url = null;
      } else {
        url = next ? collectIdsList.next : collectIdsList.previous;
      }
      const result = await SurveysApiInstance.GetSurveyHistoryList(
        {
          name: searchTerm,
          pageSize: collectIdsList.pageSize,
          recipientType: selectedRecipientType,
          sortBy: collectIdsList.sortBy[0].id,
          sort: collectIdsList.sortBy[0].desc
            ? Direction.Descending
            : Direction.Ascending,
          surveyType: SurveyType.CollectId,
          url,
        },
        this.source
      );
      if (result) {
        const { items, pagination } = result;

        this.setCollectIdsListStateAttribute({
          data: items,
          status: Status.Success,
          total: pagination.total,
          next: pagination.next,
          previous: pagination.previous,
          page: pagination.page,
          pageSize: pagination.pageSize,
        });

        if (
          initialLoad &&
          historyState &&
          historyState.surveyType === SurveyType.CollectId
        ) {
          this.scrollToCollectIdTable();
          history.replace({ state: {} });
        }
      }
    } catch (error) {
      this.setCollectIdsListStateAttribute({
        status: Status.Error,
        total: 0,
        next: null,
        previous: null,
        page: 0,
        pageSize: Constraints.LazyLoadingPageSizeExtra,
      });
      this.handleAPIErrors(error, intl.get('ERR_TOAST_SURVEYS_ERRORS'));
    }
  };

  /**
   * Get send count for recipient type
   * @returns {number} Send count
   */
  getSendCount = (recipients: Array<FilteredRecipients>): number => {
    const { selectedRecipientType } = this.state;
    let count = 0;
    if (selectedRecipientType === SurveyRecipientType.Groups) {
      count = recipients.length;
    } else {
      recipients.forEach(({ members }) => {
        count += members.length;
      });
    }
    return count;
  };

  /**
   * Handle Collect ID status check
   *
   * @param event Toggle change event
   * @param row react=table row props
   */
  handleCollectIdToggleStatusCheck = async (
    event: ChangeEvent<HTMLInputElement>,
    row
  ): Promise<void> => {
    const { appContext } = this.props;
    const { checked } = event.target;
    const { id: rowId } = row;
    try {
      this.setCollectIdsListStateAttribute(
        {
          focusedStatusToggleRowId: rowId,
          statusReloadRow: rowId,
        },
        () => {
          if (!checked) {
            /* If a Collect ID request is being turned off, toggle 
              it immediately instead of displaying the warning popup */
            this.handleCollectIdToggleChange();
          }
        }
      );
      if (checked) {
        const result = await SurveysApiInstance.GetCollectIdToggleStatus(
          rowId,
          this.source
        );
        if (result) {
          const {
            excludedMemberCount,
            excludedGroups,
            isDuplicateSurvey,
            finalRecipients,
            existingCollectIds,
          } = result;
          const sendCount = this.getSendCount(finalRecipients);
          this.setStateAttribute({
            popupDetails: {
              sendCount,
              excludedMemberCount,
              excludedGroups,
              isDuplicateSurvey,
              finalRecipients,
              existingCollectIds,
            },
            displayWarningPopup: true,
          });
        }
      }
    } catch (error) {
      this.setCollectIdsListStateAttribute({
        focusedStatusToggleRowId: '',
      });
      this.setStateAttribute({
        popupDetails: {
          sendCount: 0,
          excludedMemberCount: 0,
          excludedGroups: [],
          isDuplicateSurvey: false,
          finalRecipients: [],
          existingCollectIds: [],
        },
        displayWarningPopup: false,
      });
      if (error instanceof ApiError) {
        if (error.status === HTTP_STATUS.FORBIDDEN) {
          await this.handleForbiddenErrors();
        } else {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_SURVEYS_ERRORS',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_SURVEYS_ERRORS'));
      }
    } finally {
      this.setCollectIdsListStateAttribute({
        statusReloadRow: null,
      });
    }
  };

  /**
   * Handle Collect ID toggle change
   */
  handleCollectIdToggleChange = async (): Promise<void> => {
    const { appContext } = this.props;
    const {
      collectIdsList: {
        data,
        focusedStatusToggleRowId: rowId,
        tooltipStatus: {
          item: { tooltipStatus },
        },
      },
    } = this.state;
    try {
      this.setCollectIdsListStateAttribute({
        statusReloadRow: rowId,
      });
      const collectIdRequest = data.find(({ id }) => id === rowId);
      if (collectIdRequest) {
        const result = await SurveysApiInstance.UpdateCollectIdToggleStatus(
          rowId,
          !collectIdRequest.activeCollectId,
          this.source
        );
        const { item } = result;
        if (item && !isEmpty(item)) {
          const updatedData = data.map((collectId: SurveysHistoryViewModel) => {
            if (collectId.id === rowId) {
              return {
                ...collectId,
                activeCollectId: item.activeCollectId,
                receiverStats: item.receiverStats,
                status: item.status,
              };
            }
            return collectId;
          });
          this.setCollectIdsListStateAttribute({ data: updatedData });
          if (
            !tooltipStatus.find(
              (tooltip) =>
                tooltip.tooltip ===
                SurveyPopoverType.HISTORY_COLLECT_ID_STATUS_CHANGE
            )?.status
          ) {
            this.fetchSurveyStats();
          }
        } else {
          throw new Error(
            intl.get('ERR_SURVEYS_SURVEY_HISTORY_TOGGLE_INVALID_RESPONSE')
          );
        }
      } else {
        throw new Error(
          intl.get('ERR_SURVEYS_SURVEY_HISTORY_TOGGLE_REQUEST_NOT_FOUND')
        );
      }
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status === HTTP_STATUS.FORBIDDEN) {
          await this.handleForbiddenErrors();
        } else {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_SURVEYS_ERRORS',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_SURVEYS_ERRORS'));
      }
    } finally {
      this.setCollectIdsListStateAttribute({
        statusReloadRow: null,
        focusedStatusToggleRowId: '',
      });
      this.setStateAttribute({
        popupDetails: {
          sendCount: 0,
          excludedMemberCount: 0,
          excludedGroups: [],
          isDuplicateSurvey: false,
          finalRecipients: [],
          existingCollectIds: [],
        },
      });
    }
  };

  /**
   * Handle page change for the surveys list
   *
   * @param next Whether to fetch the next/previous page
   */
  handleSurveysPageChange = (next: boolean): void => {
    this.loadSurveysHistoryData(next, false);
  };

  /**
   * Handle page size change for the surveys list
   *
   * @param size Selected page size
   */
  handleSurveysPageSizeChange = (size: number): void => {
    this.setSurveysListStateAttribute({ pageSize: size }, (): void => {
      this.loadSurveysHistoryData(false, true);
    });
  };

  /**
   * Handle sortBy change for the surveys list
   *
   * @param sortBy Selected sorting column and order
   */
  handleSurveysSortByChange = (sortBy: Array<SortByItem>): void => {
    this.setSurveysListStateAttribute({ sortBy }, (): void => {
      this.loadSurveysHistoryData(false, true);
    });
  };

  /**
   * Handle page change for the collect IDs list
   *
   * @param next Whether to fetch the next/previous page
   */
  handleCollectIdsPageChange = (next: boolean): void => {
    this.loadCollectIdsHistoryData(next, false);
  };

  /**
   * Handle page size change for the collect IDs list
   *
   * @param size Selected page size
   */
  handleCollectIdsPageSizeChange = (pageSize: number): void => {
    this.setCollectIdsListStateAttribute({ pageSize }, (): void => {
      this.loadCollectIdsHistoryData(false, true);
    });
  };

  /**
   * Handle sortBy change for the collect IDs list
   *
   * @param sortBy Selected sorting column and order
   */
  handleCollectIdsSortByChange = (sortBy: Array<SortByItem>): void => {
    this.setCollectIdsListStateAttribute({ sortBy }, (): void => {
      this.loadCollectIdsHistoryData(false, true);
    });
  };

  /**
   * Handle refetching data for both lists
   *
   * @param surveyType Type of list to update
   */
  handleRefetchData = (surveyType: SurveyType): void => {
    if (surveyType === SurveyType.Survey) {
      this.loadSurveysHistoryData(false, true);
    } else {
      this.loadCollectIdsHistoryData(false, true);
    }
  };

  /**
   * Reload delivery status for surveys
   *
   * @param event Click event
   */
  handleReloadSurveyStatus = async (
    event: React.MouseEvent<HTMLButtonElement>,
    row
  ): Promise<void> => {
    const {
      surveysList: { data },
    } = this.state;
    const { id } = row;
    try {
      this.setSurveysListStateAttribute({ statusReloadRow: id });
      const result = await SurveysApiInstance.GetSurveyProcessedStatus(
        id,
        this.source
      );
      const { status } = result.item;
      const updatedData = data.map((survey: SurveysHistoryViewModel) => {
        if (survey.id === id) {
          return {
            ...survey,
            status,
          };
        }
        return survey;
      });
      this.setSurveysListStateAttribute({
        data: updatedData,
        status: Status.Success,
      });
    } catch (error) {
      this.handleAPIErrors(error, intl.get('ERR_TOAST_SURVEYS_ERRORS'));
    } finally {
      this.setSurveysListStateAttribute({ statusReloadRow: null });
    }
  };

  /**
   * Reload delivery status for Collect ID requests
   *
   * @param event Click event
   */
  handleReloadCollectIdStatus = async (
    event: React.MouseEvent<HTMLButtonElement>,
    row
  ): Promise<void> => {
    const {
      collectIdsList: { data },
    } = this.state;
    const { id } = row;
    try {
      this.setCollectIdsListStateAttribute({ statusReloadRow: id });
      const result = await SurveysApiInstance.GetSurveyProcessedStatus(
        id,
        this.source
      );
      const { status } = result.item;
      const updatedData = data.map((collectId: SurveysHistoryViewModel) => {
        if (collectId.id === id) {
          return {
            ...collectId,
            status,
          };
        }
        return collectId;
      });
      this.setCollectIdsListStateAttribute({
        data: updatedData,
        status: Status.Success,
      });
    } catch (error) {
      this.handleAPIErrors(error, intl.get('ERR_TOAST_SURVEYS_ERRORS'));
    } finally {
      this.setCollectIdsListStateAttribute({ statusReloadRow: null });
    }
  };

  /**
   * Handle export correlation data as excel
   *
   * @param searchTerm Current search value
   */
  handleExportCorrelationData = async (
    selectedRowId: string
  ): Promise<void> => {
    const { appContext } = this.props;
    try {
      this.setSurveysListStateAttribute({ statusReloadRow: selectedRowId });
      this.setCollectIdsListStateAttribute({ statusReloadRow: selectedRowId });
      const blob = await SurveysApiInstance.GetSurveyCorrelationExcel(
        selectedRowId,
        this.source
      );
      const dateTime = formatDate(new Date(), DateFormatType.ExcelFilename);
      const fileName = `DreamSaveInsight-Surveys-${selectedRowId}_${dateTime}.xlsx`;
      download(blob, fileName);
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status === HTTP_STATUS.FORBIDDEN) {
          await this.handleForbiddenErrors();
        } else {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_SURVEYS_ERRORS',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_SURVEYS_ERRORS'));
      }
    } finally {
      this.setSurveysListStateAttribute({ statusReloadRow: null });
      this.setCollectIdsListStateAttribute({ statusReloadRow: null });
    }
  };

  /**
   * Handle search term change event
   *
   * @param searchTerm Current search value
   */
  handleSearchTermChange = (searchTerm = ''): void => {
    this.setStateAttribute({ searchTerm }, (): void => {
      this.fetchListData();
    });
  };

  /**
   * Handle recipient type change
   *
   * @param recipientType Selected recipient type
   */
  handleRecipientTypeChange = (
    selectedRecipientType: SurveyRecipientType
  ): void => {
    const { canCreateSurveys, canCollectIDs } = this.state;
    this.setStateAttribute({ selectedRecipientType }, (): void => {
      if (canCreateSurveys) {
        this.loadSurveysHistoryData(false, true);
      }
      if (canCollectIDs) {
        this.loadCollectIdsHistoryData(false, true);
      }
    });
    /* Due to an inherent issue in the toggle button group, 
      we have to manually blur the button of the selected 
      recipient type after it is toggled. */
    if (
      selectedRecipientType === SurveyRecipientType.Groups &&
      this.groupsToggleButtonRef &&
      this.groupsToggleButtonRef.current
    ) {
      this.groupsToggleButtonRef.current.blur();
    }
    if (
      selectedRecipientType === SurveyRecipientType.Members &&
      this.membersToggleButtonRef &&
      this.membersToggleButtonRef.current
    ) {
      this.membersToggleButtonRef.current.blur();
    }
  };

  /**
   * Handles continue button click on the Collect ID warning popup
   */
  handleOnContinueCollectIdWarning = (): void => {
    this.setStateAttribute({
      displayWarningPopup: false,
    });
    this.handleCollectIdToggleChange();
  };

  /* Handles dismissing the Collect ID warning popup
   */
  handleOnCancelCollectIdWarning = (): void => {
    this.setStateAttribute({
      displayWarningPopup: false,
    });
    this.setCollectIdsListStateAttribute({ focusedStatusToggleRowId: '' });
  };

  render(): JSX.Element {
    const {
      selectedRecipientType,
      searchTerm,
      canCreateSurveys,
      canCollectIDs,
      displayWarningPopup,
      popupDetails,
      surveysList,
      collectIdsList,
    } = this.state;

    const {
      tooltipStatus: {
        item: { tooltipStatus },
      },
      status,
      data: CollectIdData,
    } = collectIdsList;
    const showGroupsSurveys =
      selectedRecipientType === SurveyRecipientType.Groups;

    const hideCollectIdStatusTooltip =
      !!tooltipStatus.find(
        (tooltip) =>
          tooltip.tooltip === SurveyPopoverType.HISTORY_COLLECT_ID_STATUS_CHANGE
      )?.status ||
      status === Status.Loading ||
      CollectIdData.length === 0;

    return (
      <div
        className={`content-container ${styles.surveysHistoryContainer}`}
        ref={this.containerRef}
      >
        <ScrollToTopOnMount />
        <Row>
          <Col xs="6">
            <DebounceInput
              minLength={2}
              debounceTimeout={TIMEOUTS.SEARCH_DEBOUNCE_TIMEOUT}
              value={searchTerm}
              onChange={(e): void =>
                this.handleSearchTermChange(e.target.value)
              }
              className={styles.search}
              placeholder={intl.get('LBL_SURVEYS_SEARCH_PLACEHOLDER')}
              disabled={
                surveysList.status === Status.Loading ||
                collectIdsList.status === Status.Loading
              }
              type="search"
            />
          </Col>
        </Row>
        <h3 className="mb-0">{intl.get('LBL_SURVEYS_SURVEY_HISTORY_TITLE')}</h3>
        <hr className="divider mb-3" />
        <Row className="d-flex align-items-center">
          <Col md={6}>
            <h3
              className="text-uppercase text-20-semibold mb-0"
              data-cy="survey-history-recipient-title"
            >
              {selectedRecipientType === SurveyRecipientType.Groups
                ? intl.get('LBL_SURVEYS_SURVEY_HISTORY_GROUPS_TITLE')
                : intl.get('LBL_SURVEYS_SURVEY_HISTORY_MEMBERS_TITLE')}
            </h3>
          </Col>
          <Col md={6} className="d-flex btn-toggle-group justify-content-end">
            <button
              ref={this.groupsToggleButtonRef}
              data-cy="survey-group-toggle"
              onClick={(): void =>
                this.handleRecipientTypeChange(SurveyRecipientType.Groups)
              }
              tabIndex={showGroupsSurveys ? -1 : 0}
              className={`btn ${showGroupsSurveys ? 'active' : ''}`}
            >
              {!showGroupsSurveys && <i className="icon-list" />}
              <span className="mr-0">
                {intl.get('BTN_SURVEYS_SURVEY_HISTORY_GROUPS_TOGGLE')}
              </span>
            </button>
            <button
              ref={this.membersToggleButtonRef}
              data-cy="survey-member-toggle"
              onClick={(): void =>
                this.handleRecipientTypeChange(SurveyRecipientType.Members)
              }
              tabIndex={showGroupsSurveys ? 0 : -1}
              className={`btn ${showGroupsSurveys ? '' : 'active'}`}
            >
              {showGroupsSurveys && <i className="icon-list" />}
              <span className="mr-0">
                {intl.get('BTN_SURVEYS_SURVEY_HISTORY_MEMBERS_TOGGLE')}
              </span>
            </button>
          </Col>
        </Row>
        {canCreateSurveys && (
          <Row className="mt-3">
            <Col xs="12" className="w-100">
              <h3 className="mb-3">
                {intl.get(
                  'LBL_SURVEYS_SURVEY_HISTORY_SURVEY_HISTORY_LIST_TITLE'
                )}
              </h3>
              <SurveysHistoryDataTable
                data={surveysList.data}
                status={surveysList.status}
                total={surveysList.total}
                hasNext={surveysList.next !== null}
                hasPrevious={surveysList.previous !== null}
                initialPage={surveysList.page}
                initialPageSize={surveysList.pageSize}
                initialSortBy={surveysList.sortBy}
                statusReloadRow={surveysList.statusReloadRow}
                onPageChange={this.handleSurveysPageChange}
                onPageSizeChange={this.handleSurveysPageSizeChange}
                onSortByChange={this.handleSurveysSortByChange}
                onRefetchData={(): void =>
                  this.handleRefetchData(SurveyType.Survey)
                }
                onReloadStatus={this.handleReloadSurveyStatus}
                onExportCorrelationData={this.handleExportCorrelationData}
              />
            </Col>
          </Row>
        )}
        <div ref={this.collectIdWrapperRef}>
          {canCollectIDs && (
            <Row className="mt-3">
              <Col xs="12">
                <h3 className="mb-4">
                  {intl.get(
                    'LBL_SURVEYS_SURVEY_HISTORY_COLLECT_IDS_LIST_TITLE'
                  )}
                </h3>

                <CollectIdsHistoryDataTable
                  data={collectIdsList.data}
                  status={collectIdsList.status}
                  total={collectIdsList.total}
                  hasNext={collectIdsList.next !== null}
                  hasPrevious={collectIdsList.previous !== null}
                  initialPage={collectIdsList.page}
                  initialPageSize={collectIdsList.pageSize}
                  initialSortBy={collectIdsList.sortBy}
                  showCollectIdGuideTooltip={!hideCollectIdStatusTooltip}
                  statusReloadRow={collectIdsList.statusReloadRow}
                  containerRef={this.containerRef}
                  onPageChange={this.handleCollectIdsPageChange}
                  onPageSizeChange={this.handleCollectIdsPageSizeChange}
                  onSortByChange={this.handleCollectIdsSortByChange}
                  onRefetchData={(): void =>
                    this.handleRefetchData(SurveyType.CollectId)
                  }
                  onReloadStatus={this.handleReloadCollectIdStatus}
                  onStatusToggleChange={this.handleCollectIdToggleStatusCheck}
                  onExportCorrelationData={this.handleExportCorrelationData}
                />
              </Col>
            </Row>
          )}
        </div>
        <SurveySendWarningPopup
          display={displayWarningPopup}
          sendCount={popupDetails.sendCount}
          excludedMemberCount={popupDetails.excludedMemberCount}
          excludedGroups={popupDetails.excludedGroups}
          surveyType={SurveyType.CollectId}
          recipientType={selectedRecipientType}
          isDuplicateSurvey={popupDetails.isDuplicateSurvey}
          viewPoint={SurveySendViewPoint.History}
          conflictingRequests={popupDetails.existingCollectIds}
          onCancel={this.handleOnCancelCollectIdWarning}
          onContinue={this.handleOnContinueCollectIdWarning}
        />
      </div>
    );
  }
}

export default SurveysHistoryView;

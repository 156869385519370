/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { Fragment, useEffect, useMemo, FC as ReactFC } from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import * as intl from 'react-intl-universal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import ReactTooltip from 'react-tooltip';

import { formatDate } from 'helpers/DateFormat';
import IndeterminateCheckbox from 'modules/private/group/components/group-list/indeterminate-checkbox/IndeterminateCheckbox';
import TableConfig from 'modules/private/projects/containers/project-users-view/TableConfig';
import ProjectUsersViewModel from 'modules/private/projects/containers/projects-view/ProjectUsersViewModel';
import DataTableFooter from 'shared/components/data-table-footer/DataTableFooter';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import ImageComponent from 'shared/components/image/ImageComponent';
import DateFormatType from 'shared/enums/DateFormatType';
import Status from 'shared/enums/Status';
import blankAvatar from 'shared/static/img/project-placeholders/blank_avatar.svg';

import DummyRow from './dummy-row-project-user/DummyRowProjectUser';
import JobRoleCell from './job-role-cell/JobRoleCell';
import ProjectUsersHeaderCell from './project-users-header-cell/ProjectUsersHeaderCell';
import ProjectUserDataTableProps from './ProjectUsersDataTableProps';
import SupervisorCell from './supervisor-cell/SupervisorCell';

const SortableColumns = TableConfig.sortCols;

const ProjectUsersDataTable: ReactFC<ProjectUserDataTableProps> = (
  props: ProjectUserDataTableProps
) => {
  const {
    data,
    updatedData,
    editMode,
    canAddUsers,
    jobRoles,
    jobRolesStatus,
    createJobRoleStatus,
    lastCreatedJobRoleId,
    lastJobRoleEditedRowId,
    supervisors,
    supervisorsFiltered,
    supervisorsStatus,
    controlledPageCount,
    pagination,
    initialPageSize,
    initialPageIndex,
    initialSortBy,
    selectedRows,
    editStatus,
    status,
    createJobRoleError,
    clearJobRoleError,
    setSelectedRows,
    onRowClick,
    onUpdateUser,
    onCreateJobRole,
    updateSortBy,
    updatePageSize,
    updatePage,
  } = props;

  /**
   * Renders the user's name and image
   *
   * @param cellProps Cell props passed down from react-table
   * @returns {JSX.Element} JSX snippet containing the component
   */
  const renderUserName = (cellProps): JSX.Element => {
    const {
      original: { image },
    } = cellProps.row;
    return (
      <div className="d-flex align-items-center">
        <ImageComponent
          ignoreBlur
          className="rounded-circle object-fit-cover"
          src={image}
          alt="User"
          fallbackSrc={blankAvatar}
          width={25}
          height={25}
        />
        <EllipsisTooltip
          tag="span"
          data-place="bottom"
          data-for="insTooltip"
          data-tip={cellProps.value}
          data-class="overflow-wrap"
          className="table-content ml-3"
        >
          {cellProps.value}
        </EllipsisTooltip>
      </div>
    );
  };

  /**
   * Renders the formatted user number
   *
   * @param cellProps Cell props passed down from react-table
   * @returns {string} Formatted user number
   */
  const renderUserId = ({ value }): string =>
    value ? `#${String(value)}` : intl.get('LBL_NA');

  /**
   * Renders the formatted last login date
   *
   * @param cellProps Cell props passed down from react-table
   * @returns {string} Formatted last login date
   */
  const renderLastLogin = ({ value }): string =>
    value ? formatDate(value, DateFormatType.DateTime) : intl.get('LBL_NA');

  /**
   * Handles disabling the column sort functionality
   *
   * @param column Name of the column
   * @param checkSelectedRows To consider selected row count
   * @param checkEditMode To consider edit mode
   * @returns {boolean}
   */
  const handleDisableSorting = (
    column: string,
    checkSelectedRows?: boolean,
    checkEditMode?: boolean
  ): boolean =>
    !SortableColumns.has(column) ||
    (checkSelectedRows ? !isEmpty(selectedRows) : false) ||
    (checkEditMode ? editMode : false) ||
    data.length === 0;

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        /* Make this column a groupByBoundary. This ensures 
        that groupBy columns are placed after it */
        groupByBoundary: true,
        className: 'no-bg checkbox-td',
        /* The cell can use the individual row's getToggleRowSelectedProps
        method to the render a checkbox */
        /* eslint-disable react/display-name */
        Cell: ({ row }): JSX.Element => (
          <IndeterminateCheckbox
            {...row.getToggleRowSelectedProps()}
            disabled={!canAddUsers}
          />
        ),
      },
      {
        dataTitle: intl.get('LBL_PROJECT_USERS_USER_COLUMN_TITLE'),
        Header: ProjectUsersHeaderCell,
        accessor: 'name',
        className: 'text-bold truncate-long-words',
        disableSortBy: handleDisableSorting('name', false, false),
        Cell: renderUserName,
      },
      {
        dataTitle: intl.get('LBL_PROJECT_USERS_USER_ID_COLUMN_TITLE'),
        Header: ProjectUsersHeaderCell,
        accessor: 'userNumber',
        className: 'text-bold text-right truncate-long-words',
        helpText: intl.get('LBL_PROJECT_USERS_USER_ID_COLUMN_TITLE_HINT'),
        disableSortBy: handleDisableSorting('userNumber', false, false),
        Cell: renderUserId,
      },
      {
        dataTitle: intl.get('LBL_PROJECT_USERS_JOB_ROLE_COLUMN_TITLE'),
        Header: ProjectUsersHeaderCell,
        accessor: (row: ProjectUsersViewModel): string =>
          get(row, 'jobRole.role', intl.get('LBL_NA')),
        id: 'jobRole',
        className: `text-bold ${
          editMode && isEmpty(selectedRows) ? '' : 'truncate-long-words'
        }`,
        helpText: intl.get('LBL_PROJECT_USERS_JOB_ROLE_COLUMN_TITLE_HINT'),
        disableSortBy: handleDisableSorting('jobRole', true, true),
        Cell: JobRoleCell,
      },
      {
        dataTitle: intl.get('LBL_PROJECT_USERS_SUPERVISOR_COLUMN_TITLE'),
        Header: ProjectUsersHeaderCell,
        accessor: (row: ProjectUsersViewModel): string =>
          get(row, 'supervisor.name', intl.get('LBL_NONE')),
        id: 'supervisor',
        className: `text-bold ${
          editMode && isEmpty(selectedRows) ? '' : 'truncate-long-words'
        }`,
        helpText: intl.get('LBL_PROJECT_USERS_SUPERVISOR_COLUMN_TITLE_HINT'),
        disableSortBy: handleDisableSorting('supervisor', true, true),
        Cell: SupervisorCell,
      },
      {
        dataTitle: intl.get('LBL_PROJECT_USERS_LAST_LOGIN_COLUMN_TITLE'),
        Header: ProjectUsersHeaderCell,
        accessor: 'lastLogin',
        className: 'text-bold text-right',
        helpText: intl.get('LBL_PROJECT_USERS_LAST_LOGIN_COLUMN_TITLE_HINT'),
        disableSortBy: handleDisableSorting('lastLogin', false, false),
        Cell: renderLastLogin,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, editMode, selectedRows, jobRolesStatus, supervisorsStatus]
  );

  /**
   * Replace updated users in user data
   */
  const displayData = data.map((user: ProjectUsersViewModel) => {
    const idKeys = Object.keys(updatedData);
    if (idKeys.includes(user.userId)) {
      return updatedData[user.userId];
    }
    return user;
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    toggleHideColumn,
    page,
    pageCount,
    gotoPage,
    toggleAllRowsSelected,
    setPageSize,
    setSortBy,
    state: { pageIndex, selectedRowIds },
  } = useTable(
    {
      columns,
      data: displayData,
      getRowId: (row: ProjectUsersViewModel) => row.userId,
      initialState: {
        pageSize: initialPageSize,
        pageIndex: initialPageIndex,
        sortBy: initialSortBy,
        selectedRowIds: selectedRows,
      },
      stateReducer: (newState, action) => {
        const { type } = action;
        if (type === 'toggleAllRowsSelected') {
          return {
            ...newState,
            selectedRowIds: action.value ? selectedRows : {},
          };
        }
        return newState;
      },
      manualSortBy: true,
      manualPagination: true,
      autoResetSortBy: false,
      autoResetPage: false,
      disableMultiSort: true,
      disableSortRemove: true,
      autoResetHiddenColumns: false,
      autoResetSelectedRows: false,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const initialSortByValue = initialSortBy[0];

  const initSortId = initialSortByValue
    ? initialSortByValue.id
    : TableConfig.defaultSort;
  const initSortDesc = initialSortByValue
    ? initialSortByValue.desc
    : TableConfig.defaultSortDesc;

  useEffect(() => {
    const newSortBy = [{ id: initSortId, desc: initSortDesc }];
    setSortBy(newSortBy);
  }, [initSortDesc, initSortId, setSortBy]);

  useEffect(() => {
    gotoPage(initialPageIndex);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [initialPageIndex]);

  useEffect(() => {
    setPageSize(initialPageSize);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [initialPageSize]);

  /**
   * This is a fix for an edge case where the backend sends an incorrect
   * pagination total (total users count) and there are no rows (list items)
   * when the user navigates to a new page (eg. actual row count and pageSize
   * are both 10, whereas the pagination total shows 11, and the user navigates
   * to the 2nd page).
   * In cases like this, the user will be navigated to the previous page.
   */
  useEffect(() => {
    const count = page.length;
    if (count === 0) {
      if (pageIndex - 1 >= 0) {
        gotoPage(pageIndex - 1);
      } else {
        gotoPage(0);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [page]);

  useEffect(() => {
    setSelectedRows(selectedRowIds);
  }, [selectedRowIds]);

  useEffect(() => {
    toggleHideColumn('selection', !editMode);
    if (!editMode) {
      toggleAllRowsSelected(false);
    }
  }, [editMode]);

  useEffect(() => {
    requestAnimationFrame(() => {
      ReactTooltip.rebuild();
    });
  }, [data, editMode, selectedRows, jobRolesStatus, supervisorsStatus]);

  /**
   * Handle sortBy change event
   *
   * @param event Column header click event
   */
  const handleChangeSortBy = (
    event: React.MouseEvent<HTMLSpanElement>
  ): void => {
    const dataSet = event.currentTarget.dataset;
    if (dataSet.sort) {
      const { id, canSort, isSorted, isSortedDesc, sortDescFirst } = JSON.parse(
        dataSet.sort
      );
      if (canSort && id !== null && id !== undefined) {
        updateSortBy([
          {
            id,
            desc: isSorted ? !isSortedDesc : sortDescFirst,
          },
        ]);
      }
    }
  };

  const isEmptyError =
    status !== Status.Loading &&
    (status === Status.Error || data.length <= 0) &&
    editStatus !== Status.Error;

  /**
   * Renders a table row
   *
   * @param row Row props passed down from react-table
   * @param cell Cell props passed down from react-table
   * @returns {JSX.Element} JSX snippet containing the row component
   */
  const renderTd = (row, cell): JSX.Element => {
    const customProps =
      onRowClick && cell.column.id !== 'selection'
        ? {
            onClick: (): void => onRowClick(row),
            style: { cursor: 'default' },
          }
        : {};

    let customCellProps;
    switch (cell.column.id) {
      case 'jobRole':
        customCellProps = {
          editMode,
          selectedRows,
          jobRoles,
          jobRolesStatus,
          createJobRoleStatus,
          lastJobRoleEditedRowId,
          lastCreatedJobRoleId,
          createJobRoleError,
          clearJobRoleError,
          onCreateJobRole,
          onUpdateUser,
        };
        break;
      case 'supervisor':
        customCellProps = {
          editMode,
          selectedRows,
          supervisors,
          supervisorsFiltered,
          supervisorsStatus,
          onUpdateUser,
        };
        break;
      default:
        break;
    }
    return (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          'data-title': cell.column.dataTitle,
          ...customProps,
        })}
      >
        <EllipsisTooltip
          tag="span"
          data-place="bottom"
          data-for="insTooltip"
          data-tip={cell.value}
          data-class="overflow-wrap"
          className="table-content text-14-semibold"
        >
          {cell.render('Cell', customCellProps)}
        </EllipsisTooltip>
      </td>
    );
  };

  return (
    <>
      <div className="insight-table-container pt-0">
        {status === Status.Loading ? (
          <SkeletonTheme color="#fafaf5" highlightColor="#ffffff">
            <Skeleton height={40} />
            <Skeleton height={62} count={10} />
          </SkeletonTheme>
        ) : (
          <table className="insight-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    if (column.id === 'selection') {
                      return (
                        <th key={column.id} {...column.getHeaderProps()} />
                      );
                    }
                    return (
                      <th
                        key={column.id}
                        {...column.getHeaderProps({
                          className: column.headerClassName ?? column.className,
                        })}
                      >
                        <span
                          data-sort={JSON.stringify({
                            id: column.id,
                            canSort: column.canSort,
                            isSorted: column.isSorted,
                            isSortedDesc: column.isSortedDesc,
                            sortDescFirst: column.sortDescFirst,
                          })}
                          className="table-label text-12-bold text-uppercase"
                          {...column.getSortByToggleProps({
                            title: '',
                            onClick: handleChangeSortBy,
                          })}
                        >
                          {column.render('Header', { meta: pagination })}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  const className = row.isSelected ? 'row-selected' : '';
                  return (
                    <tr
                      key={row.id}
                      {...row.getRowProps()}
                      className={className}
                    >
                      {row.cells.map((cell, index: number) => (
                        <Fragment key={index}>{renderTd(row, cell)}</Fragment>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <DummyRow
                  headers={headerGroups}
                  isError={status === Status.Error}
                />
              )}
            </tbody>
          </table>
        )}
        {isEmptyError && (
          <div className="no-data-message">
            <div className="title">{intl.get('ERR_CHART_EMPTY_TITLE')}</div>
            <div>{intl.get('ERR_GROUPS_EMPTY_MESSAGE')}</div>
          </div>
        )}
        <DataTableFooter
          pageSizeDisabled={isEmptyError}
          loading={status === Status.Loading}
          totalResults={pagination.total}
          gotoPage={updatePage}
          pageSize={initialPageSize}
          setPageSize={updatePageSize}
          pageCount={pageCount}
          pageIndex={initialPageIndex}
        />
      </div>
    </>
  );
};

export default ProjectUsersDataTable;

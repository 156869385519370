import { FC as ReactFC } from 'react';

import isNil from 'lodash/isNil';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';

import { getFormattedNumber } from 'helpers/NumberFormat';
import CommonError from 'modules/private/finances/components/key-metrics/common-error/CommonError';

import FinancialBenefitsProps from './FinancialBenefitsProps';

const FinancialBenefits: ReactFC<FinancialBenefitsProps> = (
  props: FinancialBenefitsProps
) => {
  const { data } = props;
  const { error, loading } = props;

  /**
   * Formats numbers for display
   *
   * @param value Value to be formatted
   * @param isRate Specifies whether to suffix with a percentage sign
   * @returns {string} Formatted value
   */
  const prepareValue = (value?: number | null, isRate?: boolean): string => {
    if (error) {
      return intl.get('LBL_NA');
    }
    if (!isNil(value)) {
      return isRate
        ? `${getFormattedNumber(value)}%`
        : getFormattedNumber(value);
    }
    return intl.get('LBL_NA');
  };

  return (
    <BlockUi tag="div" blocking={loading} className="content-box">
      <CommonError error={error} />
      <div className="content-box-title">
        <h3 className="main-title">
          {intl.get('LBL_KM_FINANCIAL_BENEFITS_TO_MEMBERS')}
          <span className="help">
            <i
              className="icon-help"
              data-for="insTooltip"
              data-tip={intl.get('LBL_KM_FINANCIAL_BENEFITS_TO_MEMBERS_HINT')}
            />
          </span>
        </h3>
      </div>
      <div className="content-section">
        <table className="content-table">
          <thead>
            <tr>
              <th>{intl.get('LBL_KM_DESCRIPTION')}</th>
              <th className="value">{intl.get('LBL_KM_VALUE')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {intl.get('LBL_KM_AVERAGE_SAVINGS_PER_MEMBER')}{' '}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get(
                      'LBL_KM_AVERAGE_SAVINGS_PER_MEMBER_HINT'
                    )}
                  />
                </span>
              </td>
              <td className="value">
                {prepareValue(data.averageSavingsPerMember)}
              </td>
            </tr>
            <tr>
              <td>
                {intl.get('LBL_KM_AVERAGE_GROUP_SHARE_OUT_PROJECTION')}{' '}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get(
                      'LBL_KM_AVERAGE_GROUP_SHARE_OUT_PROJECTION_HINT'
                    )}
                  />
                </span>
              </td>
              <td className="value">
                {prepareValue(data.averageGroupShareOutProjection)}
              </td>
            </tr>
            <tr>
              <td>
                {intl.get('LBL_KM_AVERAGE_MEMBER_SHARE_OUT_PROJECTION')}{' '}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get(
                      'LBL_KM_AVERAGE_MEMBER_SHARE_OUT_PROJECTION_HINT'
                    )}
                  />
                </span>
              </td>
              <td className="value">
                {prepareValue(data.averageMemberShareOutProjection)}
              </td>
            </tr>
            <tr>
              <td>
                {intl.get('LBL_KM_AVERAGE_NUM_OF_LOANS_GIVEN')}{' '}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get(
                      'LBL_KM_AVERAGE_NUM_OF_LOANS_GIVEN_HINT'
                    )}
                  />
                </span>
              </td>
              <td className="value">
                {prepareValue(data.averageNumOfLoansGiven)}
              </td>
            </tr>
            <tr>
              <td>
                {intl.get('LBL_KM_CUMULATIVE_VALUE_OF_LOANS_GIVEN')}{' '}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get(
                      'LBL_KM_CUMULATIVE_VALUE_OF_LOANS_GIVEN_HINT'
                    )}
                  />
                </span>
              </td>
              <td className="value">
                {prepareValue(data.cumulativeValueOfLoansGiven)}
              </td>
            </tr>
            <tr>
              <td>
                {intl.get('LBL_KM_PERCENTAGE_OF_MEMBERS_WHO_HAVE_TAKEN_A_LOAN')}{' '}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get(
                      'LBL_KM_PERCENTAGE_OF_MEMBERS_WHO_HAVE_TAKEN_A_LOAN_HINT'
                    )}
                  />
                </span>
              </td>
              <td className="value">
                {prepareValue(data.loanTakenMemberPercentage, true)}
              </td>
            </tr>
            <tr>
              <td>
                {intl.get('LBL_KM_ESTIMATED_GROUP_SHARE_OUT')}{' '}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get('LBL_KM_ESTIMATED_GROUP_SHARE_OUT_HINT')}
                  />
                </span>
              </td>
              <td className="value">
                {prepareValue(data.estimatedGroupShareOut)}
              </td>
            </tr>
            <tr>
              <td>
                {intl.get('LBL_KM_SOCIAL_FUND_UTILIZATION_RATE')}{' '}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get(
                      'LBL_KM_SOCIAL_FUND_UTILIZATION_RATE_HINT'
                    )}
                  />
                </span>
              </td>
              <td className="value">
                {prepareValue(data.socialFundUtilizationRate, true)}
              </td>
            </tr>
            <tr>
              <td>
                {intl.get('LBL_KM_AVERAGE_SOCIAL_FUND_PAYOUT')}{' '}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get(
                      'LBL_KM_AVERAGE_SOCIAL_FUND_PAYOUT_HINT'
                    )}
                  />
                </span>
              </td>
              <td className="value">
                {prepareValue(data.averageSocialFundPayout)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </BlockUi>
  );
};

export default FinancialBenefits;

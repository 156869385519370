/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import Select, { ActionMeta, ValueType } from 'react-select';
import { SelectComponentsProps } from 'react-select/src/Select';

import CountriesValueContainer from './countries-value-container/CountriesValueContainer';
import CountriesOption from './CountriesOption';
import countriesSelectCustomStyles from './countriesSelectCustomStyles';

const CountriesSelect: ReactFC<SelectComponentsProps> = (
  props: SelectComponentsProps
) => {
  const { options, styles, onChange } = props;

  /**
   * Custom onChange handle check function body for more info.
   *
   * @param selectedValue selectedValue
   * @param meta meta-data
   */
  const handleChange = (
    selectedValue: ValueType<CountriesOption>,
    meta: ActionMeta<CountriesOption> & { removedValue?: CountriesOption }
  ): void => {
    const { action, removedValue, option: removedOption } = meta;

    // removing all values return null, normalize this by empty array
    const value = (
      selectedValue === null ? [] : selectedValue
    ) as CountriesOption[];

    const removedItem =
      action === 'deselect-option' ? removedOption : removedValue;

    let newValue = value;
    switch (action) {
      case 'deselect-option':
      case 'remove-value':
      case 'pop-value':
        if (removedItem) {
          // cannot remove fixed options
          if (removedItem.isFixed) {
            return;
          }
          // if removed option is All Countries (*) replace that with fixed items; check comment below (1.0)
          if (removedItem.value === '*') {
            const fixedValues = options.filter(
              (val: CountriesOption) => val.isFixed
            );
            newValue = fixedValues;
          }
        }
        break;
      case 'clear':
        if (value) {
          newValue = options.filter((val: CountriesOption) => val.isFixed);
        }
        break;
      default:
        break;
    }

    // 1.0: if selected options contain All Countries (*) options, replace all with only that
    const allCountriesItem = value.find((item) => item.value === '*');
    if (allCountriesItem) {
      newValue = [allCountriesItem];
    }

    onChange(newValue, meta);
  };

  // fixed options will be omitted from the option list
  const newOptions = options.filter(
    (option: CountriesOption) => !option.isFixed
  );

  return (
    <Select
      {...props}
      options={newOptions}
      onChange={handleChange}
      styles={{ ...styles, ...countriesSelectCustomStyles }}
      components={{ ValueContainer: CountriesValueContainer }}
    />
  );
};

export default CountriesSelect;

import { FC as ReactFC } from 'react';

import qs from 'query-string';
import * as intl from 'react-intl-universal';
import { Button } from 'reactstrap';

import ModulePaths from 'constants/ModulePaths';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import Tables from 'constants/Tables';
import PermissionUtil from 'helpers/PermissionUtil';

import SettingsToolbarProps from './SettingsToolbarProps';

const SettingsToolbar: ReactFC<SettingsToolbarProps> = (
  props: SettingsToolbarProps
) => {
  const { match, context, history, userDropdown, location } = props;

  const { permissionsData } = context;
  const showActivityFeed = PermissionUtil.Can(
    permissionsData.claims,
    ResourceKeys.SettingsActivityFeed
  );

  const showUsersTab = PermissionUtil.Can(
    permissionsData.claims,
    ResourceKeys.SettingsUsers
  );

  const showMyOrgTab = PermissionUtil.Can(
    permissionsData.claims,
    ResourceKeys.SettingsMyOrgOrgName
  );

  const param = match?.params.settingsTab;

  /**
   * Handles tab navigation
   *
   * @param tab Name of tab
   */
  const handleChangeTab = (tab: string): void => {
    const search = {
      ...(tab === 'users' ? qs.parse(Tables.InitialQueryBasic) : {}),
      ...qs.parse(location.search),
    };

    history.replace({
      pathname: `${ModulePaths.SettingsPath}/${tab}`,
      search: qs.stringify(search),
    });
  };

  return (
    <header className="main-header">
      <h2 className="main-title mr-0">{intl.get('LBL_TITLE_SETTINGS')}</h2>
      <div className="header-col">
        <div className="insight-tab">
          <ul>
            {showMyOrgTab && (
              <li>
                <Button
                  onClick={(): void => handleChangeTab('org')}
                  active={param === 'org'}
                >
                  {intl.get('LBL_SETTINGS_MY_ORG_INFO')}
                </Button>
              </li>
            )}
            {showUsersTab && (
              <li>
                <Button
                  onClick={(): void => handleChangeTab('users')}
                  active={param === 'users'}
                >
                  {intl.get('LBL_SETTINGS_USERS')}
                </Button>
              </li>
            )}
            <li>
              <Button
                onClick={(): void => handleChangeTab('permissions')}
                active={param === 'permissions'}
              >
                {intl.get('LBL_SETTINGS_PERMISSIONS')}
              </Button>
            </li>
            {showActivityFeed && (
              <li>
                <Button
                  onClick={(): void => handleChangeTab('activity')}
                  active={param === 'activity'}
                >
                  {intl.get('LBL_SETTINGS_ACTIVITY_FEED')}
                </Button>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="header-col">
        &nbsp;&nbsp;
        {userDropdown}
      </div>
    </header>
  );
};

export default SettingsToolbar;

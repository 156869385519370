import { MessagingGroup } from 'api/messaging/group-filters/GroupFiltersResponse';
import MessagingFilter from 'shared/enums/MessagingFilter';
import Status from 'shared/enums/Status';

import MessageDetailsViewModel from '../../components/message-history/MessageDetailsViewModel';
import MessagingHistoryViewModel from '../../components/message-history/MessagingHistoryViewModel';

export type SortByItem = { id: string; desc: boolean };

export enum MessagingTab {
  Compose = 'compose',
  History = 'history',
}

export enum MessageHistoryType {
  Draft = 'draft',
  Sent = 'sent',
}

export type MessagingSearchValuesCompose = {
  tab: MessagingTab;
};

export type MessagingSearchValuesHistory = {
  size: number;
  sortBy: SortByItem[];
  page: number;
};

export type MessagingSearchValues = MessagingSearchValuesCompose &
  Partial<MessagingSearchValuesHistory>;

export type FilterDataItem = {
  value: string;
  label: string;
};

export type SelectedFilters = {
  countries: Array<FilterDataItem>;
  projects: Array<FilterDataItem>;
  facilitators: Array<FilterDataItem>;
  groupStatus: Array<FilterDataItem>;
  groupAge: Array<FilterDataItem>;
  lastMessaged: Array<FilterDataItem>;
};

export type FilterData = {
  data: Array<FilterDataItem>;
  status: Status;
  error: Error | null;
};

export type Filter = {
  name: string;
  value: string;
  count: number;
};

export type GroupFilter = {
  data: Array<Filter>;
  status: Status;
  error: Error | null;
};

type Compose = {
  title: string;
  body: string;
  countriesFilter: FilterData;
  projectsFilter: FilterData;
  facilitatorsFilter: FilterData;
  selectedFilters: SelectedFilters;
  groupFilters: GroupFilter;
  filteredGroups: Array<MessagingGroup>;
  lastUpdatedFilter: MessagingFilter | null;
  displayWarningPopup: boolean;
  displaySuccessPopup: boolean;
  isMessageSendClicked: boolean;
};

type Pagination = { total: number; page: number };

type MessageHistory = {
  data: Array<MessagingHistoryViewModel>;
  messageDetails: { [key: string]: MessageDetailsViewModel };
  messageDetailsStatus: Status;
  status: Status;
  statusReloadRow: string | null;
  pageCount: number;
  pagination: Pagination;
  searchTerm: string;
};

interface MessagingViewState {
  organizationId: string;
  organizationName: string;
  status: Status;
  showTranslationTooltip: boolean;
  compose: Compose;
  messageHistory: MessageHistory;
  didFiltersUpdate: boolean;
}

export default MessagingViewState;

// eslint-disable-next-line no-shadow
export enum ProjectTooltipType {
  AssignUser = 'ASSIGN_USER',
  AssignFacilitator = 'ASSIGN_FACILITATOR',
  AssignGroup = 'ASSIGN_GROUP',
}
type TooltipStatus = {
  tooltip: ProjectTooltipType;
  status: boolean;
};

type ProjectStats = {
  tooltipStatus: TooltipStatus[];
  userId: string;
  projectCount: number;
};

interface ProjectStatsResponse {
  item: ProjectStats;
}

export default ProjectStatsResponse;

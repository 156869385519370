import { v4 as uuid } from 'uuid';

type ImageFile = File & {
  image: HTMLImageElement;
};

class InviteProjectFacilitatorsFormValue {
  id: string;

  firstName: string;

  lastName: string;

  phoneNumber: string;

  supervisorId: string;

  image: ImageFile | null;

  location: string;

  startDate: string;

  isImplementingPartner: boolean;

  /**
   *
   */
  constructor() {
    this.id = uuid();
    this.firstName = '';
    this.lastName = '';
    this.phoneNumber = '';
    this.supervisorId = '';
    this.image = null;
    this.location = '';
    this.startDate = '';
    this.isImplementingPartner = false;
  }
}

export default InviteProjectFacilitatorsFormValue;

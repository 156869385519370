/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useMemo, useRef, useState, FC as ReactFC } from 'react';

import BlockUi from 'react-block-ui';
import { DebounceInput } from 'react-debounce-input';
import * as intl from 'react-intl-universal';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

import TIMEOUTS from 'constants/Timeouts';
import { formatDate } from 'helpers/DateFormat';
import {
  getFormattedNumber,
  getPercentageWithFormat,
} from 'helpers/NumberFormat';
import MobilePreview from 'shared/components/mobile-preview/MobilePreview';
import DateFormatType from 'shared/enums/DateFormatType';
import Status from 'shared/enums/Status';

import { MessageHistoryType } from '../../containers/messaging-view/MessagingViewState';
import MessageHistoryHeaderCell from './message-history-data-table/message-history-header-cell/MessageHistoryHeaderCell';
import MessageHistoryDataTable from './message-history-data-table/MessageHistoryDataTable';
import TableConfig from './message-history-data-table/TableConfig';
import styles from './messageHistory.module.scss';
import MessageHistoryProps from './MessageHistoryProps';

const SortableColumns = TableConfig.sortCols;

const MessageHistory: ReactFC<MessageHistoryProps> = (
  props: MessageHistoryProps
) => {
  const {
    data,
    orgName,
    controlledPageCount,
    pagination,
    initialPageSize,
    initialPageIndex,
    initialSortBy,
    status,
    searchTerm,
    messageDetails,
    onCopy,
    onExportRow,
    onReloadStatus,
    onSearchTermChange,
    onRowClick,
    updateSortBy,
    updatePageSize,
    updatePage,
    type,
    statusReloadRow,
  } = props;
  const sentButtonRef = useRef<HTMLButtonElement>(null);
  const draftButtonRef = useRef<HTMLButtonElement>(null);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const [viewOpen, setViewOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<string | undefined>();
  const [showSentList, setShowSentList] = useState<boolean>(
    type === MessageHistoryType.Sent
  );

  useEffect(() => {
    if (searchInputRef.current) searchInputRef.current.focus();
  }, [status]);

  /**
   * Handle list type change
   */
  const onListTypeChange = (showSent: boolean): void => {
    setShowSentList(showSent);
    if (showSent && sentButtonRef && sentButtonRef.current) {
      sentButtonRef.current.blur();
    }
    if (!showSent && draftButtonRef && draftButtonRef.current) {
      draftButtonRef.current.blur();
    }
  };

  /**
   * Toggle preview modal when "View" is clicked
   *
   * @param id Message ID
   */
  const onViewMessage = (id: string): void => {
    setSelectedRow(id);
    toggleModal();
  };

  /**
   * Toggle preview modal
   */
  const toggleModal = (): void => setViewOpen((open) => !open);

  /**
   * Handle modal closed event
   */
  const onModalClosed = (): void => setSelectedRow(undefined);

  /**
   * Renders the formatted user number
   *
   * @param cellProps Cell props passed down from react-table
   * @returns {string} Formatted user number
   */
  const renderCreatedBy = ({ value }): string =>
    value
      ? `${String(value.firstName)} ${String(value.lastName)}`
      : intl.get('LBL_NA');

  /**
   * Renders the formatted received percentage
   *
   * @param cellProps Cell props passed down from react-table
   * @returns {string} Formatted received percentage
   */
  const renderReceived = ({ value }): string =>
    value !== undefined && value !== null
      ? `${String(value)}%`
      : intl.get('LBL_NA');

  /**
   * Renders the formatted delivery status
   *
   * @param cellProps Cell props passed down from react-table
   * @returns {string} Formatted delivery status
   */
  const renderStatus = (cellProps): string | JSX.Element => {
    const { value, row } = cellProps;
    const { processing, sent, failed } = value;

    let totalCount = -1;
    if (
      typeof processing === 'number' &&
      typeof sent === 'number' &&
      typeof failed === 'number'
    ) {
      totalCount = Number(processing) + Number(sent) + Number(failed);
    }

    const processingPercentage = getPercentageWithFormat(
      Number(processing),
      totalCount
    );
    const sentPercentage = getPercentageWithFormat(Number(sent), totalCount);

    const failedPercentage = getPercentageWithFormat(
      Number(failed),
      totalCount
    );

    if (value !== undefined && value !== null) {
      return (
        <div className="d-flex align-items-center justify-content-end pr-0">
          <div
            className="col-3 p-0 mr-2"
            style={{
              visibility: processingPercentage === '100' ? 'visible' : 'hidden',
            }}
          >
            <button
              className="btn btn-secondary btn-sm p-1 mr-3"
              onClick={(e): Promise<void> => onReloadStatus(e, row)}
            >
              <i className="icon-refresh m-0" />
            </button>
          </div>
          <div className="col-9 p-0">
            <span className="d-flex align-items-center justify-content-end pr-0">
              <span className="col-8 p-0 mr-1">
                {intl.get('LBL_MESSAGING_HISTORY_PROCESSING')}:{' '}
              </span>
              <span className="col-4 p-0">{processingPercentage}%</span>
            </span>
            <span className="d-flex align-items-center justify-content-end pr-0">
              <span className="col-8 p-0 mr-1">
                {intl.get('LBL_MESSAGING_HISTORY_SENT')}:
              </span>
              <span className="col-4 p-0">{sentPercentage}%</span>
            </span>
            <span className="d-flex align-items-center justify-content-end pr-0">
              <span className="col-8 p-0 mr-1">
                {intl.get('LBL_MESSAGING_HISTORY_FAILED')}:
              </span>
              <span className="col-4 p-0">{failedPercentage}%</span>
            </span>
          </div>
        </div>
      );
    }
    return intl.get('LBL_NA');
  };

  /**
   * Renders the formatted last login date
   *
   * @param cellProps Cell props passed down from react-table
   * @returns {string} Formatted last login date
   */
  const renderLastLogin = ({ value }): string =>
    value ? formatDate(value, DateFormatType.ShortYear) : intl.get('LBL_NA');

  /**
   * Renders action buttons
   *
   * @param row row props from react-table
   * @returns JSX.ELement action buttons
   */
  const renderActions = ({ row }): JSX.Element => (
    <div className={styles.actions} data-id={row.id}>
      <button onClick={(): void => onViewMessage(row.id)}>
        <i className="icon-list" />
        {intl.get('BTN_MESSAGING_HISTORY_VIEW')}
      </button>
      <button onClick={(e): void => onCopy(e, row)}>
        <i
          className="icon-copy"
          style={{ fontSize: '18px', lineHeight: '24px' }} // TODO: Replace this icon. Get one from the designer
        />
        {intl.get('BTN_MESSAGING_HISTORY_DUPLICATE')}
      </button>
      <button onClick={(e): Promise<void> => onExportRow(e, row.id)}>
        <i className="icon-export" />
        {intl.get('BTN_MESSAGING_HISTORY_EXPORT')}
      </button>
    </div>
  );

  /**
   * Handles disabling the column sort functionality
   *
   * @param column Name of the column
   * @param checkSelectedRows To consider selected row count
   * @param checkEditMode To consider edit mode
   * @returns {boolean}
   */
  const handleDisableSorting = (column: string): boolean =>
    !SortableColumns.has(column) || data.length === 0;

  const columns = useMemo(
    () => [
      {
        dataTitle: intl.get('LBL_MESSAGING_HISTORY_MESSAGES'),
        Header: MessageHistoryHeaderCell,
        accessor: 'title',
        className: 'text-bold truncate-long-words',
        disableSortBy: handleDisableSorting('title'),
      },
      {
        dataTitle: intl.get('LBL_MESSAGING_HISTORY_CREATED_BY'),
        Header: MessageHistoryHeaderCell,
        accessor: 'creator',
        className: 'text-bold text-right truncate-long-words',
        disableSortBy: handleDisableSorting('creator'),
        Cell: renderCreatedBy,
      },
      {
        id: 'status',
        dataTitle: intl.get('LBL_MESSAGING_HISTORY_DELIVERY_STATUS'),
        Header: MessageHistoryHeaderCell,
        accessor: 'status',
        className: 'text-bold text-right',
        disableSortBy: handleDisableSorting('status'),
        Cell: renderStatus,
        helpText: intl.get('LBL_MESSAGING_HISTORY_DELIVERY_STATUS_HINT'),
      },
      {
        dataTitle: intl.get('LBL_MESSAGING_HISTORY_TARGET_GROUPS'),
        Header: MessageHistoryHeaderCell,
        accessor: 'receiverStats.targetedCount',
        headerClassName: 'text-right',
        className: 'text-bold text-right',
        helpText: intl.get('LBL_MESSAGING_HISTORY_TARGET_GROUPS_HINT'),
        disableSortBy: handleDisableSorting('receiverStats.targetedCount'),
      },
      {
        id: 'receiverStats.receivedCount',
        dataTitle: intl.get('LBL_MESSAGING_HISTORY_RECEIVED'),
        Header: MessageHistoryHeaderCell,
        accessor: (row): string => {
          const { receivedCount, targetedCount } = row.receiverStats;
          if (
            typeof receivedCount === 'number' &&
            typeof targetedCount === 'number'
          ) {
            return getFormattedNumber((receivedCount / targetedCount) * 100);
          }
          return intl.get('LBL_NA');
        },
        className: 'text-bold text-right',
        helpText: intl.get('LBL_MESSAGING_HISTORY_RECEIVED_HINT'),
        disableSortBy: handleDisableSorting('receiverStats.receivedCount'),
        Cell: renderReceived,
      },
      {
        dataTitle: intl.get('LBL_MESSAGING_HISTORY_DATE_SENT'),
        Header: MessageHistoryHeaderCell,
        accessor: 'sendDate',
        className: 'text-bold text-right',
        disableSortBy: handleDisableSorting('sendDate'),
        Cell: renderLastLogin,
      },
      {
        accessor: 'actions',
        disableSortBy: true,
        className: 'text-bold text-right',
        Cell: renderActions,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <>
      <Row className="d-flex align-items-start mr-0">
        <Col md={6}>
          <DebounceInput
            minLength={2}
            debounceTimeout={TIMEOUTS.SEARCH_DEBOUNCE_TIMEOUT}
            value={searchTerm}
            onChange={(e): void => onSearchTermChange(e.target.value)}
            className={styles.search}
            placeholder={intl.get('LBL_GD_SEARCH_PLACEHOLDER')}
            type="search"
            inputRef={searchInputRef}
          />
        </Col>
        <Col
          md={6}
          className="d-none btn-toggle-group justify-content-end pr-0"
        >
          {/* TODO Replace d-none with d-flex for drafts feature release */}
          <button
            ref={draftButtonRef}
            onClick={(): void => onListTypeChange(false)}
            tabIndex={showSentList ? 0 : -1}
            className={`btn ${showSentList === false ? 'active' : ''}`}
            disabled
          >
            {showSentList && <i className="icon-list" />}
            <span className="mr-0">
              {intl.get('BTN_MESSAGING_HISTORY_DRAFTED')}
            </span>
          </button>
          <button
            ref={sentButtonRef}
            onClick={(): void => onListTypeChange(true)}
            tabIndex={showSentList ? -1 : 0}
            className={`btn ${showSentList === true ? 'active' : ''}`}
          >
            {!showSentList && <i className="icon-list" />}
            <span className="mr-0">
              {intl.get('BTN_MESSAGING_HISTORY_SENT')}
            </span>
          </button>
        </Col>
      </Row>
      <div className="text-20-semibold mb-4 text-uppercase">
        {showSentList
          ? intl.get('LBL_MESSAGING_HISTORY_SENT_MESSAGES_TITLE')
          : intl.get('LBL_MESSAGING_HISTORY_DRAFTED_MESSAGES_TITLE')}
      </div>
      <div>
        <MessageHistoryDataTable
          data={data}
          messageDetails={messageDetails}
          controlledPageCount={controlledPageCount}
          pagination={pagination}
          initialPageSize={initialPageSize}
          initialPageIndex={initialPageIndex}
          initialSortBy={initialSortBy}
          status={status}
          statusReloadRow={statusReloadRow}
          onRowClick={onRowClick}
          updateSortBy={updateSortBy}
          updatePageSize={updatePageSize}
          updatePage={updatePage}
          columns={columns}
        />
      </div>
      {selectedRow && (
        <Modal
          size="lg"
          toggle={toggleModal}
          isOpen={viewOpen}
          onClosed={onModalClosed}
          className="w-50"
          returnFocusAfterClose={false}
          centered
        >
          <BlockUi
            tag="div"
            blocking={
              messageDetails[selectedRow].loadingStatus === Status.Loading
            }
          >
            <ModalHeader
              className="border-0 mt-4 pt-2 pb-3"
              toggle={toggleModal}
            />
            <ModalBody className={`${styles.marginBottom}`}>
              <MobilePreview
                title={messageDetails[selectedRow].title}
                body={messageDetails[selectedRow].body}
                orgName={orgName}
              />
            </ModalBody>
          </BlockUi>
        </Modal>
      )}
    </>
  );
};

export default MessageHistory;

import WidgetTarget from 'api/dashboard/widget/WidgetTarget';
import WidgetType from 'modules/private/dashboard/components/widget-metrics/widgets/WidgetType';

class WidgetPreference {
  position: number;

  type: WidgetType;

  targetData: WidgetTarget | null;

  constructor() {
    this.position = 0;
    this.type = WidgetType.Placeholder;
    this.targetData = null;
  }
}

export default WidgetPreference;

/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { components, MenuListComponentProps } from 'react-select';
import { Button, Col, Alert } from 'reactstrap';

import Constraints from 'constants/forms/Constraints';
import Status from 'shared/enums/Status';

import JobRoleOption from '../JobRoleOption';
import styles from './customMenuList.module.scss';

const { MenuList } = components;

const CustomMenuList: ReactFC<MenuListComponentProps<JobRoleOption>> = (
  props: MenuListComponentProps<JobRoleOption>
) => {
  const { selectProps, options } = props;
  const [value, setValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const maxLength = Constraints.MaxJobRoleLength;

  /**
   * Handles mousedown event on the job role input field
   *
   * @param event The mousedown event object
   */
  const handleMouseDown = (event): void => {
    event.stopPropagation();
    (inputRef.current as HTMLInputElement).focus();
  };

  /**
   * Handles touchend event on the job role input field
   *
   * @param event The touchend event object
   */
  const handleTouchEnd = (event): void => {
    event.stopPropagation();
    (inputRef.current as HTMLInputElement).focus();
  };

  /**
   * Handles keydown event on the job role input field
   *
   * @param event The keydown event object
   */
  const handleKeyDown = (event): void => {
    event.stopPropagation();
    (inputRef.current as HTMLInputElement).focus();
  };

  /**
   * Handles keypress event on the job role input field
   *
   * @param event The keydown event object
   */
  const handleKeyPress = (event): void => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      handleAddJobRole();
    }
  };

  /**
   * Handles adding a new job role through the input field
   */
  const handleAddJobRole = (): void => {
    selectProps.onCreateJobRole(value);
    setValue('');
  };

  /**
   * Checks if the input is disabled depends on loading
   * state and if input is empty
   *
   * @returns {boolean}
   */
  const isDisabled = (): boolean =>
    selectProps.isLoading || !value || value.trim().length <= 0;

  const showInfo = options.length <= 0;
  return (
    <>
      {selectProps.createJobRoleStatus === Status.Error && (
        <Col xs="12" className={styles['alert-margin']}>
          <Alert
            isOpen
            tag="div"
            fade
            className="col alert alert-danger m-0"
            role="alert"
          >
            {selectProps.createJobRoleError}
          </Alert>
        </Col>
      )}
      <MenuList {...props} />
      <Col xs="12" className="pb-4">
        <div className="pb-3 pt-2">
          <input
            ref={inputRef}
            className="form-control"
            value={value}
            autoCorrect="off"
            autoComplete="off"
            spellCheck="false"
            type="text"
            maxLength={maxLength}
            disabled={selectProps.isLoading}
            placeholder={intl.get('LBL_SETTINGS_USERS_JOB_ROLE_ENTER_JOB_ROLE')}
            onChange={(event): void => setValue(event.target.value)}
            onMouseDown={handleMouseDown}
            onTouchEnd={handleTouchEnd}
            onKeyDown={handleKeyDown}
            onKeyPress={handleKeyPress}
            onFocus={selectProps.onMenuInputFocus}
            aria-autocomplete="list"
            aria-label={selectProps['aria-label']}
            aria-labelledby={selectProps['aria-labelledby']}
          />
        </div>
        <Button
          disabled={isDisabled()}
          className={`btn-sm btn-secondary border-0 pl-0 mb-0 shadow-none ${styles.noTransform}`}
          type="button"
          onClick={handleAddJobRole}
        >
          <i className="icon-plus" />
          {intl.get('BTN_SETTINGS_USERS_ADD_JOB_ROLE')}
        </Button>
        {showInfo && (
          <div className="pt-3">
            <div className="insight-alert--success insight-alert" role="alert">
              {intl.get('LBL_SETTINGS_USERS_JOB_ROLE_ADD_INFO')}
            </div>
          </div>
        )}
      </Col>
    </>
  );
};
export default CustomMenuList;

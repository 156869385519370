/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC as ReactFC } from 'react';

import clone from 'lodash/clone';
import * as intl from 'react-intl-universal';
import NumberFormat from 'react-number-format';

import ModulePaths from 'constants/ModulePaths';
import { getRoundedNumber } from 'helpers/NumberFormat';
import WidgetTargetType from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetTargetType';
import WidgetViewModel from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetViewModel';
import DonutArea from 'shared/components/ins-charts/donut-area/DonutArea';
import GaugeWidget from 'shared/components/ins-charts/gauge/GaugeWidget';
import InsLink from 'shared/components/ins-link/InsLink';

import styles from '../widgets.module.scss';

const GroupsFormedWidget: ReactFC<{
  data: WidgetViewModel;
  setupInProgress: boolean;
}> = (props) => {
  const dimensionSettings = {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    aspectRatioWidth: 1,
    aspectRatioHeight: 0.6,
  };

  const { setupInProgress } = props;
  const { data } = clone(props);
  const { value, targetData } = data;

  let groupData;

  if (value) {
    groupData = {
      name: value.title,
      count: getRoundedNumber(value.count),
      percentage: getRoundedNumber(value.percentage),
      group: value.group,
    };
  }

  if (targetData) {
    groupData.percentageRange = targetData.percentageRange;
    groupData.fromTarget = targetData.targetFrom;
    groupData.toTarget = targetData.targetTo;
  }

  return (
    <div className="gender-widget">
      <div className="chart-container">
        {!data.targetData ||
          (data.targetData &&
            data.targetData.targetType !== WidgetTargetType.Range && (
              <DonutArea
                isLoading={false}
                value={groupData}
                dimension={dimensionSettings}
              />
            ))}
        {data.targetData &&
          data.targetData.targetType === WidgetTargetType.Range && (
            <GaugeWidget
              isLoading={false}
              value={groupData}
              dimension={dimensionSettings}
            />
          )}
      </div>
      <div className="chart-details">
        <span className="sub-title">
          {intl.get('LBL_CURRENT_GROUPS_FORMED')}
        </span>
        {groupData && (
          <span className="value">
            <NumberFormat
              value={getRoundedNumber(groupData.count)}
              displayType="text"
              thousandSeparator
            />
          </span>
        )}
      </div>

      <div className="chart-details separator">
        <span className="sub-title">{intl.get('LBL_PROJECT_TARGET')}</span>
        {data.targetData &&
          data.targetData.targetType === WidgetTargetType.None && (
            <span className="value">not set</span>
          )}

        {data.targetData &&
          data.targetData.targetType === WidgetTargetType.Value &&
          data.targetData.target && (
            <span className="value">
              <NumberFormat
                value={getRoundedNumber(data.targetData.target)}
                displayType="text"
                thousandSeparator
              />
            </span>
          )}

        {data.targetData &&
          data.targetData.targetType === WidgetTargetType.Range &&
          data.targetData.targetFrom &&
          data.targetData.targetTo && (
            <span className="value">
              <NumberFormat
                value={getRoundedNumber(data.targetData.targetFrom)}
                displayType="text"
                thousandSeparator
              />
              -
              <NumberFormat
                value={getRoundedNumber(data.targetData.targetTo)}
                displayType="text"
                thousandSeparator
              />
            </span>
          )}
        {setupInProgress === false && (
          <InsLink
            disabledClass="disabled"
            to={ModulePaths.GroupsPath}
            className={`btn btn-secondary btn-sm d-inline-flex ${styles.viewMoreButton}`}
          >
            <i className="icon-arrow-right" /> {intl.get('BTN_VIEW_MORE')}
          </InsLink>
        )}
      </div>
    </div>
  );
};

export default GroupsFormedWidget;

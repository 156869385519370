import {
  ExistingCollectId,
  FilteredRecipients,
} from 'modules/private/surveys/containers/surveys-draft-view/SurveysDraftViewState';
import SurveyRecipientType from 'shared/enums/SurveyRecipientType';
import SurveyType from 'shared/enums/SurveyType';

export enum SurveySendViewPoint {
  Drafts = 'DRAFTS',
  History = 'HISTORY',
}

interface SurveySendWarningPopupProps {
  display: boolean;
  sendCount: number;
  excludedMemberCount: number;
  surveyType: SurveyType;
  recipientType: SurveyRecipientType;
  isDuplicateSurvey: boolean;
  excludedGroups: Array<FilteredRecipients>;
  viewPoint: SurveySendViewPoint;
  conflictingRequests: Array<ExistingCollectId>;
  onCancel: () => void;
  onContinue: () => void;
}

export default SurveySendWarningPopupProps;

import { ChangeEvent, useEffect, useState, FC as ReactFC } from 'react';

import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import * as intl from 'react-intl-universal';
import { Route, Switch, withRouter } from 'react-router-dom';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import LabelKeysGA from 'constants/ga/LabelKeysGA';
import GlobalFilters from 'constants/GlobalFilters';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import { formatDate, getDateDiff } from 'helpers/DateFormat';
import { defaultGlobalFilters } from 'helpers/GlobalFilterUtils';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import PermissionUtil from 'helpers/PermissionUtil';
import BackupStatus from 'shared/enums/BackupStatus';
import DateFormatType from 'shared/enums/DateFormatType';
import GroupAge from 'shared/enums/GroupAge';

import BackupStatusFilterSelect from './backup-status-filter-select/BackupStatusFilterSelect';
import DateRangeFilterSelect from './date-range-filter-select/DateRangeFilterSelect';
import FilterToolbarProps from './FilterToolbarProps';
import GroupAgeFilterSelect from './group-age-filter-select/GroupAgeFilterSelect';
import MoreFiltersSelect from './more-filters-select/MoreFiltersSelect';
import ProjectsFilterSelect from './projects-filter-select/ProjectsFilterSelect';

const FilterToolbar: ReactFC<FilterToolbarProps> = (props) => {
  const {
    context,
    title,
    // routeProps,
    exportDisabled,
    location,
    userDropdown,
  } = props;

  const {
    permissionsData,
    dashboardSetupInProgress,
    globalFilters,
    setGlobalFilters,
    getProjectList,
    getGroupStatuses,
    startDashboardSetup,
    endDashboardSetup,
    startExcelReportDownload,
  } = context;

  const [projectsOpen, setProjectsOpen] = useState<boolean>(false);
  const [moreFiltersOpen, setMoreFiltersOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<Date[] | null[]>([null, null]);

  // prettier-ignore
  const [selectedProjects, setSelectedProjects] = useState<string[]>(() => globalFilters.projects);
  const [selectedGroupStatuses, setSelectedGroupStatuses] = useState<string[]>(
    () => globalFilters.groupStatuses
  );
  const [projectFilterMaxSelected, setProjectFilterMaxSelected] =
    useState<boolean>(false);
  const [groupStatusFilterMaxSelected, setGroupStatusFilterMaxSelected] =
    useState<boolean>(false);

  const canDownloadCSV = PermissionUtil.Can(
    permissionsData.claims,
    ResourceKeys.ExportCSV
  );

  /**
   * fetch project list to show on global filter
   * on mount
   */
  useEffect(() => {
    getProjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  /**
   * this effect syncs local selectedProjects with globalFilters.projects
   * on menu close, until then the changes will only be local since user
   * can select multiple projects at one go; unlike other filters. Only
   * when the menu is closed the project list will be synced to globalFilters
   * and data will be fetched.
   */
  useEffect(() => {
    if (projectsOpen === false) {
      if (!isEqual(selectedProjects, globalFilters.projects)) {
        handleProjectsFilterChange();
      }
    }
    getProjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsOpen]);

  /**
   * this effect handles a scenario where globalFilters.projects
   * were changed programmatically without direct user interaction
   * syncing up local selectedProjects with globalFilters.projects
   * in the said case, in all other instances, local selectedProjects
   * will be ahead of globalFilters.projects
   */
  useEffect(() => {
    if (
      projectsOpen === false &&
      !isEqual(selectedProjects, globalFilters.projects)
    ) {
      setSelectedProjects(globalFilters.projects);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(globalFilters.projects)]);

  /**
   * this effect syncs local selectedGroupStatuses with globalFilters.projects
   * on menu close, until then the changes will only be local since user
   * can select multiple group statuses at one go; unlike other filters. Only
   * when the menu is closed the group status list will be synced to globalFilters
   * and data will be fetched.
   */
  useEffect(() => {
    if (moreFiltersOpen === false) {
      if (!isEqual(selectedGroupStatuses, globalFilters.groupStatuses)) {
        handleChangeMoreFilters();
      }
    }
    getGroupStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moreFiltersOpen]);

  /**
   * this effect handles a scenario where globalFilters.groupStatuses
   * were changed programmatically without direct user interaction
   * syncing up local selectedGroupStatuses with globalFilters.groupStatuses
   * in the said case, in all other instances, local selectedGroupStatuses
   * will be ahead of globalFilters.groupStatuses
   */
  useEffect(() => {
    if (
      moreFiltersOpen === false &&
      !isEqual(selectedGroupStatuses, globalFilters.groupStatuses)
    ) {
      setSelectedGroupStatuses(globalFilters.groupStatuses);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(globalFilters.groupStatuses)]);

  useEffect(() => {
    if (
      globalFilters.fromDate &&
      globalFilters.toDate &&
      formatDate &&
      formatDate.length
    ) {
      const formattedStartDate = formatDate(
        dateRange[0],
        DateFormatType.GlobalFilter
      );
      const formattedEndDate = formatDate(
        dateRange[1],
        DateFormatType.GlobalFilter
      );

      const { fromDate, toDate } = globalFilters;

      if (fromDate !== formattedStartDate && toDate !== formattedEndDate) {
        const invalidDates =
          formatDate(fromDate) === GlobalFilters.InvalidDate ||
          formatDate(toDate) === GlobalFilters.InvalidDate;

        const invalidRange = invalidDates || getDateDiff(fromDate, toDate) <= 0;

        if (invalidRange) {
          setDateRange([null, null]);
        } else {
          const range = [new Date(fromDate), new Date(toDate)];
          setDateRange(range);
        }
      }
    } else if (!globalFilters.fromDate && !globalFilters.toDate) {
      setDateRange([null, null]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilters.toDate, globalFilters.fromDate]);

  /**
   * Handle global filters change event
   *
   * @param filters Updated global filters
   */
  const handleGlobalFilterChange = (filters): void => {
    setGlobalFilters(filters);
  };

  /**
   * Reset global filters
   */
  const resetGlobalFilters = (): void => {
    setGlobalFilters(defaultGlobalFilters);
  };

  /**
   * Toggle dashboard setup
   */
  const toggleDashboardSetupMode = (): void => {
    if (dashboardSetupInProgress) {
      endDashboardSetup();
    } else {
      startDashboardSetup();
    }
  };

  useEffect(() => {
    if (selectedProjects.length >= GlobalFilters.MaxListSelectable) {
      setProjectFilterMaxSelected(true);
    } else {
      setProjectFilterMaxSelected(false);
    }
  }, [selectedProjects]);

  useEffect(() => {
    if (selectedGroupStatuses.length >= GlobalFilters.MaxListSelectable) {
      setGroupStatusFilterMaxSelected(true);
    } else {
      setGroupStatusFilterMaxSelected(false);
    }
  }, [selectedGroupStatuses]);

  /**
   * Update local selectedProjects from user changes
   *
   * @param event ChangeEvent input
   */
  const handleUpdateProjects = (event: ChangeEvent<HTMLInputElement>): void => {
    const { checked, value } = event.target;
    if (checked) {
      if (!projectFilterMaxSelected) {
        setSelectedProjects((state) => [...state, value]);
      }
    } else {
      const newSelectedProjects = selectedProjects.filter(
        (code) => code !== value
      );
      setSelectedProjects(newSelectedProjects);
    }
  };

  /**
   * Update local selectedGroupStatuses from user changes
   *
   * @param event ChangeEvent input
   */
  const handleUpdateGroupStatuses = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const { checked, value } = event.target;
    if (checked) {
      if (!groupStatusFilterMaxSelected) {
        setSelectedGroupStatuses((state) => [...state, value]);
      }
    } else {
      const newSelectedGroupStatuses = selectedGroupStatuses.filter(
        (code) => code !== value
      );
      setSelectedGroupStatuses(newSelectedGroupStatuses);
    }
  };

  /**
   * Handle projects filter change event
   *
   * @param projects Selected projects
   * @param resetOtherFilters Whether to reset other filters
   */
  const handleProjectsFilterChange = (
    projects?: string[],
    resetOtherFilters = false
  ): void => {
    const filters = clone(globalFilters);
    if (projects) {
      filters.projects = projects;
    } else {
      filters.projects = selectedProjects;
    }
    if (resetOtherFilters) {
      filters.groupAge = GroupAge.None;
      filters.backupStatus = BackupStatus.None;
      filters.fromDate = '';
      filters.toDate = '';
      filters.groupStatuses = [];
    }
    /**
     * manage a ref of global project filters to be used on unmount
     */
    // globalProjectRef.current = filters.projects;
    const eventLabel = selectedProjects.join(', ');
    sendEventGA(
      CategoryKeysGA.GlobalFilters,
      ActionKeysGA.ChangeGlobalFilter,
      `${LabelKeysGA.Projects}:${eventLabel}`
    );

    handleGlobalFilterChange(filters);
  };

  /**
   * Handle more filters change event
   */
  const handleChangeMoreFilters = (): void => {
    const filters = clone(globalFilters);
    filters.groupStatuses = selectedGroupStatuses;

    const eventLabel = selectedGroupStatuses.join(', ');
    sendEventGA(
      CategoryKeysGA.GlobalFilters,
      ActionKeysGA.ChangeGlobalFilter,
      `${LabelKeysGA.GroupStatus}:${eventLabel}`
    );

    handleGlobalFilterChange(filters);
  };

  /**
   * Handle group age filter change event
   *
   * @param event ChangeEvent input
   * @param close Close method
   */
  const onAgeChange = (
    event: ChangeEvent<HTMLInputElement>,
    close: () => void
  ): void => {
    const filters = clone(globalFilters);
    filters.groupAge = event.target.value as GroupAge;

    sendEventGA(
      CategoryKeysGA.GlobalFilters,
      ActionKeysGA.ChangeGlobalFilter,
      `${LabelKeysGA.GroupAge}:${String(
        LabelKeysGA[`GROUP_AGE_${filters.groupAge}`]
      )}`
    );

    handleGlobalFilterChange(filters);
    close();
  };

  /**
   * Handle backup status filter change event
   *
   * @param event ChangeEvent input
   * @param close Close method
   */
  const onBackupStatusChange = (
    event: ChangeEvent<HTMLInputElement>,
    close: () => void
  ): void => {
    const filters = clone(globalFilters);
    filters.backupStatus = event.target.value as BackupStatus;

    sendEventGA(
      CategoryKeysGA.GlobalFilters,
      ActionKeysGA.ChangeGlobalFilter,
      `${LabelKeysGA.BackupStatus}:${String(
        LabelKeysGA[`BACKUP_STATUS_${filters.backupStatus}`]
      )}`
    );

    handleGlobalFilterChange(filters);
    close();
  };

  /**
   * Handle date range picker filter change event
   *
   * @param close Close method
   */
  const onCalendarClose = (close: () => void): void => {
    const filters = clone(globalFilters);
    if (
      dateRange !== null &&
      dateRange.length === 2 &&
      dateRange[0] &&
      dateRange[1]
    ) {
      filters.fromDate = formatDate(dateRange[0], DateFormatType.GlobalFilter);
      filters.toDate = formatDate(dateRange[1], DateFormatType.GlobalFilter);
    } else {
      filters.fromDate = '';
      filters.toDate = '';
    }

    if (filters.fromDate && filters.toDate) {
      const fromDate = formatDate(filters.fromDate);
      const toDate = formatDate(filters.toDate);
      // prettier-ignore
      sendEventGA(CategoryKeysGA.GlobalFilters, ActionKeysGA.ChangeGlobalFilter, `${LabelKeysGA.DateRange}:${fromDate}-${toDate}`);
    }

    handleGlobalFilterChange(filters);
    close();
  };

  /**
   * Maintain projects ToggleLayer open status
   * for syncing local selectedProjects with
   * globalFilter.project
   */
  const handleCloseProjects = (): void => setProjectsOpen(false);

  /**
   * Maintain projects ToggleLayer open status
   * for syncing local selectedProjects with
   * globalFilter.project
   * @param {() => void} toggle toggle handler form ToggleLayer
   */
  const handleOpenProjects = (toggle: () => void, isOpen: boolean): void => {
    setProjectsOpen(!isOpen);
    toggle();
  };

  /**
   * Maintain More filters ToggleLayer open status
   * for syncing local selectedGroupStatuses with
   * globalFilter.groupStatuses
   */
  const handleCloseMoreFilters = (): void => setMoreFiltersOpen(false);

  /**
   * Maintain More filters ToggleLayer open status
   * for syncing local selectedGroupStatuses with
   * globalFilter.groupStatuses
   * @param {() => void} toggle toggle handler form ToggleLayer
   */
  const handleOpenMoreFilters = (toggle: () => void, isOpen: boolean): void => {
    setMoreFiltersOpen(!isOpen);
    toggle();
  };

  /**
   * An issue caused here where there was a leading comment after arrow
   * return ( => ( ) which caused it to not render/execute in prod build
   * issue CRA/babel : https://github.com/facebook/create-react-app/issues/8687 */
  const renderExportButton = (): JSX.Element => (
    <UncontrolledButtonDropdown
      disabled={exportDisabled}
      className="insight-btn-dropdown"
    >
      <DropdownToggle
        disabled={exportDisabled}
        tag="button"
        className="btn header-btn"
      >
        <i className="icon-export" />
        {intl.get('LBL_EXPORT')}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem tag="button" className="btn" tabIndex={-1} disabled>
          <i className="icon-pdf" />
          {intl.get('LBL_PDF')}
        </DropdownItem>
        {canDownloadCSV && (
          <DropdownItem
            tag="button"
            className="btn"
            onClick={startExcelReportDownload}
            tabIndex={0}
          >
            <i className="icon-excel" />
            {intl.get('LBL_EXCEL')}
          </DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );

  /**
   * Render the widget setup button
   */
  const renderWidgetSetupButton = (): JSX.Element => {
    if (dashboardSetupInProgress) {
      return (
        <Button
          key="in-progress"
          className="btn header-btn btn-primary"
          onClick={toggleDashboardSetupMode}
        >
          <i className="icon-widgets" />
          {intl.get('LBL_SAVE_WIDGETS')}
        </Button>
      );
    }
    return (
      <Button
        key="default"
        className="btn header-btn"
        onClick={toggleDashboardSetupMode}
      >
        <i className="icon-widgets" />
        {intl.get('LBL_WIDGETS')}
      </Button>
    );
  };

  return (
    <header className="main-header">
      <div className="header-col">
        <h2 className="main-title">{title}</h2>
        <button className="btn header-btn filters-btn">Filters</button>
        <div className="header-form">
          <div className="select-group">
            <ProjectsFilterSelect
              appContext={context}
              globalFilters={globalFilters}
              onChange={handleUpdateProjects}
              onClose={handleCloseProjects}
              onOpen={handleOpenProjects}
              selectedProjects={selectedProjects}
              maxSelected={projectFilterMaxSelected}
            />
          </div>
          <div className="select-group">
            <BackupStatusFilterSelect
              appContext={context}
              globalFilters={globalFilters}
              onChange={onBackupStatusChange}
            />
          </div>
          <div className="select-group">
            <DateRangeFilterSelect
              appContext={context}
              dateRange={dateRange}
              globalFilters={globalFilters}
              onClose={onCalendarClose}
              setDateRange={setDateRange}
            />
          </div>
          <div className="select-group">
            <GroupAgeFilterSelect
              appContext={context}
              globalFilters={globalFilters}
              onChange={onAgeChange}
            />
          </div>
          <div className="select-group">
            <MoreFiltersSelect
              appContext={context}
              globalFilters={globalFilters}
              onChange={handleUpdateGroupStatuses}
              onClose={handleCloseMoreFilters}
              onOpen={handleOpenMoreFilters}
              selectedGroupStatuses={selectedGroupStatuses}
              maxSelected={groupStatusFilterMaxSelected}
            />
          </div>
          <button
            className="clear-filters"
            disabled={isEqual(globalFilters, defaultGlobalFilters)}
            onClick={resetGlobalFilters}
          >
            {intl.get('BTN_CLEAR_FILTERS')}
          </button>
        </div>
      </div>

      <div className="header-col">
        &nbsp;&nbsp;
        {renderExportButton()}
        &nbsp;&nbsp;
        <Switch>
          <Route path="/dashboard" render={renderWidgetSetupButton} />
        </Switch>
        {userDropdown}
      </div>
    </header>
  );
};

export default withRouter(FilterToolbar);

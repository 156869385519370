/* eslint-disable no-nested-ternary */
import { useEffect, FC as ReactFC } from 'react';

import ReactTooltip from 'react-tooltip';

import { getFormattedNumber } from 'helpers/NumberFormat';

import TableHeaderCellProps from './TableHeaderCellProps';

const TableHeaderCell: ReactFC<TableHeaderCellProps> = ({
  column,
  meta,
}: TableHeaderCellProps) => {
  const { dataTitle, helpText } = column;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [column, meta]);

  return (
    <span className="table-label">
      {dataTitle}
      {column.id === 'name' ? (
        ` (${getFormattedNumber(meta.total)})`
      ) : (
        <span className="help">
          <i className="icon-help" data-for="insTooltip" data-tip={helpText} />
        </span>
      )}
      {meta.total > 1 && (
        <span
          className={
            column.isSorted
              ? column.isSortedDesc
                ? 'icon-chevron-down'
                : 'icon-chevron-up'
              : ''
          }
        />
      )}
    </span>
  );
};

export default TableHeaderCell;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { Component } from 'react';

import axios from 'axios';
import { Modal } from 'reactstrap';

import AuthApiInstance from 'api/auth/AuthApi';
import ApiError from 'api/common/ApiError';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import LabelKeysGA from 'constants/ga/LabelKeysGA';
import TIMEOUTS from 'constants/Timeouts';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import setPageTitle from 'helpers/setPageTitle';
import VerifyAccountError from 'modules/public/auth/components/verify-account-error/VerifyAccountError';
import VerifyAccountStatusHandler from 'modules/public/auth/components/verify-account-handler/VerifyAccountStatusHandler';
import VerifyAccountSuccess from 'modules/public/auth/components/verify-account-success/VerifyAccountSuccess';
import AuthService from 'services/AuthService';
import ModalBackground from 'shared/components/hoc/dummy-background/ModalBackground';
import VerificationError from 'shared/enums/VerificationError';

import VerifyAccountViewProps from './VerifyAccountViewProps';
import VerifyAccountViewState from './VerifyAccountViewState';

class VerifyAccountView extends Component<
  VerifyAccountViewProps,
  VerifyAccountViewState
> {
  constructor(props: VerifyAccountViewProps) {
    super(props);
    this.state = {
      accountVerificationInProgress: false,
      userEmail: null,
      emailSent: false,
      emailSendingInProgress: false,
      verificationError: null,
      errorType: VerificationError.INVALID_CODE,
    };
  }

  componentDidMount(): void {
    setPageTitle();

    const { location } = this.props;

    const query = new URLSearchParams(location.search);
    const codeParam = query.get('code');
    const emailParam = query.get('email');
    this.expiryTime =
      query.get('expiry_time') ?? TIMEOUTS.AUTH_VERIFICATION_EXPIRY;
    this.handleAccountVerification(codeParam, emailParam);
  }

  componentWillUnmount(): void {
    this.source.cancel();
  }

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  expiryTime = '';

  /**
   * Gets correct verification screen as a child component
   *
   * @returns {JSX.Element} JSX snippet containing the appropriate child component
   */
  getModalChildren = (): JSX.Element => {
    const { verificationError, errorType, emailSent, emailSendingInProgress } =
      this.state;
    if (verificationError) {
      return (
        <VerifyAccountError
          emailSent={emailSent}
          loading={emailSendingInProgress}
          errorType={errorType}
          expiryTimeString={this.expiryTime}
          handleEmailResend={this.handleResendEmail}
        />
      );
    }
    return <VerifyAccountSuccess />;
  };

  /**
   * Sets updated state to container state
   *
   * @param updateState Updated state object
   */
  setStateAttribute = (updateState: Partial<VerifyAccountViewState>): void =>
    this.setState((state) => ({ ...state, ...updateState }));

  /**
   * Handles re-sending email to user
   */
  handleResendEmail = async (): Promise<void> => {
    const { userEmail } = this.state;
    if (userEmail) {
      try {
        this.setStateAttribute({ emailSendingInProgress: true });
        await AuthApiInstance.ResendEmail(userEmail, this.source);
        this.setStateAttribute({ emailSent: true });
      } catch (error) {
        this.setStateAttribute({ emailSent: false });
      } finally {
        this.setStateAttribute({ emailSendingInProgress: false });
      }
    }
  };

  /**
   * Handles verification of the user's account
   *
   * @param code Verification code from the URL
   * @param email The user's email address
   */
  handleAccountVerification = async (
    code: string | null | undefined,
    email: string | null
  ): Promise<void> => {
    const { appContext } = this.props;
    if (code && email) {
      try {
        this.setStateAttribute({ accountVerificationInProgress: true });
        const user = await AuthApiInstance.VerifyNewUser(
          code,
          email,
          this.source
        );
        AuthService.onLogin(user);

        /* fetch and update permissions */
        await appContext.getPermissions();
        /* fetch and update getting started steps */
        await appContext.getGettingStartedState();
        /* fetch and update user info */
        await appContext.getUserInfo();

        sendEventGA(
          CategoryKeysGA.AccountCreateAccountVerification,
          ActionKeysGA.Verify,
          LabelKeysGA.Success
        );
      } catch (error) {
        if (error instanceof Error)
          this.setStateAttribute({ verificationError: error });

        if (error instanceof ApiError && error.response) {
          const errorMessage = error.response.message;
          const moddedErrorMessage = errorMessage
            ? (errorMessage as VerificationError)
            : VerificationError.GENERIC;
          this.setStateAttribute({
            userEmail: email,
            errorType: moddedErrorMessage,
          });

          sendEventGA(
            CategoryKeysGA.AccountCreateAccountVerification,
            ActionKeysGA.Verify,
            `${LabelKeysGA.Fail}: ${String(moddedErrorMessage)}`
          );
        }
      } finally {
        this.setStateAttribute({ accountVerificationInProgress: false });
      }
    } else {
      this.setStateAttribute({
        verificationError: new Error(),
        errorType: VerificationError.INVALID_CODE,
      });
    }
  };

  render(): JSX.Element {
    const { accountVerificationInProgress } = this.state;
    return (
      <ModalBackground>
        <Modal size="lg" isOpen backdrop="static" centered>
          <VerifyAccountStatusHandler loading={accountVerificationInProgress}>
            {this.getModalChildren()}
          </VerifyAccountStatusHandler>
        </Modal>
      </ModalBackground>
    );
  }
}

export default VerifyAccountView;

/* eslint-disable react/jsx-props-no-spreading */
import { useState, FC as ReactFC } from 'react';

import queryString from 'query-string';
import * as intl from 'react-intl-universal';
import {
  Route,
  RouteChildrenProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import AuthApiInstance from 'api/auth/AuthApi';
import ModulePaths from 'constants/ModulePaths';
import { defaultGlobalFilters } from 'helpers/GlobalFilterUtils';
import { MessagingTab } from 'modules/private/messaging/containers/messaging-view/MessagingViewState';
import AuthService from 'services/AuthService';
import HeaderToolbarProps from 'shared/components/header-toolbar/HeaderToolbarProps';
import ImageComponent from 'shared/components/image/ImageComponent';
import blankAvatar from 'shared/static/img/project-placeholders/blank_avatar.svg';

import FilterToolbar from './filter-toolbar/FilterToolbar';
import GroupDetailsToolbar from './group-details-toolbar/GroupDetailsToolbar';
import InviteUsersToolbar from './invite-users-toolbar/InviteUsersToolbar';
import MessagingToolbar from './messaging-toolbar/MessagingToolbar';
import ProjectUsersToolbar from './project-users-toolbar/ProjectUsersToolbar';
import ProjectsToolbar from './projects-toolbar/ProjectsToolbar';
import SettingsToolbar from './settings-toolbar/SettingsToolbar';
import SurveysToolbar from './surveys-toolbar/SurveysToolbar';

const HeaderToolbar: ReactFC<HeaderToolbarProps> = (props) => {
  const { context } = props;

  const { setGlobalFilters, userInfoData } = context;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  /**
   * Handle click event on logout button
   */
  const handleLogoutClick = async (): Promise<void> => {
    setGlobalFilters(defaultGlobalFilters);
    try {
      await AuthApiInstance.LogoutAsync();
    } catch (error) {
      // console.log(error);
    } finally {
      AuthService.Logout();
    }
  };

  /**
   * Toggle logout dropdown
   */
  const toggleLogoutMenu = (): void =>
    setDropdownOpen((prevState) => !prevState);

  const userDropDown = (
    <Dropdown isOpen={dropdownOpen} toggle={toggleLogoutMenu}>
      <DropdownToggle
        className="header-user"
        tag="button"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        <ImageComponent
          className="w-100"
          ignoreBlur
          src={userInfoData.data?.imageURL}
          alt="profile"
          fallbackSrc={blankAvatar}
        />
      </DropdownToggle>
      <DropdownMenu className="user-dropdown-menu">
        <DropdownItem className="user-dropdown-btn" onClick={handleLogoutClick}>
          {intl.get('BTN_LOGOUT')}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );

  /**
   * Render the filter toolbar
   *
   * @param routeChildProps Route props
   * @param type Type of page
   * @param exportDisabled Whether to disable the export button
   * @returns {JSX.Element} JSX snippet containing the toolbar
   */
  const renderFilterToolbar = (
    routeChildProps: RouteChildrenProps,
    type: string,
    exportDisabled?: boolean
  ): JSX.Element => (
    <FilterToolbar
      title={intl.get(type)}
      context={context}
      userDropdown={userDropDown}
      exportDisabled={exportDisabled}
      routeProps={routeChildProps}
    />
  );

  /**
   * Render the settings toolbar
   *
   * @param routeChildProps Route props
   * @returns {JSX.Element} JSX snippet containing the toolbar
   */
  const renderSettingsToolbar = (
    childProps: RouteChildrenProps<{ settingsTab: string }>
  ): JSX.Element => {
    const path = childProps.match?.path ?? ModulePaths.SettingsPath;

    return (
      <Switch>
        <Route path={`${path}${ModulePaths.SettingsUsersInvitePath}`}>
          <InviteUsersToolbar
            context={context}
            {...childProps}
            userDropdown={userDropDown}
          />
        </Route>
        <Route path={`${path}/`}>
          <SettingsToolbar
            context={context}
            {...childProps}
            userDropdown={userDropDown}
          />
        </Route>
      </Switch>
    );
  };

  /**
   * Render the groups toolbar
   *
   * @param routeChildProps Route props
   * @returns {JSX.Element} JSX snippet containing the toolbar
   */
  const renderGroupsToolbar = (
    routeChildProps: RouteChildrenProps<{ groupNumber: string }>
  ): JSX.Element => {
    const path = routeChildProps.match?.path ?? ModulePaths.GroupsPath;
    return (
      <Switch>
        <Route exact path={`${path}${ModulePaths.GroupDetailsPath}`}>
          {(
            subRouteProps: RouteChildrenProps<{ groupNumber: string }>
          ): JSX.Element => (
            <GroupDetailsToolbar
              context={context}
              {...subRouteProps}
              userDropdown={userDropDown}
            />
          )}
        </Route>
        <Route path={`${path}/`}>
          {renderFilterToolbar(routeChildProps, 'LBL_TITLE_GROUPS')}
        </Route>
      </Switch>
    );
  };

  /**
   * Render the projects toolbar
   *
   * @param childProps Route props
   * @returns {JSX.Element} JSX snippet containing the toolbar
   */
  const renderProjectsToolbar = (
    childProps: RouteChildrenProps
  ): JSX.Element => {
    const path = childProps.match?.path ?? ModulePaths.ProjectsPath;
    return (
      <Switch>
        <Route
          exact
          path={[
            `${path}${ModulePaths.ProjectUsersIdPath}`,
            `${path}${ModulePaths.ProjectFacilitatorsIdPath}`,
          ]}
        >
          {(
            subRouteProps: RouteChildrenProps<{ projectId: string }>
          ): JSX.Element => (
            <ProjectUsersToolbar
              context={context}
              {...subRouteProps}
              userDropdown={userDropDown}
            />
          )}
        </Route>
        <Route path={path}>
          {(subRouteProps): JSX.Element => (
            <ProjectsToolbar
              context={context}
              {...subRouteProps}
              userDropdown={userDropDown}
            />
          )}
        </Route>
      </Switch>
    );
  };

  /**
   * Render the messaging toolbar
   *
   * @returns {JSX.Element} JSX snippet containing the toolbar
   */
  const renderMessagingToolbar = (
    childProps: RouteChildrenProps
  ): JSX.Element => {
    const { match, location } = childProps;
    const path = match?.path ?? ModulePaths.MessagingPath;
    const search = queryString.parse(location.search);
    return (
      <Switch>
        <Route exact path={`${path}`}>
          {(subRouteProps): JSX.Element => (
            <MessagingToolbar
              context={context}
              isComposeTab={
                search.tab === MessagingTab.Compose || search.tab === undefined
              }
              exportDisabled={false}
              {...subRouteProps}
              userDropdown={userDropDown}
            />
          )}
        </Route>
      </Switch>
    );
  };

  /**
   * Render the surveys toolbar
   *
   * @returns {JSX.Element} JSX snippet containing the toolbar
   */
  const renderSurveysToolbar = (
    childProps: RouteChildrenProps
  ): JSX.Element => {
    const { match } = childProps;
    const path = match?.path ?? ModulePaths.SurveysPath;
    return (
      <Switch>
        <Route exact path={`${path}`}>
          {(subRouteProps): JSX.Element => (
            <SurveysToolbar
              {...subRouteProps}
              context={context}
              userDropdown={userDropDown}
            />
          )}
        </Route>
      </Switch>
    );
  };

  return (
    <Switch>
      <Route path={ModulePaths.DashboardPath}>
        {(routeChildProps): JSX.Element =>
          renderFilterToolbar(routeChildProps, 'LBL_TITLE_DASHBOARD')
        }
      </Route>
      <Route path={ModulePaths.FinancesPath}>
        {(routeChildProps): JSX.Element =>
          renderFilterToolbar(
            routeChildProps,
            'LBL_TITLE_FINANCIAL_METRICS',
            true
          )
        }
      </Route>
      <Route path={ModulePaths.GroupsPath}>
        {(
          routeChildProps: RouteChildrenProps<{ groupNumber: string }>
        ): JSX.Element => renderGroupsToolbar(routeChildProps)}
      </Route>
      <Route path={ModulePaths.FacilitatorsPath}>
        {(routeChildProps): JSX.Element =>
          renderFilterToolbar(routeChildProps, 'LBL_TITLE_FACILITATORS')
        }
      </Route>
      <Route path={ModulePaths.ProjectsPath}>
        {(routeChildProps): JSX.Element =>
          renderProjectsToolbar(routeChildProps)
        }
      </Route>
      <Route path={ModulePaths.SettingsParamPath}>
        {(
          routeChildProps: RouteChildrenProps<{ settingsTab: string }>
        ): JSX.Element => renderSettingsToolbar(routeChildProps)}
      </Route>
      <Route path={ModulePaths.MessagingPath}>
        {(routeChildProps): JSX.Element =>
          renderMessagingToolbar(routeChildProps)
        }
      </Route>
      <Route path={ModulePaths.SurveysParamPath}>
        {(routeChildProps): JSX.Element =>
          renderSurveysToolbar(routeChildProps)
        }
      </Route>
    </Switch>
  );
};

export default withRouter(HeaderToolbar);

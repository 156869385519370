/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import ModulePaths from 'constants/ModulePaths';
import { getCompactNumber } from 'helpers/NumberFormat';
import InsLink from 'shared/components/ins-link/InsLink';

import LineChart from '../../line-chart/LineChart';
import AttendanceProps from './AttendanceProps';

const Attendance: ReactFC<AttendanceProps> = (props) => {
  const { data, meta, xAxisConfig, filters } = props;

  /**
   * Formats tick values
   *
   * @param value Tick value
   * @returns {string} Formatted tick value
   */
  const tickFormatter = (value: any): string =>
    `${getCompactNumber(Number(value))}%`;

  const sliceRenderer = (value): string => `${String(value)}%`;

  const legendLabelRenderer = (name): string =>
    intl.get(`LBL_MEMBERSHIP_${String(name)}`);

  const renderChartLegend = (): JSX.Element => (
    <div className="chart-legend inline">
      <div className="item">
        <span className="color" style={{ backgroundColor: '#68C7C2' }} />
        <span className="label">
          <span className="name">
            {intl.get('LBL_MEMBERSHIP_ATTENDANCE_IN_PERSON')}
          </span>
        </span>
      </div>
      <div className="item">
        <span className="color" style={{ backgroundColor: '#F8E9C5' }} />
        <span className="label">
          <span className="name">{`${intl.get(
            'LBL_MEMBERSHIP_ATTENDANCE_IN_PERSON'
          )} ${String(filters.timeUnit.label)}`}</span>
        </span>
      </div>
      <div className="item">
        <span className="color" style={{ backgroundColor: '#93CEEF' }} />
        <span className="label">
          <span className="name">
            {intl.get('LBL_MEMBERSHIP_ATTENDANCE_WITH_REP')}
          </span>
        </span>
      </div>
      <div className="item">
        <span className="color" style={{ backgroundColor: '#F19BDD' }} />
        <span className="label">
          <span className="name">{`${intl.get(
            'LBL_MEMBERSHIP_ATTENDANCE_WITH_REP'
          )} ${String(filters.timeUnit.label)}`}</span>
        </span>
      </div>
    </div>
  );

  return (
    <>
      <LineChart
        data={data}
        meta={meta}
        xAxisConfig={xAxisConfig}
        filters={filters}
        legendLabelRenderer={legendLabelRenderer}
        tickFormatter={tickFormatter}
        sliceRenderer={sliceRenderer}
      />
      <div className="chart-footer">
        {renderChartLegend()}
        <span className="chart-overview">
          <InsLink
            disabledClass="disabled"
            to={ModulePaths.GroupsPath}
            className="btn btn-secondary btn-sm"
          >
            <i className="icon-arrow-right" /> {intl.get('BTN_GROUPS_PAGE')}
          </InsLink>
        </span>
      </div>
    </>
  );
};

export default Attendance;

import { CSSProperties } from 'react';

type SelectState = { data: { isFixed: boolean } };

const countriesSelectCustomStyles = {
  multiValue: (base: CSSProperties, state: SelectState): CSSProperties =>
    state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base,

  multiValueLabel: (base: CSSProperties, state: SelectState): CSSProperties =>
    state.data.isFixed
      ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
      : base,

  multiValueRemove: (base: CSSProperties, state: SelectState): CSSProperties =>
    state.data.isFixed ? { ...base, display: 'none' } : base,
};

export default countriesSelectCustomStyles;

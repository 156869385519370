import { useEffect, useState, FC as ReactFC } from 'react';

import find from 'lodash/find';
import map from 'lodash/map';
import { Modal } from 'reactstrap';

import DashboardApi from 'api/dashboard/DashboardApi';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import LabelKeysGA from 'constants/ga/LabelKeysGA';
import GrantKeys from 'constants/permissions/GrantKeys';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import GettingStartedCompleted from 'modules/private/dashboard/components/getting-started/getting-started-completed/GettingStartedCompleted';
import GettingStartedStatusHandler from 'modules/private/dashboard/components/getting-started/getting-started-handler/GettingStartedStatusHandler';
import GettingStartedHeader from 'modules/private/dashboard/components/getting-started/getting-started-header/GettingStartedHeader';
import CreateViewProjects from 'modules/private/dashboard/components/getting-started/getting-started-steps/CreateViewProjects';
import InviteUsers from 'modules/private/dashboard/components/getting-started/getting-started-steps/InviteUsers';
import SettingUpGroupData from 'modules/private/dashboard/components/getting-started/getting-started-steps/SettingUpGroupData';
import Status from 'shared/enums/Status';
import usePrevious from 'shared/hooks/use-previous/UsePrevious';

import GettingStartedModalProps from './GettingStartedModalProps';

const GettingStartedModal: ReactFC<GettingStartedModalProps> = (
  props: GettingStartedModalProps
) => {
  const {
    isOpen,
    userInfo,
    userInfoStatus,
    gettingStartedSteps,
    onToggle,
    fetchGettingStartedSteps,
    setGuideOpen,
  } = props;

  const [active, setActive] = useState(1);
  const [haveGroups, setHaveGroups] = useState(true);
  const [showGSComplete, setShowGSComplete] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const userName = userInfoStatus === Status.Success ? userInfo!.firstName : '';

  const stepsObject = gettingStartedSteps.data ?? {
    completed: false,
    status: [],
  };

  const prevStepsObject = usePrevious(stepsObject);

  const inCompletedFirstStep = find(stepsObject.status, { status: false });

  useEffect(() => {
    if (inCompletedFirstStep && inCompletedFirstStep.order !== active) {
      setActive(inCompletedFirstStep.order);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inCompletedFirstStep]);

  /**
   * Handles toggling active collapsible component
   *
   * @param event The number of the toggled collapsible
   */
  const toggleActive = (event: number): void => {
    setActive(event === active ? 0 : event);
  };

  /**
   * Handles the change event for the groups option
   *
   * @param option Whether user has groups or npt
   */
  const onGroupOptionChange = (option: boolean): void => {
    setHaveGroups(option);
  };

  /**
   * Handles the read event for the group data guide
   */
  const handleReadClick = async (): Promise<void> => {
    const pdfLink = await DashboardApi.GetGettingStartedGuide(haveGroups);
    fetchGettingStartedSteps();
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfLink;
    downloadLink.download = 'guide.pdf';
    downloadLink.target = '_blank';
    downloadLink.dispatchEvent(new MouseEvent('click'));

    sendEventGA(
      CategoryKeysGA.GettingStartedSettingUpGroupData,
      ActionKeysGA.Read,
      haveGroups
        ? LabelKeysGA.GETTING_STARTED_HAVE_GROUPS
        : LabelKeysGA.GETTING_STARTED_NO_GROUPS
    );
  };

  /**
   * Handles opening the getting started modal
   */
  const handleOpened = (): void => {
    fetchGettingStartedSteps();
    setGuideOpen(true);
  };

  /**
   * Handles closing the getting started modal
   */
  const handleClosed = (): void => {
    setGuideOpen(false);
    setShowGSComplete(false);
  };

  const loading =
    (gettingStartedSteps.status === Status.Loading &&
      gettingStartedSteps.silent === false) ||
    userInfoStatus === Status.Loading;

  /**
   * if the user has seen the getting started complete screen;
   * and coming back to getting started guide to see the already
   * completed steps;
   */
  const completedPrior =
    prevStepsObject !== undefined &&
    stepsObject.completed === prevStepsObject.completed &&
    stepsObject.completed === true;

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      className="no-rounded-corners no-border"
      centered
      returnFocusAfterClose={false}
      onOpened={handleOpened}
      onClosed={handleClosed}
      toggle={onToggle}
    >
      <GettingStartedStatusHandler
        status={gettingStartedSteps.status}
        loading={loading}
        silent={gettingStartedSteps.silent}
      >
        {(inCompletedFirstStep !== undefined || completedPrior) &&
        !showGSComplete ? (
          <>
            <GettingStartedHeader userName={userName} />
            <div className="collapsible">
              {map(stepsObject.status, (step) => {
                switch (step.claim) {
                  case GrantKeys.SetUpManualView:
                    return (
                      <SettingUpGroupData
                        key={step.order}
                        order={step.order}
                        stepCompleted={step.status}
                        active={active}
                        toggle={toggleActive}
                        haveGroups={haveGroups}
                        onGroupOptionChange={onGroupOptionChange}
                        handleReadClick={handleReadClick}
                      />
                    );
                  case GrantKeys.UserInvite:
                    return (
                      <InviteUsers
                        key={step.order}
                        order={step.order}
                        onClosed={handleClosed}
                        stepCompleted={step.status}
                        active={active}
                        toggle={toggleActive}
                      />
                    );
                  case GrantKeys.ProjectCreate:
                    return (
                      <CreateViewProjects
                        key={step.order}
                        order={step.order}
                        onClosed={handleClosed}
                        stepCompleted={step.status}
                        active={active}
                        toggle={toggleActive}
                        projectPermission="CREATE"
                      />
                    );
                  case GrantKeys.ProjectView:
                    return (
                      <CreateViewProjects
                        key={step.order}
                        order={step.order}
                        onClosed={handleClosed}
                        stepCompleted={step.status}
                        active={active}
                        toggle={toggleActive}
                        projectPermission="VIEW"
                      />
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </>
        ) : (
          <GettingStartedCompleted
            updateGettingStartedStates={fetchGettingStartedSteps}
            setShowGSComplete={setShowGSComplete}
            onToggle={onToggle}
          />
        )}
      </GettingStartedStatusHandler>
    </Modal>
  );
};

export default GettingStartedModal;

import axios, { AxiosPromise, AxiosError, AxiosResponse } from 'axios';
import * as localStorage from 'local-storage';

import AuthApiInstance from 'api/auth/AuthApi';
import StorageKeys from 'constants/StorageKeys';
import HTTP_STATUS from 'shared/enums/HttpStatus';

type RequestCallback = () => void;

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const { CancelToken } = axios;

function onFulfilled(response) {
  return response;
}

async function onRejected(error) {
  if (axios.isCancel(error)) {
    /* An unresolved promise is returned here, in the case of a request
    cancellation (which throws an error), so as to not trigger an
    error in the container/component that fired the request. In turn,
    the error toast will not be displayed to the user.
    See this github issue (https://github.com/axios/axios/issues/583) and this
    stack-overflow post (https://stackoverflow.com/questions/36734900/what-happens-if-you-dont-resolve-or-reject-a-promise)
    for further details. */
    return new Promise(() => undefined);
  }
  const errorResponse = error.response;
  const { status } = errorResponse;

  /** handle only if request fails with 401 and a request that need
   * authorization token, also ignores the actual token refresh API
   * call. Otherwise reject promise with error back to axios instance
   */
  if (
    status === HTTP_STATUS.UNAUTHORIZED &&
    errorResponse.config.url !==
      `${String(Axios.defaults.baseURL)}auth/refresh` &&
    errorResponse.config.headers.Authorization
  ) {
    return refreshTokenAndReattemptRequest(error);
  }
  return Promise.reject(error);
}

Axios.interceptors.response.use(onFulfilled, onRejected);

/** token refreshing status */
let isRefreshingToken = false;

/**  This is the list of waiting requests that will retry after the token refresh complete */
let requestQueue: RequestCallback[] = [];

/**
 * refresh access token and retry [401] failed requests with new token
 * @param error @type {AxiosError}
 */
async function refreshTokenAndReattemptRequest(
  error: AxiosError
): Promise<AxiosResponse> {
  try {
    const { response: errorResponse } = error;
    /** a pending promise to retry each request received due to a [401]
     * which will use the requestInfo from error configuration.
     */
    const retryOriginalRequest = new Promise<AxiosPromise>((resolve) => {
      /** failed requests will be added to a request queue which will in-turn execute the callback
       * to resolve the promise above, after the token refresh is completed. This step is important
       * since; token refresh has already being attempted. Request queue must only be handled once
       * the token is properly refreshed.
       */
      addRequestToQueue(() => {
        if (errorResponse && errorResponse.config.headers) {
          const token = localStorage.get<string>(StorageKeys.TokenKey);
          errorResponse.config.headers.Authorization = `Bearer ${token}`;
          /** re-attempt the request after token refresh success */
          resolve(Axios(errorResponse.config));
        }
      });
    });

    /** the very first request that fails with a [401] will possible
     * be truthy for this condition, where it will attempt to refresh
     * the access token.
     */
    if (!isRefreshingToken) {
      isRefreshingToken = true;

      /** the very first request that fails with [401] will attempt at token refresh
       * and will not return from the @refreshTokenAndReattemptRequest until success
       * or failure since promise is awaited, others will immediately return with
       * @retryOriginalRequest pending promise.
       */

      try {
        await AuthApiInstance.RefreshAsync();

        isRefreshingToken = false;
        /** if and after token refresh is successful queued requests will be retried */
        onAccessTokenFetched();
        // eslint-disable-next-line @typescript-eslint/no-shadow
      } catch (error) {
        isRefreshingToken = false;
        requestQueue = [];
      }
    }
    /**
     * return unresolved promise per each request back to axios
     * to keep them pending and avoid resolving to an error
     */
    return retryOriginalRequest;
  } catch (localError) {
    /** if in-case this process fails, return a Promise.reject
     * back to the onRejected handler of the interceptor
     */
    return Promise.reject(localError);
  }
}

/**
 * execute all request callback in {@link requestQueue} in FIFO
 * and resets {@link requestQueue} to empty array
 */
function onAccessTokenFetched(): void {
  requestQueue.forEach((requestCallback) => requestCallback());
  requestQueue = [];
}

/** handler to add request callbacks to {@link requestQueue} */
function addRequestToQueue(requestCallback: RequestCallback): void {
  requestQueue.push(requestCallback);
}

export default Axios;

export { CancelToken };

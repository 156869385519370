import orderBy from 'lodash/orderBy';

import { getColorFor } from 'helpers/ColorHelper';
import WidgetValueGroup from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetValueGroup';
import WidgetViewModel from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetViewModel';
import Donut from 'shared/components/ins-charts/donut/Donut';
import InsChartValue from 'shared/components/ins-charts/InsChartValue';

const AdditionalFundsWidget: React.FC<{ data: WidgetViewModel }> = (props) => {
  const maxItemCount = 6;
  const dimensionSettings = {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    aspectRatioWidth: 1,
    aspectRatioHeight: 0.6,
  };

  const { data } = props;
  const { values } = data;

  const insuranceFundData = values?.find(
    (value) => value.group === WidgetValueGroup.InsuranceFund
  );
  const educationFundData = values?.find(
    (value) => value.group === WidgetValueGroup.EducationFund
  );
  const groupBusinessFundData = values?.find(
    (value) => value.group === WidgetValueGroup.GroupBusinessFund
  );
  const celebrationFundData = values?.find(
    (value) => value.group === WidgetValueGroup.CelebrationFund
  );
  const technologyFundData = values?.find(
    (value) => value.group === WidgetValueGroup.TechnologyFund
  );
  const customFundData = values?.find(
    (value) => value.group === WidgetValueGroup.CustomFund
  );

  const donutValues = Array<InsChartValue>();

  if (
    insuranceFundData &&
    educationFundData &&
    groupBusinessFundData &&
    celebrationFundData &&
    technologyFundData &&
    customFundData
  ) {
    donutValues.push({
      name: insuranceFundData.title,
      count: insuranceFundData.count,
      percentage: insuranceFundData.percentage,
      group: insuranceFundData.group,
    });
    donutValues.push({
      name: educationFundData.title,
      count: educationFundData.count,
      percentage: educationFundData.percentage,
      group: educationFundData.group,
    });
    donutValues.push({
      name: groupBusinessFundData.title,
      count: groupBusinessFundData.count,
      percentage: groupBusinessFundData.percentage,
      group: groupBusinessFundData.group,
    });
    donutValues.push({
      name: celebrationFundData.title,
      count: celebrationFundData.count,
      percentage: celebrationFundData.percentage,
      group: celebrationFundData.group,
    });
    donutValues.push({
      name: technologyFundData.title,
      count: technologyFundData.count,
      percentage: technologyFundData.percentage,
      group: technologyFundData.group,
    });
    donutValues.push({
      name: customFundData.title,
      count: customFundData.count,
      percentage: customFundData.percentage,
      group: customFundData.group,
    });
  }

  return (
    <div className="gender-widget">
      <div className="chart-container">
        <Donut
          isLoading={false}
          values={donutValues}
          dimension={dimensionSettings}
          showCount
        />
      </div>
      {donutValues.length === maxItemCount && (
        <div className="chart-legend">
          <div className="chart-legend-inner">
            {orderBy(donutValues, 'percentage', 'desc').map((value) => (
              <div key={value.name} className="item">
                <div className="item-col color-container">
                  <span
                    className="color"
                    style={{
                      backgroundColor: getColorFor(value.group),
                    }}
                  />
                </div>
                <div className="label item-col">
                  <span className="value">{value.percentage}%</span>
                </div>
                <div className="label item-col">
                  &nbsp;-&nbsp;
                  <span className="name">{value.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdditionalFundsWidget;

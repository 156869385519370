const StorageKeys = {
  TokenKey: 'access_token',
  RefreshKey: 'refresh_token',
  LastNameKey: 'last_name',
  UserNameKey: 'user_name',
  FirstNameKey: 'first_name',
  Permissions: 'permissions',
  OrganizationId: 'organizationId',
  Features: 'features',
};

export default StorageKeys;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, FC as ReactFC } from 'react';

import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import { useLocation } from 'react-router-dom';
import { Fade } from 'reactstrap';

import GroupDetailMapItem from 'api/group/GroupDetailMapItem';
import ModulePaths from 'constants/ModulePaths';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import { getFormattedNumber } from 'helpers/NumberFormat';
import history from 'router-history';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import BackupStatus from 'shared/enums/BackupStatus';
import locationPin from 'shared/static/img/location-pin.svg';
import noDataPin from 'shared/static/img/no-data-pin.svg';

import styles from './googleMap.module.scss';
import GoogleMapProps from './GoogleMapProps';
import InsInfoWindow from './InsInfoWindow';

const MapContainer: ReactFC<GoogleMapProps> = (props) => {
  const { google, data, loading } = props;
  const location = useLocation();

  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [activeMarker, setActiveMarker] = useState<any>({});
  const [selectedPlace, setSelectedPlace] = useState<any>({});
  const [groupDetail, setGroupDetail] = useState<GroupDetailMapItem>();
  const [mapBounds, setMapBounds] = useState<any>();

  /**
   * Handles marker click event
   *
   * @param place Selected place on map
   * @param marker Marker that was clicked on
   */
  const onMarkerClick = (place: any, marker: any): void => {
    const selectedGroup = data.find((d) => {
      if (d.location !== null) {
        return (
          d.location.latitude === place.position.lat &&
          d.location.longitude === place.position.lng
        );
      }
      return false;
    });
    setActiveMarker(marker);
    setSelectedPlace(place);
    setGroupDetail(selectedGroup);
    setShowInfo(true);
  };

  /**
   * Handles map click event
   */
  const onMapClicked = (): void => {
    if (showInfo) {
      setShowInfo(false);
      setActiveMarker({});
    }
  };

  useEffect(() => {
    if (google.maps && data.length) {
      const nonNullLocations = data.filter((item) => item.location !== null);
      if (nonNullLocations.length > 0) {
        const bounds = new google.maps.LatLngBounds();
        nonNullLocations.forEach((d) => {
          if (d.location !== null) {
            bounds.extend({
              lat: d.location.latitude,
              lng: d.location.longitude,
            });
          }
        });
        setMapBounds(bounds);
      } else {
        /**
         * bounds set to contain all markers but also maintaining a
         * non repeating map view.
         */
        const strictBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(70.4043, -143.5291),
          new google.maps.LatLng(-46.11251, 163.4288)
        );
        setMapBounds(strictBounds);
      }
    } else if (google.maps && data.length === 0) {
      setShowInfo(false);
      setActiveMarker({});
    }
  }, [google, data]);

  /**
   * Handles group details link click event
   */
  const onLinkClick = (): void => {
    history.push({
      pathname: `${ModulePaths.GroupsPath}/${String(
        groupDetail?.number
      )}/details`,
      search: getGlobalFiltersQuery(location.search),
    });
  };

  /**
   * Renders formatted number
   *
   * @param value Value to be formatted
   * @returns {string} Formatted value
   */
  const renderFormatted = (value?: Nullable<number>): string =>
    value ? getFormattedNumber(value) : '—';

  /**
   * Renders formatted percentage
   *
   * @param value Value to be formatted
   * @returns {string} Formatted percentage
   */
  const renderPercentage = (value?: Nullable<number>): string =>
    value !== undefined && value !== null
      ? `${getFormattedNumber(Number(value))}%`
      : '—';

  /**
   * Renders formatted text
   *
   * @param value Value to be formatted
   * @returns {string} Formatted text
   */
  const renderText = (value?: Nullable<string>): string =>
    value || intl.get('LBL_NA');

  return (
    <div className="group-map-view-container">
      {!loading && data.length === 0 && (
        <Fade mountOnEnter unmountOnExit in className={styles.overlay}>
          <div className="map-no-data">
            <img src={noDataPin} />
            <h4 className="color-orange">
              {intl.get('LBL_GROUPS_MAP_NO_GROUP_LOCATIONS')}
            </h4>
          </div>
        </Fade>
      )}
      <BlockUi tag="div" blocking={loading} className="map-view-container">
        <Map
          minZoom={2}
          google={google}
          onClick={onMapClicked}
          bounds={mapBounds}
        >
          {data.map(
            (point) =>
              point.location !== null && (
                <Marker
                  key={point.id}
                  position={{
                    lat: point.location.latitude,
                    lng: point.location.longitude,
                  }}
                  title={`${point.name} - ${point.number}`}
                  onClick={onMarkerClick}
                  map={selectedPlace.map}
                  icon={{
                    url: locationPin,
                    anchor: new google.maps.Point(34, 68),
                    scaledSize: new google.maps.Size(68, 68),
                  }}
                />
              )
          )}

          <InsInfoWindow
            google={google}
            map={selectedPlace.map}
            marker={activeMarker}
            visible={showInfo}
          >
            <div className="map-info-window">
              <div className="group-detail-link">
                <button
                  className="btn btn-secondary btn-sm"
                  onClick={onLinkClick}
                >
                  {intl.get('LBL_GROUPS_MAP_GROUP_DETAILS')}
                  <i className="icon-arrow-right" />
                </button>
              </div>
              <div className="group-detail">
                <div className="group-name">
                  <EllipsisTooltip
                    tag="h4"
                    data-place="top"
                    data-for="insTooltip"
                    data-tip={`${String(groupDetail?.name)} ${String(
                      groupDetail?.number
                    )}`}
                    data-class="overflow-wrap"
                    className="truncate"
                  >
                    {`${renderText(groupDetail?.name)} ${renderText(
                      groupDetail?.number
                    )}`}
                  </EllipsisTooltip>
                  <EllipsisTooltip
                    clamp
                    tag="span"
                    data-place="top"
                    data-for="insTooltip"
                    data-tip={`${String(groupDetail?.location?.address)}`}
                    data-class="overflow-wrap"
                    className="truncate two-lines"
                  >
                    {renderText(groupDetail?.location?.address)}
                  </EllipsisTooltip>
                </div>
                <div className="group-detail-row">
                  <span>{intl.get('LBL_GROUPS_MAP_MEMBERS')}</span>
                  <p>{renderFormatted(groupDetail?.memberCount)}</p>
                </div>
                <div className="group-detail-row">
                  <span>{intl.get('LBL_GROUPS_MAP_ATTENDANCE_RATE')}</span>
                  <p>{renderPercentage(groupDetail?.attendanceRate)}</p>
                </div>
                <div className="group-detail-row">
                  <span>
                    {intl.get('LBL_GROUPS_MAP_AVG_SAVINGS_PER_MEMBER')}
                  </span>
                  <p>{renderFormatted(groupDetail?.averageSavingsPerMember)}</p>
                </div>
                <div className="group-detail-row">
                  <span>{intl.get('LBL_GROUPS_MAP_BACKUP_STATUS')}</span>
                  <p>
                    {intl.get(
                      `LBL_${groupDetail?.backupStatus || BackupStatus.None}`
                    )}
                  </p>
                </div>
              </div>
            </div>
          </InsInfoWindow>
        </Map>
      </BlockUi>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: `${String(process.env.REACT_APP_GOOGLE_MAP_KEY)}`,
})(MapContainer);

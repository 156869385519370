import GroupWidgetType from 'modules/private/group/components/group-widgets/widgets/GroupWidgetType';

class GroupWidgetPreference {
  position: number;

  type: GroupWidgetType;

  constructor() {
    this.position = 0;
    this.type = GroupWidgetType.Placeholder;
  }
}

export default GroupWidgetPreference;

/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { Fragment, useEffect, useMemo, FC as ReactFC } from 'react';

import get from 'lodash/get';
import * as intl from 'react-intl-universal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { usePagination, useSortBy, useTable } from 'react-table';
import ReactTooltip from 'react-tooltip';
import { FormGroup, Label } from 'reactstrap';

import TableConfig from 'modules/private/settings/containers/users-view/TableConfig';
import { UserTabFilters } from 'modules/private/settings/containers/users-view/UsersViewState';
import UserViewModel from 'modules/private/settings/containers/users-view/UserViewModel';
import DataTableFooter from 'shared/components/data-table-footer/DataTableFooter';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import Status from 'shared/enums/Status';
import UserStatus from 'shared/enums/UserStatus';

import UsersHeaderCell from '../users-header-cell/UsersHeaderCell';
import DummyRowUser from './dummy-row-user/DummyRowUser';
import UserDataTableProps from './UserDataTableProps';
import styles from './usersDataTable.module.scss';

const SortableColumns = TableConfig[UserTabFilters.USERS].sortCols;

const UsersDataTable: ReactFC<UserDataTableProps> = (
  props: UserDataTableProps
) => {
  const {
    data,
    updateSortBy,
    updatePageSize,
    updatePage,
    controlledPageCount,
    pagination,
    initialPageSize,
    initialPageIndex,
    initialSortBy,
    status,
    showDeactivated,
    onToggleShowDeactivated,
    showDeactivatedCheckbox,
  } = props;

  /**
   * Formats and renders cell value
   *
   * @param param0 Cell props
   * @returns {string} Formatted cell value
   */
  const renderCell = ({ value }): string => value || '—';

  const columns = useMemo(
    () => [
      {
        dataTitle: intl.get('LBL_SETTINGS_USERS_NAME'),
        Header: UsersHeaderCell,
        accessor: 'name',
        spanClass: 'left-align',
        className: 'text-bold truncate-long-words',
        disableSortBy: !SortableColumns.has('name'),
        Cell: renderCell,
      },
      {
        dataTitle: intl.get('LBL_SETTINGS_USERS_EMAIL'),
        Header: UsersHeaderCell,
        accessor: 'email',
        className: 'text-center truncate-long-words',
        disableSortBy: !SortableColumns.has('email'),
      },
      {
        dataTitle: intl.get('LBL_SETTINGS_USERS_JOB_ROLE'),
        Header: UsersHeaderCell,
        id: 'jobRole',
        accessor: (row: UserViewModel): string => get(row, 'jobRole.role', '—'),
        className: 'text-center truncate-long-words',
        helpText: intl.get('LBL_USER_LIST_HELP_TEXT_JOB_ROLE'),
        disableSortBy: !SortableColumns.has('jobRole'),
      },
      {
        dataTitle: intl.get('LBL_SETTINGS_USERS_IMP_PARTNER'),
        Header: UsersHeaderCell,
        accessor: 'implementingPartner',
        className: 'text-center truncate-long-words',
        helpText: intl.get('LBL_USER_LIST_HELP_TEXT_IMPLEMENTING_PARTNER'),
        disableSortBy: !SortableColumns.has('implementingPartner'),
        Cell: renderCell,
      },
      {
        dataTitle: intl.get('LBL_SETTINGS_USERS_PERMISSION_LEVEL'),
        Header: UsersHeaderCell,
        accessor: 'permissionLevel',
        id: 'permissionLevel',
        className: 'text-center truncate-long-words',
        helpText: intl.get('LBL_USER_LIST_HELP_TEXT_PERMISSION_LEVEL'),
        disableSortBy: !SortableColumns.has('permissionLevel'),
      },
      {
        dataTitle: intl.get('LBL_SETTINGS_USERS_COUNTRY_DATA'),
        Header: UsersHeaderCell,
        accessor: (row): string =>
          row.countryDataAccess ? row.countryDataAccess.join(', ') : '—',
        id: 'countryDataAccess',
        className: 'text-center truncate-long-words',
        helpText: intl.get('LBL_USER_LIST_HELP_TEXT_COUNTRY_DATA_ACCESS'),
        disableSortBy: !SortableColumns.has('countryDataAccess'),
        Cell: renderCell,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.id,
      initialState: {
        pageSize: initialPageSize,
        pageIndex: initialPageIndex,
        sortBy: initialSortBy,
      },
      manualSortBy: true,
      manualPagination: true,
      autoResetSortBy: false,
      autoResetPage: false,
      disableMultiSort: true,
      disableSortRemove: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  );

  const initialSortByValue = initialSortBy[0];

  const initSortId = initialSortByValue
    ? initialSortByValue.id
    : TableConfig[UserTabFilters.USERS].defaultSort;
  const initSortDesc = initialSortByValue
    ? initialSortByValue.desc
    : TableConfig[UserTabFilters.USERS].defaultSortDesc;

  useEffect(() => {
    const newSortBy = [{ id: initSortId, desc: initSortDesc }];
    setSortBy(newSortBy);
  }, [initSortDesc, initSortId, setSortBy]);

  useEffect(() => {
    gotoPage(initialPageIndex);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [initialPageIndex]);

  useEffect(() => {
    setPageSize(initialPageSize);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [initialPageSize]);

  useEffect(() => {
    requestAnimationFrame(() => {
      ReactTooltip.rebuild();
    });
  }, [data]);

  /**
   * Handle sortBy change event
   *
   * @param event Column header click event
   */
  const handleChangeSortBy = (
    event: React.MouseEvent<HTMLSpanElement>
  ): void => {
    const dataSet = event.currentTarget.dataset;
    if (dataSet.sort) {
      const { id, canSort, isSorted, isSortedDesc, sortDescFirst } = JSON.parse(
        dataSet.sort
      );
      if (canSort && id !== null && id !== undefined) {
        updateSortBy([
          {
            id,
            desc: isSorted ? !isSortedDesc : sortDescFirst,
          },
        ]);
      }
    }
  };

  const isEmptyError =
    status !== Status.Loading && (status === Status.Error || data.length <= 0);

  /**
   * Renders a table row
   *
   * @param cell Cell props passed down from react-table
   * @param deactivated Whether the user is deactivated
   * @returns {JSX.Element} JSX snippet containing the row component
   */
  const renderTd = (cell, deactivated: boolean): JSX.Element => {
    const deactivatedTip = deactivated
      ? intl.get('LBL_SETTINGS_USERS_DEACTIVATED_USER')
      : '';

    return (
      <td
        {...cell.getCellProps({
          className: cell.column.className,
          'data-title': cell.column.dataTitle,
        })}
      >
        <div
          data-for="insTooltip"
          data-tip={deactivatedTip}
          data-class="row-info overflow-wrap width-auto"
          data-place="bottom"
        />
        <EllipsisTooltip
          tag="span"
          data-for="insTooltip"
          data-tip={cell.value || ''}
          data-class="row-info overflow-wrap width-auto"
          data-place="bottom"
          className={`table-content text-14-semibold wrap-content ${
            String(cell.column.spanClass) ?? ''
          } ${styles.wrapContent}`}
        >
          {cell.render('Cell')}
        </EllipsisTooltip>
        <div
          data-for="insTooltip"
          data-tip={deactivatedTip}
          data-class="row-info overflow-wrap width-auto"
          data-place="bottom"
        />
      </td>
    );
  };

  return (
    <>
      <div className="insight-table-container pt-0">
        {status === Status.Loading ? (
          <SkeletonTheme color="#fafaf5" highlightColor="#ffffff">
            <Skeleton height={40} />
            <Skeleton height={62} count={10} />
          </SkeletonTheme>
        ) : (
          <PerfectScrollbar>
            <table
              className={`insight-table ${styles.table}`}
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        key={column.id}
                        {...column.getHeaderProps({
                          className: column.headerClassName ?? column.className,
                        })}
                      >
                        <span
                          data-sort={JSON.stringify({
                            id: column.id,
                            canSort: column.canSort,
                            isSorted: column.isSorted,
                            isSortedDesc: column.isSortedDesc,
                            sortDescFirst: column.sortDescFirst,
                          })}
                          className="table-label text-12-bold text-uppercase"
                          {...column.getSortByToggleProps({
                            title: '',
                            onClick: handleChangeSortBy,
                          })}
                        >
                          {column.render('Header', { meta: pagination })}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length > 0 ? (
                  page.map((row) => {
                    prepareRow(row);
                    const className =
                      row.original.status === UserStatus.Deactivated
                        ? 'row-deactivated'
                        : '';
                    return (
                      <tr
                        key={row.id}
                        {...row.getRowProps()}
                        className={className}
                      >
                        {row.cells.map((cell, index: number) => (
                          <Fragment key={index}>
                            {renderTd(
                              cell,
                              row.original.status === UserStatus.Deactivated
                            )}
                          </Fragment>
                        ))}
                      </tr>
                    );
                  })
                ) : (
                  <DummyRowUser
                    headers={headerGroups}
                    isError={status === Status.Error}
                  />
                )}
              </tbody>
            </table>
          </PerfectScrollbar>
        )}
        {isEmptyError && (
          <div className="no-data-message">
            <div className="title">{intl.get('ERR_CHART_EMPTY_TITLE')}</div>
            <div>{intl.get('ERR_GROUPS_EMPTY_MESSAGE')}</div>
          </div>
        )}
        {showDeactivatedCheckbox && (
          <FormGroup>
            <div className="insight-checkbox-group">
              <Label className={`${styles.fitContent}`}>
                <span className="insight-checkbox mr-3" data-cy="terms-cb">
                  <input
                    checked={showDeactivated}
                    onChange={onToggleShowDeactivated}
                    type="checkbox"
                    name="showDeactivated"
                  />
                  <i className="icon-check" />
                </span>
                {intl.get('LBL_SETTINGS_USERS_SHOW_DEACTIVATED')}
              </Label>
            </div>
          </FormGroup>
        )}
        <DataTableFooter
          pageSizeDisabled={isEmptyError}
          loading={status === Status.Loading}
          totalResults={pagination.total}
          gotoPage={updatePage}
          pageSize={initialPageSize}
          setPageSize={updatePageSize}
          pageCount={pageCount}
          pageIndex={initialPageIndex}
        />
      </div>
    </>
  );
};

export default UsersDataTable;

import { useEffect, FC as ReactFC } from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';

import { getFormattedNumber } from 'helpers/NumberFormat';
import GroupMetricsProps from 'modules/private/group/components/group-metrics/GroupMetricsProps';

import GroupMetricType from './GroupMetricType';

const GroupMetrics: ReactFC<GroupMetricsProps> = (props) => {
  const { data, loading } = props;

  const groups = data.find((d) => d.cardType === GroupMetricType.GroupsFormed);
  const members = data.find((d) => d.cardType === GroupMetricType.MemberCount);
  const avMem = data.find((d) => d.cardType === GroupMetricType.AverageMembers);
  const atten = data.find((d) => d.cardType === GroupMetricType.AttendanceRate);
  const reps = data.find((d) => d.cardType === GroupMetricType.RepRate);
  const dropout = data.find((d) => d.cardType === GroupMetricType.DropoutRate);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const overallAttendance = (atten?.value ?? 0) + (reps?.value ?? 0);

  return (
    <div className="row dashboard-row five-col-row">
      <div className="col flex-col">
        <div className="content-box">
          <BlockUi blocking={loading}>
            <div className="chart-details">
              <span className="sub-title">
                {intl.get('LBL_NO_OF_GROUPS')}{' '}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get('LBL_NO_GROUPS_HELP_TEXT')}
                  />
                </span>
              </span>
            </div>
            <div className="chart-details separator">
              <span className="value text-24-semibold">
                <NumberFormat
                  value={groups?.value || 0}
                  displayType="text"
                  thousandSeparator
                />
              </span>
            </div>
          </BlockUi>
        </div>
      </div>
      <div className="col flex-col">
        <div className="content-box">
          <BlockUi blocking={loading}>
            <div className="chart-details">
              <span className="sub-title">
                {intl.get('LBL_NO_OF_MEMBERS')}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get('LBL_NO_MEMBERS_HELP_TEXT')}
                  />
                </span>
              </span>
            </div>
            <div className="chart-details separator">
              <span className="value text-24-semibold">
                <NumberFormat
                  value={members?.value || 0}
                  displayType="text"
                  thousandSeparator
                />
              </span>
            </div>
          </BlockUi>
        </div>
      </div>
      <div className="col flex-col">
        <div className="content-box">
          <BlockUi blocking={loading}>
            <div className="chart-details">
              <span className="sub-title">
                {intl.get('LBL_AVERAGE_NO_OF_MEMBERS_PER_GROUP')}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get(
                      'LBL_AVERAGE_NO_OF_MEMBERS_PER_GROUP_HELP_TEXT'
                    )}
                  />
                </span>
              </span>
            </div>
            <div className="chart-details separator">
              <span className="value text-24-semibold">
                <NumberFormat
                  value={getFormattedNumber(avMem?.value || 0)}
                  displayType="text"
                  thousandSeparator
                />
              </span>
            </div>
          </BlockUi>
        </div>
      </div>
      <div className="col flex-col">
        <div className="content-box">
          <BlockUi blocking={loading}>
            <div className="chart-details">
              <span className="sub-title">
                {intl.get('LBL_OVERALL_ATTENDANCE')}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get('LBL_OVERALL_ATTENDANCE_HELP_TEXT')}
                  />
                </span>
              </span>
            </div>
            <div className="chart-details separator">
              <span className="value text-24-semibold">
                <NumberFormat
                  value={getFormattedNumber(overallAttendance)}
                  displayType="text"
                  decimalScale={1}
                  suffix="%"
                />
              </span>
            </div>
          </BlockUi>
        </div>
      </div>
      <div className="col flex-col">
        <div className="content-box">
          <BlockUi blocking={loading}>
            <div className="chart-details">
              <span className="sub-title">
                {intl.get('LBL_DROPOUT_RATE')}
                <span className="help">
                  <i
                    className="icon-help"
                    data-for="insTooltip"
                    data-tip={intl.get('LBL_DROPOUT_RATE_HELP_TEXT')}
                  />
                </span>
              </span>
            </div>
            <div className="chart-details separator">
              <span className="value text-24-semibold">
                <NumberFormat
                  value={getFormattedNumber(dropout?.value || 0)}
                  displayType="text"
                  suffix="%"
                />
              </span>
            </div>
          </BlockUi>
        </div>
      </div>
    </div>
  );
};

export default GroupMetrics;

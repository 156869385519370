import { useEffect, useState, FC as ReactFC } from 'react';

import * as localStorage from 'local-storage';
import { isEmpty, isEqual, omit } from 'lodash';
import * as intl from 'react-intl-universal';
import { useLocation } from 'react-router-dom';

import AuthApiInstance from 'api/auth/AuthApi';
import UserPermissions from 'api/auth/user/UserPermissions';
import ApiError from 'api/common/ApiError';
import ProjectsApiInstance from 'api/projects/ProjectsApi';
import SettingsApiInstance from 'api/settings/SettingsApi';
import ErrorCodes from 'constants/ErrorCodes';
import StorageKeys from 'constants/StorageKeys';
import AppContext from 'context/AppContext';
import { getFirstError, getLocalizedErrorString } from 'helpers/ErrorFormat';
import {
  defaultGlobalFilters,
  getGlobalFilters,
  getGlobalFiltersUrl,
} from 'helpers/GlobalFilterUtils';
import history from 'router-history';
import AuthService from 'services/AuthService';
import GlobalFilters from 'shared/components/header-toolbar/GlobalFilters';
import EventKey from 'shared/enums/EventKey';
import HttpStatus from 'shared/enums/HttpStatus';
import Status from 'shared/enums/Status';
import { EventBus } from 'shared/events/EventBus';
import { CustomErrorArgs } from 'shared/types/eventTypes';
import { CallbackAsyncArg, NoParamFuncAsync } from 'shared/types/functionTypes';

import {
  GettingStartedStateData,
  GroupStatusData,
  PermissionData,
  ProjectListData,
  UserInfoData,
} from './AppProviderState';

const AppProvider: ReactFC = (props) => {
  const { children } = props;

  const location = useLocation();

  let excelReportDownloadCallback: NoParamFuncAsync | null;
  let dashboardSetupCallback: NoParamFuncAsync | null;
  let groupDetailsSetupCallback: CallbackAsyncArg<boolean> | null;

  // prettier-ignore
  const [dashboardSetupInProgress, setDashboardSetupInProgress] = useState<boolean>(false);
  // prettier-ignore
  const [groupDetailsSetupInProgress, setGroupDetailsSetupInProgress] = useState<boolean>(false);
  // prettier-ignore
  const [globalFilters, setGlobalFilters] = useState<GlobalFilters>(defaultGlobalFilters);
  // prettier-ignore
  const [permissionsData, setPermissionsData] = useState<PermissionData>({ status: Status.Loading, silent:false, claims: [], permissionLevel: null });
  // prettier-ignore
  const [gettingStartedStates, setGettingStartedStates] = useState<GettingStartedStateData>({ status: Status.Loading, silent:false, data: null, });
  // prettier-ignore
  const [userInfoData, setUserInfoData] = useState<UserInfoData>({ status: Status.Loading, data: null, features: null });
  /** Used to hide left nav tooltip while getting started modal is open */
  const [guideOpen, setGuideOpen] = useState<boolean>(false);
  /** Used to show resent invite success */
  // prettier-ignore
  const [resendInviteStatus, setResendInviteStatus] = useState<Status>(Status.Idle);
  /** Used to communicate submit status of settings invite users */
  // prettier-ignore
  const [inviteUsersSubmitting, setInviteUsersSubmitting] = useState<boolean>(false);

  /** Used to store project list for global filter */
  // prettier-ignore
  const [projectListData, setProjectListData] = useState<ProjectListData>({ status:Status.Idle, data:{}, filtered:{} });

  const [groupStatusData, setGroupStatusData] = useState<GroupStatusData>({
    status: Status.Idle,
    data: {},
    error: '',
  });

  /** Message to be displayed on toast for caught errors */
  // prettier-ignore
  const [errorToastText, setErrorToastText] = useState<string | undefined>(undefined);

  /** Header to be displayed on toast for caught errors */
  // prettier-ignore
  const [errorToastHeader, setErrorToastHeader] = useState<string>(intl.get('ERR_TOAST_SOMETHING_WEN_WRONG'));

  /** Message to be displayed on success toast */
  // prettier-ignore
  const [successToastText, setSuccessToastText] = useState<string | undefined>(undefined);

  /** Header to be displayed on success toast */
  // prettier-ignore
  const [successToastHeader, setSuccessToastHeader] = useState<string>(intl.get('LBL_TOAST_ITS_DONE'));

  useEffect(() => {
    EventBus.getInstance().register(EventKey.RequestForbidden, async () => {
      await getPermissions(true);
      setErrorToastText(intl.get('ERR_NO_PERMISSION_MESSAGE'));
    });
    EventBus.getInstance().register(
      EventKey.HandleCustomError,
      (eventArgs: CustomErrorArgs) => {
        const { error, genericErrorString, genericCallback, customCallback } =
          eventArgs;
        const errorCode = getFirstError(error);
        if (errorCode === ErrorCodes.Generic) {
          setErrorToastText(intl.get(genericErrorString));
          genericCallback();
        } else {
          setErrorToastText(intl.get(getLocalizedErrorString(errorCode)));
          customCallback(errorCode);
        }
      }
    );
  });

  /**
   * Hides the error toast
   */
  const hideErrorToast = (): void => {
    setErrorToastText(undefined);
    setErrorToastHeader(intl.get('ERR_TOAST_SOMETHING_WEN_WRONG'));
  };

  /**
   * Hides the success toast
   */
  const hideSuccessToast = (): void => {
    setSuccessToastText(undefined);
    setSuccessToastHeader(intl.get('LBL_TOAST_ITS_DONE'));
  };

  /**
   * Fetch project list for project global filter to refresh project list
   *
   * @param text Search string
   */
  const getProjectList = async (text?: string): Promise<void> => {
    const globalFiltersSearch = getGlobalFilters(location.search);
    setProjectListData((state) => ({ ...state, status: Status.Loading }));
    try {
      const projects = await ProjectsApiInstance.GetAllProjects(text);
      const projectsNormalized = projects.items.reduce(
        (accumulator, current) => ({
          ...accumulator,
          [current.code]: current,
        }),
        {}
      );

      /* BEGIN - Validate invalid/deleted project (global filter) in search query */
      // Only validate if search term is not present
      if (!text) {
        const projectsList = projects.items.map((item) => item.code);
        const searchProjectsList = globalFiltersSearch.projects;
        const filteredProjects = searchProjectsList.filter((item) =>
          projectsList.includes(item)
        );
        /* If selected projects exist in global filters AND 
          there is a mismatch between all possible projects 
          and any selected projects in global filters */
        const projectsInvalid =
          !isEmpty(searchProjectsList) &&
          !isEqual(filteredProjects, searchProjectsList);
        if (projectsInvalid) {
          /* Display error toast */
          setErrorToastText(
            intl.get('ERR_TOAST_GLOBAL_FILTERS_PROJECTS_MISMATCH')
          );
          const newGlobalFilters = {
            ...globalFilters,
            projects: [],
          };
          /* Reset projects global filter */
          setGlobalFilters(newGlobalFilters);
          /* Redirect app to valid global filter state */
          history.push(getGlobalFiltersUrl(newGlobalFilters, location));
        }
      }
      /* END - Validate invalid/deleted project (global filter) in search query */

      if (text && text.trim().length > 0) {
        setProjectListData((prev) => ({
          status: Status.Success,
          filtered: projectsNormalized,
          data: prev.data,
        }));
      } else {
        setProjectListData({
          status: Status.Success,
          data: projectsNormalized,
          filtered: projectsNormalized,
        });
      }
    } catch (error) {
      setProjectListData({ status: Status.Error, data: {}, filtered: {} });
    }
  };

  /**
   * Fetch group statuses for group status global filter to refresh status list
   */
  const getGroupStatuses = async (): Promise<void> => {
    setGroupStatusData((state) => ({ ...state, status: Status.Loading }));
    let orgId = localStorage.get<string>(StorageKeys.OrganizationId);
    try {
      if (!orgId) {
        const { organizationId } = await AuthApiInstance.GetUserInfo();
        orgId = organizationId;
        localStorage.set(StorageKeys.OrganizationId, orgId);
      }
      const response = await SettingsApiInstance.GetOrganizationGroupStatuses(
        orgId
      );

      const groupStatusNormalized = response.items.reduce(
        (accumulator, item) => ({ ...accumulator, [item.status]: item }),
        {}
      );

      setGroupStatusData({
        data: groupStatusNormalized,
        status: Status.Success,
        error: '',
      });
    } catch (error) {
      let errorText = intl.get('OOPS_SOMETHING_WENT_WRONG');
      if (error instanceof ApiError) {
        if (error.status === HttpStatus.FORBIDDEN) {
          errorText = intl.get('ERR_NO_PERMISSION_MESSAGE');
        }
      }
      setGroupStatusData({ status: Status.Error, data: {}, error: errorText });
    }
  };

  /** Functions for dashboard setup */
  const setDashboardSetupCallback = (c: NoParamFuncAsync): void => {
    dashboardSetupCallback = c;
  };

  const startDashboardSetup = (): void => setDashboardSetupInProgress(true);

  const endDashboardSetup = async (): Promise<void> => {
    if (dashboardSetupCallback) {
      await dashboardSetupCallback();
    }
    setDashboardSetupInProgress(false);
  };

  /** Functions for group details setup */
  const setGroupDetailsSetupCallback = (c: CallbackAsyncArg<boolean>): void => {
    groupDetailsSetupCallback = c;
  };

  const startGroupDetailsSetup = (): void =>
    setGroupDetailsSetupInProgress(true);

  const endGroupDetailsSetup = async (cancel = false): Promise<void> => {
    if (groupDetailsSetupCallback) {
      await groupDetailsSetupCallback(cancel);
    }
    setGroupDetailsSetupInProgress(false);
  };

  /** Functions for excel download */
  const setExcelReportDownloadCallback = (callback: NoParamFuncAsync): void => {
    excelReportDownloadCallback = callback;
  };

  const startExcelReportDownload = (): void => {
    if (excelReportDownloadCallback) {
      excelReportDownloadCallback();
    }
  };

  /**
   * Logs out user
   */
  const logoutUser = async (): Promise<void> => {
    try {
      await AuthApiInstance.LogoutAsync();
    } catch (error) {
      // console.log(error);
    } finally {
      AuthService.Logout();
    }
  };

  /**
   * Fetch user permissions and adds them to local-storage
   *
   * @param silent Whether loading permissions should keep app from loading; check PublicRoutes.tsx
   */
  const getPermissions = async (silent?: boolean): Promise<void> => {
    setPermissionsData((prevState) => ({
      ...prevState,
      silent: !!silent,
      status: Status.Loading,
    }));
    try {
      const permissionsResponse = await AuthApiInstance.GetUserPermissions();
      try {
        // prettier-ignore
        localStorage.set<UserPermissions>(StorageKeys.Permissions, permissionsResponse);
      } catch (error) {
        //   console.log('error:', error);
      } finally {
        setPermissionsData((prevState) => ({
          ...prevState,
          silent: false,
          status: Status.Success,
          ...permissionsResponse,
        }));
      }
    } catch (error) {
      logoutUser();
    }
  };

  /**
   * Fetch getting started states
   *
   * @param silent Whether setting getting started states should keep app from loading; check PublicRoutes.tsx
   */
  const getGettingStartedState = async (silent = false): Promise<void> => {
    setGettingStartedStates((prevState) => ({
      ...prevState,
      silent,
      status: Status.Loading,
    }));
    try {
      const gettingStartedStateResponse =
        await AuthApiInstance.GetGettingStartedState();
      setGettingStartedStates((prevState) => ({
        ...prevState,
        status: Status.Success,
        silent: false,
        data: gettingStartedStateResponse,
      }));
    } catch (error) {
      setGettingStartedStates((prevState) => ({
        ...prevState,
        status: Status.Error,
        silent: false,
        data: null,
      }));
    }
  };

  /**
   * Fetch logged-in user's info
   */
  const getUserInfo = async (): Promise<void> => {
    try {
      setUserInfoData((state) => ({ ...state, status: Status.Loading }));
      const userInfo = await AuthApiInstance.GetUserInfo();
      setUserInfoData({
        status: Status.Success,
        data: omit(userInfo, ['features']),
        features: userInfo.features,
      });
    } catch (error) {
      setUserInfoData({ data: null, features: null, status: Status.Error });
    }
  };

  useEffect(() => {
    try {
      /* Reset toasts */
      setErrorToastText(undefined);
      setErrorToastHeader(intl.get('ERR_TOAST_SOMETHING_WEN_WRONG'));
      setSuccessToastText(undefined);
      setSuccessToastHeader(intl.get('LBL_TOAST_ITS_DONE'));

      const userPermissions = AuthService.GetUserPermissions();
      const userFeatures = AuthService.GetUserFeatures();
      if (userPermissions.claims) {
        setPermissionsData((prevState) => ({
          ...prevState,
          status: Status.Success,
          ...userPermissions,
        }));
      }
      if (userFeatures) {
        setUserInfoData((prevState) => ({
          ...prevState,
          status: Status.Success,
          features: userFeatures,
        }));
      }
    } catch (error) {
      //   console.log('error:', error);
    }

    if (AuthService.IsLoggedIn()) {
      getPermissions();
      getGettingStartedState();
      getUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContext.Provider
      value={{
        dashboardSetupInProgress,
        groupDetailsSetupInProgress,
        globalFilters,
        permissionsData,
        gettingStartedStates,
        guideOpen,
        resendInviteStatus,
        inviteUsersSubmitting,
        projectListData,
        groupStatusData,
        userInfoData,
        errorToastText,
        errorToastHeader,
        successToastText,
        successToastHeader,
        setSuccessToastText,
        setSuccessToastHeader,
        setErrorToastText,
        setErrorToastHeader,
        hideSuccessToast,
        hideErrorToast,
        getUserInfo,
        getGroupStatuses,
        getProjectList,
        setInviteUsersSubmitting,
        setResendInviteStatus,
        setGuideOpen,
        getGettingStartedState,
        getPermissions,
        startDashboardSetup,
        startGroupDetailsSetup,
        endDashboardSetup,
        endGroupDetailsSetup,
        setGlobalFilters,
        setDashboardSetupCallback,
        setGroupDetailsSetupCallback,
        setExcelReportDownloadCallback,
        startExcelReportDownload,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;

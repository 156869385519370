import { CancelTokenSource } from 'axios';

import ApiBase from 'api/common/ApiBase';
import ApiError from 'api/common/ApiError';
import ListFilters from 'api/common/ListFilters';
import ListResponse from 'api/common/ListResponse';
import ProjectsPagination from 'api/projects/ProjectsPagination';
import { ProjectsApiTags } from 'constants/request-tags/RequestTags';

import PaginatedListResponse from '../common/PaginatedListResponse';
import FacilitatorImageUploadUrlResponse, {
  RequiredHeaders,
} from './FacilitatorImageUploadUrlResponse';
import ProjectCodeResponse from './ProjectCodeResponse';
import ProjectCompatibilityResponse from './ProjectCompatibilityResponse';
import ProjectCreateResponse from './ProjectCreateResponse';
import ProjectDataMinimal from './ProjectDataMinimal';
import ProjectDetail from './ProjectDetail';
import ProjectFacilitator from './ProjectFacilitator';
import ProjectItem from './ProjectItem';
import ProjectOrgCountry from './ProjectOrgCountry';
import ProjectsFileItem from './ProjectsFileItem';
import ProjectStatsResponse from './ProjectStatsResponse';
import ProjectUser from './ProjectUser';

class ProjectsApi extends ApiBase {
  async GetNewProjectId(
    cancelToken: CancelTokenSource
  ): Promise<ProjectCodeResponse> {
    const path = 'projects/project_code';
    const projectId = this.GetAsync<ProjectCodeResponse>({
      action: path,
      tag: ProjectsApiTags.GetNewProjectId,
      cancelSource: cancelToken,
    });

    return projectId;
  }

  async GetProjectsListData(
    listFilters: Pick<ProjectsPagination, 'page' | 'pageSize'>,
    cancelToken: CancelTokenSource
  ): Promise<PaginatedListResponse<ProjectItem>> {
    const { page, pageSize } = listFilters;
    const listFilterQuery = `page=${page}&pageSize=${pageSize}`;

    const path = `projects?${listFilterQuery}`;
    const listData = this.GetAsync<PaginatedListResponse<ProjectItem>>({
      action: path,
      tag: ProjectsApiTags.GetProjectsListData,
      cancelSource: cancelToken,
    });
    return listData;
  }

  async GetProject(
    projectId: string,
    cancelToken?: CancelTokenSource
  ): Promise<ProjectItem> {
    const path = `projects/${projectId}/details`;
    const action = `${path}`;
    const data = this.GetAsync<ProjectItem>({
      action,
      tag: ProjectsApiTags.GetProject,
      cancelSource: cancelToken,
    });
    return data;
  }

  async CreateProject(
    details: Partial<ProjectDetail>,
    cancelToken: CancelTokenSource
  ): Promise<ProjectCreateResponse> {
    const path = 'projects';
    const result = this.PostAsync<ProjectCreateResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body: details,
      tag: ProjectsApiTags.CreateProject,
      cancelSource: cancelToken,
    });
    return result;
  }

  async UpdateProject(
    details: Partial<ProjectDetail>,
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const path = 'projects';
    const result = this.PutAsync<Record<string, unknown>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body: details,
      tag: ProjectsApiTags.UpdateProject,
      cancelSource: cancelToken,
    });
    return result;
  }

  async DeleteProject(
    projectId: string,
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const path = `projects/${projectId}`;

    const result = this.DeleteAsync<Record<string, unknown>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: ProjectsApiTags.DeleteProject,
      cancelSource: cancelToken,
    });
    return result;
  }

  async GetProjectUsers(
    projectId: string,
    cancelToken: CancelTokenSource,
    listFilters?: Omit<ListFilters, 'search'>
  ): Promise<PaginatedListResponse<ProjectUser>> {
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryString = listFilters
      ? `?sortBy=${listFilters.sortBy}&sort=${listFilters.sort}&page=${listFilters.page}&pageSize=${listFilters.pageSize}&timezone=${clientTimezone}`
      : `?timezone=${clientTimezone}`;

    const path = `projects/${projectId}/users${queryString}`;

    const action = `${path}`;

    const data = this.GetAsync<PaginatedListResponse<ProjectUser>>({
      action,
      tag: ProjectsApiTags.GetProjectUsers,
      cancelSource: cancelToken,
    });
    return data;
  }

  async GetProjectFacilitators(
    projectId: string,
    cancelToken: CancelTokenSource,
    listFilters?: Omit<ListFilters, 'search'>
  ): Promise<PaginatedListResponse<ProjectFacilitator>> {
    const queryString = listFilters
      ? `?sortBy=${listFilters.sortBy}&sort=${listFilters.sort}&page=${listFilters.page}&pageSize=${listFilters.pageSize}`
      : '';
    const path = `projects/${projectId}/facilitators${queryString}`;
    const action = `${path}`;
    const data = this.GetAsync<PaginatedListResponse<ProjectFacilitator>>({
      action,
      tag: ProjectsApiTags.GetProjectFacilitators,
      cancelSource: cancelToken,
    });
    return data;
  }

  async GetAllProjects(
    text?: string
  ): Promise<ListResponse<ProjectDataMinimal>> {
    const search = text && text.trim().length > 0 ? text : '';
    const path = `projects/all-projects?${search ? `search=${search}` : ''}`;
    const allProjectData = this.GetAsync<ListResponse<ProjectDataMinimal>>({
      action: path,
      tag: ProjectsApiTags.GetAllProjects,
    });
    return allProjectData;
  }

  async GetAllProjectUsers(
    projectId: string,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<ProjectUser>> {
    const path = `projects/${projectId}/all-users`;
    const allProjectUsersData = this.GetAsync<ListResponse<ProjectUser>>({
      action: path,
      tag: ProjectsApiTags.GetAllProjectUsers,
      cancelSource: cancelToken,
    });
    return allProjectUsersData;
  }

  async AddProjectUsers(
    projectId: string,
    users: string[],
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const path = `projects/${projectId}/users`;
    const action = `${path}`;
    const body = {
      users,
    };

    const result = this.PostAsync<Record<string, unknown>>({
      action,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: ProjectsApiTags.AddProjectUsers,
      cancelSource: cancelToken,
    });

    return result;
  }

  async AddProjectFacilitators(
    projectId: string,
    facilitators: string[],
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const path = `projects/${projectId}/facilitators`;
    const action = `${path}`;
    const body = {
      facilitators,
    };

    const result = this.PostAsync<Record<string, unknown>>({
      action,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: ProjectsApiTags.AddProjectFacilitators,
      cancelSource: cancelToken,
    });

    return result;
  }

  async GetUserProjectStats(
    cancelToken: CancelTokenSource
  ): Promise<ProjectStatsResponse> {
    const path = 'user/project-stats';
    const data = this.GetAsync<ProjectStatsResponse>({
      action: path,
      tag: ProjectsApiTags.GetUserProjectStats,
      cancelSource: cancelToken,
    });
    return data;
  }

  async GetProjectOrgCountries(
    id: string,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<ProjectOrgCountry>> {
    const path = `projects/${id}/countries`;
    const projectOrgCountries = this.GetAsync<ListResponse<ProjectOrgCountry>>({
      action: path,
      tag: ProjectsApiTags.GetProjectOrgCountries,
      cancelSource: cancelToken,
    });
    return projectOrgCountries;
  }

  async GetUserProjectCompatibility(
    userId: string,
    projectId: string,
    cancelToken: CancelTokenSource
  ): Promise<ProjectCompatibilityResponse> {
    const path = `user/${userId}/project-compatibility/${projectId}`;
    const result = this.GetAsync<ProjectCompatibilityResponse>({
      action: path,
      tag: ProjectsApiTags.GetUserProjectCompatibility,
      cancelSource: cancelToken,
    });
    return result;
  }

  async GetFacilitatorImageUploadUrl(
    body: Array<ProjectsFileItem>,
    cancelToken: CancelTokenSource
  ): Promise<FacilitatorImageUploadUrlResponse> {
    const path = `organizations/facilitators/profile-image/presigned`;

    const uploadUrlResponse = this.PostAsync<FacilitatorImageUploadUrlResponse>(
      {
        action: path,
        anonymous: false,
        includeAuthToken: true,
        body,
        tag: ProjectsApiTags.GetFacilitatorImageUploadUrl,
        cancelSource: cancelToken,
      }
    );

    return uploadUrlResponse;
  }

  async AssignGroupsToProject(
    projectId: string,
    groups: string[],
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const path = `projects/${projectId}/groups`;
    const body = { groups };

    const response = this.PostAsync<Record<string, unknown>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: ProjectsApiTags.AssignGroupsToProject,
      cancelSource: cancelToken,
    });

    return response;
  }

  // eslint-disable-next-line class-methods-use-this
  async UploadFacilitatorImage(
    url: string,
    requiredHeaders: RequiredHeaders,
    file: Blob
  ): Promise<Response> {
    const headers = {
      ...requiredHeaders,
      'Content-Type': 'application/octet-stream',
    };

    const uploadUrlResponse = await fetch(url, {
      method: 'PUT',
      headers,
      body: file,
    });

    const { ok, status, statusText } = uploadUrlResponse;
    return new Promise((resolve, reject) => {
      if (ok) {
        resolve(uploadUrlResponse);
      } else {
        const error = new ApiError(
          `Request failed with status ${status}`,
          status,
          statusText
        );
        reject(error);
      }
    });
  }

  async GetProjectUsersExcel(
    projectId: string,
    cancelToken: CancelTokenSource
  ): Promise<Blob> {
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryString = `?timezone=${clientTimezone}`;

    const path = `projects/${projectId}/users/export`;

    const action = `${path}${queryString}`;

    const excelData = this.GetAsyncBlob({
      action,
      tag: ProjectsApiTags.GetProjectUsersExcel,
      cancelSource: cancelToken,
    });

    return excelData;
  }

  async GetProjectFacilitatorsExcel(
    projectId: string,
    cancelToken: CancelTokenSource
  ): Promise<Blob> {
    const path = `projects/${projectId}/facilitators/export`;

    const excelData = this.GetAsyncBlob({
      action: path,
      tag: ProjectsApiTags.GetProjectFacilitatorsExcel,
      cancelSource: cancelToken,
    });

    return excelData;
  }

  async RemoveProjectUsers(
    projectId: string,
    users: string[],
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const path = `projects/${projectId}/users`;
    const action = `${path}`;
    const body = {
      users,
    };
    const result = this.DeleteAsync<Record<string, unknown>>({
      action,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: ProjectsApiTags.RemoveProjectUsers,
      cancelSource: cancelToken,
    });
    return result;
  }

  async RemoveProjectFacilitators(
    projectId: string,
    facilitators: string[],
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const path = `projects/${projectId}/facilitators`;
    const action = `${path}`;
    const body = {
      facilitators,
    };
    const result = this.DeleteAsync<Record<string, unknown>>({
      action,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: ProjectsApiTags.RemoveProjectFacilitators,
      cancelSource: cancelToken,
    });
    return result;
  }
}

const ProjectsApiInstance = new ProjectsApi();

export default ProjectsApiInstance;

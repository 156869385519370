import { useEffect, useRef, useState, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { useLocation } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardFooter,
  CardText,
  Col,
  Popover,
  PopoverBody,
  Row,
} from 'reactstrap';

import Misc from 'constants/misc/Misc';
import ModulePaths from 'constants/ModulePaths';
import {
  defaultGlobalFilters,
  getGlobalFiltersSearch,
} from 'helpers/GlobalFilterUtils';
import { getFormattedNumber } from 'helpers/NumberFormat';
import InsLink from 'shared/components/ins-link/InsLink';
import clearIcon from 'shared/static/img/ic_x.svg';
import xlsIconDisabled from 'shared/static/img/xls_disabled.svg';

import styles from '../projectListItem.module.scss';
import ProjectListItemViewModel from '../ProjectListItemViewModel';
import ProjectItemGroupCardProps from './ProjectItemGroupCardProps';

const getTip = (data: ProjectListItemViewModel['notifications']): string => {
  if (data.UNASSIGNED_TO_FACILITATOR > 0 && data.UNASSIGNED_GROUP_STATUS > 0) {
    return intl.get('LBL_PROJECTS_GROUP_WARNING_TOOLTIP_BOTH', {
      f: getFormattedNumber(data.UNASSIGNED_TO_FACILITATOR),
      s: getFormattedNumber(data.UNASSIGNED_GROUP_STATUS),
    });
  }
  if (data.UNASSIGNED_TO_FACILITATOR > 0) {
    return intl.get('LBL_PROJECTS_GROUP_WARNING_TOOLTIP_FACILITATORS', {
      f: getFormattedNumber(data.UNASSIGNED_TO_FACILITATOR),
    });
  }
  if (data.UNASSIGNED_GROUP_STATUS > 0) {
    return intl.get('LBL_PROJECTS_GROUP_WARNING_TOOLTIP_STATUS', {
      s: getFormattedNumber(data.UNASSIGNED_GROUP_STATUS),
    });
  }
  return '';
};

const ProjectItemGroupCard: ReactFC<ProjectItemGroupCardProps> = (
  props: ProjectItemGroupCardProps
) => {
  const {
    guideProjectId,
    projectId,
    groupCount,
    projectCode,
    notifications,
    showGroupsGuide,
    setShowGroupsGuide,
  } = props;

  const location = useLocation();
  const filters = { ...defaultGlobalFilters, projects: [projectCode] };
  const search = getGlobalFiltersSearch(filters, location);

  const projectGroupsPath = `${ModulePaths.GroupsPath}?${search}&${Misc.ViaProjectsQuery}`;

  const popperRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles closing the popper
   *
   * @param open Whether to close popper
   */
  const closePopper = (open: boolean): void => {
    setIsOpen(open);
    setShowGroupsGuide(open);
  };

  const isError = Object.values(notifications).some((count) => count > 0);

  return (
    <Card className={`${styles.card} ${isError ? styles.warn : ''}`}>
      <CardBody>
        <Row>
          <Col>
            <CardText
              className={`text-14-semibold ${
                isError ? 'text-orange' : 'text-gray'
              } ${styles.transformUpper}`}
            >
              {intl.get('LBL_PROJECTS_GROUPS')}
            </CardText>
          </Col>
          <div className="col-auto" ref={popperRef}>
            {guideProjectId === projectId && groupCount > 0 && showGroupsGuide && (
              <Popover
                isOpen={isOpen}
                placement="top"
                container="body"
                hideArrow
                positionFixed
                modifiers={{
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: true,
                  },
                  flip: { enabled: false },
                }}
                toggle={(): void => closePopper(!isOpen)}
                trigger="manual"
                popperClassName="project-popper groups-popper"
                target={popperRef}
                delay={{ show: 500, hide: 100 }}
              >
                {(): JSX.Element => (
                  <PopoverBody>
                    <div>
                      <div className="text-center text-gray">
                        <span className="text-14-medium">
                          {intl.get(
                            'LBL_PROJECTS_TOOLTIP_GUIDE_GROUPS_SECONDARY_DESCRIPTION'
                          )}
                        </span>
                      </div>
                      <div className="close-container invert">
                        <button
                          className="insight-close-button"
                          type="button"
                          onClick={(): void => closePopper(false)}
                        >
                          <img src={clearIcon} alt="Close" />
                        </button>
                      </div>
                    </div>
                  </PopoverBody>
                )}
              </Popover>
            )}
            <InsLink
              disabledClass={styles.disabled}
              disabled={groupCount <= 0}
              className={`text-gray ${styles.plus}`}
              to={projectGroupsPath}
            >
              <i className="icon-more reduce-icon" />
            </InsLink>
          </div>
          <Col xs="12">
            <h3>
              {getFormattedNumber(groupCount)}{' '}
              <i
                data-for="insTooltip"
                data-tip={getTip(notifications)}
                data-place="bottom"
                data-class="error"
                className="icon-warning text-orange"
              />
            </h3>
          </Col>
        </Row>
      </CardBody>
      <CardFooter className={styles.footer}>
        <Row form>
          <Col xs="auto" className="pr-0 d-flex align-items-center">
            <img src={xlsIconDisabled} alt="excel icon" />
          </Col>
          <Col className="d-flex pr-0 align-items-center">
            <p className="text-12-medium text-gray mb-0">
              {intl.get('LBL_PROJECTS_NO_DATA_YET')}
            </p>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default ProjectItemGroupCard;

import * as intl from 'react-intl-universal';

import WidgetType from 'modules/private/dashboard/components/widget-metrics/widgets/WidgetType';

type WidgetConfig = {
  name: string;
  tooltip: string;
  configurable: boolean;
  targetSettings: boolean;
};

class WidgetConfigs {
  public static GetWidgetConfig(type: WidgetType): WidgetConfig {
    const widgetConfigs: { [key: string]: WidgetConfig } = {
      [WidgetType.Age]: {
        name: intl.get('LBL_WID_AGE'),
        tooltip: intl.get('LBL_WID_AGE_HINT'),
        configurable: false,
        targetSettings: false,
      },
      [WidgetType.AverageSavings]: {
        name: intl.get('LBL_WID_AVERAGE_SAVINGS_MEMBER'),
        tooltip: intl.get('LBL_WID_AVERAGE_SAVINGS_MEMBER_HINT'),
        configurable: true,
        targetSettings: true,
      },
      [WidgetType.CumulativeSavings]: {
        name: intl.get('LBL_WID_CUMULATIVE_SAVINGS'),
        tooltip: intl.get('LBL_WID_CUMULATIVE_SAVINGS_HINT'),
        configurable: true,
        targetSettings: true,
      },
      [WidgetType.Education]: {
        name: intl.get('LBL_WID_EDUCATION'),
        tooltip: intl.get('LBL_WID_EDUCATION_HINT'),
        configurable: false,
        targetSettings: false,
      },
      [WidgetType.Gender]: {
        name: intl.get('LBL_WID_GENDER'),
        tooltip: intl.get('LBL_WID_GENDER_HINT'),
        configurable: false,
        targetSettings: false,
      },
      [WidgetType.PersonalGoalAchievement]: {
        name: intl.get('LBL_WID_PERSONAL_GOAL_ACHIEVEMENT'),
        tooltip: intl.get('LBL_WID_PERSONAL_GOAL_ACHIEVEMENT_HINT'),
        configurable: false,
        targetSettings: false,
      },
      [WidgetType.Groups]: {
        name: intl.get('LBL_WID_GROUPS_FORMED'),
        tooltip: intl.get('LBL_WID_GROUPS_FORMED_HINT'),
        configurable: true,
        targetSettings: true,
      },
      [WidgetType.LoanFund]: {
        name: intl.get('LBL_WID_LOAN_FUND_UTILIZATION'),
        tooltip: intl.get('LBL_WID_LOAN_FUND_UTILIZATION_HINT'),
        configurable: true,
        targetSettings: true,
      },
      [WidgetType.PersonalGoalType]: {
        name: intl.get('LBL_WID_PERSONAL_GOAL_TYPES'),
        tooltip: intl.get('LBL_WID_PERSONAL_GOAL_TYPES_HINT'),
        configurable: false,
        targetSettings: false,
      },
      [WidgetType.LoanType]: {
        name: intl.get('LBL_WID_LOAN_TYPES'),
        tooltip: intl.get('LBL_WID_LOAN_TYPES_HINT'),
        configurable: false,
        targetSettings: false,
      },
      [WidgetType.SavingsLeaderBoard]: {
        name: intl.get('LBL_WID_SAVINGS_LEADER_BOARD'),
        tooltip: intl.get('LBL_WID_SAVINGS_LEADER_BOARD_HINT'),
        configurable: false,
        targetSettings: false,
      },
      [WidgetType.DreamStartLeaderBoard]: {
        name: intl.get('LBL_WID_DREAM_STAR_LEADER_BOARD'),
        tooltip: intl.get('LBL_WID_DREAM_STAR_LEADER_BOARD_HINT'),
        configurable: false,
        targetSettings: false,
      },
      [WidgetType.Dependents]: {
        name: intl.get('LBL_WID_DEPENDENTS'),
        tooltip: intl.get('LBL_WID_DEPENDENTS_HINT'),
        configurable: false,
        targetSettings: false,
      },
      [WidgetType.MemberChildren]: {
        name: intl.get('LBL_WID_MEMBERS_CARING_FOR_CHILDREN'),
        tooltip: intl.get('LBL_WID_MEMBERS_CARING_FOR_CHILDREN_HINT'),
        configurable: false,
        targetSettings: false,
      },
      [WidgetType.AdditionalFunds]: {
        name: intl.get('LBL_WID_ADDITIONAL_FUNDS'),
        tooltip: intl.get('LBL_WID_ADDITIONAL_FUNDS_HINT'),
        configurable: false,
        targetSettings: false,
      },
    };

    return widgetConfigs[type];
  }
}

export default WidgetConfigs;

import { FC as ReactFC } from 'react';

import orderBy from 'lodash/orderBy';

import { getColorFor } from 'helpers/ColorHelper';
import GroupWidgetValueGroup from 'modules/private/group/components/group-widgets/widgets/group-widget/GroupWidgetValueGroup';
import GroupWidgetViewModel from 'modules/private/group/components/group-widgets/widgets/group-widget/GroupWidgetViewModel';
import Donut from 'shared/components/ins-charts/donut/Donut';
import InsChartValue from 'shared/components/ins-charts/InsChartValue';

const LoanTypesWidget: ReactFC<{ data: GroupWidgetViewModel }> = (props) => {
  const maxItemCount = 5;
  const dimensionSettings = {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    aspectRatioWidth: 1,
    aspectRatioHeight: 0.6,
  };

  const { data } = props;
  const { values } = data;

  const businessData = values?.find(
    (value) => value.group === GroupWidgetValueGroup.Business
  );
  const educationData = values?.find(
    (value) => value.group === GroupWidgetValueGroup.Education
  );
  const householdData = values?.find(
    (value) => value.group === GroupWidgetValueGroup.Household
  );
  const medicalData = values?.find(
    (value) => value.group === GroupWidgetValueGroup.Medical
  );
  const otherData = values?.find(
    (value) => value.group === GroupWidgetValueGroup.Other
  );

  const donutValues = Array<InsChartValue>();

  if (
    businessData &&
    educationData &&
    householdData &&
    medicalData &&
    otherData
  ) {
    donutValues.push({
      name: businessData.title,
      count: businessData.count,
      percentage: businessData.percentage,
      group: businessData.group,
    });
    donutValues.push({
      name: educationData.title,
      count: educationData.count,
      percentage: educationData.percentage,
      group: educationData.group,
    });
    donutValues.push({
      name: householdData.title,
      count: householdData.count,
      percentage: householdData.percentage,
      group: householdData.group,
    });
    donutValues.push({
      name: medicalData.title,
      count: medicalData.count,
      percentage: medicalData.percentage,
      group: medicalData.group,
    });
    donutValues.push({
      name: otherData.title,
      count: otherData.count,
      percentage: otherData.percentage,
      group: otherData.group,
    });
  }

  return (
    <div className="gender-widget">
      <div className="chart-container">
        <Donut
          isLoading={false}
          values={donutValues}
          dimension={dimensionSettings}
        />
      </div>
      {donutValues.length === maxItemCount && (
        <div className="chart-legend">
          <div className="chart-legend-inner">
            {orderBy(donutValues, 'percentage', 'desc').map((value) => (
              <div key={value.name} className="item">
                <div className="item-col color-container">
                  <span
                    className="color"
                    style={{
                      backgroundColor: getColorFor(value.group),
                    }}
                  />
                </div>
                <div className="label item-col">
                  <span className="value">{value.percentage}%</span>
                </div>
                <div className="label item-col">
                  &nbsp;-&nbsp;
                  <span className="name">{value.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LoanTypesWidget;

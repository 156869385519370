/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  ChangeEvent,
  FC as ReactFC,
  useContext,
  useEffect,
  useState,
} from 'react';

import { AxisProps } from '@nivo/axes';
import { Serie } from '@nivo/line';
import BlockUi from 'react-block-ui';
import { DebounceInput } from 'react-debounce-input';
import * as intl from 'react-intl-universal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactTooltip from 'react-tooltip';
import {
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';

import Meta from 'api/common/Meta';
import Facilitator from 'api/messaging/group-filters/Facilitator';
import Constraints from 'constants/forms/Constraints';
import TIMEOUTS from 'constants/Timeouts';
import AppContext from 'context/AppContext';
import { getRandomColorFor } from 'helpers/ColorHelper';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import ImageComponent from 'shared/components/image/ImageComponent';
import { Option } from 'shared/components/ins-form-fields/formik-select/FormikSelectOption';
import Frequency from 'shared/enums/Frequency';
import blankAvatar from 'shared/static/img/project-placeholders/blank_avatar.svg';

import Attendance from './attendance/Attendance';
import AverageMembers from './average-members/AverageMembers';
import AverageSavings from './average-savings/AverageSavings';
import styles from './facilitatorMetrics.module.scss';
import FacilitatorMetricContainerProps from './FacilitatorMetricsContainerProps';
import FacilitatorMetricType from './FacilitatorMetricsType';
import Groups from './groups/Groups';
import Members from './members/Members';
import SupervisorFilterSelect from './supervisor-filter-select/SupervisorFilterSelect';

type ChartData = {
  [FacilitatorMetricType.Groups]: { data: Serie[]; meta: Partial<Meta> };
  [FacilitatorMetricType.Members]: { data: Serie[]; meta: Partial<Meta> };
  [FacilitatorMetricType.Attendance]: { data: Serie[]; meta: Partial<Meta> };
  [FacilitatorMetricType.AverageSavings]: {
    data: Serie[];
    meta: Partial<Meta>;
  };
  [FacilitatorMetricType.AverageMembers]: {
    data: Serie[];
    meta: Partial<Meta>;
  };
};

const MAX_FACILITATORS_SELECTED = Constraints.MaxFacilitatorsInMetric;

const FacilitatorMetricContainer: ReactFC<FacilitatorMetricContainerProps> = (
  props
) => {
  const defaultBottomAxis: AxisProps = {
    format: '%Y, %b',
    // tickValues: 'every 1 month',
  };
  const defaultChartData: ChartData = {
    [FacilitatorMetricType.Groups]: { data: Array<Serie>(), meta: {} },
    [FacilitatorMetricType.Members]: { data: Array<Serie>(), meta: {} },
    [FacilitatorMetricType.Attendance]: { data: Array<Serie>(), meta: {} },
    [FacilitatorMetricType.AverageSavings]: {
      data: Array<Serie>(),
      meta: {},
    },
    [FacilitatorMetricType.AverageMembers]: {
      data: Array<Serie>(),
      meta: {},
    },
  };

  const defaultTab = FacilitatorMetricType.Groups;

  const {
    data,
    filters,
    loading,
    supervisors,
    facilitators,
    onFacilitatorSelectionChange,
    onCloseSupervisorSelect,
  } = props;

  const groups = data.find((i) => i.type === 'GROUPS');

  const { globalFilters } = useContext(AppContext);

  const [activeTab, setActiveTab] = useState<FacilitatorMetricType>(defaultTab);
  const [xAxisConfig, setxAxisConfig] = useState<AxisProps>(defaultBottomAxis);
  const [chartData, setChartData] = useState<ChartData>(defaultChartData);
  const [filteredFacilitators, setFilteredFacilitators] =
    useState<Facilitator[]>(facilitators);
  const [filteredSupervisors, setFilteredSupervisors] =
    useState<Option[]>(supervisors);
  const [facilitatorSearchTerm, setFacilitatorSearchTerm] = useState('');

  useEffect(() => {
    const classifiedChartData: ChartData = defaultChartData;
    data.forEach((d) => {
      d.dataPointCollection.forEach((dpc, index) => {
        const dataPoints = dpc.dataPoints.map((dp) => ({
          x: dp.key,
          y: dp.value || 0,
          name: dpc.name,
        }));

        const dataPointsSerie: Serie = {
          id: dpc.name,
          data: dataPoints,
          color: getRandomColorFor(index),
        };

        classifiedChartData[d.type].meta = d.meta;
        classifiedChartData[d.type].data.push(dataPointsSerie);
      });
    });
    setChartData(classifiedChartData);
  }, [data]);

  useEffect(() => {
    switch (filters.frequency.value) {
      case Frequency.Yearly:
        setxAxisConfig({
          format: '%Y',
          tickValues: 'every year',
        });
        break;
      case Frequency.Quarterly:
        setxAxisConfig({
          format: '%Y, Q%q',
          tickValues: groups?.dataPointCollection[0].dataPoints.length,
        });
        break;
      default:
        setxAxisConfig({
          format: '%Y, %b',
        });
        break;
    }
  }, [filters.frequency, groups?.dataPointCollection[0].dataPoints.length]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    setFilteredFacilitators(facilitators);
  }, [facilitators]);

  useEffect(() => {
    setFilteredSupervisors(supervisors);
  }, [supervisors]);

  useEffect(() => {
    setFacilitatorSearchTerm('');
  }, [globalFilters, filters.supervisors]);

  const isActive = (tab: FacilitatorMetricType): string => {
    const activeTabClasses = 'tab active';
    const idleTabClasses = 'tab idle';

    return activeTab === tab ? activeTabClasses : idleTabClasses;
  };

  /**
   * Change the active tab to the selected tab
   *
   * @param tab Selected tab
   */
  const changeActiveTab = (tab: FacilitatorMetricType): void => {
    setActiveTab(tab);
  };

  /**
   * Handles facilitator selection change
   *
   * @param event ChangeEvent input
   */
  const handleFacilitatorSelectionChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const { checked, value } = event.target;
    onFacilitatorSelectionChange(value, checked);
  };

  /**
   * Maintain supervisors ToggleLayer open status
   * for syncing local selected supervisors
   * @param {() => void} toggle toggle handler form ToggleLayer
   */
  const handleOpenSupervisorsSelect = (toggle: () => void): void => {
    toggle();
  };

  /**
   * Handle supervisor search filter
   *
   * @param searchTerm {string} input
   */
  const handleSupervisorSearch = (searchTerm: string): void => {
    const searchedSupervisors = supervisors.filter((supervisor) => {
      let isMatched =
        supervisor.label.substring(0, searchTerm.length).toLowerCase() ===
        searchTerm.toLowerCase();
      const names = supervisor.label.split(' ');
      names.forEach((element) => {
        isMatched =
          isMatched ||
          element.substring(0, searchTerm.length).toLowerCase() ===
            searchTerm.toLowerCase();
      });
      return isMatched;
    });
    setFilteredSupervisors(searchedSupervisors);
  };

  /**
   * Handle facilitator search filter
   *
   * @param event input change event
   */
  const handleFacilitatorSearch = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;

    const searchedFacilitators = facilitators.filter((facilitator) => {
      let isMatched =
        facilitator.name.substring(0, value.length).toLowerCase() ===
        value.toLowerCase();
      const names = facilitator.name.split(' ');
      names.forEach((element) => {
        isMatched =
          isMatched ||
          element.substring(0, value.length).toLowerCase() ===
            value.toLowerCase();
      });
      return isMatched;
    });
    setFacilitatorSearchTerm(value);
    setFilteredFacilitators(searchedFacilitators);
  };

  const handleSupervisorSelectClose = (selectedSupervisors: string[]): void => {
    onCloseSupervisorSelect(selectedSupervisors);
    setFilteredSupervisors(supervisors);
  };

  /**
   * Render facilitators list
   *
   * @returns {JSX.Element} JSX elements containing Facilitators list or empty state
   */
  const renderFacilitatorsList = (): JSX.Element => {
    if (filteredFacilitators.length === 0) {
      return (
        <FormGroup className="insight-checkbox-group pb-2 pt-2">
          <Label className={`${styles.reduceOpacity}`}>
            <span className={`insight-checkbox mr-3 ${styles.checkbox}`}>
              <Input type="checkbox" tabIndex={-1} disabled />
              <i className="icon-check" />
            </span>
            <span className="text-12-medium">{intl.get('LBL_NA')}</span>
          </Label>
        </FormGroup>
      );
    }

    return (
      <div>
        {filteredFacilitators.map((facilitator) => {
          const isChecked = filters.facilitators.includes(facilitator.id);
          const selectionBlocked =
            !isChecked &&
            filters.facilitators.length >= MAX_FACILITATORS_SELECTED;
          return (
            <FormGroup
              className={`insight-checkbox-group ${styles.wrap} facilitator`}
              key={facilitator.id}
            >
              <span
                data-for="insTooltip"
                data-place="bottom"
                data-class="error bring-it-up"
                data-tip={
                  selectionBlocked
                    ? intl.get(
                        'ERR_FACILITATOR_METRIC_SELECTION_LIMIT_REACHED',
                        {
                          limit: MAX_FACILITATORS_SELECTED,
                        }
                      )
                    : ''
                }
              >
                <Label
                  className={`${isChecked ? 'checked-label' : ''} ${
                    selectionBlocked ? styles.reduceOpacity : ''
                  }`}
                >
                  <span
                    className={`insight-checkbox ${
                      selectionBlocked ? 'disabled-checkbox' : ''
                    }`}
                  >
                    <Input
                      type="checkbox"
                      tabIndex={0}
                      name={facilitator.name}
                      value={facilitator.id}
                      checked={isChecked}
                      onChange={handleFacilitatorSelectionChange}
                      disabled={selectionBlocked}
                    />
                    <i className="icon-check" />
                  </span>
                  <div className="d-flex avatar">
                    <ImageComponent
                      className="w-100 rounded-circle object-fit-cover"
                      ignoreBlur
                      src={facilitator.image}
                      alt="profile"
                      fallbackSrc={blankAvatar}
                    />
                  </div>
                  {selectionBlocked ? (
                    <span
                      className={`truncate text-11-semibold ${styles.option}`}
                    >
                      {facilitator.name}
                    </span>
                  ) : (
                    <EllipsisTooltip
                      tag="span"
                      data-place="right"
                      data-for="insTooltip"
                      data-tip={facilitator.name}
                      data-class="overflow-wrap"
                      className={`truncate text-11-semibold ${styles.option}  ${
                        isChecked ? styles.checked : ''
                      }`}
                    >
                      {facilitator.name}
                    </EllipsisTooltip>
                  )}
                </Label>
              </span>
            </FormGroup>
          );
        })}
      </div>
    );
  };

  return (
    <BlockUi tag="div" blocking={loading} className="row dashboard-row">
      <div className="col">
        <div className="content-box">
          <div className="content-box-title has-dropdowns pb-4 pt-2">
            <h3 className="main-title">
              {intl.get('LBL_FM_FACILITATOR_METRICS')}
              <span className="help">
                <i
                  className="icon-help"
                  data-for="insTooltip"
                  data-tip={intl.get('LBL_FACILITATOR_METRICS_HELP_TEXT')}
                />
              </span>
            </h3>
            <div className="inline-form">
              <FormGroup className="insight-select-group">
                <Label htmlFor="supervisor" className="chart-filter-label">
                  {intl.get('LBL_CHART_FILTER_SUPERVISOR')}
                </Label>
                <div className="select-group">
                  <SupervisorFilterSelect
                    supervisors={filteredSupervisors}
                    selectedSupervisors={filters.supervisors}
                    onOpen={handleOpenSupervisorsSelect}
                    onSupervisorSearch={handleSupervisorSearch}
                    onClose={handleSupervisorSelectClose}
                  />
                </div>
              </FormGroup>
            </div>
          </div>
          <Row>
            <Col xs={9}>
              <Nav tabs className="content-box-tabs">
                <NavItem>
                  <NavLink
                    className={isActive(FacilitatorMetricType.Groups)}
                    onClick={(): void =>
                      changeActiveTab(FacilitatorMetricType.Groups)
                    }
                  >
                    <div className="chart-details">
                      <span className="sub-title">
                        {intl.get(
                          `LBL_FACILITATOR_${FacilitatorMetricType.Groups}`
                        )}
                        <span className="help">
                          <i
                            className="icon-help"
                            data-for="insTooltip"
                            data-tip={intl.get(
                              'LBL_FACILITATOR_GROUPS_HELP_TEXT'
                            )}
                          />
                        </span>
                      </span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={isActive(FacilitatorMetricType.Members)}
                    onClick={(): void =>
                      changeActiveTab(FacilitatorMetricType.Members)
                    }
                  >
                    <div className="chart-details">
                      <span className="sub-title">
                        {intl.get(
                          `LBL_FACILITATOR_${FacilitatorMetricType.Members}`
                        )}
                        <span className="help">
                          <i
                            className="icon-help"
                            data-for="insTooltip"
                            data-tip={intl.get(
                              'LBL_FACILITATOR_MEMBERS_HELP_TEXT'
                            )}
                          />
                        </span>
                      </span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={isActive(FacilitatorMetricType.AverageMembers)}
                    onClick={(): void =>
                      changeActiveTab(FacilitatorMetricType.AverageMembers)
                    }
                  >
                    <div className="chart-details">
                      <span className="sub-title">
                        {intl.get(
                          `LBL_FACILITATOR_${FacilitatorMetricType.AverageMembers}`
                        )}
                        <span className="help">
                          <i
                            className="icon-help"
                            data-for="insTooltip"
                            data-tip={intl.get(
                              'LBL_FACILITATOR_AVERAGE_MEMBERS_HELP_TEXT'
                            )}
                          />
                        </span>
                      </span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={isActive(FacilitatorMetricType.Attendance)}
                    onClick={(): void =>
                      changeActiveTab(FacilitatorMetricType.Attendance)
                    }
                  >
                    <div className="chart-details">
                      <span className="sub-title">
                        {intl.get(
                          `LBL_FACILITATOR_${FacilitatorMetricType.Attendance}`
                        )}
                        <span className="help">
                          <i
                            className="icon-help"
                            data-for="insTooltip"
                            data-tip={intl.get(
                              'LBL_FACILITATOR_ATTENDANCE_RATE_HELP_TEXT'
                            )}
                          />
                        </span>
                      </span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={isActive(FacilitatorMetricType.AverageSavings)}
                    onClick={(): void =>
                      changeActiveTab(FacilitatorMetricType.AverageSavings)
                    }
                  >
                    <div className="chart-details">
                      <span className="sub-title">
                        {intl.get(
                          `LBL_FACILITATOR_${FacilitatorMetricType.AverageSavings}`
                        )}
                        <span className="help">
                          <i
                            className="icon-help"
                            data-for="insTooltip"
                            data-tip={intl.get(
                              'LBL_FACILITATOR_AVERAGE_SAVINGS_HELP_TEXT'
                            )}
                          />
                        </span>
                      </span>
                    </div>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId={FacilitatorMetricType.Groups}>
                  <Groups
                    data={chartData.GROUPS.data}
                    meta={chartData.GROUPS.meta}
                    xAxisConfig={xAxisConfig}
                    filters={filters}
                  />
                </TabPane>
                <TabPane tabId={FacilitatorMetricType.Members}>
                  <Members
                    data={chartData.MEMBERS.data}
                    meta={chartData.MEMBERS.meta}
                    xAxisConfig={xAxisConfig}
                    filters={filters}
                  />
                </TabPane>
                <TabPane tabId={FacilitatorMetricType.AverageMembers}>
                  <AverageMembers
                    data={chartData.AVERAGE_MEMBERS.data}
                    meta={chartData.AVERAGE_MEMBERS.meta}
                    xAxisConfig={xAxisConfig}
                    filters={filters}
                  />
                </TabPane>
                <TabPane tabId={FacilitatorMetricType.Attendance}>
                  <Attendance
                    data={chartData.ATTENDANCE_RATE.data}
                    meta={chartData.ATTENDANCE_RATE.meta}
                    xAxisConfig={xAxisConfig}
                    filters={filters}
                  />
                </TabPane>
                <TabPane tabId={FacilitatorMetricType.AverageSavings}>
                  <AverageSavings
                    data={chartData.AVERAGE_SAVINGS.data}
                    meta={chartData.AVERAGE_SAVINGS.meta}
                    xAxisConfig={xAxisConfig}
                    filters={filters}
                  />
                </TabPane>
              </TabContent>
            </Col>
            <div className="col-lg-3 flex-col">
              <div className="facilitators-search">
                <div className="search-textbox">
                  <i className="icon-search" />
                  <DebounceInput
                    minLength={2}
                    debounceTimeout={TIMEOUTS.SEARCH_DEBOUNCE_TIMEOUT}
                    onChange={handleFacilitatorSearch}
                    type="search"
                    className="search-text"
                    placeholder={intl.get(
                      'LBL_FACILITATOR_METRICS_SEARCH_PLACEHOLDER'
                    )}
                    value={facilitatorSearchTerm}
                  />
                </div>
                <div className="text-secondary text-11-semibold mt-2 d-flex justify-content-end">{`(${filters.facilitators.length}/${facilitators.length})`}</div>
                <div className="facilitators-list mt-2">
                  <PerfectScrollbar className={styles.scroll}>
                    {renderFacilitatorsList()}
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </BlockUi>
  );
};

export default React.memo(FacilitatorMetricContainer);

import { useEffect, useRef, useState, FC as ReactFC } from 'react';

import { FieldProps, Formik, FormikHelpers, FormikProps } from 'formik';
import { Location } from 'history';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import * as intl from 'react-intl-universal';
import { Col, Row } from 'reactstrap';

import ApiError from 'api/common/ApiError';
import mergeRefs from 'helpers/mergeRefs';
import history from 'router-history';
import MobilePreview from 'shared/components/mobile-preview/MobilePreview';
import RouteChangeGuard from 'shared/components/route-change-guard/RouteChangeGuard';

import { MessagingTab } from '../../containers/messaging-view/MessagingViewState';
import ComposeMessageFormValidation from './ComposeMessageFormValidation';
import ComposeMessageFormValues from './ComposeMessageFormValues';
import ComposeMessageProps from './ComposeMessageProps';
import GroupsFilterPanel from './groups-filter-panel/GroupsFilterPanel';
import GroupsFilterTagContainer from './groups-filter-tag-container/GroupsFilterTagContainer';
import MessageForm from './message-form/MessageForm';

const ComposeMessage: ReactFC<ComposeMessageProps> = (
  props: ComposeMessageProps
) => {
  const {
    innerFormikRef,
    orgName,
    tab,
    status,
    groupFiltersStatus,
    countriesData,
    projectsData,
    facilitatorsData,
    groupFilters,
    showTranslationTooltip,
    selectedFilters,
    location,
    onFiltersChanged,
    handleSendMessage,
    selectedGroupsCount,
    appliedFiltersCount,
    handleSetErrorToast,
    onNavigationChange,
    isMessageSendClicked,
    getUrlConfig,
  } = props;

  const [commonError, setCommonError] = useState<string | undefined>();

  const formikRef = useRef<FormikProps<ComposeMessageFormValues>>();

  const initialValues: ComposeMessageFormValues = {
    title: '',
    body: '',
  };

  useEffect(() => {
    setCommonError(undefined);
  }, []);

  /**
   * Return formik field errors or null
   *
   * @param name string; field which the error belongs
   * @param param1 formik field props
   */
  const renderStatusError = (
    name: string,
    { form }: FieldProps<ComposeMessageFormValues>
  ): string | null | JSX.Element => {
    const { status: formStatus } = form;
    const dotNameArray = name.split('.');
    const fieldName = dotNameArray[dotNameArray.length - 1];
    if (formStatus) {
      const fieldKeys = Object.keys(formStatus.fields);
      if (fieldKeys.length > 0 && formStatus.fields[fieldName]) {
        return intl.get(
          `ERR_API_MESSAGING_${String(formStatus.fields[fieldName])}`
        );
      }
    }
    return null;
  };

  /**
   * Handle message form submission
   */
  const handleSubmit = (
    values: ComposeMessageFormValues,
    formikHelpers: FormikHelpers<ComposeMessageFormValues>
  ): void => {
    try {
      setCommonError(undefined);
      formikHelpers.setSubmitting(true);
      handleSendMessage(values.title, values.body);
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status === undefined) {
          setCommonError(intl.get('ERR_MESSAGING_COMPOSE_UNABLE_TO_SEND'));
        } else {
          setCommonError(intl.get('ERR_MESSAGING_COMPOSE_GENERIC'));
        }
      } else {
        setCommonError(intl.get('ERR_MESSAGING_COMPOSE_GENERIC'));
      }
    }
  };

  /**
   * Handles blocking navigation out of the form
   *
   * @param isDirty Form dirty status
   * @param location Location to navigate to
   * @returns {boolean} Form navigation blocking status
   */
  const handleBlockingNavigation = (
    isDirty: boolean | undefined,
    nextLocation: Location
  ): boolean => {
    onNavigationChange();
    const formikData = formikRef.current;
    const { tab: nextTab } = getUrlConfig(nextLocation);
    if (
      nextLocation.pathname !== location.pathname ||
      nextTab === MessagingTab.History
    ) {
      if (formikData && isDirty) {
        const currentValues = formikData.values;

        if (!isEqual(initialValues, currentValues)) {
          return true;
        }
      } else if (appliedFiltersCount > 0) {
        return true;
      }
    }
    return false;
  };

  return (
    <Formik
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      innerRef={mergeRefs([innerFormikRef, formikRef])}
      initialValues={initialValues}
      validationSchema={ComposeMessageFormValidation.GetValidationSchema()}
      onSubmit={handleSubmit}
    >
      {({ dirty, values, isValid }): JSX.Element => (
        <>
          <RouteChangeGuard
            when={dirty || appliedFiltersCount > 0}
            navigate={(nextLocation): void => history.push(nextLocation)}
            shouldBlockNavigation={(loc: Location): boolean =>
              handleBlockingNavigation(dirty, loc)
            }
          />
          <Row>
            <Col xs="5">
              <Row>
                <Col xs="12">
                  <div className="content-box mb-4">
                    <GroupsFilterTagContainer
                      status={groupFiltersStatus}
                      totalCount={selectedGroupsCount}
                      groupFilters={groupFilters}
                      isMessageSendClicked={isMessageSendClicked}
                    />
                  </div>
                </Col>
                <Col xs="12">
                  <div className="mb-0">
                    <MessageForm
                      status={status}
                      orgName={orgName}
                      renderStatusError={renderStatusError}
                      showTranslationTooltip={showTranslationTooltip}
                      error={commonError}
                      values={values}
                      tab={tab}
                      isValid={dirty && isValid && !isEmpty(groupFilters)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs="4">
              <MobilePreview
                body={values.body}
                orgName={orgName}
                title={values.title}
              />
            </Col>
            <Col xs="3">
              <GroupsFilterPanel
                countriesData={countriesData}
                projectsData={projectsData}
                facilitatorsData={facilitatorsData}
                onFiltersChanged={onFiltersChanged}
                selectedFilters={selectedFilters}
                status={status}
                groupFiltersStatus={groupFiltersStatus}
                handleSetErrorToast={handleSetErrorToast}
              />
            </Col>
          </Row>
        </>
      )}
    </Formik>
  );
};

export default ComposeMessage;

import { ReactNode, FC as ReactFC } from 'react';

import { Field, FieldProps, Form, FormikProps } from 'formik';
import noop from 'lodash/noop';
import * as intl from 'react-intl-universal';
import {
  Button,
  Col,
  FormGroup,
  Label,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import ApiError from 'api/common/ApiError';
import Constraints from 'constants/forms/Constraints';
import ModulePaths from 'constants/ModulePaths';
import { ErrorStatus } from 'helpers/ErrorFormat';
import ProfileFormValues, {
  ProfileFormValueTypes,
} from 'modules/public/auth/containers/create-profile-view/ProfileFormValues';
import EnhancedFormikError from 'shared/components/enhanced-formik-error/EnhancedFormikError';
import ImageComponent from 'shared/components/image/ImageComponent';
import FileInputField from 'shared/components/ins-form-fields/file-input-field/FileInputField';
import NewPasswordField from 'shared/components/ins-form-fields/new-password-field/NewPasswordField';
import PhoneNumberField from 'shared/components/ins-form-fields/phone-number-field/PhoneNumberField';
import InsLink from 'shared/components/ins-link/InsLink';
import Status from 'shared/enums/Status';
import placeholderAvatar from 'shared/static/img/il_avatar.svg';

import styles from './createProfileFormBody.module.scss';

type CreateProfileFormBodyProps = FormikProps<ProfileFormValues> & {
  email: string;
  resendEmail: () => void;
  imageStatus: Status;
  resendEmailStatus: Status;
  expiryTimeString: string;
};

const CreateProfileFormBody: ReactFC<CreateProfileFormBodyProps> = ({
  values,
  status,
  email,
  isSubmitting,
  imageStatus,
  resendEmailStatus,
  expiryTimeString,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  resendEmail,
}: CreateProfileFormBodyProps): JSX.Element => {
  /**
   * Returns common errors which will be shown up-top as ReactNode
   *
   * @param formStatus ErrorStatus | null | undefined; status from formik which has API errors
   * @returns {ReactNode}
   */
  const renderCommonErrors = (
    formStatus: ErrorStatus | null | undefined
  ): ReactNode => {
    if ((formStatus && formStatus.msg) || imageStatus === Status.Error) {
      let error: null | string | JSX.Element = null;
      if (formStatus && formStatus.msg) {
        const fieldKeys = Object.keys(formStatus.fields);
        const commonFields = ['code', 'email'];

        if (fieldKeys.length === 0) {
          error = formStatus.msg;
        } else if (commonFields.includes(fieldKeys[0])) {
          /** special case when invite code has expired, so the user is given a link
           * to resend invite to themselves
           */
          if (formStatus.fields[fieldKeys[0]] === 11011) {
            error = (
              <>
                {intl.get(
                  `ERR_API_CODE_CREATE_PROFILE_${
                    formStatus.fields[fieldKeys[0]]
                  }`,
                  {
                    expiry: expiryTimeString,
                  }
                )}{' '}
                <button
                  type="button"
                  disabled={resendEmailStatus === Status.Loading}
                  onClick={resendEmail}
                >
                  {intl.get('BTN_CREATE_PROFILE_RESEND_INVITE')}
                </button>
              </>
            );
          } else {
            error = intl.get(
              `ERR_API_CODE_CREATE_PROFILE_${formStatus.fields[fieldKeys[0]]}`
            );
          }
        }
      } else {
        error = intl.get('ERR_CP_SOMETHING_WENT_WRONG_UPLOAD_IMAGE');
      }

      if (error) {
        return (
          <Row>
            <Col>
              <FormGroup>
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              </FormGroup>
            </Col>
          </Row>
        );
      }
    }
    return null;
  };

  /**
   * Handles removing image from form
   *
   */
  const handleRemoveImage = (): void => {
    setFieldTouched('image', false);
    setFieldValue('image', null);
  };

  /**
   * Set selected file to form state
   *
   * @param files Selected File | Files[]
   * @param event Select file's change event
   */
  const handleImageSelect = (
    files: File[] | null,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (files) {
      const selectedFile = files[0];
      const name = event.currentTarget.name ?? 'image';
      try {
        const file = selectedFile;
        /* Does not preview image in the case of an unsupported extension */
        if (!Constraints.SupportedImageFormats.includes(file.type)) {
          throw new Error(intl.get('ERR_CP_UNSUPPORTED_FILE_FORMAT'));
        }
        const image = new Image();
        image.src = URL.createObjectURL(file);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        file.image = image;

        setFieldTouched(name, true);
        setFieldValue(name, file);
      } catch (error) {
        setFieldTouched(name, true, false);
        setFieldValue(name, null, false);
        if (error instanceof ApiError) {
          setFieldError(
            name,
            error.message ?? intl.get('OOPS_SOMETHING_WENT_WRONG')
          );
        } else {
          setFieldError(name, intl.get('OOPS_SOMETHING_WENT_WRONG'));
        }
      }
    }
  };

  /**
   * Returns formik field errors or null
   *
   * @param name Name of field which the error belongs to
   * @param param1 Formik field props
   * @returns {string | null | JSX.Element}
   */
  const renderStatusError = (
    name: string,
    { form }: FieldProps<ProfileFormValueTypes, ProfileFormValues>
  ): string | null | JSX.Element => {
    const { status: formStatus } = form;
    const dotNameArray = name.split('.');
    const fieldName = dotNameArray[dotNameArray.length - 1];
    if (formStatus) {
      const fieldKeys = Object.keys(formStatus.fields);
      if (fieldKeys.length > 0 && formStatus.fields[fieldName]) {
        return intl.get(
          `ERR_API_CODE_CREATE_PROFILE_${String(formStatus.fields[fieldName])}`
        );
      }
    }
    return null;
  };

  return (
    <>
      <ModalHeader className="increase-font text-center pb-2">
        {intl.get('LBL_CREATE_PROFILE_WELCOME_TO_INSIGHTS')}
        <small>{intl.get('LBL_CREATE_PROFILE_SIGNING_IN_AS', { email })}</small>
      </ModalHeader>
      <ModalBody>
        <div className={styles.wrapper}>
          <Form>
            {renderCommonErrors(status)}
            <Row>
              <Col xs="5">
                <FormGroup>
                  <div className={styles.frame}>
                    <ImageComponent
                      switcher
                      className={styles.picture}
                      fallbackClassName={styles.placeholder}
                      fallbackSrc={placeholderAvatar}
                      src={values.image?.image?.src}
                      alt="image"
                    />
                  </div>
                  <EnhancedFormikError
                    name="image"
                    renderSecondaryError={renderStatusError}
                  />
                  <div className={styles.fileInput}>
                    <Label className="btn btn-sm btn-secondary align-middle">
                      <Field
                        className="form-control"
                        id="image"
                        name="image"
                        type="file"
                        accept={Constraints.SupportedImageFormats.join(', ')}
                        onChange={handleImageSelect}
                        component={FileInputField}
                      />
                      <i className="icon-export" />
                      {intl.get('BTN_CREATE_PROFILE_UPLOAD_IMAGE')}
                    </Label>
                    <Button
                      type="button"
                      onClick={handleRemoveImage}
                      disabled={values.image === null}
                      className="btn btn-link btn-sm mx-auto shadow-none"
                    >
                      {intl.get('BTN_CREATE_PROFILE_REMOVE_IMAGE')}
                    </Button>
                  </div>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="firstName">
                    {intl.get('LBL_CA_FIRST_NAME')}
                  </Label>
                  <Field
                    type="text"
                    name="firstName"
                    autoComplete="given-name"
                    maxLength={Constraints.MaxFieldLength}
                    className="form-control"
                  />
                  <EnhancedFormikError
                    name="firstName"
                    renderSecondaryError={renderStatusError}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="lastName">
                    {intl.get('LBL_CA_LAST_NAME')}
                  </Label>
                  <Field
                    type="text"
                    name="lastName"
                    autoComplete="family-name"
                    maxLength={Constraints.MaxFieldLength}
                    className="form-control"
                  />
                  <EnhancedFormikError
                    name="lastName"
                    renderSecondaryError={renderStatusError}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="phoneNumber">
                    {intl.get('LBL_CREATE_PROFILE_PHONE_NUMBER')}
                  </Label>
                  <Field
                    name="phoneNumber"
                    type="tel"
                    autoComplete="tel"
                    maxLength={Constraints.MaxFieldLength}
                    className="form-control"
                    component={PhoneNumberField}
                  />
                  <EnhancedFormikError
                    name="phoneNumber"
                    renderSecondaryError={renderStatusError}
                  />
                </FormGroup>
                <input
                  type="text"
                  name="username"
                  value={email}
                  autoComplete="username"
                  onChange={noop}
                  style={{ display: 'none' }}
                />
                <FormGroup>
                  <Label htmlFor="password">
                    {intl.get('LBL_CREATE_PROFILE_PASSWORD')}
                  </Label>
                  <Field
                    type="password"
                    autoComplete="new-password"
                    name="password"
                    maxLength={Constraints.MaxFieldLength}
                    className="form-control"
                    component={NewPasswordField}
                  />
                  <EnhancedFormikError
                    name="password"
                    renderSecondaryError={renderStatusError}
                  />
                </FormGroup>
                <FormGroup>
                  <div className="insight-checkbox-group pt-0 pb-0">
                    <Label className={styles.checkBoxLabel}>
                      <span
                        className="insight-checkbox mr-3"
                        data-cy="terms-cb"
                      >
                        <Field type="checkbox" name="rememberMe" />
                        <i className="icon-check" />
                      </span>
                      {intl.get('LBL_CREATE_PROFILE_REMEMBER_ME')}
                    </Label>
                    <EnhancedFormikError
                      name="rememberMe"
                      renderSecondaryError={renderStatusError}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="insight-checkbox-group pt-0 pb-0">
                    <Label className={styles.checkBoxLabel}>
                      <span
                        className="insight-checkbox mr-3"
                        data-cy="terms-cb"
                      >
                        <Field type="checkbox" name="termsAccepted" />
                        <i className="icon-check" />
                      </span>
                      <a
                        className="text-gray"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.dreamstartlabs.com/dreamsave-privacy-policy.html"
                      >
                        {intl.get('LBL_CA_I_ACCEPT_TERMS_AND_CONDITIONS')}
                      </a>
                    </Label>
                    <EnhancedFormikError
                      name="termsAccepted"
                      renderSecondaryError={renderStatusError}
                    />
                  </div>
                </FormGroup>
                <Row className={styles.bottomWrapper}>
                  <Col className="text-14-semibold text-muted pt-3">
                    {intl.get('LBL_CREATE_PROFILE_WRONG_ACCOUNT')}
                    <InsLink
                      to={`${ModulePaths.AuthPath}${ModulePaths.AuthLoginPath}`}
                    >
                      {' '}
                      {intl.get('LBL_CREATE_PROFILE_LOGIN_INSTEAD')}
                    </InsLink>
                  </Col>
                  <Col xs="auto">
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      color="primary"
                    >
                      {intl.get('BTN_CREATE_PROFILE_CONTINUE')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </ModalBody>
    </>
  );
};

export default CreateProfileFormBody;

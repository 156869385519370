import { ReactNode, useRef, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import { Route, Switch } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import Misc from 'constants/misc/Misc';
import ModulePaths from 'constants/ModulePaths';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import PermissionUtil from 'helpers/PermissionUtil';
import InsNavLink from 'shared/components/ins-nav-link/InsNavLink';

import GroupDetailsToolbarProps from './GroupDetailsToolbarProps';

const GroupDetailsToolbar: ReactFC<GroupDetailsToolbarProps> = (
  props: GroupDetailsToolbarProps
) => {
  const { context, userDropdown } = props;
  const {
    groupDetailsSetupInProgress,
    startGroupDetailsSetup,
    endGroupDetailsSetup,
    startExcelReportDownload,
    permissionsData,
  } = context;

  const headerDivRef = useRef<HTMLDivElement>(null);
  const trackPointer = useRef<number | null>(null);

  const canDownloadCSV = PermissionUtil.Can(
    permissionsData.claims,
    ResourceKeys.ExportCSV
  );

  /**
   * Cancel group details setup
   */
  const cancelGroupDetailsSetupMode = (): void => {
    if (groupDetailsSetupInProgress) {
      endGroupDetailsSetup(true);
    }
  };

  /**
   * Toggle group details setup
   */
  const toggleGroupDetailsSetupMode = (): void => {
    if (groupDetailsSetupInProgress) {
      endGroupDetailsSetup();
    } else {
      startGroupDetailsSetup();
    }
  };

  /**
   * Handle mouse enter/leave events
   *
   * @param event Mouse event
   */
  const handleDivMouseEnterLeave = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    if (groupDetailsSetupInProgress === false) {
      trackPointer.current = null;
      return;
    }
    if (event.type === 'mouseenter') {
      trackPointer.current = event.clientY;
    } else if (event.type === 'mouseleave') {
      trackPointer.current = null;
    }
  };

  const dataTip = groupDetailsSetupInProgress
    ? intl.get('ERR_SAVE_WIDGETS')
    : '';

  // const getBacktoGroupLink = (): string => {
  //   if (history.location.state) {
  //     return history.location.state as string;
  //   }
  //   return '/groups';
  // };

  return (
    <header ref={headerDivRef} className="main-header">
      <div
        className="header-col"
        data-for="topBarError"
        // FIXME: fix with css and remove unnecessary ref use
        style={{ height: headerDivRef.current?.clientHeight }}
        data-tip={dataTip}
        data-class="error place-bottom"
        data-place="bottom"
        onMouseEnter={handleDivMouseEnterLeave}
        onMouseLeave={handleDivMouseEnterLeave}
      >
        <h2 className="main-title" style={{ display: 'flex' }}>
          <InsNavLink
            to={ModulePaths.GroupsPath}
            disabled={groupDetailsSetupInProgress}
            disabledClass="disabled-nav-link"
          >
            <i className="icon-arrow-left" />
            {intl.get('BTN_BACK_TO_GROUPS')}
          </InsNavLink>
        </h2>
      </div>
      <div className="header-col">
        <Switch>
          <Route
            path={`${ModulePaths.GroupsPath}${ModulePaths.GroupDetailsPath}`}
            render={(): JSX.Element => (
              <>
                {groupDetailsSetupInProgress && (
                  <Button
                    className="btn header-btn cancel-btn"
                    onClick={cancelGroupDetailsSetupMode}
                  >
                    {intl.get('BTN_CANCEL')}
                  </Button>
                )}
                &nbsp;&nbsp;
                <Button
                  key={groupDetailsSetupInProgress ? 'in-progress' : 'default'}
                  className={`btn header-btn ${
                    groupDetailsSetupInProgress ? 'btn-primary' : ''
                  }`}
                  onClick={toggleGroupDetailsSetupMode}
                >
                  {groupDetailsSetupInProgress
                    ? intl.get('BTN_SAVE_CHANGES')
                    : intl.get('BTN_EDIT')}
                </Button>
              </>
            )}
          />
        </Switch>
        &nbsp;&nbsp;
        <UncontrolledButtonDropdown className="insight-btn-dropdown">
          <DropdownToggle tag="button" className="btn header-btn">
            <i className="icon-export" />
            {intl.get('LBL_EXPORT')}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag="button" className="btn" disabled>
              <i className="icon-pdf" />
              {intl.get('LBL_PDF')}
            </DropdownItem>
            {canDownloadCSV && (
              <DropdownItem
                tag="button"
                className="btn"
                onClick={startExcelReportDownload}
              >
                <i className="icon-excel" />
                {intl.get('LBL_EXCEL')}
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
        &nbsp;&nbsp;
        {userDropdown}
      </div>
      <ReactTooltip
        type="light"
        html
        effect="solid"
        arrowColor="transparent"
        id="topBarError"
        getContent={[
          (text): ReactNode =>
            groupDetailsSetupInProgress && trackPointer.current ? text : null,
          Misc.EffectiveVisualDelay,
        ]}
      />
    </header>
  );
};

export default GroupDetailsToolbar;

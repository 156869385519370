/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import ModulePaths from 'constants/ModulePaths';
import {
  getCompactNumber,
  getFormattedNumberWithDecimals,
} from 'helpers/NumberFormat';
import AverageSavingsProps from 'modules/private/dashboard/components/financial-metrics/average-savings/AverageSavingsProps';
import InsLink from 'shared/components/ins-link/InsLink';

import LineChart from '../../line-chart/LineChart';

const AverageSavings: ReactFC<AverageSavingsProps> = (props) => {
  const { data, meta, xAxisConfig, filters } = props;

  /**
   * Formats tick values
   *
   * @param number Tick value
   * @returns {string} Formatted tick value
   */
  const formatNumber = (number): string => getCompactNumber(Number(number));

  const sliceRenderer = (value): string =>
    getFormattedNumberWithDecimals(value);

  const legendLabelRenderer = (): string =>
    intl.get('LBL_AS_AVERAGE_SAVINGS_PER_MEMBER');

  const renderChartLegend = (): JSX.Element => (
    <div className="chart-legend inline">
      <div className="item">
        <span className="color" style={{ backgroundColor: '#68C7C2' }} />
        <span className="label">
          <span className="name">
            {intl.get('LBL_AS_AVERAGE_SAVINGS_PER_MEMBER')}
          </span>
        </span>
      </div>
      <div className="item">
        <span className="color" style={{ backgroundColor: '#F8E9C5' }} />
        <span className="label">
          <span className="name">{`${intl.get(
            'LBL_AS_AVERAGE_SAVINGS_PER_MEMBER'
          )} ${String(filters.timeUnit.label)}`}</span>
        </span>
      </div>
    </div>
  );

  return (
    <>
      <LineChart
        data={data}
        meta={meta}
        xAxisConfig={xAxisConfig}
        filters={filters}
        tickFormatter={formatNumber}
        sliceRenderer={sliceRenderer}
        legendLabelRenderer={legendLabelRenderer}
      />
      <div className="chart-footer">
        {renderChartLegend()}
        <span className="chart-overview">
          <InsLink
            disabledClass="disabled"
            to={ModulePaths.FinancesPath}
            className="btn btn-secondary btn-sm"
          >
            <i className="icon-arrow-right" /> {intl.get('BTN_FINANCES_PAGE')}
          </InsLink>
        </span>
      </div>
    </>
  );
};

export default AverageSavings;

const Misc = {
  EffectiveVisualDelay: 100, // milliseconds //https://www.nngroup.com/articles/response-times-3-important-limits/
  ViaProjectsQuery: 'viaProjects=true',
  DefaultDecimalPlaces: 1,
  DefaultBackupStatusLastRefreshedDate: 'N/A',
  DefaultBackupStatusRefreshTime: '',
  WidgetPercentageDigitLimit: 6,
};

export default Misc;

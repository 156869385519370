import { useContext, useEffect, useRef } from 'react';

import isEqual from 'lodash/isEqual';
import { useHistory, useLocation } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';

import AppContext from 'context/AppContext';
import {
  getGlobalFilters,
  setGlobalFiltersUrl,
} from 'helpers/GlobalFilterUtils';

import useFirstRender from '../use-first-render/useFirstRender';

/**
 * centralize url app state sync logic
 */
const useUrlGlobalFilters = (): void => {
  const history = useHistory();
  const location = useLocation();
  const { setGlobalFilters, globalFilters } = useContext(AppContext);

  const globalFilterUnSynced = useRef(false);

  const firstRender = useFirstRender();

  /**
   * only runs when there is a mismatch between
   * actual global filters in context and url
   */
  useDeepCompareEffect(() => {
    globalFilterUnSynced.current = !isEqual(
      getGlobalFilters(location.search),
      globalFilters
    );
  }, [location.search, globalFilters]);

  useDeepCompareEffect(() => {
    /**
     * !firstRender makes sure the priority of
     * initial render goes to the URL just so
     * that it would not run into a loop where
     * url updates state and state updates url
     */
    if (globalFilterUnSynced.current && !firstRender) {
      setGlobalFiltersUrl(globalFilters, location, history);
    }
  }, [globalFilters, globalFilterUnSynced.current]);

  useEffect(() => {
    if (globalFilterUnSynced.current) {
      const urlGlobalFilters = getGlobalFilters(location.search);
      setGlobalFilters(urlGlobalFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, globalFilterUnSynced.current]);
};

export default useUrlGlobalFilters;

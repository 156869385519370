import { useEffect, useState, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';

import ProjectsApiInstance from 'api/projects/ProjectsApi';
import ModulePaths from 'constants/ModulePaths';
import InsNavLink from 'shared/components/ins-nav-link/InsNavLink';

import ProjectUsersToolbarProps from './ProjectUsersToolbarProps';

const ProjectUsersToolbar: ReactFC<ProjectUsersToolbarProps> = (
  props: ProjectUsersToolbarProps
) => {
  const { match, userDropdown } = props;

  const [projectCode, setProjectCode] = useState<string | null>(null);

  useEffect(() => {
    loadSelectedProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Fetch project data
   */
  const loadSelectedProjectData = async (): Promise<void> => {
    try {
      const projectId = match?.params.projectId ?? '';
      const result = await ProjectsApiInstance.GetProject(projectId);
      if (result) {
        setProjectCode(result.code);
      }
    } catch (error) {
      // console.log('error: ', error);
    }
  };

  return (
    <header className="main-header">
      <div className="header-col">
        <h2 className="main-title" style={{ display: 'flex' }}>
          <InsNavLink
            to={ModulePaths.ProjectsPath}
            disabledClass="disabled-nav-link"
          >
            <i className="icon-arrow-left" />
            {`${intl.get('LBL_BACK_TO_PROJECT')} ${
              projectCode ? `#${projectCode}` : ''
            }`}
          </InsNavLink>
        </h2>
      </div>
      <div className="header-col">{userDropdown}</div>
    </header>
  );
};

export default ProjectUsersToolbar;

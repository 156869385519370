export type SortBy = { id: string; desc: boolean };
class PageQuery {
  page: number;

  pageSize: number;

  sortBy: string;

  sort: string;

  search: string;

  /**
   *
   */
  constructor(
    page: number,
    pageSize: number,
    sortBy: SortBy[],
    search: string,
    resetPageIndex = false
  ) {
    const pageIndexAltered = resetPageIndex ? 0 : page;
    const sortByAttribute = sortBy[0].id;
    const sortDirection = sortBy[0].desc ? 'desc' : 'asc';

    this.page = pageIndexAltered;
    this.pageSize = pageSize;
    this.sortBy = sortByAttribute;
    this.sort = sortDirection;
    this.search = search;
  }
}

export default PageQuery;

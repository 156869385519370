import { parsePhoneNumberFromString as parseMin } from 'libphonenumber-js/min';
import * as intl from 'react-intl-universal';
import * as yup from 'yup';
import { ValidationError } from 'yup';

import Constraints from 'constants/forms/Constraints';

class InviteProjectFacilitatorsValidations {
  /**
   * Defines a Yup validation schema for the invite facilitators form
   *
   * @returns {yup.ObjectSchema | undefined}
   */
  public static GetValidationSchema(): yup.ObjectSchema | undefined {
    const validationSchema: yup.ObjectSchema = yup.object().shape({
      facilitators: yup.array().of(
        yup.object().shape({
          firstName: yup
            .string()
            .trim()
            .required(
              intl.get('ERR_PROJECTS_FACILITATORS_FIRST_NAME_CANNOT_BE_EMPTY')
            ),
          lastName: yup
            .string()
            .trim()
            .required(
              intl.get('ERR_PROJECTS_FACILITATORS_LAST_NAME_CANNOT_BE_EMPTY')
            ),
          supervisorId: yup
            .string()
            .required(
              intl.get(
                'ERR_PROJECTS_FACILITATORS_SUPERVISED_BY_CANNOT_BE_EMPTY'
              )
            ),
          location: yup.string().trim(),
          phoneNumber: yup
            .string()
            .test(
              'isValid',
              intl.get('ERR_CP_PHONE_NUMBER_INVALID'),
              function validNumber(value: string) {
                try {
                  if (value) {
                    const parsed = parseMin(value);
                    if (parsed) {
                      return parsed.isValid();
                    }
                    return false;
                  }
                  return true;
                } catch (error) {
                  return false;
                }
              }
            ),
          image: yup
            .mixed()
            .test(
              'fileSize',
              intl.get('ERR_CP_FILE_TOO_LARGE', {
                mb: Constraints.MaxImageSizeMb,
              }),
              function testSize(value): boolean | ValidationError {
                if (value) {
                  if (value.size) {
                    return value.size <= Constraints.MaxImageSize;
                  }
                  return new ValidationError(
                    intl.get('ERR_CP_UNSUPPORTED_FILE_FORMAT'),
                    value,
                    this.path
                  );
                }
                return true;
              }
            )
            .test(
              'fileFormat',
              intl.get('ERR_CP_UNSUPPORTED_FILE_FORMAT'),
              function testFormat(value): boolean | ValidationError {
                if (value) {
                  if (value.type) {
                    return Constraints.SupportedImageFormats.includes(
                      value.type
                    );
                  }
                  return new ValidationError(
                    intl.get('ERR_CP_UNSUPPORTED_FILE_FORMAT'),
                    value,
                    this.path
                  );
                }
                return true;
              }
            ),
        })
      ),
    });

    return validationSchema;
  }
}

export default InviteProjectFacilitatorsValidations;

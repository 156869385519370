/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC as ReactFC } from 'react';

import clone from 'lodash/clone';
import * as intl from 'react-intl-universal';
import NumberFormat from 'react-number-format';

import { getRoundedNumber } from 'helpers/NumberFormat';
import WidgetViewModel from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetViewModel';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import DonutArea from 'shared/components/ins-charts/donut-area/DonutArea';

import WidgetValueGroup from '../widget/WidgetValueGroup';

const MemberChildrenWidget: ReactFC<{
  data: WidgetViewModel;
}> = (props) => {
  const dimensionSettings = {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    aspectRatioWidth: 1,
    aspectRatioHeight: 0.6,
  };

  const { data } = clone(props);
  const { values } = data;

  const membersCaringData = values?.find(
    (value) => value.group === WidgetValueGroup.MembersCaringForChildren
  );
  const totalMembersData = values?.find(
    (value) => value.group === WidgetValueGroup.TotalGroupMembers
  );

  let memberChildrenData;

  if (membersCaringData && totalMembersData) {
    memberChildrenData = {
      name: membersCaringData.title,
      count: getRoundedNumber(membersCaringData.count),
      percentage: getRoundedNumber(membersCaringData.percentage),
      group: membersCaringData.group,
    };
  }

  return (
    <div className="gender-widget">
      <div className="chart-container">
        <DonutArea
          isLoading={false}
          value={memberChildrenData}
          dimension={dimensionSettings}
          colorCoded={false}
        />
      </div>
      <div className="chart-details side-by-side">
        <div className="vertical-separator padded-box">
          <span className="sub-title mb-2">
            {intl.getHTML('LBL_WID_MEMBERS_CARING_FOR_CHILDREN_LABEL_1')}
          </span>
          {membersCaringData && (
            <span className="value text-28-semibold primary-value truncate">
              <NumberFormat
                value={membersCaringData.count}
                displayType="text"
                thousandSeparator
                renderText={(count): JSX.Element => (
                  <EllipsisTooltip
                    tag="span"
                    data-class="overflow-wrap"
                    data-place="bottom"
                    data-for="insTooltip"
                    data-tip={count}
                    className="truncate"
                  >
                    {count}
                  </EllipsisTooltip>
                )}
              />
            </span>
          )}
        </div>
        <div className="padded-box">
          <span className="sub-title mb-2">
            {intl.getHTML('LBL_WID_MEMBERS_CARING_FOR_CHILDREN_LABEL_2')}
          </span>
          {totalMembersData && (
            <span className="value text-28-semibold truncate">
              <NumberFormat
                value={totalMembersData.count}
                displayType="text"
                thousandSeparator
                renderText={(count): JSX.Element => (
                  <EllipsisTooltip
                    tag="span"
                    data-class="overflow-wrap"
                    data-place="bottom"
                    data-for="insTooltip"
                    data-tip={count}
                    className="truncate"
                  >
                    {count}
                  </EllipsisTooltip>
                )}
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberChildrenWidget;

import { FC as ReactFC } from 'react';

import isNil from 'lodash/isNil';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';

import { getFormattedNumber } from 'helpers/NumberFormat';
import CommonError from 'modules/private/finances/components/key-metrics/common-error/CommonError';

import BalanceSheetProps from './BalanceSheetProps';

const BalanceSheet: ReactFC<BalanceSheetProps> = (props: BalanceSheetProps) => {
  const { data } = props;
  const { error, loading } = props;

  /**
   * Formats numbers to be displayed
   *
   * @param value Value to be formatted
   * @returns {string} Formatted value
   */
  const prepareValue = (value?: number | null): string => {
    if (error) {
      return intl.get('LBL_NA');
    }
    if (!isNil(value)) {
      return getFormattedNumber(value);
    }
    return intl.get('LBL_NA');
  };

  return (
    <>
      <BlockUi blocking={loading} tag="div" className="content-box">
        <CommonError error={error} />
        <div className="content-box-title">
          <h3 className="main-title">
            {intl.get('LBL_KM_BALANCE_SHEET')}
            <span className="help">
              <i
                className="icon-help"
                data-for="insTooltip"
                data-tip={intl.get('LBL_KM_BALANCE_SHEET_HINT')}
              />
            </span>
          </h3>
        </div>
        <div className="content-section">
          <table className="content-table">
            <thead>
              <tr>
                <th>
                  {intl.get('LBL_KM_ASSETS')}
                  <span className="help">
                    <i
                      className="icon-help"
                      data-for="insTooltip"
                      data-tip={intl.get('LBL_KM_ASSETS_HINT')}
                    />
                  </span>
                </th>
                <th className="value">{prepareValue(data.assets)}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {intl.get('LBL_KM_LOAN_FUND_BALANCE')}
                  <span className="help">
                    <i
                      className="icon-help"
                      data-for="insTooltip"
                      data-tip={intl.get('LBL_KM_LOAN_FUND_BALANCE_HINT')}
                    />
                  </span>
                </td>
                <td className="value">{prepareValue(data.loanFundBalance)}</td>
              </tr>
              <tr>
                <td>
                  {intl.get('LBL_KM_BANK_BALANCE')}
                  <span className="help">
                    <i
                      className="icon-help"
                      data-for="insTooltip"
                      data-tip={intl.get('LBL_KM_BANK_BALANCE_HINT')}
                    />
                  </span>
                </td>
                <td className="value">{prepareValue(data.bankBalance)}</td>
              </tr>
              <tr>
                <td>
                  {intl.get('LBL_KM_VALUE_OF_LOANS_OUTSTANDING')}
                  <span className="help">
                    <i
                      className="icon-help"
                      data-for="insTooltip"
                      data-tip={intl.get(
                        'LBL_KM_VALUE_OF_LOANS_OUTSTANDING_HINT'
                      )}
                    />
                  </span>
                </td>
                <td className="value">{prepareValue(data.loansOutstanding)}</td>
              </tr>
              <tr>
                <td>
                  {intl.get('LBL_KM_PROPERTY_NOW')}
                  <span className="help">
                    <i
                      className="icon-help"
                      data-for="insTooltip"
                      data-tip={intl.get('LBL_KM_PROPERTY_NOW_HINT')}
                    />
                  </span>
                </td>
                <td className="value">{prepareValue(data.propertyNow)}</td>
              </tr>
              <tr>
                <td>
                  {intl.get('LBL_KM_SOCIAL_FUND_BALANCE')}
                  <span className="help">
                    <i
                      className="icon-help"
                      data-for="insTooltip"
                      data-tip={intl.get('LBL_KM_SOCIAL_FUND_BALANCE_HINT')}
                    />
                  </span>
                </td>
                <td className="value">
                  {prepareValue(data.socialFundBalance)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="content-section">
          <table className="content-table">
            <thead>
              <tr>
                <th>
                  {intl.get('LBL_KM_LIABILITIES')}
                  <span className="help">
                    <i
                      className="icon-help"
                      data-for="insTooltip"
                      data-tip={intl.get('LBL_KM_LIABILITIES_HINT')}
                    />
                  </span>
                </th>
                {/* <th className="value"></th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {intl.get('LBL_KM_EXTERNAL_DEBTS')}
                  <span className="help">
                    <i
                      className="icon-help"
                      data-for="insTooltip"
                      data-tip={intl.get('LBL_KM_EXTERNAL_DEBTS_HINT')}
                    />
                  </span>
                </td>
                <td className="value">{prepareValue(data.externalDebts)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="content-section">
          <table className="content-table">
            <thead>
              <tr>
                <th>
                  {intl.get('LBL_KM_EQUITY')}
                  <span className="help">
                    <i
                      className="icon-help"
                      data-for="insTooltip"
                      data-tip={intl.get('LBL_KM_EQUITY_HINT')}
                    />
                  </span>
                </th>
                <th className="value">{prepareValue(data.equity)}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {intl.get('LBL_KM_CUMULATIVE_SAVINGS')}
                  <span className="help">
                    <i
                      className="icon-help"
                      data-for="insTooltip"
                      data-tip={intl.get('LBL_KM_CUMULATIVE_SAVINGS_HINT')}
                    />
                  </span>
                </td>
                <td className="value">
                  {prepareValue(data.cumulativeSavings)}
                </td>
              </tr>
              <tr>
                <td>
                  {intl.get('LBL_KM_SOCIAL_FUND_BALANCE')}
                  <span className="help">
                    <i
                      className="icon-help"
                      data-for="insTooltip"
                      data-tip={intl.get('LBL_KM_SOCIAL_FUND_BALANCE_HINT')}
                    />
                  </span>
                </td>
                <td className="value">
                  {prepareValue(data.socialFundBalance)}
                </td>
              </tr>
              <tr>
                <td>
                  {intl.get('LBL_KM_PROPERTY_AT_START_OF_CYCLE')}
                  <span className="help">
                    <i
                      className="icon-help"
                      data-for="insTooltip"
                      data-tip={intl.get(
                        'LBL_KM_PROPERTY_AT_START_OF_CYCLE_HINT'
                      )}
                    />
                  </span>
                </td>
                <td className="value">
                  {prepareValue(data.propertyAtStartOfCycle)}
                </td>
              </tr>
              <tr>
                <td>
                  {intl.get('LBL_KM_NET_PROFIT')}
                  <span className="help">
                    <i
                      className="icon-help"
                      data-for="insTooltip"
                      data-tip={intl.get('LBL_KM_NET_PROFIT_HINT')}
                    />
                  </span>
                </td>
                <td className="value">{prepareValue(data.netProfit)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </BlockUi>
    </>
  );
};
export default BalanceSheet;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button, Col, Fade, Row } from 'reactstrap';

import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import TrapFocus from 'shared/components/trap-focus/TrapFocus';
import SurveyRecipientType from 'shared/enums/SurveyRecipientType';
import SurveyType from 'shared/enums/SurveyType';

import styles from '../surveysPopups.module.scss';
import SurveySendWarningPopupProps, {
  SurveySendViewPoint,
} from './SurveySendWarningPopupProps';

const SurveySendWarningPopup: ReactFC<SurveySendWarningPopupProps> = (
  props: SurveySendWarningPopupProps
) => {
  const {
    display,
    sendCount,
    excludedMemberCount,
    excludedGroups,
    surveyType,
    recipientType,
    isDuplicateSurvey,
    viewPoint,
    conflictingRequests,
    onCancel,
    onContinue,
  } = props;

  /**
   * Builds send count message for survey type
   *
   * @returns {string} Send count message
   */
  const getSurveySendCountMessage = (): string => {
    let message = intl.get(
      'LBL_SURVEYS_DRAFTED_SURVEYS_GROUP_SEND_WARNING_POPUP_PART_2',
      {
        count: sendCount,
      }
    );
    if (recipientType === SurveyRecipientType.Members) {
      message = intl.get(
        'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_SEND_WARNING_POPUP_PART_2',
        {
          count: sendCount,
        }
      );
    }

    return message;
  };

  /**
   * Builds send count message for Collect ID type
   *
   * @returns {string} Send count message
   */
  const getCollectIdSendCountMessage = (): string => {
    let message = intl.get(
      'LBL_SURVEYS_DRAFTED_COLLECT_ID_GROUP_SEND_WARNING_POPUP_PART_2',
      {
        count: sendCount,
      }
    );
    if (recipientType === SurveyRecipientType.Members) {
      message = intl.get(
        'LBL_SURVEYS_DRAFTED_COLLECT_ID_MEMBER_SEND_WARNING_POPUP_PART_2',
        {
          count: sendCount,
        }
      );
    }

    if (viewPoint === SurveySendViewPoint.History) {
      message = intl.get(
        'LBL_SURVEYS_HISTORY_COLLECT_ID_GROUP_SEND_WARNING_POPUP_PART_2'
      );
      if (recipientType === SurveyRecipientType.Members) {
        message = intl.get(
          'LBL_SURVEYS_HISTORY_COLLECT_ID_MEMBER_SEND_WARNING_POPUP_PART_2'
        );
      }
    }

    return message;
  };

  /**
   * Renders a message for when the send count is 0
   *
   * @returns {JSX.Element} Send count 0 message
   */
  const renderSendCountZeroMessage = (): JSX.Element => (
    <>
      <Col xs="12">
        <hr className="divider mt-1" />
      </Col>
      <Col
        xs="12"
        className={`mb-3 text-14-medium text-center ${styles.textDanger}`}
      >
        {intl.get('ERR_ALERT_INVALID_RECIPIENT_COUNT')}
      </Col>
    </>
  );

  const displayDuplicateCollectIdRequestSendQuestion =
    (viewPoint === SurveySendViewPoint.Drafts && sendCount > 0) ||
    viewPoint === SurveySendViewPoint.History;

  /**
   * Renders a message for when the filter criteria in a
   * Collect ID request matches a previous request
   *
   * @returns {JSX.Element} Duplicate request message
   */
  const renderDuplicateCollectIdRequestMessage = (): JSX.Element => (
    <>
      <Col xs="12">
        <hr className="divider mt-1" />
      </Col>
      <Col xs="12" className="mb-3 text-14-medium text-center text-gray">
        {intl.get(
          'LBL_SURVEYS_DRAFTED_SURVEYS_SEND_COLLECT_ID_DUPLICATE_WARNING',
          { count: conflictingRequests.length }
        )}
      </Col>
      {conflictingRequests.length > 0 && (
        <Col xs="12" className="p-3">
          <PerfectScrollbar className={styles.scrollContainer}>
            <div className="text-14-medium text-gray mb-0">
              <ul className="pl-0">
                {conflictingRequests.map((request) => (
                  <li key={request.id}>
                    <EllipsisTooltip
                      tag="div"
                      data-place="right"
                      data-for="insTooltip"
                      data-tip={request.name}
                      data-class="overflow-wrap"
                      className="truncate"
                    >
                      {request.name}
                    </EllipsisTooltip>
                  </li>
                ))}
              </ul>
            </div>
          </PerfectScrollbar>
        </Col>
      )}
      {displayDuplicateCollectIdRequestSendQuestion && (
        <Col xs="12" className="mb-3 text-14-medium text-center text-gray">
          {intl.get(
            'LBL_SURVEYS_DRAFTED_SURVEYS_SEND_COLLECT_ID_DUPLICATE_WARNING_QUESTION'
          )}
        </Col>
      )}
    </>
  );

  /**
   * Renders excluded groups/members message
   *
   * @returns {JSX.Element} JSX snippet containing the exclusions content
   */
  const renderExclusionContent = (): JSX.Element => (
    <>
      {recipientType === SurveyRecipientType.Groups &&
        excludedGroups.length > 0 && (
          <>
            <Col xs="12" className="mb-3 text-14-medium text-center text-gray">
              {intl.get(
                'LBL_SURVEYS_DRAFTED_SURVEYS_GROUP_SEND_WARNING_POPUP_PART_3'
              )}
            </Col>
            <Col xs="12" className="p-3">
              <PerfectScrollbar className={styles.scrollContainer}>
                <div className="text-14-medium text-gray mb-0">
                  <ul className="pl-0">
                    {excludedGroups.map((group) => (
                      <li key={group.groupId}>
                        <EllipsisTooltip
                          tag="div"
                          data-place="right"
                          data-for="insTooltip"
                          data-tip={group.name}
                          data-class="overflow-wrap"
                          className="truncate"
                        >
                          {group.name}
                        </EllipsisTooltip>
                      </li>
                    ))}
                  </ul>
                </div>
              </PerfectScrollbar>
            </Col>
          </>
        )}
      {recipientType === SurveyRecipientType.Members &&
        excludedMemberCount > 0 && (
          <Col xs="12" className="mb-3 text-14-medium text-center text-gray">
            {intl.get(
              'LBL_SURVEYS_DRAFTED_SURVEYS_MEMBER_SEND_WARNING_POPUP_PART_3',
              {
                count: excludedMemberCount,
              }
            )}
          </Col>
        )}
    </>
  );

  /**
   * Renders the warning popup content for Survey type
   *
   * @returns {JSX.Element} JSX snippet containing the warning popup content
   */
  const getSurveyWarningContent = (
    firstFocus: React.RefObject<any>,
    lastFocus: React.RefObject<any>
  ): JSX.Element => (
    <>
      <Row className="w-100">
        <Col xs="12" className="mb-3 text-14-medium text-center text-gray">
          {intl.get('LBL_SURVEYS_DRAFTED_SURVEYS_SEND_WARNING_POPUP_PART_1')}
        </Col>
        <Col xs="12" className="mb-3 text-14-medium text-center text-gray">
          {getSurveySendCountMessage()}
        </Col>
        {renderExclusionContent()}
        {sendCount <= 0 && renderSendCountZeroMessage()}
        <Col xs="12" className="justify-content-center d-flex mt-3 pt-3">
          <Button
            innerRef={lastFocus}
            className="btn btn-light btn-sm text-14-semibold mr-3"
            onClick={onCancel}
          >
            {intl.get('BTN_SURVEYS_SURVEY_SEND_NO_CANCEL')}
          </Button>
          <Button
            innerRef={firstFocus}
            className="btn btn-warning btn-sm text-14-semibold ml-3"
            type="button"
            disabled={sendCount <= 0}
            onClick={onContinue}
          >
            {intl.get('BTN_SURVEYS_SURVEY_SEND_YES_SEND')}
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center w-100" />
    </>
  );

  const cannotSendCollectIdRequest =
    viewPoint === SurveySendViewPoint.Drafts && sendCount <= 0;

  /**
   * Renders the warning popup content for Collect ID type
   *
   * @returns {JSX.Element} JSX snippet containing the warning popup content
   */
  const getCollectIdWarningContent = (
    firstFocus: React.RefObject<any>,
    lastFocus: React.RefObject<any>
  ): JSX.Element => (
    <>
      <Row className="w-100">
        <Col xs="12" className="mb-3 text-14-medium text-center text-gray">
          {intl.get('LBL_SURVEYS_DRAFTED_COLLECT_ID_SEND_WARNING_POPUP_PART_1')}
        </Col>
        <Col xs="12" className="mb-3 text-14-medium text-center text-gray">
          {getCollectIdSendCountMessage()}
        </Col>
        {renderExclusionContent()}
        {isDuplicateSurvey && renderDuplicateCollectIdRequestMessage()}
        {cannotSendCollectIdRequest && renderSendCountZeroMessage()}
        <Col xs="12" className="justify-content-center d-flex mt-3 pt-3">
          <Button
            innerRef={lastFocus}
            className="btn btn-light btn-sm text-14-semibold mr-3"
            onClick={onCancel}
          >
            {intl.get('BTN_SURVEYS_COLLECT_ID_SEND_NO_CANCEL')}
          </Button>
          <Button
            innerRef={firstFocus}
            className="btn btn-warning btn-sm text-14-semibold ml-3"
            type="button"
            disabled={cannotSendCollectIdRequest}
            onClick={onContinue}
          >
            {intl.get('BTN_SURVEYS_COLLECT_ID_SEND_YES_TURN_ON')}
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center w-100" />
    </>
  );

  return (
    <Fade mountOnEnter unmountOnExit in={display} className={styles.overlay}>
      <TrapFocus autoFocus>
        {({ firstFocus, lastFocus }): JSX.Element => (
          <div className={`${styles.confirmation} ${styles.warning}`}>
            {surveyType === SurveyType.Survey
              ? getSurveyWarningContent(firstFocus, lastFocus)
              : getCollectIdWarningContent(firstFocus, lastFocus)}
          </div>
        )}
      </TrapFocus>
    </Fade>
  );
};

export default SurveySendWarningPopup;

/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import ModulePaths from 'constants/ModulePaths';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import CreateAccountView from 'modules/public/auth/containers/create-account-view/CreateAccountView';
import CreateProfileView from 'modules/public/auth/containers/create-profile-view/CreateProfileView';
import ForgotPasswordView from 'modules/public/auth/containers/forgot-password-view/ForgotPasswordView';
import Login from 'modules/public/auth/containers/login/Login';
import SetNewPasswordView from 'modules/public/auth/containers/set-new-password-view/SetNewPasswordView';
import VerifyAccountView from 'modules/public/auth/containers/verify-account-view/VerifyAccountView';

import PublicRouteProps from './PublicRouteProps';

const PublicRoutes: ReactFC<PublicRouteProps> = (props: PublicRouteProps) => {
  const match = useRouteMatch();
  const path = match && match.path;
  const { appContext, location } = props;
  return (
    <Switch>
      <Route exact path={`${path}${ModulePaths.AuthLoginPath}`}>
        {(routeProps): JSX.Element => (
          <Login {...routeProps} appContext={appContext} />
        )}
      </Route>

      <Route exact path={`${match.path}${ModulePaths.AuthForgotPasswordPath}`}>
        {(routeProps): JSX.Element => (
          <ForgotPasswordView {...routeProps} appContext={appContext} />
        )}
      </Route>

      <Route exact path={`${match.path}${ModulePaths.AuthNewPasswordPath}`}>
        {(routeProps): JSX.Element => (
          <SetNewPasswordView appContext={appContext} {...routeProps} />
        )}
      </Route>

      <Route exact path={`${path}${ModulePaths.AuthCreateProfilePath}`}>
        {(routeProps): JSX.Element => (
          <CreateProfileView {...routeProps} appContext={appContext} />
        )}
      </Route>

      <Route exact path={`${match.path}${ModulePaths.AuthCreateAccountPath}`}>
        {(): JSX.Element => <CreateAccountView />}
      </Route>

      <Route exact path={`${match.path}${ModulePaths.AuthVerifyAccountPath}`}>
        {(routeProps): JSX.Element => (
          <VerifyAccountView appContext={appContext} {...routeProps} />
        )}
      </Route>

      <Route path="*">
        <Redirect
          to={{
            pathname: `${path}${ModulePaths.AuthLoginPath}`,
            search: getGlobalFiltersQuery(location.search),
          }}
        />
      </Route>
    </Switch>
  );
};

export default PublicRoutes;

import GroupSavingsMethod from 'shared/enums/GroupSavingsMethod';

type GroupFacilitator = {
  id: string;
  name: string;
};

type GroupStatus = {
  id: string;
  status: string;
};

type Project = {
  code: string;
  id: string;
  name: string;
};

class GroupDetails {
  id: string;

  name: string;

  number: string;

  project: Project | null;

  facilitator: GroupFacilitator | null;

  address: string;

  backupStatus: string;

  groupContact: string;

  groupStatus: GroupStatus | null;

  cycleStartDate: string;

  cycleShareoutDate: string;

  lastMeetingDate: string;

  numberOfMembers: number;

  pricePerShare: number;

  minimumSharesPerMeeting: number;

  minimumSavingsPerMeeting: number;

  meetingFrequency: string;

  savingsMethod: GroupSavingsMethod;

  constructor() {
    this.id = '';
    this.name = '';
    this.number = '';
    this.facilitator = null;
    this.address = '';
    this.backupStatus = '';
    this.groupContact = '';
    this.groupStatus = null;
    this.cycleStartDate = '';
    this.cycleShareoutDate = '';
    this.lastMeetingDate = '';
    this.numberOfMembers = 0;
    this.project = null;
    this.pricePerShare = 0;
    this.minimumSharesPerMeeting = 0;
    this.minimumSavingsPerMeeting = 0;
    this.meetingFrequency = '';
    this.savingsMethod = GroupSavingsMethod.Shares;
  }
}

export default GroupDetails;

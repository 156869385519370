enum DateFormatType {
  Default = 'MM/DD/YYYY',
  ShortYear = 'MM/DD/YY',
  GlobalFilter = 'YYYY-MM-DD',
  BlobRequest = 'MM-DD-YYYY-HH-mm-ss',
  ExcelFilename = 'MM-DD-YYYY_HH.mm.ss',
  Iso = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]',
  DateTime = 'MM/D/YY hh:mm a',
  DatePicker = 'MM/dd/y',
  SurveyCreation = 'DD MMMM YYYY',
  BackupStatusLastRefreshedDate = 'DD/MM/YY',
  BackupStatusLastRefreshedTime = 'hh:mm a',
  BackupStatusLastRefreshedPreFormatTime = 'HH:mm:ss',
}

export default DateFormatType;

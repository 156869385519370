/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, FC as ReactFC } from 'react';

import { AxisProps } from '@nivo/axes';
import { Serie } from '@nivo/line';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import {
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import Meta from 'api/common/Meta';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import LabelKeysGA from 'constants/ga/LabelKeysGA';
import { getColorFor } from 'helpers/ColorHelper';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import { getRoundedNumber } from 'helpers/NumberFormat';
import Attendance from 'modules/private/dashboard/components/membership-metrics/attendance/Attendance';
import Dropout from 'modules/private/dashboard/components/membership-metrics/dropouts/Dropout';
import Groups from 'modules/private/dashboard/components/membership-metrics/groups/Groups';
import Members from 'modules/private/dashboard/components/membership-metrics/members/Members';
import MembershipMetricContainerProps from 'modules/private/dashboard/components/membership-metrics/membership-metric-container/MembershipMetricContainerProps';
import MembershipMetricType from 'modules/private/dashboard/components/membership-metrics/MembershipMetricType';
import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import FilterType from 'shared/enums/FilterType';
import Frequency from 'shared/enums/Frequency';
import TimeUnit from 'shared/enums/TimeUnit';

type ChartData = {
  [MembershipMetricType.Groups]: { data: Serie[]; meta: Partial<Meta> };
  [MembershipMetricType.Members]: { data: Serie[]; meta: Partial<Meta> };
  [MembershipMetricType.Attendance]: { data: Serie[]; meta: Partial<Meta> };
  [MembershipMetricType.Dropout]: { data: Serie[]; meta: Partial<Meta> };
};

const MembershipMetricContainer: ReactFC<MembershipMetricContainerProps> = (
  props
) => {
  const defaultBottomAxis: AxisProps = {
    format: '%Y, %b',
    // tickValues: 'every 1 month',
  };
  const defaultChartData: ChartData = {
    [MembershipMetricType.Groups]: { data: Array<Serie>(), meta: {} },
    [MembershipMetricType.Members]: { data: Array<Serie>(), meta: {} },
    [MembershipMetricType.Attendance]: { data: Array<Serie>(), meta: {} },
    [MembershipMetricType.Dropout]: { data: Array<Serie>(), meta: {} },
  };
  const defaultTab = MembershipMetricType.Groups;

  const { data, filters, loading, onFilterChange } = props;
  const groups = data.find((i) => i.type === MembershipMetricType.Groups);
  const members = data.find((i) => i.type === MembershipMetricType.Members);
  const attendance = data.find(
    (i) => i.type === MembershipMetricType.Attendance
  );
  const dropouts = data.find((i) => i.type === MembershipMetricType.Dropout);
  const [xAxisConfig, setxAxisConfig] = useState<AxisProps>(defaultBottomAxis);
  const [activeTab, setActiveTab] = useState<MembershipMetricType>(defaultTab);
  const [timeUnits, setTimeUnits] = useState<SelectFieldOption[]>([]);
  const [frequencies, setFrequency] = useState<SelectFieldOption[]>([]);
  const [chartData, setChartData] = useState<ChartData>(defaultChartData);

  useEffect(() => {
    const timeUnitOptions = Object.values(TimeUnit).map((value) => ({
      value,
      label: intl.get(`BTN_${value}`),
    }));
    const frequencyOptions = Object.values(Frequency).map((value) => ({
      value,
      label: intl.get(`BTN_${value}`),
    }));

    setTimeUnits(timeUnitOptions);
    setFrequency(frequencyOptions);
  }, []);

  useEffect(() => {
    const classifiedChartData: ChartData = defaultChartData;
    data.forEach((d) => {
      d.dataPointCollection.forEach((dpc) => {
        const dataPoints = dpc.dataPoints.map((dp) => ({
          x: dp.key,
          y: dp.value || 0,
          name: dpc.name,
        }));
        const comparisonPoints = dpc.comparisonDataPoints.map((cp) => ({
          x: cp.key,
          y: cp.value || 0,
          name: dpc.name,
          comparisonKey: cp.comparisonKey,
        }));

        const dataPointsSerie: Serie = {
          id: dpc.name,
          data: dataPoints,
          color: getColorFor(dpc.name),
        };

        const comparisonPointsSerie: Serie = {
          id: `${dpc.name}_COMP`,
          data: comparisonPoints,
          color: getColorFor(`${dpc.name}_COMP`),
        };

        classifiedChartData[d.type].meta = d.meta;
        classifiedChartData[d.type].data.push(comparisonPointsSerie);
        classifiedChartData[d.type].data.push(dataPointsSerie);
      });
    });
    setChartData(classifiedChartData);
  }, [data]);

  useEffect(() => {
    switch (filters.frequency.value) {
      case Frequency.Yearly:
        setxAxisConfig({
          format: '%Y',
          tickValues: 'every year',
        });
        break;
      case Frequency.Quarterly:
        setxAxisConfig({
          format: '%Y, Q%q',
          tickValues: groups?.dataPointCollection[0].dataPoints.length,
        });
        break;
      default:
        setxAxisConfig({
          format: '%Y, %b',
          // tickValues: 'every 1 month',
        });
        break;
    }
  }, [filters.frequency, groups?.dataPointCollection[0].dataPoints.length]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const changeActiveTab = (tab: MembershipMetricType): void => {
    setActiveTab(tab);
    sendEventGA(
      CategoryKeysGA.DashboardMembershipMetrics,
      ActionKeysGA.ChangeChartTab,
      LabelKeysGA[tab]
    );
  };

  /**
   * Get classnames for active/inactive tabs
   *
   * @param tab Membership metrics chart type
   * @returns {string} Classnames for active/inactive tabs
   */
  const isActive = (tab: MembershipMetricType): string => {
    const activeTabClasses = 'tab active';
    const idleTabClasses = 'tab idle';

    return activeTab === tab ? activeTabClasses : idleTabClasses;
  };

  /**
   * Get overall attendance percentage
   *
   * @returns {number} Overall attendance percentage
   */
  const getOverallAttendance = (): number => {
    const attendanceIp =
      attendance?.tabData.find(
        (i) => i.name === MembershipMetricType.AttendanceCount
      )?.value ?? 0;

    const attendanceBr =
      attendance?.tabData.find((i) => i.name === MembershipMetricType.RepCount)
        ?.value ?? 0;

    return attendanceIp + attendanceBr;
  };

  /**
   * Handles membership metrics filter change event
   *
   * @param filter Selected filter option
   * @param type Type of filter
   */
  const handleFilterChange = (
    filter: SelectFieldOption,
    type: FilterType
  ): void => {
    onFilterChange(filter, type);

    sendEventGA(
      `${CategoryKeysGA.DashboardMembershipMetrics}/${String(
        LabelKeysGA[activeTab]
      )}`,
      ActionKeysGA.ChangeChartFilter,
      `${String(LabelKeysGA[type])}:${String(LabelKeysGA[filter.value])}`
    );
  };

  return (
    <BlockUi tag="div" blocking={loading} className="row dashboard-row">
      <div className="col">
        <div className="content-box">
          <div className="content-box-title has-dropdowns pb-4 pt-2">
            <h3 className="main-title">
              {intl.get('LBL_MEMBERSHIP_METRICS')}
              <span className="help">
                <i
                  className="icon-help"
                  data-for="insTooltip"
                  data-tip={intl.get('LBL_MEMBERSHIP_METRICS_HELP_TEXT')}
                />
              </span>
            </h3>
            <div className="inline-form">
              <FormGroup className="insight-select-group">
                <Label htmlFor="dateComparison" className="chart-filter-label">
                  {intl.get('LBL_CHART_FILTER_DATE_COMPARISON')}
                </Label>
                <Select
                  name="dateComparison"
                  value={filters.timeUnit}
                  options={timeUnits}
                  onChange={(value): void =>
                    handleFilterChange(
                      value as SelectFieldOption,
                      FilterType.TimeUnit
                    )
                  }
                  classNamePrefix="insight-select-no-border"
                />
              </FormGroup>
              <FormGroup className="insight-select-group">
                <Label htmlFor="frequency" className="chart-filter-label">
                  {intl.get('LBL_CHART_FILTER_FREQUENCY')}
                </Label>
                <Select
                  name="frequency"
                  value={filters.frequency}
                  options={frequencies}
                  onChange={(value): void =>
                    handleFilterChange(
                      value as SelectFieldOption,
                      FilterType.Frequency
                    )
                  }
                  classNamePrefix="insight-select-no-border"
                />
              </FormGroup>
            </div>
          </div>

          <Nav tabs className="content-box-tabs">
            <NavItem>
              <NavLink
                className={isActive(MembershipMetricType.Groups)}
                onClick={(): void =>
                  changeActiveTab(MembershipMetricType.Groups)
                }
              >
                <div className="chart-details">
                  <span className="sub-title">
                    {intl.get(`LBL_${MembershipMetricType.Groups}`)}
                    <span className="help">
                      <i
                        className="icon-help"
                        data-for="insTooltip"
                        data-tip={intl.get(
                          'LBL_MEMBERSHIP_NUMBER_OF_GROUPS_HELP_TEXT'
                        )}
                      />
                    </span>
                  </span>
                  <span className="value">
                    <NumberFormat
                      value={getRoundedNumber(
                        groups?.tabData.find(
                          (i) => i.name === MembershipMetricType.GroupCount
                        )?.value || 0
                      )}
                      displayType="text"
                      thousandSeparator
                    />
                  </span>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={isActive(MembershipMetricType.Members)}
                onClick={(): void =>
                  changeActiveTab(MembershipMetricType.Members)
                }
              >
                <div className="chart-details">
                  <span className="sub-title">
                    {intl.get(`LBL_${MembershipMetricType.Members}`)}
                    <span className="help">
                      <i
                        className="icon-help"
                        data-for="insTooltip"
                        data-tip={intl.get(
                          'LBL_MEMBERSHIP_NUMBER_OF_MEMBERS_HELP_TEXT'
                        )}
                      />
                    </span>
                  </span>
                  <span className="value">
                    <NumberFormat
                      value={getRoundedNumber(
                        members?.tabData.find(
                          (i) => i.name === MembershipMetricType.MemberCount
                        )?.value || 0
                      )}
                      displayType="text"
                      thousandSeparator
                    />
                  </span>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={isActive(MembershipMetricType.Attendance)}
                onClick={(): void =>
                  changeActiveTab(MembershipMetricType.Attendance)
                }
              >
                <div className="chart-details">
                  <span className="sub-title">
                    {intl.get('LBL_OVERALL_ATTENDANCE')}
                    <span className="help">
                      <i
                        className="icon-help"
                        data-for="insTooltip"
                        data-tip={intl.get('LBL_OVERALL_ATTENDANCE_HELP_TEXT')}
                      />
                    </span>
                  </span>
                  <span className="value">
                    <NumberFormat
                      value={getRoundedNumber(getOverallAttendance())}
                      suffix="%"
                      displayType="text"
                      decimalScale={1}
                      thousandSeparator
                    />
                  </span>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={isActive(MembershipMetricType.Dropout)}
                onClick={(): void =>
                  changeActiveTab(MembershipMetricType.Dropout)
                }
              >
                <div className="chart-details">
                  <span className="sub-title">
                    {intl.get(`LBL_${MembershipMetricType.Dropout}`)}
                    <span className="help">
                      <i
                        className="icon-help"
                        data-for="insTooltip"
                        data-tip={intl.get(
                          'LBL_MEMBERSHIP_DROPOUT_VS_INACTIVE_RATE_HELP_TEXT'
                        )}
                      />
                    </span>
                  </span>
                  <span className="value">
                    <NumberFormat
                      value={getRoundedNumber(
                        dropouts?.tabData.find(
                          (i) => i.name === MembershipMetricType.DropoutCount
                        )?.value || 0
                      )}
                      suffix="%"
                      displayType="text"
                      thousandSeparator
                    />
                    &nbsp;vs&nbsp;
                    <NumberFormat
                      value={getRoundedNumber(
                        dropouts?.tabData.find(
                          (i) => i.name === MembershipMetricType.InactiveCount
                        )?.value || 0
                      )}
                      suffix="%"
                      displayType="text"
                      thousandSeparator
                    />
                  </span>
                </div>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId={MembershipMetricType.Groups}>
              <Groups
                data={chartData.GROUPS.data}
                meta={chartData.GROUPS.meta}
                xAxisConfig={xAxisConfig}
                filters={filters}
              />
            </TabPane>
            <TabPane tabId={MembershipMetricType.Members}>
              <Members
                data={chartData.MEMBERS.data}
                meta={chartData.MEMBERS.meta}
                xAxisConfig={xAxisConfig}
                filters={filters}
              />
            </TabPane>
            <TabPane tabId={MembershipMetricType.Attendance}>
              <Attendance
                filters={filters}
                data={chartData.ATTENDANCE.data}
                meta={chartData.ATTENDANCE.meta}
                xAxisConfig={xAxisConfig}
              />
            </TabPane>
            <TabPane tabId={MembershipMetricType.Dropout}>
              <Dropout
                filters={filters}
                data={chartData.DROPOUT.data}
                meta={chartData.DROPOUT.meta}
                xAxisConfig={xAxisConfig}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </BlockUi>
  );
};

export default MembershipMetricContainer;

/* eslint-disable react/no-array-index-key */
import { FC as ReactFC } from 'react';

import NumberFormat from 'react-number-format';

import { getRoundedNumber } from 'helpers/NumberFormat';
import WidgetViewModel from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetViewModel';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';

const SavingsLeaderBoardWidget: ReactFC<{ data: WidgetViewModel }> = (
  props
) => {
  const maxItemCount = 10;

  const { data } = props;
  const { values } = data;

  return (
    <div className="savings-leader-widget">
      {values && values.length <= maxItemCount && (
        <div className="leader-board-container">
          <table className="leader-board">
            <tbody>
              {values.map((leader, i) => (
                <tr key={i}>
                  <td className="id">{i + 1}.</td>
                  <td className="name">
                    <EllipsisTooltip
                      tag="span"
                      forceRebuild
                      data-place="bottom"
                      data-for="insTooltip"
                      data-class="overflow-wrap"
                      data-tip={leader.title}
                      className="truncate"
                    >
                      {leader.title}
                    </EllipsisTooltip>
                    <EllipsisTooltip
                      tag="span"
                      data-place="bottom"
                      data-for="insTooltip"
                      data-class="overflow-wrap"
                      data-tip={leader.subTitle}
                      className="country truncate"
                    >
                      {leader.subTitle}
                    </EllipsisTooltip>
                  </td>
                  <td>
                    <EllipsisTooltip
                      tag="span"
                      data-place="bottom"
                      data-for="insTooltip"
                      data-class="overflow-wrap"
                      data-tip={leader.projectName ?? '—'}
                      className="project truncate"
                    >
                      {leader.projectName ?? '—'}
                    </EllipsisTooltip>
                  </td>
                  <td className="value">
                    <NumberFormat
                      value={getRoundedNumber(leader.count)}
                      displayType="text"
                      thousandSeparator
                      renderText={(count): JSX.Element => (
                        <EllipsisTooltip
                          tag="span"
                          data-class="overflow-wrap"
                          data-place="bottom"
                          data-for="insTooltip"
                          data-tip={count}
                          className="truncate"
                        >
                          {count}
                        </EllipsisTooltip>
                      )}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SavingsLeaderBoardWidget;

import { CancelTokenSource } from 'axios';
import queryString from 'query-string';

import ApiBase from 'api/common/ApiBase';
import ListResponse from 'api/common/ListResponse';
import { FinanceApiTags } from 'constants/request-tags/RequestTags';
import MembershipMetricsFilter from 'modules/private/dashboard/components/membership-metrics/MembershipMetricsFilter';
import FinancialMetricsChartFilter from 'modules/private/finances/components/financial-metrics-chart-container/FinancialMetricsChartFilter';
import FinancialMetricsChartTypes from 'modules/private/finances/components/financial-metrics-chart-container/FinancialMetricsChartTypes';
import GlobalFilters from 'shared/components/header-toolbar/GlobalFilters';

import FinancialMetric from '../dashboard/financial/FinancialMetric';
import KeyMetricsDetails from './key-metrics-details/KeyMetricsDetails';

class FinancesApi extends ApiBase {
  private static parseGlobalFilters(f: GlobalFilters): string {
    const defaultFilters = `backupStatus=${f.backupStatus}&groupAge=${f.groupAge}`;

    let dateFilters = '';

    if (f.fromDate && f.toDate) {
      dateFilters = `&dateFrom=${f.fromDate}&dateTo=${f.toDate}`;
    }

    let moreFilters = '';
    if (f.groupStatuses && f.groupStatuses.length > 0) {
      const groupStatusIds = f.groupStatuses;
      moreFilters = `&${queryString.stringify({
        groupStatus: groupStatusIds,
      })}`;
    }

    let projectFilters = '';
    if (f.projects && f.projects.length > 0) {
      const projectCodes = f.projects;
      projectFilters = `&${queryString.stringify({ projects: projectCodes })}`;
    }

    return `${defaultFilters}${dateFilters}${projectFilters}${moreFilters}`;
  }

  private static parseFinancialFilters(f: FinancialMetricsChartFilter): string {
    return `cyclePhase=${String(f.cyclePhase.value)}&frequency=${String(
      f.frequency.value
    )}&dateComparison=${String(f.timeUnit.value)}`;
  }

  private static parseMembershipFilters(f: MembershipMetricsFilter): string {
    return `frequency=${String(f.frequency.value)}&dateComparison=${String(
      f.timeUnit.value
    )}`;
  }

  async GetKeyMetrics(
    globalFilters: GlobalFilters,
    cancelToken: CancelTokenSource
  ): Promise<KeyMetricsDetails> {
    const path = 'finances/details';

    const globalFilterQuery = FinancesApi.parseGlobalFilters(globalFilters);

    const action = `${path}?${globalFilterQuery}`;
    const financialMetricsData = this.GetAsync<KeyMetricsDetails>({
      action,
      tag: FinanceApiTags.GetKeyMetrics,
      cancelSource: cancelToken,
    });

    return financialMetricsData;
  }

  async GetFinancesChartData(
    chartType: FinancialMetricsChartTypes,
    globalFilters: GlobalFilters,
    filters: FinancialMetricsChartFilter,
    cancelToken: CancelTokenSource
  ): Promise<FinancialMetric> {
    const path = 'finances/metrics';

    const globalFilterQuery = FinancesApi.parseGlobalFilters(globalFilters);
    const financialFilterQuery = FinancesApi.parseFinancialFilters(filters);

    const action = `${path}?type=${chartType}&${globalFilterQuery}&${financialFilterQuery}`;
    const financialMetricsData = this.GetAsync<FinancialMetric>({
      action,
      tag: FinanceApiTags.GetFinancesChartData,
      cancelSource: cancelToken,
    });

    return financialMetricsData;
  }

  async GetFinancialMetricData(
    globalFilters: GlobalFilters,
    filters: FinancialMetricsChartFilter,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<FinancialMetric>> {
    const path = 'dashboard/metrics/financials';

    const globalFilterQuery = FinancesApi.parseGlobalFilters(globalFilters);
    const financialFilterQuery = FinancesApi.parseFinancialFilters(filters);

    const action = `${path}?${globalFilterQuery}&${financialFilterQuery}`;
    const financialMetricsData = this.GetAsync<ListResponse<FinancialMetric>>({
      action,
      tag: FinanceApiTags.GetFinancialMetricData,
      cancelSource: cancelToken,
    });

    return financialMetricsData;
  }
}

const FinancesApiInstance = new FinancesApi();

export default FinancesApiInstance;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { createElement, ReactNode, useContext } from 'react';

import { Severity } from '@sentry/react';
import { Location } from 'history';
import { Redirect, Route } from 'react-router-dom';

import ApiError from 'api/common/ApiError';
import ModulePaths from 'constants/ModulePaths';
import AppContext from 'context/AppContext';
import FeatureValidationUtil from 'helpers/FeatureValidationUtil';
import InsightsLogger from 'helpers/logging/InsightsLogger';
import AuthService from 'services/AuthService';

import PrivateRouteProps from './PrivateRouteProps';

const PrivateRoute = (props: PrivateRouteProps): JSX.Element => {
  const { component, children, render, ...rest } = props;

  const {
    userInfoData: { features },
  } = useContext(AppContext);

  /**
   * Check whether the feature is enabled before accessing
   *
   * @param pathName Accessing location path
   * @returns {boolean} Whether the user can access the feature or not
   */
  const checkFeatureEnabled = (pathName: string): boolean => {
    const routeResource = `/${pathName.split('/')[1]}`;
    const isFeatureAvailable = FeatureValidationUtil.Can(
      features || [],
      routeResource
    );
    return isFeatureAvailable;
  };

  /**
   * Render the children component
   *
   * @param childProps Route props
   * @returns {ReactNode} JSX snippet containing the child components
   */
  const renderChildren = (childProps): ReactNode =>
    component
      ? createElement(component, childProps)
      : children && typeof children === 'function'
      ? children(childProps)
      : render
      ? render(childProps)
      : null;

  /**
   * Render the redirect component and log error to Sentry
   *
   * @param from Previous location
   * @returns {ReactNode} JSX snippet containing the redirect component
   */
  const renderRedirect = (from: Location): ReactNode => {
    const error = new ApiError(
      `Request failed with error: ${String(new Error('Token not found'))}`
    );
    InsightsLogger.logError(error, Severity.Critical);
    return (
      <Redirect
        to={{
          pathname: `${ModulePaths.AuthPath}${ModulePaths.AuthLoginPath}`,
          state: { from },
        }}
      />
    );
  };

  /**
   * Render the redirect component and log error to Sentry
   *
   * @param routeProps Route props
   * @returns {ReactNode} JSX snippet containing the redirect component or child component
   */
  const renderReactNode = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    routeProps: any
  ): ReactNode => {
    if (AuthService.IsLoggedIn()) {
      if (checkFeatureEnabled(routeProps.location.pathname)) {
        // Return the accessing component if it can be accessed
        return renderChildren(routeProps);
      }
      // Redirect to the dashboard if the feature cannot access
      return (
        <Redirect
          to={{
            pathname: `${ModulePaths.DashboardPath}`,
          }}
        />
      );
    }
    // Redirect to login if not logged in
    return renderRedirect(routeProps.location);
  };

  return (
    <Route
      {...rest}
      render={(routeProps): ReactNode => renderReactNode(routeProps)}
    />
  );
};

export default PrivateRoute;

class KeyMetricsDetails {
  assets: number | null;

  cumulativeValueOfLoansGiven: number | null;

  returnOnSavings: number | null;

  averageSavingsPerMember: number | null;

  averageNumOfLoansGiven: number | null;

  propertyAtStartOfCycle: number | null;

  liabilities: number | null;

  netProfit: number | null;

  loanFundBalance: number | null;

  loansOutstanding: number | null;

  averageGroupShareOutProjection: number | null;

  loanToSavingsRatio: number | null;

  socialFundUtilizationRate: number | null;

  externalDebts: number | null;

  portfolioAtRisk: number | null;

  averageMemberShareOutProjection: number | null;

  averageSocialFundPayout: number | null;

  propertyNow: number | null;

  loanRepaymentRate: number | null;

  bankBalance: number | null;

  estimatedGroupShareOut: number | null;

  cumulativeSavings: number | null;

  loanTakenMemberPercentage: number | null;

  equity: number | null;

  socialFundBalance: number | null;

  loanFundUtilization: number | null;

  /**
   *
   */
  constructor() {
    this.assets = 0;
    this.cumulativeValueOfLoansGiven = 0;
    this.returnOnSavings = 0;
    this.averageSavingsPerMember = 0;
    this.averageNumOfLoansGiven = 0;
    this.propertyAtStartOfCycle = 0;
    this.liabilities = 0;
    this.netProfit = 0;
    this.loanFundBalance = 0;
    this.loansOutstanding = 0;
    this.averageGroupShareOutProjection = 0;
    this.loanToSavingsRatio = 0;
    this.socialFundUtilizationRate = 0;
    this.externalDebts = 0;
    this.portfolioAtRisk = 0;
    this.averageMemberShareOutProjection = 0;
    this.averageSocialFundPayout = 0;
    this.propertyNow = 0;
    this.loanRepaymentRate = 0;
    this.bankBalance = 0;
    this.estimatedGroupShareOut = 0;
    this.cumulativeSavings = 0;
    this.loanTakenMemberPercentage = 0;
    this.equity = 0;
    this.socialFundBalance = 0;
    this.loanFundUtilization = 0;
  }
}

export default KeyMetricsDetails;

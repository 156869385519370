import { CancelTokenSource } from 'axios';
import queryString from 'query-string';

import ApiBase from 'api/common/ApiBase';
import ListResponse from 'api/common/ListResponse';
import ObjectResponse from 'api/common/ObjectResponse';
import SeekPaginatedListResponse from 'api/common/SeekPaginatedListResponse';
import SuccessResponse from 'api/common/SuccessResponse';
import ProjectDataMinimal from 'api/projects/ProjectDataMinimal';
import { SurveysApiTags } from 'constants/request-tags/RequestTags';

import DraftedSurvey from './DraftedSurvey';
import Country from './filters/Country';
import Facilitator from './filters/Facilitator';
import SurveyFiltersRequest from './filters/SurveyFiltersRequest';
import SurveyFiltersResponse from './filters/SurveyFiltersResponse';
import RecipientsStatusRequestBody from './RecipientsStatusRequestBody';
import RecipientsStatusResponse from './RecipientsStatusResponse';
import SendSurveyRequestBody from './SendSurveyRequestBody';
import SurveyHistoryResponse from './survey-history/SurveyHistoryResponse';
import SurveyProcessedStatus from './survey-history/SurveyProcessedStatus';
import SurveyHistoryRequest from './survey-history/SurveysHistoryRequest';
import ToggleStatusResponse from './survey-history/ToggleStatusResponse';
import SurveyStats from './SurveyStats';

class SurveysApi extends ApiBase {
  /**
   * Fetch drafted surveys list
   */
  async GetDraftedSurveysData(
    url: string | null,
    search: string,
    pageSize = 10,
    cancelToken: CancelTokenSource
  ): Promise<SeekPaginatedListResponse<DraftedSurvey>> {
    let listFilterQuery = `pageSize=${pageSize}`;
    if (url === null) {
      listFilterQuery +=
        search && search.trim().length > 0
          ? `&name=${encodeURIComponent(search.trim())}`
          : '';
    } else {
      // eslint-disable-next-line prefer-destructuring
      listFilterQuery = url.split('?')[1];
    }

    const path = `surveys/drafts${
      listFilterQuery ? `?${listFilterQuery}` : ''
    }`;
    const listData = this.GetAsync<SeekPaginatedListResponse<DraftedSurvey>>({
      action: path,
      tag: SurveysApiTags.GetDraftedSurveysData,
      cancelSource: cancelToken,
    });
    return listData;
  }

  /**
   * Fetch latest deployed draft surveys from Kobo and save in Insights DB
   */
  async FetchLatestSurveys(
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = 'surveys/drafts/fetch';
    const response = this.PostAsync<SuccessResponse>({
      action: path,
      tag: SurveysApiTags.FetchLatestSurveys,
      cancelSource: cancelToken,
    });
    return response;
  }

  /**
   * Get survey stats for surveys tooltips
   */
  async GetSurveysStatsData(
    cancelToken: CancelTokenSource
  ): Promise<SurveyStats> {
    const path = 'surveys/tooltip-status';
    const statsData = this.GetAsync<SurveyStats>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: SurveysApiTags.GetSurveysStatsData,
      cancelSource: cancelToken,
    });
    return statsData;
  }

  /**
   * Get countries to populate filters
   */
  async GetCountries(
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<Country>> {
    const path = `groups/countries`;
    const countriesResponse = this.GetAsync<Promise<ListResponse<Country>>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: SurveysApiTags.GetCountries,
      cancelSource: cancelToken,
    });
    return countriesResponse;
  }

  /**
   * Get projects to populate projects filter
   *
   * @param countryCodes Country codes to filter by
   */
  async GetProjectsForCountry(
    countryCodes: Array<string>,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<ProjectDataMinimal>> {
    const queryParams = `${queryString.stringify({
      countryCode: countryCodes,
    })}`;
    const path = `projects/all-projects?${queryParams}`;
    const projectsResponse = this.GetAsync<
      Promise<ListResponse<ProjectDataMinimal>>
    >({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: SurveysApiTags.GetProjectsForCountry,
      cancelSource: cancelToken,
    });
    return projectsResponse;
  }

  /**
   * Get facilitator to populate filters
   *
   * @param projectIds Project Ids to filter by
   */
  async GetFacilitatorsForProjects(
    projectIds: Array<string>,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<Facilitator>> {
    const queryParams = `${queryString.stringify({
      projectId: projectIds,
    })}`;
    const path = `organizations/facilitators/lookup?${queryParams}`;
    const facilitatorsResponse = this.GetAsync<
      Promise<ListResponse<Facilitator>>
    >({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: SurveysApiTags.GetFacilitatorsForProjects,
      cancelSource: cancelToken,
    });
    return facilitatorsResponse;
  }

  /**
   * Get survey recipients based on filters
   *
   * @param surveyFilters Selected groups and members filters
   */
  async GetSurveyRecipients(
    surveyFilters: SurveyFiltersRequest,
    cancelToken: CancelTokenSource
  ): Promise<SurveyFiltersResponse> {
    const path = 'surveys/recipients';
    const body = { ...surveyFilters };
    const response = this.PostAsync<Promise<SurveyFiltersResponse>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SurveysApiTags.GetSurveyRecipients,
      cancelSource: cancelToken,
    });

    return response;
  }

  /**
   * Fetch recipients status prior to send
   *
   * @param filteredRecipients Filtered recipients
   */
  async FetchRecipientsStatus(
    recipients: RecipientsStatusRequestBody,
    cancelToken: CancelTokenSource
  ): Promise<RecipientsStatusResponse> {
    const path = 'surveys/check-recipients-status';
    const body = { ...recipients };
    const response = this.PostAsync<Promise<RecipientsStatusResponse>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SurveysApiTags.FetchRecipientsStatus,
      cancelSource: cancelToken,
    });

    return response;
  }

  /**
   * Send survey
   *
   * @param surveyRequest Selected filters and selected survey details
   */
  async SendSurvey(
    surveyRequest: SendSurveyRequestBody,
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = 'surveys/send';
    const body = { ...surveyRequest };
    const response = this.PostAsync<Promise<SuccessResponse>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SurveysApiTags.SendSurvey,
      cancelSource: cancelToken,
    });

    return response;
  }

  /**
   * Get survey history list
   *
   * @param surveyHistoryRequest Get survey history
   */
  async GetSurveyHistoryList(
    surveyHistoryRequest: SurveyHistoryRequest,
    cancelToken: CancelTokenSource
  ): Promise<SeekPaginatedListResponse<SurveyHistoryResponse>> {
    const { pageSize, url, name, sortBy, surveyType, recipientType, sort } =
      surveyHistoryRequest;

    let listFilterQuery = `pageSize=${pageSize}&sortBy=${sortBy}&surveyType=${surveyType}&recipientType=${recipientType}&sort=${sort}`;
    if (url === null) {
      listFilterQuery +=
        name && name.trim().length > 0
          ? `&name=${encodeURIComponent(name.trim())}`
          : '';
    } else {
      // eslint-disable-next-line prefer-destructuring
      listFilterQuery = url.split('?')[1];
    }

    const path = `surveys?${listFilterQuery}`;
    const response = this.GetAsync<
      Promise<SeekPaginatedListResponse<SurveyHistoryResponse>>
    >({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: SurveysApiTags.GetSurveyHistoryList,
      cancelSource: cancelToken,
    });

    return response;
  }

  /**
   * Get survey processed status
   *
   * @param id Survey ID
   */
  async GetSurveyProcessedStatus(
    id: string,
    cancelToken: CancelTokenSource
  ): Promise<SurveyProcessedStatus> {
    const path = `surveys/${id}/processed-status`;
    const response = this.GetAsync<SurveyProcessedStatus>({
      action: path,
      tag: SurveysApiTags.GetSurveyProcessedStatus,
      cancelSource: cancelToken,
    });
    return response;
  }

  /**
   * Get Collect ID toggle recipient status
   *
   * @param id Survey ID
   */
  async GetCollectIdToggleStatus(
    id: string,
    cancelToken: CancelTokenSource
  ): Promise<RecipientsStatusResponse> {
    const path = `surveys/${id}/check-recipients-status`;
    const response = this.GetAsync<RecipientsStatusResponse>({
      action: path,
      tag: SurveysApiTags.GetCollectIdToggleStatus,
      cancelSource: cancelToken,
    });
    return response;
  }

  /**
   * Update Collect ID toggle status
   *
   * @param id Survey ID
   */
  async UpdateCollectIdToggleStatus(
    id: string,
    statusOn: boolean,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<ToggleStatusResponse>> {
    const path = `surveys/${id}/toggle-status?statusOn=${String(statusOn)}`;
    const response = this.PutAsync<ObjectResponse<ToggleStatusResponse>>({
      action: path,
      tag: SurveysApiTags.UpdateCollectIdToggleStatus,
      cancelSource: cancelToken,
    });
    return response;
  }

  /**
   * Get survey correlation data
   *
   * @param id Survey id
   */
  async GetSurveyCorrelationExcel(
    id: string,
    cancelToken: CancelTokenSource
  ): Promise<Blob> {
    const path = `surveys/${id}/generate-excel`;

    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const action = `${path}?timezone=${clientTimezone}`;

    const excelData = this.GetAsyncBlob({
      action,
      tag: SurveysApiTags.GetSurveyCorrelationExcel,
      cancelSource: cancelToken,
    });
    return excelData;
  }
}

const SurveysApiInstance = new SurveysApi();

export default SurveysApiInstance;

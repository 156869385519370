/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router-dom';

import ModulePaths from 'constants/ModulePaths';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import ProtectedRoute from 'shared/components/hoc/protected-route/ProtectedRoute';
import ProjectViewMode from 'shared/enums/ProjectViewMode';

import ProjectFacilitatorsView from './containers/project-facilitators-view/ProjectFacilitatorsView';
import ProjectUsersView from './containers/project-users-view/ProjectUsersView';
import ProjectsView from './containers/projects-view/ProjectsView';
import ProjectsRouteProps from './ProjectsRouteProps';

export type ProjectIdParams = { projectId: string };

const ProjectsRoutes: ReactFC<ProjectsRouteProps> = (
  props: ProjectsRouteProps
) => {
  const { match, location } = props;
  return (
    <Switch>
      <ProtectedRoute
        exact
        redirectTo={ModulePaths.ProjectsPath}
        path={`${match.path}${ModulePaths.ProjectFacilitatorsIdPath}`}
      >
        {(routeProps): JSX.Element => (
          <ProjectFacilitatorsView {...props} {...routeProps} />
        )}
      </ProtectedRoute>

      <ProtectedRoute
        exact
        redirectTo={ModulePaths.ProjectsPath}
        path={`${match.path}${ModulePaths.ProjectUsersIdPath}`}
      >
        {(
          routeProps: RouteChildrenProps<{ projectId: string }>
        ): JSX.Element => <ProjectUsersView {...props} {...routeProps} />}
      </ProtectedRoute>

      <ProtectedRoute
        exact
        resource={ResourceKeys.ProjectsCreateNewProject}
        redirectTo={ModulePaths.ProjectsPath}
        path={`${match.path}${ModulePaths.ProjectCreatePath}`}
      >
        {(routeProps): JSX.Element => (
          <ProjectsView
            {...props}
            {...routeProps}
            type={ProjectViewMode.CREATE_PROJECT}
          />
        )}
      </ProtectedRoute>

      <ProtectedRoute
        resource={ResourceKeys.ProjectsItemEdit}
        redirectTo={ModulePaths.ProjectsPath}
        exact
        path={`${match.path}${ModulePaths.ProjectEditIdPath}`}
      >
        {(
          routeProps: RouteChildrenProps<{ projectId: string }>
        ): JSX.Element => (
          <ProjectsView
            {...props}
            {...routeProps}
            type={ProjectViewMode.EDIT_PROJECT}
          />
        )}
      </ProtectedRoute>

      <ProtectedRoute
        resource={ResourceKeys.ProjectsItemAddUsers}
        redirectTo={ModulePaths.ProjectsPath}
        exact
        path={`${match.path}${ModulePaths.ProjectAddUsersIdPath}`}
      >
        {(routeProps: RouteChildrenProps<ProjectIdParams>): JSX.Element => (
          <ProjectsView
            {...props}
            {...routeProps}
            type={ProjectViewMode.ADD_USERS}
          />
        )}
      </ProtectedRoute>

      <ProtectedRoute
        resource={ResourceKeys.ProjectsItemAddFacilitators}
        redirectTo={ModulePaths.ProjectsPath}
        exact
        path={`${match.path}${ModulePaths.ProjectAddFacilitatorsIdPath}`}
      >
        {(routeProps: RouteChildrenProps<ProjectIdParams>): JSX.Element => (
          <ProjectsView
            {...props}
            {...routeProps}
            type={ProjectViewMode.ADD_FACILITATORS}
          />
        )}
      </ProtectedRoute>

      <ProtectedRoute
        redirectTo={ModulePaths.ProjectsPath}
        exact
        path={`${match.path}`}
      >
        {(routeProps): JSX.Element => (
          <ProjectsView
            {...props}
            {...routeProps}
            type={ProjectViewMode.NONE}
          />
        )}
      </ProtectedRoute>

      {/* No match for this sub-route; could show a 404 */}
      <Route path="*">
        <Redirect
          to={{
            pathname: ModulePaths.ProjectsPath,
            search: getGlobalFiltersQuery(location.search),
          }}
        />
        ;
      </Route>
    </Switch>
  );
};

export default ProjectsRoutes;

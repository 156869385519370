/* eslint-disable no-else-return */
import { ReactNode, useEffect, FC as ReactFC } from 'react';

import { ArrayHelpers, Field, FieldArray, FieldProps, Form } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import { Button, Col, Fade, FormGroup, Input, Label, Row } from 'reactstrap';

import Constraints from 'constants/forms/Constraints';
import EnhancedFormikError from 'shared/components/enhanced-formik-error/EnhancedFormikError';

import styles from '../../permissionsList.module.scss';
import EditPermissionFormValues from '../EditPermissionFormValues';
import {
  Feature,
  InputType,
  InputTypeFeature,
} from '../EditPermissionsListItemProps';
import EditPermissionFormProps from './EditPermissionFormProps';

const EditPermissionForm: ReactFC<EditPermissionFormProps> = (
  props: EditPermissionFormProps
) => {
  const {
    groupedFeatures,
    isSubmitting,
    onCancel,
    isModalView,
    canEditPermissions,
    values,
    errors,
    dirty,
    permission,
    onError,
    commonError,
  } = props;

  useEffect(() => {
    if (!isSubmitting) {
      if (!isEmpty(errors)) {
        onError(intl.get('ERR_PERMISSION_EDIT_FORM_VALIDATION_ERRORS'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  /**
   * Return formik field errors or null
   *
   * @param name string; field which the error belongs
   * @param param1 formik field props
   */
  const renderStatusError = (
    name: string,
    { form }: FieldProps<EditPermissionFormValues>
  ): string | null | JSX.Element => {
    const { status: formStatus } = form;
    const dotNameArray = name.split('.');
    const fieldName = dotNameArray[dotNameArray.length - 1];
    if (formStatus) {
      const fieldKeys = Object.keys(formStatus.fields);
      if (fieldKeys.length > 0 && formStatus.fields[fieldName]) {
        return intl.get(
          `ERR_API_PERMISSIONS_${String(formStatus.fields[fieldName])}`
        );
      }
    }
    return null;
  };

  /**
   * Handle feature enable and disable for a permission level on edit view.
   *
   * @param event checkbox change event
   * @param arrayHelpers Field array render properties
   * @param formValues form values
   */
  const handleFeatureChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    arrayHelpers: ArrayHelpers,
    formValues: EditPermissionFormValues
  ): void => {
    if (event.target.checked) arrayHelpers.push(event.target.value);
    else {
      const idx = formValues.features.indexOf(event.target.value);
      arrayHelpers.remove(idx);
    }
  };

  /**
   * Handle feature enable and disable for a radio button
   *
   * @param oppositeRadio the radio to un-check when one is checked
   * @param event checkbox change event
   * @param arrayHelpers Field array render properties
   *
   */
  const handleRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    oppositeRadio: string,
    arrayHelpers: ArrayHelpers
  ): void => {
    if (event.target.checked) {
      arrayHelpers.push(event.target.value);
      const idx = values.features.indexOf(oppositeRadio);
      if (idx >= 0) {
        arrayHelpers.remove(idx);
      }
    }
  };

  /**
   * Reset form to initial values on edit cancel.
   */
  const handleCancel = (): void => {
    onCancel();
  };

  /**
   * Render permission related feature claims with groups.
   *
   * @param formValues form values
   * @param arrayHelpers Field array render properties
   */
  const renderFeatures = (
    formValues: EditPermissionFormValues,
    arrayHelpers: ArrayHelpers,
    isDefaultPermission: boolean
  ): ReactNode =>
    map(groupedFeatures, (featureGroup) => {
      const inputTypeFeatures: Array<Feature | InputTypeFeature> =
        Object.values(
          featureGroup.reduce((accumulator, current) => {
            if (current.metaData) {
              const metaName = current.metaData.name;
              const type = current.metaData.inputType;
              return {
                ...accumulator,
                [metaName]: {
                  ...(accumulator[metaName] ? accumulator[metaName] : {}),
                  type,
                  name: metaName,
                  items: [
                    ...(accumulator[metaName]
                      ? accumulator[metaName].items
                      : []),
                    current,
                  ],
                },
              };
            }
            return { ...accumulator, [current.name]: current };
          }, {})
        );

      const grantNodes: JSX.Element[] = [];
      inputTypeFeatures.forEach((feature: Feature | InputTypeFeature) => {
        if ('type' in feature) {
          if (feature.type === InputType.RADIO) {
            const radioNodes: JSX.Element[] = [];
            feature.items.forEach((item, _, featureArray) => {
              const name = get(item, 'metaData.name');

              const featureChecked = formValues.features.includes(item.name);

              const isDisabled =
                isDefaultPermission || !canEditPermissions || !item.editable;

              const disableText = !isDefaultPermission && !item.editable;

              const oppositeRadio = featureArray.filter(
                (element) => element.name !== item.name
              )[0].name;

              const handleChange = (
                event: React.ChangeEvent<HTMLInputElement>
              ): void => handleRadioChange(event, oppositeRadio, arrayHelpers);

              const newFeatureNode = (
                <Label
                  key={item.name}
                  className={`${styles.editPermissionsRadio} ${
                    featureChecked ? styles.checked : ''
                  } ${disableText ? styles.disabledText : ''}`}
                >
                  <span
                    className={`insight-radio ${
                      isDisabled ? 'disabled-radio' : ''
                    }`}
                  >
                    <Input
                      name={name}
                      id={item.name}
                      type="radio"
                      value={item.name}
                      checked={featureChecked}
                      onChange={handleChange}
                      className="radio-button"
                      disabled={isDisabled}
                    />
                    <i className="icon-radio" />
                  </span>
                  {item.text}
                </Label>
              );
              radioNodes.push(newFeatureNode);
            });
            grantNodes.push(
              <div
                key={feature.name}
                role="group"
                className={`insight-radio-group ${styles.editPermissionsRadioGroup}`}
                aria-labelledby="insight-radio-group"
              >
                {radioNodes}
              </div>
            );
          }
        } else {
          const featureChecked = formValues.features.includes(feature.name);

          const isDisabled =
            isDefaultPermission || !canEditPermissions || !feature.editable;

          const disableText = !isDefaultPermission && !feature.editable;

          grantNodes.push(
            <div
              key={feature.name}
              role="group"
              aria-labelledby="insight-checkbox-group"
            >
              <label
                className={`${styles.checkbox} ${
                  featureChecked ? styles.checked : ''
                } ${disableText ? styles.disabledText : ''} text-14-medium`}
                key={feature.name}
              >
                <span
                  className={`insight-checkbox ${
                    isDisabled ? 'disabled-checkbox' : ''
                  }`}
                >
                  <Input
                    name="features"
                    type="checkbox"
                    value={feature.name}
                    checked={featureChecked}
                    onChange={(e): void =>
                      handleFeatureChange(e, arrayHelpers, formValues)
                    }
                    disabled={isDisabled}
                  />
                  <i className="icon-check" />
                </span>
                {feature.text}
              </label>
            </div>
          );
        }
      });

      return (
        <Row className={styles.checkboxList} key={featureGroup[0].groupName}>
          <Col md="5">
            <div className="text-16-semibold" id="checkbox-group">
              {featureGroup[0].groupName}
            </div>
          </Col>
          <Col>{grantNodes}</Col>
        </Row>
      );
    });

  /**
   * Render common errors
   *
   * @returns {JSX.Element}
   */
  const renderCommonError = (): JSX.Element => (
    <Fade in={!!commonError} mountOnEnter unmountOnExit className="form-group">
      <div className="alert alert-danger" role="alert">
        {commonError}
      </div>
    </Fade>
  );

  const maxLength = Constraints.MaxFieldLength;
  const maxLengthArea = Constraints.MaxFieldLengthTextArea;

  return (
    <BlockUi tag="div" blocking={isSubmitting}>
      <Form>
        <div className={`${styles.item} ${styles.collapse}`}>
          <div className="d-flex">
            <div className="flex-grow-1 mr-3">{renderCommonError()}</div>
            <div className="d-flex justify-content-end">
              <Button
                className="btn-warning mr-2"
                size="sm"
                onClick={handleCancel}
              >
                {intl.get('BTN_CANCEL')}
              </Button>
              {!permission.isDefault && canEditPermissions && (
                <Button
                  color="primary"
                  size="sm"
                  type="submit"
                  disabled={!dirty}
                >
                  {intl.get('BTN_SAVE')}
                </Button>
              )}
            </div>
          </div>
          <Row className={styles.formWrap}>
            <Col xs={isModalView ? 6 : 4}>
              <FormGroup>
                <Label for="title">
                  {intl.get('LBL_SETTINGS_PERMISSION_ENTER_TITLE')}
                </Label>
                <Field
                  className="form-control"
                  name="title"
                  id="title"
                  maxLength={maxLength}
                  placeholder="Add title here"
                  readOnly={permission.isDefault || !canEditPermissions}
                />
                <EnhancedFormikError
                  name="title"
                  renderSecondaryError={renderStatusError}
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">
                  {intl.get('LBL_SETTINGS_PERMISSION_DESCRIPTION')}
                </Label>
                <Field
                  className="form-control"
                  as="textarea"
                  maxLength={maxLengthArea}
                  name="description"
                  id="description"
                  placeholder="Add description here"
                  readOnly={permission.isDefault || !canEditPermissions}
                />
                <EnhancedFormikError
                  name="description"
                  renderSecondaryError={renderStatusError}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <div className="text-12-semibold text-uppercase">
                {intl.get('LBL_SETTINGS_PERMISSION_PERMISSIONS')}
              </div>
            </Col>
            <Col xs="12">
              <hr className={`${styles.divider} ${styles.sub}`} />
            </Col>
            <Col xs="12">
              <FieldArray
                name="features"
                render={(arrayHelpers): ReactNode =>
                  renderFeatures(values, arrayHelpers, permission.isDefault)
                }
              />
              <Row>
                <Col xs={{ size: 7, offset: 5 }}>
                  <FormGroup style={{ transform: 'translateY(-45px)' }}>
                    <EnhancedFormikError
                      name="features"
                      renderSecondaryError={renderStatusError}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>
    </BlockUi>
  );
};

export default EditPermissionForm;

/* eslint-disable react/no-array-index-key */
import {
  ReactNode,
  useEffect,
  useRef,
  useState,
  FC as ReactFC,
  memo,
} from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Col,
  Popover,
  PopoverBody,
  Row,
} from 'reactstrap';

import ProjectItem from 'api/projects/ProjectItem';
import ModulePaths from 'constants/ModulePaths';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import Tables from 'constants/Tables';
import { formatDate } from 'helpers/DateFormat';
import { getFormattedNumber } from 'helpers/NumberFormat';
import PermissionUtil from 'helpers/PermissionUtil';
import ProjectUsersViewModel from 'modules/private/projects/containers/projects-view/ProjectUsersViewModel';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import PopperResizeContent from 'shared/components/hoc/popper-resize-content/PopperResizeContent';
import ImageComponent from 'shared/components/image/ImageComponent';
import InsLink from 'shared/components/ins-link/InsLink';
import DateFormatType from 'shared/enums/DateFormatType';
import Status from 'shared/enums/Status';
import usePrevious from 'shared/hooks/use-previous/UsePrevious';
import blankAvatar from 'shared/static/img/project-placeholders/blank_avatar.svg';

import ProjectItemGroupCard from './project-item-group-card/ProjectItemGroupCard';
import styles from './projectListItem.module.scss';
import ProjectListItemProps from './ProjectListItemProps';
import ProjectListItemViewModel from './ProjectListItemViewModel';

enum TooltipGuideType {
  USERS = 'users',
  FACILITATORS = 'facilitators',
  GROUPS = 'groups',
}

const ProjectListItem: ReactFC<ProjectListItemProps> = (
  props: ProjectListItemProps
) => {
  const {
    data: {
      permissions,
      list,
      projectStats,
      projectStatsStatus,
      guideProjectId,
      showGroupsGuide,
      isItemLoaded,
      setShowGroupsGuide,
    },
    index,
    style,
  } = props;

  const project = list[index];
  const loaded = isItemLoaded(index);

  const { userId, projectCount } = projectStats;

  const prevStatsStatus = usePrevious(projectStatsStatus);

  // prettier-ignore
  const [usersTooltipGuideOpen, setUsersTooltipGuideOpen] = useState<boolean>(false);
  // prettier-ignore
  const [facilitatorsTooltipGuideOpen, setFacilitatorsTooltipGuideOpen] = useState<boolean>(false);

  const rowRef = useRef<HTMLDivElement | null>(null);

  const currentProject: ProjectListItemViewModel | ProjectItem =
    project ?? new ProjectListItemViewModel();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    if (
      prevStatsStatus !== projectStatsStatus &&
      projectStatsStatus === Status.Success
    ) {
      setUsersTooltipGuideOpen(true);
      setFacilitatorsTooltipGuideOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectStatsStatus]);

  /**
   * Toggles the users tooltip guide
   */
  const toggleUsersTooltipGuide = (): void =>
    setUsersTooltipGuideOpen((open) => !open);

  /**
   * Toggles the facilitators tooltip guide
   */
  const toggleFacilitatorsTooltipGuide = (): void =>
    setFacilitatorsTooltipGuideOpen((open) => !open);

  const currentProjectUsers =
    currentProject.users.length > 0 ? currentProject.users : [];

  const currentProjectFacilitators =
    currentProject.facilitators.length > 0 ? currentProject.facilitators : [];

  const guideData = {
    [TooltipGuideType.USERS]: {
      title: intl.get('LBL_PROJECTS_TOOLTIP_GUIDE_USERS_TITLE'),
      description: intl.get('LBL_PROJECTS_TOOLTIP_GUIDE_USERS_DESCRIPTION'),
      secondaryDescription: '',
      isOpen: usersTooltipGuideOpen,
      onToggle: toggleUsersTooltipGuide,
      status: projectStats.showUserTooltip,
      target: 'userGuide',
      classNames: 'project-popper',
    },
    [TooltipGuideType.FACILITATORS]: {
      title: intl.get('LBL_PROJECTS_TOOLTIP_GUIDE_FACILITATORS_TITLE'),
      description: intl.get(
        'LBL_PROJECTS_TOOLTIP_GUIDE_FACILITATORS_DESCRIPTION'
      ),
      secondaryDescription: '',
      isOpen: facilitatorsTooltipGuideOpen,
      onToggle: toggleFacilitatorsTooltipGuide,
      status: projectStats.showFacilitatorTooltip,
      target: 'facilitatorGuide',
      classNames: 'project-popper facilitator-popper',
    },
  };

  const canEditProject = PermissionUtil.Can(
    permissions.claims,
    ResourceKeys.ProjectsItemEdit
  );
  const canAddUsers = PermissionUtil.Can(
    permissions.claims,
    ResourceKeys.ProjectsItemAddUsers
  );
  const canAddFacilitators = PermissionUtil.Can(
    permissions.claims,
    ResourceKeys.ProjectsItemAddFacilitators
  );

  /**
   * Renders a preview list of users added to the project
   *
   * @param usersList List of users added to the project
   * @returns {ReactNode} The JSX snippet containing the user list
   */
  const renderUsersRow = (
    usersList: Array<ProjectUsersViewModel>
  ): ReactNode => {
    if (usersList.length > 0) {
      return (
        <>
          <div className={styles.frame}>
            {usersList.map((user, imageIndex) => (
              <div key={imageIndex} className={styles.face}>
                <ImageComponent
                  src={user.image}
                  alt={user.name}
                  fallbackSrc={blankAvatar}
                />
              </div>
            ))}
          </div>
          <InsLink
            disabledClass="disabled"
            disabled={currentProject.users.length <= 0}
            to={`${ModulePaths.ProjectsPath}/${currentProject.projectId}${ModulePaths.ProjectUsersPath}${Tables.InitialQueryBasic}`}
            className={`${styles.view} btn btn-secondary-outline`}
          >
            {intl.getHTML('LBL_PROJECTS_HTML_VIEW_ALL')}
          </InsLink>
        </>
      );
    }
    return (
      <p className="text-12-medium text-gray mb-0">
        {intl.get('LBL_PROJECTS_NO_DATA_YET')}
      </p>
    );
  };

  /**
   * Renders a preview list of facilitators added to the project
   *
   * @param facilitatorList List of facilitators added to the project
   * @returns {ReactNode} The JSX snippet containing the user list
   */
  const renderFacilitatorsRow = (
    facilitatorList: Array<ProjectUsersViewModel>
  ): ReactNode => {
    if (facilitatorList.length > 0) {
      return (
        <>
          <div className={styles.frame}>
            {facilitatorList.map((user, imageIndex) => (
              <div key={imageIndex} className={styles.face}>
                <ImageComponent
                  src={user.image}
                  alt={user.name}
                  fallbackSrc={blankAvatar}
                />
              </div>
            ))}
          </div>
          <InsLink
            disabledClass="disabled"
            disabled={currentProject.facilitators.length <= 0}
            to={`${ModulePaths.ProjectsPath}/${currentProject.projectId}${ModulePaths.ProjectFacilitatorsPath}${Tables.InitialQueryBasic}`}
            className={`${styles.view} btn btn-secondary-outline`}
          >
            {intl.getHTML('LBL_PROJECTS_HTML_VIEW_ALL')}
          </InsLink>
        </>
      );
    }
    return (
      <p className="text-12-medium text-gray mb-0">
        {intl.get('LBL_PROJECTS_NO_DATA_YET')}
      </p>
    );
  };

  /**
   * Renders the tooltip guide for adding users, facilitators and groups when
   * a user creates their first project
   *
   * @param type Type of tooltip guide to be rendered
   * @param showSecondaryDesc If true, the secondary description will be displayed
   * @returns {ReactNode | null} The JSX snippet containing the tooltip
   */
  const renderTooltipGuide = (
    type: TooltipGuideType,
    showSecondaryDesc = false
  ): ReactNode | null => {
    // prettier-ignore
    const renderTooltip = index === 0 && userId === currentProject.createdById && projectCount === 1 && guideData[type].status;
    if (renderTooltip) {
      return (
        <Popover
          isOpen={guideData[type].isOpen}
          placement="top"
          container="body"
          hideArrow
          positionFixed
          modifiers={{
            preventOverflow: { enabled: true, escapeWithReference: true },
            flip: { enabled: false },
          }}
          toggle={guideData[type].onToggle}
          trigger="manual"
          popperClassName={guideData[type].classNames}
          target={guideData[type].target}
          delay={{ show: 500, hide: 100 }}
        >
          {({ scheduleUpdate }): JSX.Element => (
            <PopperResizeContent
              observeElement={rowRef}
              onResize={scheduleUpdate}
            >
              <PopoverBody>
                <div>
                  <div className="text-center text-gray">
                    {!showSecondaryDesc && (
                      <h4 className="text-14-semibold">
                        {guideData[type].title}
                      </h4>
                    )}
                    <span className="text-14-light">
                      {showSecondaryDesc
                        ? guideData[type].secondaryDescription
                        : guideData[type].description}
                    </span>
                  </div>
                </div>
              </PopoverBody>
            </PopperResizeContent>
          )}
        </Popover>
      );
    }
    return null;
  };

  const formattedStartDate =
    loaded && currentProject.startDate
      ? formatDate(currentProject.startDate, DateFormatType.ShortYear)
      : intl.get('LBL_NA');

  const formattedEndDate =
    loaded && currentProject.endDate
      ? formatDate(currentProject.endDate, DateFormatType.ShortYear)
      : intl.get('LBL_NA');

  const countryList = currentProject.countries.join(', ');

  return (
    <div
      style={{
        ...style,
        // https://github.com/bvaughn/react-window#can-i-add-padding-to-the-top-and-bottom-of-a-list

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        top: (Number(style.top) ?? 0) + 56,

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        height: (style.height ?? 0) - 56,
      }}
      ref={rowRef}
    >
      <BlockUi blocking={!loaded} tag="div">
        <Col xs="12" className="pl-0">
          <div className={styles.item}>
            <Row>
              <Col className={styles.meta}>
                {currentProject.isTestProject && (
                  <div className="mb-1">
                    <Badge color="primary">
                      {intl.get('LBL_PROJECTS_PRACTICE_PROJECT')}
                    </Badge>
                  </div>
                )}
                <h4 className="mb-1">
                  <EllipsisTooltip
                    tag="span"
                    data-place="bottom"
                    data-for="insTooltip"
                    data-tip={currentProject.name}
                    data-class="overflow-wrap"
                  >
                    {currentProject.name}
                  </EllipsisTooltip>{' '}
                  <span className="text-gray">{`(#${currentProject.code})`}</span>
                </h4>
                <EllipsisTooltip
                  tag="p"
                  data-place="bottom"
                  data-for="insTooltip"
                  data-class="overflow-wrap"
                  className="text-12-medium text-gray truncate"
                  data-tip={intl.get('LBL_PROJECT_DESCRIPTION', {
                    start: formattedStartDate,
                    end: formattedEndDate,
                    createdBy: currentProject.createdBy,
                  })}
                >
                  {intl.get('LBL_PROJECT_DESCRIPTION', {
                    start: formattedStartDate,
                    end: formattedEndDate,
                    createdBy: currentProject.createdBy,
                  })}
                </EllipsisTooltip>
                <InsLink
                  to={`${ModulePaths.ProjectsPath}${ModulePaths.ProjectEditPath}${currentProject.projectId}`}
                  className="btn btn-secondary d-inline-flex btn-sm mt-2"
                  disabled={!canEditProject}
                  hide={!canEditProject}
                  disabledClass="disabled"
                >
                  {intl.get('BTN_PROJECTS_EDIT')}
                </InsLink>
              </Col>
              <Col md="auto" className="ml-auto">
                <Row form>
                  <Col md="auto">
                    <Card className={styles.card}>
                      <CardBody>
                        <Row>
                          <Col>
                            <CardText
                              className={`text-14-semibold text-gray ${styles.transformUpper}`}
                            >
                              {intl.get('LBL_PROJECTS_USERS')}
                            </CardText>
                          </Col>
                          <Col
                            md="auto"
                            id={guideData[TooltipGuideType.USERS].target}
                          >
                            {canAddUsers &&
                              renderTooltipGuide(TooltipGuideType.USERS)}
                            <InsLink
                              disabledClass={styles.disabled}
                              hide={!canAddUsers}
                              className={`text-gray ${styles.plus}`}
                              to={`${ModulePaths.ProjectsPath}/${currentProject.projectId}${ModulePaths.ProjectAddUsersPath}`}
                            >
                              <i className="icon-plus" />
                            </InsLink>
                          </Col>
                          <Col xs="12">
                            <h3>
                              {getFormattedNumber(currentProject.usersCount)}
                            </h3>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter className={styles.footer}>
                        <Row>
                          <Col xs={12} className="d-flex justify-content-start">
                            {renderUsersRow(currentProjectUsers)}
                          </Col>
                        </Row>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col md="auto">
                    <Card className={styles.card}>
                      <CardBody>
                        <Row>
                          <Col className="pr-0">
                            <CardText
                              className={`text-14-semibold text-gray ${styles.transformUpper}`}
                            >
                              {intl.get('LBL_PROJECTS_FACILITATORS')}
                            </CardText>
                          </Col>
                          <Col
                            className="pl-0"
                            md="auto"
                            id={guideData[TooltipGuideType.FACILITATORS].target}
                          >
                            {canAddFacilitators &&
                              renderTooltipGuide(TooltipGuideType.FACILITATORS)}
                            <InsLink
                              disabledClass={styles.disabled}
                              className={`text-gray ${styles.plus}`}
                              to={`${ModulePaths.ProjectsPath}/${currentProject.projectId}${ModulePaths.ProjectAddFacilitatorsPath}`}
                              hide={!canAddFacilitators}
                            >
                              <i className="icon-plus" />
                            </InsLink>
                          </Col>
                          <Col xs="12">
                            <h3>
                              {getFormattedNumber(
                                currentProject.facilitatorsCount
                              )}
                            </h3>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter className={styles.footer}>
                        <Row>
                          <Col xs="12" className="justify-content-start d-flex">
                            {renderFacilitatorsRow(currentProjectFacilitators)}
                          </Col>
                        </Row>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col md="auto">
                    <ProjectItemGroupCard
                      projectId={currentProject.projectId}
                      guideProjectId={guideProjectId}
                      groupCount={currentProject.groupCount}
                      projectCode={currentProject.code}
                      projectName={currentProject.name}
                      notifications={currentProject.notifications}
                      showGroupsGuide={showGroupsGuide}
                      setShowGroupsGuide={setShowGroupsGuide}
                    />
                  </Col>
                  <Col md="auto">
                    <Card className={styles.card}>
                      <CardBody>
                        <Row>
                          <Col>
                            <CardText
                              className={`text-14-semibold text-gray ${styles.transformUpper}`}
                            >
                              {intl.get('LBL_PROJECTS_COUNTRY')}
                            </CardText>
                          </Col>
                          <Col xs="12">
                            <EllipsisTooltip
                              tag="h3"
                              clamp
                              data-class="overflow-wrap"
                              data-place="bottom"
                              data-for="insTooltip"
                              data-tip={countryList}
                            >
                              {countryList}
                            </EllipsisTooltip>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </BlockUi>
    </div>
  );
};

/** suggested by react-window to prevent unnecessary re-renders
 *  https://react-window.now.sh/#/api/FixedSizeList */
export default memo(ProjectListItem);

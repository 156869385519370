/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { components, MenuProps } from 'react-select';

import JobRoleOption from '../JobRoleOption';

const CustomMenu: ReactFC<MenuProps<JobRoleOption>> = (props) => {
  const { children } = props;
  return (
    <div id="select-menu-job-role-id">
      <components.Menu {...props}>{children}</components.Menu>
    </div>
  );
};

export default CustomMenu;

import Misc from 'constants/misc/Misc';

/**
 * Curried function to initialize the number formatter and format given value
 *
 * @param locale Locale that the internationalization library is initialized with
 * @param options Optional set of options for number formatter
 * @param value Number to be formatted
 * @returns {string} Formatted value
 */
const getNumberFormatter =
  (locale?: string, options?: Intl.NumberFormatOptions) =>
  (value: number): string => {
    const formatter = new Intl.NumberFormat(locale, options);
    return formatter.format(value);
  };

const getFormattedNumberWithDecimals = getNumberFormatter('en-US');

const getFormattedNumber = getNumberFormatter('en-US', {
  maximumFractionDigits: Misc.DefaultDecimalPlaces,
});

/**
 * Formats numbers into a compact format with decimals
 */
const getCompactNumber = getNumberFormatter('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
});

/**
 * Formats numbers into a compact format with fraction digits based on locale
 */
const getCompactNumberWithFractionDigits = getNumberFormatter('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
  maximumFractionDigits: Misc.DefaultDecimalPlaces,
});

/**
 * Formats numbers in the millions into a compact format wit fraction digits based on locale
 */
const getCompactNumberForMillionAbove = (value: number): string => {
  if (value > 999999) return getCompactNumberWithFractionDigits(value);
  return getFormattedNumber(value);
};

/**
 * Formats numbers into a currency string based on locale
 *
 * @param value Number to be formatted
 * @param currency Currency to be formatted into
 * @param fractionDigits Specifies if formatted string should contain fraction digits
 * @returns {string} Formatted currency string
 */
const getCurrencyString = (
  value: number,
  currency?: string,
  fractionDigits?: number
): string =>
  getNumberFormatter('en-US', {
    style: 'currency',
    currency: currency ?? 'USD',
    minimumFractionDigits: fractionDigits ?? Misc.DefaultDecimalPlaces,
  })(value);

/**
 * Round numbers to a specific decimal points (default is 0)
 *
 * NOTE: Recommended to be used to round values passed to
 * third-party libraries that don't expose a configurable
 * number formatter
 *
 * @param numberValue Number to be rounded
 * @param decimalPoints Number of decimal points to round the number into (default is 0)
 *
 * @returns {number} Rounded number
 */
const getRoundedNumber = (
  numberValue: number,
  decimalPoints = Misc.DefaultDecimalPlaces
): number => Number(numberValue.toFixed(decimalPoints));

/**
 * Get percentage with formatted value
 *
 * @param numberValue Number to get the percentage
 * @param totalValue Total value
 *
 * @returns {string} Percentage value
 */
const getPercentageWithFormat = (
  numberValue: number,
  totalValue: number
): string => {
  let percentage = '0';
  if (totalValue !== 0 && totalValue !== -1) {
    percentage = getFormattedNumber((numberValue / totalValue) * 100);
  }

  return percentage;
};

export {
  getFormattedNumber,
  getFormattedNumberWithDecimals,
  getCurrencyString,
  getCompactNumber,
  getCompactNumberWithFractionDigits,
  getCompactNumberForMillionAbove,
  getRoundedNumber,
  getPercentageWithFormat,
};

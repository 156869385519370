/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { Fragment, useEffect, useMemo, FC as ReactFC } from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import * as intl from 'react-intl-universal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import ReactTooltip from 'react-tooltip';

import { getFormattedNumber } from 'helpers/NumberFormat';
import IndeterminateCheckbox from 'modules/private/group/components/group-list/indeterminate-checkbox/IndeterminateCheckbox';
import TableConfig from 'modules/private/projects/containers/project-facilitators-view/TableConfig';
import ProjectFacilitatorViewModel from 'modules/private/projects/containers/projects-view/ProjectFacilitatorViewModel';
import DataTableFooter from 'shared/components/data-table-footer/DataTableFooter';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import ImageComponent from 'shared/components/image/ImageComponent';
import Status from 'shared/enums/Status';
import blankAvatar from 'shared/static/img/project-placeholders/blank_avatar.svg';

import DummyRow from './dummy-row-project-facilitator/DummyRowProjectFacilitator';
import ProjectFacilitatorsHeaderCell from './project-facilitators-header-cell/ProjectFacilitatorsHeaderCell';
import ProjectFacilitatorsDataTableProps from './ProjectFacilitatorsDataTableProps';
import SupervisorCell from './supervisor-cell/SupervisorCell';

const SortableColumns = TableConfig.sortCols;

const ProjectFacilitatorsDataTable: ReactFC<ProjectFacilitatorsDataTableProps> =
  (props: ProjectFacilitatorsDataTableProps) => {
    const {
      data,
      updatedData,
      editMode,
      canAddFacilitators,
      supervisors,
      supervisorsFiltered,
      supervisorsStatus,
      controlledPageCount,
      pagination,
      initialPageSize,
      initialPageIndex,
      initialSortBy,
      selectedRows,
      editStatus,
      status,
      setSelectedRows,
      onRowClick,
      onUpdateFacilitator,
      updateSortBy,
      updatePageSize,
      updatePage,
    } = props;

    /**
     * Renders the facilitator's name and image
     *
     * @param cellProps Cell props passed down from react-table
     * @returns {JSX.Element} JSX snippet containing the component
     */
    const renderFacilitatorName = (cellProps): JSX.Element => {
      const {
        original: { image },
      } = cellProps.row;
      return (
        <div className="d-flex align-items-center">
          <ImageComponent
            ignoreBlur
            className="rounded-circle object-fit-cover"
            src={image}
            alt="User"
            fallbackSrc={blankAvatar}
            width={25}
            height={25}
          />
          <EllipsisTooltip
            tag="span"
            data-place="bottom"
            data-for="insTooltip"
            data-tip={cellProps.value}
            data-class="overflow-wrap"
            className="table-content ml-3"
          >
            {cellProps.value}
          </EllipsisTooltip>
        </div>
      );
    };

    /**
     * Renders the formatted facilitator number
     *
     * @param cellProps Cell props passed down from react-table
     * @returns {string} Formatted facilitator number
     */
    const renderFacilitatorId = ({ value }): string =>
      value ? `#${String(value)}` : intl.get('LBL_NA');

    const renderAssignedGroups = ({ value }): string =>
      getFormattedNumber(value) ?? 0;

    const renderLocation = ({ value }): string => value || '—';

    /**
     * Handles disabling the column sort functionality
     *
     * @param column Name of the column
     * @param checkSelectedRows To consider selected row count
     * @param checkEditMode To consider edit mode
     * @returns {boolean}
     */
    const handleDisableSorting = (
      column: string,
      checkSelectedRows?: boolean,
      checkEditMode?: boolean
    ): boolean =>
      !SortableColumns.has(column) ||
      (checkSelectedRows ? !isEmpty(selectedRows) : false) ||
      (checkEditMode ? editMode : false) ||
      data.length === 0;

    const columns = useMemo(
      () => [
        {
          id: 'selection',
          // Make this column a groupByBoundary. This ensures that groupBy columns
          // are placed after it
          groupByBoundary: true,
          className: 'no-bg checkbox-td',
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          /* eslint-disable react/display-name */
          Cell: ({ row }): JSX.Element => (
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              disabled={!canAddFacilitators}
            />
          ),
        },
        {
          dataTitle: intl.get('LBL_PROJECT_FACILITATORS_COLUMN_NAME'),
          Header: ProjectFacilitatorsHeaderCell,
          accessor: 'name',
          className: 'text-bold truncate-long-words',
          disableSortBy: handleDisableSorting('name', false, false),
          Cell: renderFacilitatorName,
        },
        {
          dataTitle: intl.get('LBL_PROJECT_FACILITATORS_COLUMN_ID'),
          Header: ProjectFacilitatorsHeaderCell,
          accessor: 'facilitatorNumber',
          className: 'text-bold truncate-long-words text-right',
          helpText: intl.get('LBL_PROJECT_FACILITATORS_ID_COLUMN_HINT'),
          disableSortBy: handleDisableSorting(
            'facilitatorNumber',
            false,
            false
          ),
          Cell: renderFacilitatorId,
        },
        {
          dataTitle: intl.get('LBL_PROJECT_FACILITATORS_COLUMN_SUPERVISED_BY'),
          Header: ProjectFacilitatorsHeaderCell,
          accessor: (row: ProjectFacilitatorViewModel): string =>
            get(row, 'supervisor.name', intl.get('LBL_NONE')),
          id: 'supervisor',
          className: `text-bold ${
            editMode && isEmpty(selectedRows) ? '' : 'truncate-long-words'
          }`,
          helpText: intl.get(
            'LBL_PROJECT_FACILITATOR_SUPERVISED_BY_COLUMN_HINT'
          ),
          disableSortBy: handleDisableSorting('supervisor', true, true),
          Cell: SupervisorCell,
        },
        {
          dataTitle: intl.get('LBL_PROJECT_FACILITATORS_COLUMN_LOCATION'),
          Header: ProjectFacilitatorsHeaderCell,
          accessor: 'location',
          className: 'text-bold truncate-long-words',
          helpText: intl.get('LBL_PROJECT_FACILITATORS_LOCATION_COLUMN_HINT'),
          disableSortBy: handleDisableSorting('location', false, false),
          Cell: renderLocation,
        },
        {
          dataTitle: intl.get('LBL_PROJECT_FACILITATORS_COLUMN_SAVINGS_GROUPS'),
          Header: ProjectFacilitatorsHeaderCell,
          accessor: 'assignedGroupCount',
          className: 'text-bold truncate-long-words text-right ml-auto',
          helpText: intl.get(
            'LBL_PROJECT_FACILITATORS_GROUPS_ASSIGNED_COLUMN_HINT'
          ),
          disableSortBy: handleDisableSorting(
            'assignedGroupCount',
            false,
            false
          ),
          Cell: renderAssignedGroups,
        },
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [data, editMode, selectedRows, supervisorsStatus]
    );

    /**
     * Replace updated facilitators in facilitator data
     */
    const displayData = data.map((facilitator: ProjectFacilitatorViewModel) => {
      const idKeys = Object.keys(updatedData);
      if (idKeys.includes(facilitator.id)) {
        return updatedData[facilitator.id];
      }
      return facilitator;
    });

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      toggleHideColumn,
      page,
      pageCount,
      gotoPage,
      toggleAllRowsSelected,
      setPageSize,
      setSortBy,
      state: { pageIndex, selectedRowIds },
    } = useTable(
      {
        columns,
        data: displayData,
        getRowId: (row: ProjectFacilitatorViewModel) => row.id,
        initialState: {
          pageSize: initialPageSize,
          pageIndex: initialPageIndex,
          sortBy: initialSortBy,
          selectedRowIds: selectedRows,
        },
        stateReducer: (newState, action) => {
          const { type } = action;
          if (type === 'toggleAllRowsSelected') {
            return {
              ...newState,
              selectedRowIds: action.value ? selectedRows : {},
            };
          }
          return newState;
        },
        manualSortBy: true,
        manualPagination: true,
        autoResetSortBy: false,
        autoResetPage: false,
        disableMultiSort: true,
        disableSortRemove: true,
        autoResetHiddenColumns: false,
        autoResetSelectedRows: false,
        pageCount: controlledPageCount,
      },
      useSortBy,
      usePagination,
      useRowSelect
    );

    const initialSortByValue = initialSortBy[0];

    const initSortId = initialSortByValue
      ? initialSortByValue.id
      : TableConfig.defaultSort;
    const initSortDesc = initialSortByValue
      ? initialSortByValue.desc
      : TableConfig.defaultSortDesc;

    useEffect(() => {
      const newSortBy = [{ id: initSortId, desc: initSortDesc }];
      setSortBy(newSortBy);
    }, [initSortDesc, initSortId, setSortBy]);

    useEffect(() => {
      gotoPage(initialPageIndex);
      /* eslint-disable react-hooks/exhaustive-deps */
    }, [initialPageIndex]);

    useEffect(() => {
      setPageSize(initialPageSize);
      /* eslint-disable react-hooks/exhaustive-deps */
    }, [initialPageSize]);

    /**
     * This is a fix for an edge case where the backend sends an incorrect
     * pagination total (total facilitators count) and there are no rows (list items)
     * when the facilitator navigates to a new page (eg. actual row count and pageSize
     * are both 10, whereas the pagination total shows 11, and the facilitator navigates
     * to the 2nd page).
     * In cases like this, the facilitator will be navigated to the previous page.
     */
    useEffect(() => {
      const count = page.length;
      if (count === 0) {
        if (pageIndex - 1 >= 0) {
          gotoPage(pageIndex - 1);
        } else {
          gotoPage(0);
        }
      }
      /* eslint-disable react-hooks/exhaustive-deps */
    }, [page]);

    useEffect(() => {
      setSelectedRows(selectedRowIds);
    }, [selectedRowIds]);

    useEffect(() => {
      toggleHideColumn('selection', !editMode);
      if (!editMode) {
        toggleAllRowsSelected(false);
      }
    }, [editMode]);

    useEffect(() => {
      requestAnimationFrame(() => {
        ReactTooltip.rebuild();
      });
    }, [data, editMode, selectedRows, supervisorsStatus]);

    /**
     * Handle sortBy change event
     *
     * @param event Column header click event
     */
    const handleChangeSortBy = (
      event: React.MouseEvent<HTMLSpanElement>
    ): void => {
      const dataSet = event.currentTarget.dataset;
      if (dataSet.sort) {
        const { id, canSort, isSorted, isSortedDesc, sortDescFirst } =
          JSON.parse(dataSet.sort);
        if (canSort && id !== null && id !== undefined) {
          updateSortBy([
            {
              id,
              desc: isSorted ? !isSortedDesc : sortDescFirst,
            },
          ]);
        }
      }
    };

    const isEmptyError =
      status !== Status.Loading &&
      (status === Status.Error || data.length <= 0) &&
      editStatus !== Status.Error;

    /**
     * Renders a table row
     *
     * @param row Row props passed down from react-table
     * @param cell Cell props passed down from react-table
     * @returns {JSX.Element} JSX snippet containing the row component
     */
    const renderTd = (row, cell): JSX.Element => {
      const customProps =
        onRowClick && cell.column.id !== 'selection'
          ? {
              onClick: (): void => onRowClick(row),
              style: { cursor: 'default' },
            }
          : {};

      let customCellProps;
      if (cell.column.id === 'supervisor') {
        customCellProps = {
          editMode,
          selectedRows,
          supervisors,
          supervisorsFiltered,
          supervisorsStatus,
          onUpdateFacilitator,
        };
      }

      return (
        <td
          {...cell.getCellProps({
            className: cell.column.className,
            'data-title': cell.column.dataTitle,
            ...customProps,
          })}
        >
          <EllipsisTooltip
            tag="span"
            data-place="bottom"
            data-for="insTooltip"
            data-tip={cell.value}
            data-class="overflow-wrap"
            className="table-content text-14-semibold"
          >
            {cell.render('Cell', customCellProps)}
          </EllipsisTooltip>
        </td>
      );
    };

    return (
      <>
        <div className="insight-table-container pt-0">
          {status === Status.Loading ? (
            <SkeletonTheme color="#fafaf5" highlightColor="#ffffff">
              <Skeleton height={40} />
              <Skeleton height={62} count={10} />
            </SkeletonTheme>
          ) : (
            <table className="insight-table" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      if (column.id === 'selection') {
                        return (
                          <th key={column.id} {...column.getHeaderProps()} />
                        );
                      }
                      return (
                        <th
                          key={column.id}
                          {...column.getHeaderProps({
                            className:
                              column.headerClassName ?? column.className,
                          })}
                        >
                          <span
                            data-sort={JSON.stringify({
                              id: column.id,
                              canSort: column.canSort,
                              isSorted: column.isSorted,
                              isSortedDesc: column.isSortedDesc,
                              sortDescFirst: column.sortDescFirst,
                            })}
                            className="table-label text-12-bold text-uppercase"
                            {...column.getSortByToggleProps({
                              title: '',
                              onClick: handleChangeSortBy,
                            })}
                          >
                            {column.render('Header', { meta: pagination })}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length > 0 ? (
                  page.map((row) => {
                    prepareRow(row);
                    const className = row.isSelected ? 'row-selected' : '';
                    return (
                      <tr
                        key={row.id}
                        {...row.getRowProps()}
                        className={className}
                      >
                        {row.cells.map((cell, index: number) => (
                          <Fragment key={index}>{renderTd(row, cell)}</Fragment>
                        ))}
                      </tr>
                    );
                  })
                ) : (
                  <DummyRow
                    headers={headerGroups}
                    isError={status === Status.Error}
                  />
                )}
              </tbody>
            </table>
          )}
          {isEmptyError && (
            <div className="no-data-message">
              <div className="title">{intl.get('ERR_CHART_EMPTY_TITLE')}</div>
              <div>{intl.get('ERR_GROUPS_EMPTY_MESSAGE')}</div>
            </div>
          )}
          <DataTableFooter
            pageSizeDisabled={isEmptyError}
            loading={status === Status.Loading}
            totalResults={pagination.total}
            gotoPage={updatePage}
            pageSize={initialPageSize}
            setPageSize={updatePageSize}
            pageCount={pageCount}
            pageIndex={initialPageIndex}
          />
        </div>
      </>
    );
  };

export default ProjectFacilitatorsDataTable;

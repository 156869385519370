import moment, { Moment, unitOfTime } from 'moment';

import DateFormatType from 'shared/enums/DateFormatType';

const getCurrentDateTime = (): Moment => moment();

/**
 * Formats date according to supplied format
 *
 * @param date Date object to be formatted
 * @param fmt Date format string
 * @returns {string} Formatted date string
 */
const formatDate = (
  date: moment.MomentInput,
  fmt: DateFormatType = DateFormatType.Default
): string => moment(date).format(fmt as string);

/**
 * Formats date according to supplied format
 *
 * @param date Date object to be formatted
 * @param preFormat Format of the input date object
 * @param fmt Date format string
 * @returns {string} Formatted date string
 */
const formatNonStandardDateTimeString = (
  date: moment.MomentInput,
  preFormat: DateFormatType,
  fmt: DateFormatType = DateFormatType.Default,
  convertLocal = false
): string => {
  if (convertLocal) {
    const preFormattedUTC = moment(date, preFormat).format(DateFormatType.Iso);
    return formatUTCDateToLocal(preFormattedUTC, fmt);
  }
  return moment(date, preFormat).format(fmt as string);
};

/**
 * Convert UTC date to local and format according to supplied format
 *
 * @param date Date object to be formatted
 * @param fmt Date format string
 * @returns {string} Formatted date string
 */
const formatUTCDateToLocal = (
  utcDate: moment.MomentInput,
  fmt: DateFormatType = DateFormatType.Default
): string =>
  moment
    .utc(utcDate)
    .local()
    .format(fmt as string);

/**
 * Get difference in date range for given unit of time
 *
 * @param min Start date of date range
 * @param max End date of date range
 * @param unit Unit of difference
 * @returns {number} Difference between date range
 */
const getDateDiff = (
  min: moment.MomentInput,
  max: moment.MomentInput,
  unit?: unitOfTime.Diff
): number => {
  const maxMoment = moment(max);
  const minMoment = moment(min);
  return maxMoment.diff(minMoment, unit);
};

/**
 * Adds a gap of time to the given date
 *
 * @param date Date string/object
 * @param gap Gap of time to be added
 * @param unit Unit of difference
 * @returns {moment.Moment} Moment object
 */
const addTime = (
  date: moment.MomentInput,
  gap: number,
  unit: unitOfTime.DurationConstructor
): moment.Moment => {
  const momentDate = moment(date);
  return momentDate.add(gap, unit);
};

/**
 * Get list of months in date range
 *
 * @param minDate Start date of date range
 * @param maxDate End date of date range
 * @returns {Date[]} Date array of months in given range
 */
const getMonthsList = (minDate: Moment, maxDate: Moment): Date[] => {
  const months: Date[] = [];
  while (maxDate.diff(minDate, 'months') >= 0) {
    months.push(minDate.toDate());
    minDate.add(1, 'month');
  }
  return months;
};

/**
 * Get list of quarters in date range
 *
 * @param minDate Start date of date range
 * @param maxDate End date of date range
 * @returns {Date[]} Date array of quarters in given range
 */
const getQuartersList = (minDate: Moment, maxDate: Moment): Date[] => {
  const quarters: Date[] = [];
  while (maxDate.diff(minDate, 'quarters') >= 0) {
    quarters.push(minDate.toDate());
    minDate.add(1, 'quarter');
  }
  return quarters;
};

/**
 * Get list of years in date range
 *
 * @param minDate Start date of date range
 * @param maxDate End date of date range
 * @returns {Date[]} Date array of years in given range
 */
const getYearsList = (minDate: Moment, maxDate: Moment): Date[] => {
  const years: Date[] = [];
  while (maxDate.diff(minDate, 'years') >= 0) {
    years.push(minDate.toDate());
    minDate.add(1, 'year');
  }
  return years;
};

export default {};
export {
  getCurrentDateTime,
  getDateDiff,
  addTime,
  formatDate,
  formatNonStandardDateTimeString,
  formatUTCDateToLocal,
  getMonthsList,
  getQuartersList,
  getYearsList,
};

import { v4 as uuid } from 'uuid';

class ProjectUsersFormValue {
  id: string;

  jobRole: string;

  isImplementingPartner: boolean;

  permission: string;

  countryDataAccess: string[];

  supervisorId: string;

  email: string;

  constructor(defaultCountries?: string[]) {
    this.id = uuid();
    this.email = '';
    this.isImplementingPartner = false;
    this.jobRole = '';
    this.supervisorId = '';
    this.permission = '';
    this.countryDataAccess = defaultCountries ?? [];
  }
}

export default ProjectUsersFormValue;

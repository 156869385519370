/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState, FC as ReactFC } from 'react';

import { OptionProps, components } from 'react-select';

import TIMEOUTS from 'constants/Timeouts';

import JobRoleOption from '../JobRoleOption';
import styles from './customOption.module.scss';

const { Option } = components;
type CustomOptionProps = OptionProps<JobRoleOption>;

const CustomOption: ReactFC<CustomOptionProps> = (props: CustomOptionProps) => {
  const { data, innerProps, innerRef } = props;
  const [id, setId] = useState<string | null>(null);
  const [doFocus, setDoFocus] = useState<boolean>(false);
  useEffect(() => {
    if (data.focused === true && id !== data.value) {
      setId(data.value);
      setDoFocus(true);
      setTimeout(() => {
        setDoFocus(false);
      }, TIMEOUTS.CUSTOM_OPTION_TIMEOUT);
    }
  }, [data.focused, data.value, id]);
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={doFocus ? `${styles.blinking}` : ''}
    >
      <Option {...props} />
    </div>
  );
};

export default CustomOption;

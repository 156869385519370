import { AxiosResponse, CancelTokenSource } from 'axios';

import ApiBase from 'api/common/ApiBase';
import ApiError from 'api/common/ApiError';
import Axios from 'api/common/Axios';
import { AuthApiTags } from 'constants/request-tags/RequestTags';

import AccountBody from './create-account/AccountBody';
import CheckPartnerIdResponse from './create-account/CheckPartnerIdResponse';
import CreateAccountResponse from './create-account/CreateAccountResponse';
import ResetPasswordResponse from './create-account/ResetPasswordResponse';
import LoginResponse from './LoginResponse';
import RefreshResponse from './RefreshResponse';
import CreateProfileRequest from './user/CreateProfileRequest';
import GettingStartedState from './user/GettingStartedState';
import PresignedUploadUrlResponse, {
  RequiredHeaders,
} from './user/PresignedUploadUrlResponse';
import User from './user/User';
import UserInfo from './user/UserInfo';
import UserPermissions from './user/UserPermissions';

class AuthApi extends ApiBase {
  async LoginAsync(
    email: string,
    password: string,
    rememberMe: boolean
  ): Promise<LoginResponse> {
    const body = {
      email,
      password,
      rememberMe,
    };
    const loginResponse = await this.TokenAsync({
      body,
      tag: AuthApiTags.LoginAsync,
    });

    return loginResponse;
  }

  async LogoutAsync(): Promise<void> {
    const action = 'auth/logout';
    await this.PostAsync({ action, tag: AuthApiTags.LogoutAsync });
  }

  async RefreshAsync(): Promise<RefreshResponse> {
    const response = await this.RefreshTokenAsync<RefreshResponse>({
      tag: AuthApiTags.RefreshAsync,
    });
    return response;
  }

  /* account-creation */
  async VerifyNewUser(
    code: string,
    email: string,
    cancelToken: CancelTokenSource
  ): Promise<User> {
    const action = 'user/verify';
    const body = {
      email,
      code,
    };

    const userData = this.PostAsync<User>({
      action,
      anonymous: true,
      includeAuthToken: false,
      body,
      tag: AuthApiTags.VerifyNewUser,
      cancelSource: cancelToken,
    });
    return userData;
  }

  async VerifyEmailExistence(
    email: string,
    cancelToken: CancelTokenSource
  ): Promise<boolean> {
    const action = `user/email-exists/${email}`;
    const response = await this.GetAsync<{ exists: boolean }>({
      action,
      anonymous: false,
      includeAuthToken: false,
      tag: AuthApiTags.VerifyEmailExistence,
      cancelSource: cancelToken,
    });
    return response.exists;
  }

  async CheckPartnerIdExists(
    partnerId: string,
    cancelToken: CancelTokenSource
  ): Promise<CheckPartnerIdResponse> {
    const path = `account/partner-id-exists/${partnerId}`;

    const action = `${path}`;
    const partnerIdExistsResponse = this.GetAsync<CheckPartnerIdResponse>({
      action,
      anonymous: false,
      includeAuthToken: false,
      tag: AuthApiTags.CheckPartnerIdExists,
      cancelSource: cancelToken,
    });

    return partnerIdExistsResponse;
  }

  async CreateAccount(
    values: AccountBody,
    cancelToken: CancelTokenSource
  ): Promise<CreateAccountResponse> {
    const path = `account`;

    const accountResponse = this.PostAsync<CreateAccountResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: false,
      tag: AuthApiTags.CreateAccount,
      cancelSource: cancelToken,
      body: values,
    });

    return accountResponse;
  }

  async ResendEmail(
    email: string,
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const encodedEmail = encodeURIComponent(email);

    const path = `user/resend-verification/${encodedEmail}`;

    const resendEmailResponse = this.PostAsync<Record<string, unknown>>({
      action: path,
      anonymous: false,
      includeAuthToken: false,
      tag: AuthApiTags.ResendEmail,
      cancelSource: cancelToken,
    });

    return resendEmailResponse;
  }

  async GetUserPermissions(): Promise<UserPermissions> {
    const path = `user/permissions`;

    const userPermissions = this.GetAsync<UserPermissions>({
      action: path,
      tag: AuthApiTags.GetUserPermissions,
    });

    return userPermissions;
  }

  async GetGettingStartedState(): Promise<GettingStartedState> {
    const path = `user/get-started-status`;

    const gettingStartedStateResponse = this.GetAsync<GettingStartedState>({
      action: path,
      tag: AuthApiTags.GetGettingStartedState,
    });

    return gettingStartedStateResponse;
  }

  async GetUserInfo(): Promise<UserInfo> {
    const path = `user/info`;

    const userInfoResponse = this.GetAsync<UserInfo>({
      action: path,
      tag: AuthApiTags.GetUserInfo,
    });

    return userInfoResponse;
  }

  async SendResetPasswordEmail(
    email: string,
    cancelToken: CancelTokenSource
  ): Promise<ResetPasswordResponse> {
    const encodedEmail = encodeURIComponent(email);

    const path = `user/reset-password/${encodedEmail}`;

    const resetPasswordEmailResponse = this.PostAsync<ResetPasswordResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: false,
      tag: AuthApiTags.SendResetPasswordEmail,
      cancelSource: cancelToken,
    });

    return resetPasswordEmailResponse;
  }

  async VerifyUserForPasswordReset(
    code: string,
    email: string,
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const action = 'user/reset-password/status';

    const body = {
      email,
      code,
    };

    const verifyResetPasswordResponse = this.PostAsync<Record<string, unknown>>(
      {
        action,
        anonymous: true,
        includeAuthToken: false,
        body,
        tag: AuthApiTags.VerifyUserForPasswordReset,
        cancelSource: cancelToken,
      }
    );

    return verifyResetPasswordResponse;
  }

  async ResetPassword(
    code: string,
    email: string,
    password: string,
    cancelToken: CancelTokenSource
  ): Promise<User> {
    const action = 'user/reset-password';

    const body = {
      email,
      password,
      code,
    };

    const resetPasswordResponse = this.PostAsync<User>({
      action,
      anonymous: false,
      includeAuthToken: false,
      body,
      tag: AuthApiTags.ResetPassword,
      cancelSource: cancelToken,
    });

    return resetPasswordResponse;
  }

  async CreateProfile(
    profile: CreateProfileRequest,
    cancelToken: CancelTokenSource
  ): Promise<User> {
    const path = 'user/profile';

    const createProfileResponse = this.PostAsync<User>({
      action: path,
      anonymous: false,
      includeAuthToken: false,
      body: profile,
      tag: AuthApiTags.CreateProfile,
      cancelSource: cancelToken,
    });

    return createProfileResponse;
  }

  async GetImageUploadUrl(
    fileName: string,
    email: string,
    code: string,
    cancelToken: CancelTokenSource
  ): Promise<PresignedUploadUrlResponse> {
    const path = `user/profile-image/upload-url`;

    const body = {
      fileName,
      email,
      code,
    };

    const uploadUrlResponse = this.PostAsync<PresignedUploadUrlResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: false,
      body,
      tag: AuthApiTags.GetImageUploadUrl,
      cancelSource: cancelToken,
    });

    return uploadUrlResponse;
  }

  UploadProfileImage = async (
    url: string,
    requiredHeaders: RequiredHeaders,
    file: Blob,
    cancelToken: CancelTokenSource
  ): Promise<AxiosResponse> => {
    const headers = {
      ...requiredHeaders,
      'Content-Type': 'application/octet-stream',
    };

    const uploadUrlResponse = await Axios({
      url,
      method: 'PUT',
      headers,
      data: file,
      cancelToken: cancelToken.token,
    });

    const { status, statusText } = uploadUrlResponse;
    return new Promise((resolve, reject) => {
      if (uploadUrlResponse.status === 200) {
        resolve(uploadUrlResponse);
      } else {
        const error = new ApiError(
          `Request failed with status ${status}`,
          status,
          statusText
        );
        reject(error);
      }
    });
  };

  async GetInviteStatus(
    code: string,
    email: string,
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const encodedEmail = encodeURIComponent(email);
    const path = `user/invite-status?code=${code}&email=${encodedEmail}`;

    const inviteStatusResponse = this.GetAsync<Record<string, unknown>>({
      action: path,
      anonymous: false,
      includeAuthToken: false,
      tag: AuthApiTags.GetInviteStatus,
      cancelSource: cancelToken,
    });

    return inviteStatusResponse;
  }

  async ResendProfileInvite(
    email: string,
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const encodedEmail = encodeURIComponent(email);
    const path = `user/resend-invite/${encodedEmail}`;
    // const response = this.PostAsync<object>(path, false, false, {});

    const response = this.PostAsync<Record<string, unknown>>({
      action: path,
      anonymous: false,
      includeAuthToken: false,
      body: {},
      tag: AuthApiTags.ResendProfileInvite,
      cancelSource: cancelToken,
    });

    return response;
  }
}

const AuthApiInstance = new AuthApi();

export default AuthApiInstance;

const Constraints = {
  MaxFieldLength: 100,
  MaxFieldLengthTextArea: 500,
  MaxJobRoleLength: 100,
  MaxFormItemsInviteUsers: 10,
  MaxImageSize: 5000000,
  MaxImageSizeMb: 5,
  MaxImageWidth: 300,
  MaxImageHeight: 300,
  SupportedImageFormats: ['image/jpg', 'image/jpeg', 'image/png'],
  LazyLoadingPageSize: 3,
  LazyLoadingPageSizeExtra: 10,
  MaxMessageTitleLength: 40,
  MaxMessageBodyLength: 300,
  MaxFacilitatorsInMetric: 12,
  MaxSupervisorsInMetric: 10,
};

Constraints.MaxImageSizeMb = Constraints.MaxImageSize / 1000000;
export default Constraints;

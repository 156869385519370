import { FC as ReactFC } from 'react';

import styles from './groupFilterTag.module.scss';
import GroupFilterTagProps from './GroupFilterTagProps';

const GroupFilterTag: ReactFC<GroupFilterTagProps> = (
  props: GroupFilterTagProps
) => {
  const { groupFilter } = props;
  const { count, name, value } = groupFilter;

  return (
    <div className={`truncate ${styles.groupTag}`}>
      <span>{`${name},`}</span>&nbsp;
      <span className="truncate">{`${value}`}</span>&nbsp;
      <span>{`(${count})`}</span>
    </div>
  );
};

export default GroupFilterTag;

/* eslint-disable react/jsx-props-no-spreading */
import { Component, ReactNode, createRef } from 'react';

import Select, { ActionMeta, Props, ValueType } from 'react-select';

import CustomMenuList from './custom-menu-list/CustomMenuList';
import CustomMenu from './custom-menu/CustomMenu';
import CustomOption from './custom-option/CustomOption';
import JobRoleOption from './JobRoleOption';

type JobRoleSelectState = {
  isFocused: boolean;
  inputValue: string;
};

class JobRoleSelect extends Component<
  Props<JobRoleOption>,
  JobRoleSelectState
> {
  constructor(props: Props<JobRoleOption> | Readonly<Props<JobRoleOption>>) {
    super(props);

    this.containerRef = createRef();
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.state = { isFocused: false, inputValue: '' };
  }

  componentDidMount(): void {
    document.addEventListener('mousedown', this.onDocumentClick);
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.onDocumentClick);
  }

  /**
   * Sets input as focused to local state
   */
  inputFocus = (): void => this.setState({ isFocused: true });

  containerRef;

  /**
   * Handles click event on the job role select dropdown
   *
   * @param event The click event object
   */
  onDocumentClick = (event): void => {
    const menu = document.getElementById('select-menu-job-role-id');

    if (
      !this.containerRef.current.contains(event.target) ||
      !menu ||
      !menu.contains(event.target)
    ) {
      this.setState({ isFocused: false, inputValue: '' });
      this.props.clearJobRoleError();
    }
  };

  /**
   * Handles selected option change event on the job role select dropdown
   */
  onChange = (
    value: ValueType<JobRoleOption>,
    actionMeta: ActionMeta<JobRoleOption>
  ): void => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value, actionMeta);
    }
    this.setState({ isFocused: false });
  };

  /**
   * Handles input change event on the job role select dropdown
   *
   * @param val Input string for new job role
   */
  onInputChange = (val: string): void => this.setState({ inputValue: val });

  /**
   * Renders the job role select dropdown
   *
   * @returns {ReactNode} JSX snippet containing the custom job role select dropdown
   */
  render(): ReactNode {
    const { isFocused, inputValue } = this.state;
    const { props } = this;
    const { isInlineEdit } = props;
    const currentProps = {
      isFocused: isFocused || undefined,
      menuIsOpen: isFocused || undefined,
      menuPortalTarget: isInlineEdit ? this.containerRef.current : undefined,
    };

    return (
      <div ref={this.containerRef}>
        <Select
          {...props}
          components={{
            MenuList: CustomMenuList,
            Menu: CustomMenu,
            Option: CustomOption,
          }}
          inputValue={inputValue}
          onMenuInputFocus={this.inputFocus}
          onChange={this.onChange}
          onInputChange={this.onInputChange}
          backspaceRemovesValue={false}
          {...currentProps}
        />
      </div>
    );
  }
}

export default JobRoleSelect;

const AuthApiTags = {
  LoginAsync: 'LoginAsync',
  LogoutAsync: 'LogoutAsync',
  RefreshAsync: 'RefreshAsync',
  VerifyNewUser: 'VerifyNewUser',
  VerifyEmailExistence: 'VerifyEmailExistence',
  CheckPartnerIdExists: 'CheckPartnerIdExists',
  CreateAccount: 'CreateAccount',
  ResendEmail: 'ResendEmail',
  GetUserPermissions: 'GetUserPermissions',
  GetGettingStartedState: 'GetGettingStartedState',
  GetUserInfo: 'GetUserInfo',
  SendResetPasswordEmail: 'SendResetPasswordEmail',
  VerifyUserForPasswordReset: 'VerifyUserForPasswordReset',
  ResetPassword: 'ResetPassword',
  CreateProfile: 'CreateProfile',
  GetImageUploadUrl: 'GetImageUploadUrl',
  UploadProfileImage: 'UploadProfileImage',
  GetInviteStatus: 'GetInviteStatus',
  ResendProfileInvite: 'ResendProfileInvite',
};

const DashBoardApiTags = {
  GetDashboardExcel: 'GetDashboardExcel',
  GetWidgetData: 'GetWidgetData',
  GetFinancialMetricData: 'GetFinancialMetricData',
  GetMembershipMetricData: 'GetMembershipMetricData',
  GetGroupMetricsData: 'GetGroupMetricsData',
  SetWidgetPreferences: 'SetWidgetPreferences',
  GetGettingStartedGuide: 'GetGettingStartedGuide',
  SetGettingStartedStatus: 'SetGettingStartedStatus',
  GetBackupStatusLastRefreshed: 'GetBackupStatusLastRefreshed',
  GetFacilitatorMetricData: 'GetFacilitatorMetricData',
  GetFacilitators: 'GetFacilitators',
};

const FinanceApiTags = {
  GetKeyMetrics: 'GetKeyMetrics',
  GetFinancesChartData: 'GetFinancesChartData',
  GetFinancialMetricData: 'GetFinancialMetricData',
};

const GroupApiTags = {
  GetGroupsExcel: 'GetGroupsExcel',
  GetGroupListData: 'GetGroupListData',
  GetGroupsAllIds: 'GetGroupsAllIds',
  SetTablePreferences: 'SetTablePreferences',
  GetBackupStatusSummaryData: 'GetBackupStatusSummaryData',
  GetGroupMetricData: 'GetGroupMetricData',
  GetGroupMapData: 'GetGroupMapData',
  GetGroupDetailsExcel: 'GetGroupDetailsExcel',
  GetGroupDetails: 'GetGroupDetails',
  GetWidgetData: 'GetWidgetData',
  GetGroupNotifications: 'GetGroupNotifications',
  GetGroupOverviewStats: 'GetGroupOverviewStats',
  GetGroupFinancialMetrics: 'GetGroupFinancialMetrics',
  GetGroupDetailsBalanceSheet: 'GetGroupDetailsBalanceSheet',
  AssignGroupStatusToGroups: 'AssignGroupStatusToGroups',
  AssignFacilitatorsToProject: 'AssignFacilitatorsToProject',
  SetWidgetPreferences: 'SetWidgetPreferences',
};

const MessageApiTags = {
  CreateMessage: 'CreateMessage',
  GetMessageListData: 'GetMessageListData',
  GetMessage: 'GetMessage',
  GetCountries: 'GetCountries',
  GetProjectsForCountry: 'GetProjectsForCountry',
  GetFacilitatorsForProjects: 'GetFacilitatorsForProjects',
  PostGroupFilters: 'PostGroupFilters',
  GetMessagingExcel: 'GetMessagingExcel',
  GetMessageProcessedStatus: 'GetMessageProcessedStatus',
  CheckGroupStatus: 'CheckGroupStatus',
};

const ProjectsApiTags = {
  GetNewProjectId: 'GetNewProjectId',
  GetProjectsListData: 'GetProjectsListData',
  GetProject: 'GetProject',
  CreateProject: 'CreateProject',
  UpdateProject: 'UpdateProject',
  DeleteProject: 'DeleteProject',
  GetProjectUsers: 'GetProjectUsers',
  GetProjectFacilitators: 'GetProjectFacilitators',
  GetAllProjects: 'GetAllProjects',
  GetAllProjectUsers: 'GetAllProjectUsers',
  AddProjectUsers: 'AddProjectUsers',
  AddProjectFacilitators: 'AddProjectFacilitators',
  GetUserProjectStats: 'GetUserProjectStats',
  GetProjectOrgCountries: 'GetProjectOrgCountries',
  GetUserProjectCompatibility: 'GetUserProjectCompatibility',
  GetFacilitatorImageUploadUrl: 'GetFacilitatorImageUploadUrl',
  AssignGroupsToProject: 'AssignGroupsToProject',
  UploadFacilitatorImage: 'UploadFacilitatorImage',
  GetProjectUsersExcel: 'GetProjectUsersExcel',
  GetProjectFacilitatorsExcel: 'GetProjectFacilitatorsExcel',
  RemoveProjectUsers: 'RemoveProjectUsers',
  RemoveProjectFacilitators: 'RemoveProjectFacilitators',
};

const SettingsApiTags = {
  GetJobRoles: 'GetJobRoles',
  CreateJobRole: 'CreateJobRole',
  CreatePermissionLevel: 'CreatePermissionLevel',
  UpdatePermissionLevel: 'UpdatePermissionLevel',
  GetPermissionLevels: 'GetPermissionLevels',
  GetDefaultClaims: 'GetDefaultClaims',
  GetOrganizationCountries: 'GetOrganizationCountries',
  CreateUsersByBulk: 'CreateUsersByBulk',
  UpdateUsersByBulk: 'UpdateUsersByBulk',
  UpdateFacilitatorsByBulk: 'UpdateFacilitatorsByBulk',
  CreateFacilitatorsByBulk: 'CreateFacilitatorsByBulk',
  GetOrganizationUsers: 'GetOrganizationUsers',
  LookupOrganizationUsers: 'LookupOrganizationUsers',
  LookupOrganizationFacilitators: 'LookupOrganizationFacilitators',
  GetOrganizationGroupStatuses: 'GetOrganizationGroupStatuses',
  ResendInvite: 'ResendInvite',
  GetOrganizationInfo: 'GetOrganizationInfo',
};

const SurveysApiTags = {
  GetDraftedSurveysData: 'GetDraftedSurveysData',
  FetchLatestSurveys: 'FetchLatestSurveys',
  GetSurveysStatsData: 'GetSurveysStatsData',
  GetCountries: 'GetCountries',
  GetProjectsForCountry: 'GetProjectsForCountry',
  GetFacilitatorsForProjects: 'GetFacilitatorsForProjects',
  GetSurveyRecipients: 'GetSurveyRecipients',
  FetchRecipientsStatus: 'FetchRecipientsStatus',
  SendSurvey: 'SendSurvey',
  GetSurveyHistoryList: 'GetSurveyHistoryList',
  GetSurveyProcessedStatus: 'GetSurveyProcessedStatus',
  GetCollectIdToggleStatus: 'GetCollectIdToggleStatus',
  UpdateCollectIdToggleStatus: 'UpdateCollectIdToggleStatus',
  GetSurveyCorrelationExcel: 'GetSurveyCorrelationExcel',
};

export {
  AuthApiTags,
  DashBoardApiTags,
  FinanceApiTags,
  GroupApiTags,
  MessageApiTags,
  ProjectsApiTags,
  SettingsApiTags,
  SurveysApiTags,
};

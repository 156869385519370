import { FC as ReactFC } from 'react';

import orderBy from 'lodash/orderBy';

import { getColorFor } from 'helpers/ColorHelper';
import WidgetViewModel from 'modules/private/dashboard/components/widget-metrics/widgets/widget/WidgetViewModel';
import Donut from 'shared/components/ins-charts/donut/Donut';
import InsChartValue from 'shared/components/ins-charts/InsChartValue';

const EducationWidget: ReactFC<{ data: WidgetViewModel }> = (props) => {
  const maxItemCount = 4;
  const dimensionSettings = {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    aspectRatioWidth: 1,
    aspectRatioHeight: 0.6,
  };

  const { data } = props;
  const { values } = data;

  const noFormalEducationData = values?.find(
    (value) => value.group === 'NO_FORMAL_EDUCATION'
  );
  const primarySchoolData = values?.find(
    (value) => value.group === 'PRIMARY_SCHOOL'
  );
  const secondarySchoolData = values?.find(
    (value) => value.group === 'SECONDARY_SCHOOL'
  );
  const collageEducationData = values?.find(
    (value) => value.group === 'COLLEGE'
  );

  const donutValues = Array<InsChartValue>();

  if (
    noFormalEducationData &&
    secondarySchoolData &&
    primarySchoolData &&
    collageEducationData
  ) {
    donutValues.push({
      name: noFormalEducationData.title,
      count: noFormalEducationData.count,
      percentage: noFormalEducationData.percentage,
      group: noFormalEducationData.group,
    });
    donutValues.push({
      name: secondarySchoolData.title,
      count: secondarySchoolData.count,
      percentage: secondarySchoolData.percentage,
      group: secondarySchoolData.group,
    });
    donutValues.push({
      name: primarySchoolData.title,
      count: primarySchoolData.count,
      percentage: primarySchoolData.percentage,
      group: primarySchoolData.group,
    });
    donutValues.push({
      name: collageEducationData.title,
      count: collageEducationData.count,
      percentage: collageEducationData.percentage,
      group: collageEducationData.group,
    });
  }

  return (
    <div className="education-widget">
      <div className="chart-container">
        <Donut
          isLoading={false}
          values={donutValues}
          dimension={dimensionSettings}
        />
      </div>
      {donutValues.length === maxItemCount && (
        <div className="chart-legend">
          <div className="chart-legend-inner">
            {orderBy(donutValues, 'percentage', 'desc').map((value) => (
              <div key={value.name} className="item">
                <div className="item-col color-container">
                  <span
                    className="color"
                    style={{
                      backgroundColor: getColorFor(value.group),
                    }}
                  />
                </div>
                <div className="item-col label">
                  <span className="value">{value.percentage}%</span>
                </div>
                <div className="item-col label">
                  &nbsp;-&nbsp;
                  <span className="name">{value.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default EducationWidget;

import { CancelTokenSource } from 'axios';
import queryString from 'query-string';

import ApiBase from 'api/common/ApiBase';
import ListResponse from 'api/common/ListResponse';
import SuccessResponse from 'api/common/SuccessResponse';
import WidgetPreference from 'api/dashboard/widget/WidgetPreference';
import Facilitator from 'api/messaging/group-filters/Facilitator';
import { DashBoardApiTags } from 'constants/request-tags/RequestTags';
import FacilitatorMetricsFilters from 'modules/private/dashboard/components/facilitator-metrics/FacilitatorMetricsFilter';
import FinancialMetricsFilter from 'modules/private/dashboard/components/financial-metrics/FinancialMetricsFilter';
import MembershipMetricsFilter from 'modules/private/dashboard/components/membership-metrics/MembershipMetricsFilter';
import GlobalFilters from 'shared/components/header-toolbar/GlobalFilters';
import CyclePhase from 'shared/enums/CyclePhase';

import FacilitatorMetric from './facilitator/FacilitatorMetric';
import FinancialMetric from './financial/FinancialMetric';
import GroupMetric from './group/GroupMetric';
import LastRefreshedResponse from './LastRefreshedResponse';
import MembershipMetric from './membership/MembershipMetric';
import Widget from './widget/Widget';

class DashboardApi extends ApiBase {
  private static parseGlobalFilters(f: GlobalFilters): string {
    const defaultFilters = `backupStatus=${f.backupStatus}&groupAge=${f.groupAge}`;

    let dateFilters = '';

    if (f.fromDate && f.toDate) {
      dateFilters = `&dateFrom=${f.fromDate}&dateTo=${f.toDate}`;
    }

    let moreFilters = '';
    if (f.groupStatuses && f.groupStatuses.length > 0) {
      const groupStatusIds = f.groupStatuses;
      moreFilters = `&${queryString.stringify({
        groupStatus: groupStatusIds,
      })}`;
    }

    let projectFilters = '';
    if (f.projects && f.projects.length > 0) {
      const projectCodes = f.projects;
      projectFilters = `&${queryString.stringify({ projects: projectCodes })}`;
    }
    return `${defaultFilters}${dateFilters}${projectFilters}${moreFilters}`;
  }

  private static parseFinancialFilters(f: FinancialMetricsFilter): string {
    return `cyclePhase=${String(f.cyclePhase.value)}&frequency=${String(
      f.frequency.value
    )}&dateComparison=${String(f.timeUnit.value)}`;
  }

  private static parseMembershipFilters(f: MembershipMetricsFilter): string {
    return `frequency=${String(f.frequency.value)}&dateComparison=${String(
      f.timeUnit.value
    )}`;
  }

  private static parseFacilitatorFilters(f: FacilitatorMetricsFilters): string {
    const defaultFilters = `frequency=${String(f.frequency.value)}`;

    let facilitatorFilter = '';

    if (f.facilitators.length) {
      facilitatorFilter = `&${queryString.stringify({
        facilitators: f.facilitators,
      })}`;
    }

    return `${defaultFilters}${facilitatorFilter}`;
  }

  async GetDashboardExcel(
    globalFilters: GlobalFilters,
    reportTime: string,
    cancelToken: CancelTokenSource
  ): Promise<Blob> {
    const path = 'dashboard/excel-export';
    const action = `${path}?${DashboardApi.parseGlobalFilters(
      globalFilters
    )}&reportTime=${reportTime}`;

    const excelData = this.GetAsyncBlob({
      action,
      tag: DashBoardApiTags.GetDashboardExcel,
      cancelSource: cancelToken,
    });

    return excelData;
  }

  async GetWidgetData(
    filters: GlobalFilters,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<Widget>> {
    const path = 'dashboard/widgets';

    const action = `${path}?${DashboardApi.parseGlobalFilters(filters)}`;
    const widgetData = this.GetAsync<ListResponse<Widget>>({
      action,
      tag: DashBoardApiTags.GetWidgetData,
      cancelSource: cancelToken,
    });

    return widgetData;
  }

  async GetFinancialMetricData(
    globalFilters: GlobalFilters,
    filters: FinancialMetricsFilter,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<FinancialMetric>> {
    const path = 'dashboard/metrics/financials';

    const globalFilterQuery = DashboardApi.parseGlobalFilters(globalFilters);
    const financialFilterQuery = DashboardApi.parseFinancialFilters(filters);

    const action = `${path}?${globalFilterQuery}&${financialFilterQuery}`;
    const financialMetricsData = this.GetAsync<ListResponse<FinancialMetric>>({
      action,
      tag: DashBoardApiTags.GetFinancialMetricData,
      cancelSource: cancelToken,
    });

    return financialMetricsData;
  }

  async GetMembershipMetricData(
    globalFilters: GlobalFilters,
    filters: MembershipMetricsFilter,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<MembershipMetric>> {
    const path = 'dashboard/metrics/membership';

    const globalFilterQuery = DashboardApi.parseGlobalFilters(globalFilters);
    const membershipFilterQuery = DashboardApi.parseMembershipFilters(filters);

    const action = `${path}?${globalFilterQuery}&${membershipFilterQuery}`;
    const membershipMetricsData = this.GetAsync<ListResponse<MembershipMetric>>(
      {
        action,
        tag: DashBoardApiTags.GetMembershipMetricData,
        cancelSource: cancelToken,
      }
    );

    return membershipMetricsData;
  }

  async GetGroupMetricsData(
    filters: GlobalFilters,
    cyclePhase: CyclePhase,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<GroupMetric>> {
    const path = 'dashboard/metrics/groups';

    const cyclePhaseFilter = `&cyclePhase=${cyclePhase}`;

    const action = `${path}?${cyclePhaseFilter}&${DashboardApi.parseGlobalFilters(
      filters
    )}`;
    const groupMetricsData = this.GetAsync<ListResponse<GroupMetric>>({
      action,
      tag: DashBoardApiTags.GetGroupMetricsData,
      cancelSource: cancelToken,
    });

    return groupMetricsData;
  }

  async SetWidgetPreferences(
    preferences: WidgetPreference[],
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const action = 'user/preferences/dashboard/widgets';
    const result = await this.PutAsync<SuccessResponse>({
      action,
      anonymous: false,
      includeAuthToken: true,
      body: preferences,
      tag: DashBoardApiTags.SetWidgetPreferences,
      cancelSource: cancelToken,
    });

    return result;
  }

  async GetGettingStartedGuide(
    haveGroups: boolean,
    cancelToken?: CancelTokenSource
  ): Promise<string> {
    const action = `organizations/download-guide?option=${haveGroups ? 1 : 2}`;
    const response = await this.GetAsync<{ link: string }>({
      action,
      anonymous: false,
      includeAuthToken: true,
      tag: DashBoardApiTags.GetGettingStartedGuide,
      cancelSource: cancelToken,
    });
    return response.link;
  }

  async SetGettingStartedStatus(
    cancelToken?: CancelTokenSource
  ): Promise<SuccessResponse> {
    const action = 'user/get-started-status/complete';
    const response = await this.PutAsync<SuccessResponse>({
      action,
      anonymous: false,
      includeAuthToken: true,
      tag: DashBoardApiTags.SetGettingStartedStatus,
      cancelSource: cancelToken,
    });
    return response;
  }

  async GetBackupStatusLastRefreshed(
    cancelToken?: CancelTokenSource
  ): Promise<LastRefreshedResponse> {
    const action = 'dashboard/last-refreshed';
    const response = await this.GetAsync<LastRefreshedResponse>({
      action,
      anonymous: false,
      includeAuthToken: true,
      tag: DashBoardApiTags.GetBackupStatusLastRefreshed,
      cancelSource: cancelToken,
    });
    return response;
  }

  async GetFacilitatorMetricData(
    globalFilters: GlobalFilters,
    filters: FacilitatorMetricsFilters,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<FacilitatorMetric>> {
    const path = 'dashboard/metrics/facilitators';

    const globalFilterQuery = DashboardApi.parseGlobalFilters(globalFilters);
    const facilitatorFilterQuery =
      DashboardApi.parseFacilitatorFilters(filters);

    const action = `${path}?${globalFilterQuery}&${facilitatorFilterQuery}`;
    const facilitatorMetricsData = this.GetAsync<
      ListResponse<FacilitatorMetric>
    >({
      action,
      tag: DashBoardApiTags.GetFacilitatorMetricData,
      cancelSource: cancelToken,
    });

    return facilitatorMetricsData;
  }

  // eslint-disable-next-line class-methods-use-this
  async GetFacilitators(
    globalFilters: GlobalFilters,
    supervisors?: string[],
    cancelToken?: CancelTokenSource
  ): Promise<ListResponse<Facilitator>> {
    const globalFilterQuery = DashboardApi.parseGlobalFilters(globalFilters);

    const supervisorParams = `${queryString.stringify({
      supervisorId: supervisors,
    })}`;
    const enableGroupFilter = `${queryString.stringify({
      enableGroupFilter: true,
    })}`;

    const path = `organizations/facilitators/lookup?`;
    let action = `${path}${globalFilterQuery}`;
    action = `${action}&${enableGroupFilter}`;

    if (supervisors?.length) {
      action = `${action}&${supervisorParams}`;
    }

    const facilitatorsResponse = this.GetAsync<
      Promise<ListResponse<Facilitator>>
    >({
      action,
      anonymous: false,
      includeAuthToken: true,
      tag: DashBoardApiTags.GetFacilitators,
      cancelSource: cancelToken,
    });

    return facilitatorsResponse;
  }
}

const DashboardApiInstance = new DashboardApi();

export default DashboardApiInstance;

/* eslint-disable */
import * as localStorage from 'local-storage';

import Feature from 'api/auth/user/Feature';
import UserPermissions from 'api/auth/user/UserPermissions';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import ModulePaths from 'constants/ModulePaths';
import StorageKeys from 'constants/StorageKeys';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import LoginResponse from 'api/auth/LoginResponse';

class AuthService {
  /**
   * Check if current user is still logged in
   *
   * @returns {boolean} Specifies whether current user's session is valid
   */
  static IsLoggedIn = (): boolean =>
    !!localStorage.get<string>(StorageKeys.TokenKey);

  /**
   * Logs out current user
   */
  static Logout = (): void => {
    localStorage.clear();
    sendEventGA(CategoryKeysGA.Account, ActionKeysGA.Logout);
    window.location.href = ModulePaths.RootPath;
  };

  /**
   * Writes currently logged-in user's information to local storage
   *
   * @param user Currently logged-in user
   */
  static onLogin = (response: LoginResponse): void => {
    localStorage.set<string>(StorageKeys.FirstNameKey, response.firstName);
    localStorage.set<string>(StorageKeys.LastNameKey, response.lastName);
    localStorage.set<string>(
      StorageKeys.OrganizationId,
      response.organizationId
    );
    localStorage.set<string>(StorageKeys.UserNameKey, response.userName);
    localStorage.set<string>(StorageKeys.TokenKey, response.accessToken);
    localStorage.set<string>(StorageKeys.RefreshKey, response.refreshToken);
    localStorage.set<Array<Feature>>(StorageKeys.Features, response.features);

  }

  /**
   * Sets currently logged-in user's tokenKey and refreshKey to local storage
   *
   * @param response Currently logged-in user
   */
   static onRefresh = (response): void => {
    localStorage.set<string>(StorageKeys.TokenKey, response.accessToken);
    localStorage.set<string>(StorageKeys.RefreshKey, response.refreshToken);
  };

  /**
   * Gets currently logged-in user's permissions from local storage
   *
   * @returns {UserPermissions} Permissions object
   */
  static GetUserPermissions = (): UserPermissions =>
    localStorage.get<UserPermissions>(StorageKeys.Permissions);

  /**
   * Gets currently logged-in user's accessible features from local storage
   *
   * @returns {Array<Feature>} Features list
   */
  static GetUserFeatures = (): Array<Feature> =>
    localStorage.get<Array<Feature>>(StorageKeys.Features);
}

export default AuthService;

/* eslint-disable class-methods-use-this */
import { CancelTokenSource } from 'axios';

import ApiBase from 'api/common/ApiBase';
import ListFilters from 'api/common/ListFilters';
import ListResponse from 'api/common/ListResponse';
import { SettingsApiTags } from 'constants/request-tags/RequestTags';
import { UserTabFilters } from 'modules/private/settings/containers/users-view/UsersViewState';
import PermissionsViewMode from 'shared/enums/PermissionsViewMode';

import CreateFacilitatorByBulk from './facilitators/CreateFacilitatorByBulk';
import OrganizationFacilitator from './facilitators/OrganizationFacilitator';
import UpdateFacilitatorsByBulk from './facilitators/UpdateFacilitatorsByBulk';
import OrganizationGroupStatus from './groupStatus/OrganizationGroupStatus';
import JobRolesResponse from './jobRoles/JobRolesResponse';
import OrganizationInfoResponse from './organization/OrganizationInfoResponse';
import ClaimResponse from './permissions/ClaimResponse';
import CreatePermissionRequest from './permissions/CreatePermissionRequest';
import OrganizationCountry from './permissions/OrganizationCountry';
import PermissionResponse from './permissions/PermissionResponse';
import CreateUsersByBulkRequest from './users/CreateUsersByBulkRequest';
import OrganizationUser from './users/OrganizationUser';
import PaginatedUserListResponse from './users/PaginatedUserListResponse';
import UpdateUsersByBulkRequest from './users/UpdateUsersByBulkRequest';
import User from './users/User';

type CreateJobRoleResponse = { id: string };
type CreatePermissionResponse = { Code: number; Description: string };

class SettingsApi extends ApiBase {
  /**
   * get job roles list
   */
  async GetJobRoles(cancelToken: CancelTokenSource): Promise<JobRolesResponse> {
    const path = 'organizations/job-roles';
    const jobRolesResponse = this.GetAsync<JobRolesResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: SettingsApiTags.GetJobRoles,
      cancelSource: cancelToken,
    });
    return jobRolesResponse;
  }

  /**
   * create a job role
   * @param jobRole job role name
   */
  async CreateJobRole(
    jobRole: string,
    cancelToken: CancelTokenSource
  ): Promise<CreateJobRoleResponse> {
    const path = 'organizations/job-roles';
    const body = { jobRole };
    const createJobRoleResponse = this.PostAsync<CreateJobRoleResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SettingsApiTags.CreateJobRole,
      cancelSource: cancelToken,
    });
    return createJobRoleResponse;
  }

  /**
   * create a permission level
   * @param permission permission object
   */
  async CreatePermissionLevel(
    orgId: string,
    permission: CreatePermissionRequest,
    cancelToken?: CancelTokenSource
  ): Promise<CreatePermissionResponse> {
    const path = `organizations/${orgId}/permissions`;
    const body = permission;
    const createPermissionResponse = this.PostAsync<CreatePermissionResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SettingsApiTags.CreatePermissionLevel,
      cancelSource: cancelToken,
    });
    return createPermissionResponse;
  }

  /**
   * create a permission level
   * @param permission permission object
   */
  async UpdatePermissionLevel(
    orgId: string,
    permission: CreatePermissionRequest,
    cancelToken?: CancelTokenSource
  ): Promise<CreatePermissionResponse> {
    const path = `organizations/${orgId}/permissions`;
    const body = permission;
    const updatePermissionResponse = this.PutAsync<CreatePermissionResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SettingsApiTags.UpdatePermissionLevel,
      cancelSource: cancelToken,
    });
    return updatePermissionResponse;
  }

  /**
   * get permission levels for a given organization
   * @param orgId organization id
   */
  async GetPermissionLevels(
    orgId: string,
    viewMode: PermissionsViewMode,
    cancelToken: CancelTokenSource
  ): Promise<PermissionResponse> {
    const queryString = `?viewMode=${viewMode}`;

    const path = `organizations/${orgId}/permissions`;

    const action = `${path}${queryString}`;

    const permissionLevels = this.GetAsync<PermissionResponse>({
      action,
      tag: SettingsApiTags.GetPermissionLevels,
      cancelSource: cancelToken,
    });
    return permissionLevels;
  }

  /**
   * get default claims for a given organization
   * @param orgId organization id
   */
  async GetDefaultClaims(
    orgId: string,
    cancelToken: CancelTokenSource
  ): Promise<ClaimResponse> {
    const path = `organizations/${orgId}/default_claims`;
    const defaultClaims = this.GetAsync<ClaimResponse>({
      action: path,
      tag: SettingsApiTags.GetDefaultClaims,
      cancelSource: cancelToken,
    });
    return defaultClaims;
  }

  async GetOrganizationCountries(
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<OrganizationCountry>> {
    const path = `organizations/countries`;
    const organizationCountries = this.GetAsync<
      ListResponse<OrganizationCountry>
    >({
      action: path,
      tag: SettingsApiTags.GetOrganizationCountries,
      cancelSource: cancelToken,
    });
    return organizationCountries;
  }

  async CreateUsersByBulk(
    body: CreateUsersByBulkRequest[],
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const path = `user/bulk`;
    const createUsersByBulkResponse = this.PostAsync<Record<string, unknown>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SettingsApiTags.CreateUsersByBulk,
      cancelSource: cancelToken,
    });
    return createUsersByBulkResponse;
  }

  async UpdateUsersByBulk(
    values: UpdateUsersByBulkRequest[],
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const path = `user/bulk`;
    const body = {
      users: values,
    };
    const updateUsersByBulkResponse = this.PutAsync<Record<string, unknown>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SettingsApiTags.UpdateUsersByBulk,
      cancelSource: cancelToken,
    });
    return updateUsersByBulkResponse;
  }

  async UpdateFacilitatorsByBulk(
    values: UpdateFacilitatorsByBulk[],
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const path = `organizations/facilitators/bulk`;
    const body = {
      facilitators: values,
    };
    const updateUsersByBulkResponse = this.PutAsync<Record<string, unknown>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SettingsApiTags.UpdateFacilitatorsByBulk,
      cancelSource: cancelToken,
    });
    return updateUsersByBulkResponse;
  }

  async CreateFacilitatorsByBulk(
    body: CreateFacilitatorByBulk[],
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const path = `organizations/facilitators/bulk`;
    const createFacilitatorsByBulkResponse = this.PostAsync<
      Record<string, unknown>
    >({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: SettingsApiTags.CreateFacilitatorsByBulk,
      cancelSource: cancelToken,
    });
    return createFacilitatorsByBulkResponse;
  }

  /**
   * get users for a given organization
   * @param listFilters filters applied for list { page, pageSize, sortBy, sort }
   * @param orgId organization id
   */
  async GetOrganizationUsers(
    orgId: string,
    filter: string,
    showDeactivated: boolean,
    listFilters: Omit<ListFilters, 'search'>,
    cancelToken: CancelTokenSource
  ): Promise<PaginatedUserListResponse<User>> {
    const path = `organizations/${orgId}/users`;

    const { page, pageSize, sortBy, sort } = listFilters;
    const listFilterQuery = `page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sort=${sort}`;
    let statusFilterQuery = `&userStatus=${
      filter === UserTabFilters.INVITED ? 'VERIFICATION_PENDING' : 'ACTIVE'
    }`;
    if (filter === UserTabFilters.USERS && showDeactivated) {
      statusFilterQuery = `${statusFilterQuery}&userStatus=DEACTIVATED&prioritizeDeactivated=true`;
    }

    const action = `${path}?${listFilterQuery}${statusFilterQuery}`;

    const usersResponse = this.GetAsync<PaginatedUserListResponse<User>>({
      action,
      tag: SettingsApiTags.GetOrganizationUsers,
      cancelSource: cancelToken,
    });
    return usersResponse;
  }

  /**
   * get organization users for lookup
   * @param query query to look up
   */
  async LookupOrganizationUsers(
    cancelToken: CancelTokenSource,
    query?: string
  ): Promise<ListResponse<OrganizationUser>> {
    let path = 'user/lookup';
    if (query) {
      const encodedQuery = encodeURIComponent(query);
      const searchQuery = `?search=${encodedQuery}`;
      path = `${path}${searchQuery}`;
    }
    const data = this.GetAsync<ListResponse<OrganizationUser>>({
      action: path,
      tag: SettingsApiTags.LookupOrganizationUsers,
      cancelSource: cancelToken,
    });
    return data;
  }

  /**
   * get organization users for lookup
   * @param query query to look up
   */
  async LookupOrganizationFacilitators(
    cancelToken: CancelTokenSource,
    query?: string
  ): Promise<ListResponse<OrganizationFacilitator>> {
    let path = 'organizations/facilitators/lookup';
    if (query && query.trim().length > 0) {
      const encodedQuery = encodeURIComponent(query);
      const searchQuery = `?search=${encodedQuery}`;
      path = `${path}${searchQuery}`;
    }
    const data = this.GetAsync<ListResponse<OrganizationFacilitator>>({
      action: path,
      tag: SettingsApiTags.LookupOrganizationFacilitators,
      cancelSource: cancelToken,
    });
    return data;
  }

  /**
   * get organization group statuses
   * @param query query to look up
   */
  async GetOrganizationGroupStatuses(
    organizationId: string
  ): Promise<ListResponse<OrganizationGroupStatus>> {
    const path = `organizations/${organizationId}/group-status`;
    const data = this.GetAsync<ListResponse<OrganizationGroupStatus>>({
      action: path,
      tag: SettingsApiTags.GetOrganizationGroupStatuses,
    });
    return data;
  }

  // eslint-disable-next-line no-unused-vars
  async ResendInvite(
    email: string,
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const path = `user/resend-invite/${email}`;
    const response = this.PostAsync<Record<string, unknown>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: SettingsApiTags.ResendInvite,
      cancelSource: cancelToken,
    });
    return response;
  }

  async GetOrganizationInfo(
    orgId: string,
    cancelToken: CancelTokenSource
  ): Promise<OrganizationInfoResponse> {
    const path = `organizations/${orgId}`;
    const response = this.GetAsync<OrganizationInfoResponse>({
      action: path,
      tag: SettingsApiTags.GetOrganizationInfo,
      cancelSource: cancelToken,
    });
    return response;
  }
}

const SettingsApiInstance = new SettingsApi();

export default SettingsApiInstance;

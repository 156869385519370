/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, FC as ReactFC } from 'react';

import * as intl from 'react-intl-universal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import ReactTooltip from 'react-tooltip';

import TableConfig from 'modules/private/projects/containers/project-users-view/TableConfig';
import DataTableFooter from 'shared/components/data-table-footer/DataTableFooter';
import Status from 'shared/enums/Status';

import MessageHistoryViewModel from '../MessagingHistoryViewModel';
import DummyRow from './dummy-row/DummyRow';
import MessageHistoryRow from './message-history-row/MessageHistoryRow';
import styles from './messageHistoryDataTable.module.scss';
import ProjectUserDataTableProps from './MessageHistoryDataTableProps';

const MessageHistoryDataTable: ReactFC<ProjectUserDataTableProps> = (
  props: ProjectUserDataTableProps
) => {
  const {
    data,
    messageDetails,
    controlledPageCount,
    pagination,
    statusReloadRow,
    initialPageSize,
    initialPageIndex,
    initialSortBy,
    status,
    columns,
    updateSortBy,
    updatePageSize,
    updatePage,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    setSortBy,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      getRowId: (row: MessageHistoryViewModel) => row.id,
      initialState: {
        pageSize: initialPageSize,
        pageIndex: initialPageIndex,
        sortBy: initialSortBy,
      },
      manualSortBy: true,
      manualPagination: true,
      autoResetSortBy: false,
      autoResetPage: false,
      disableMultiSort: true,
      disableSortRemove: true,
      autoResetHiddenColumns: false,
      autoResetSelectedRows: false,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const initialSortByValue = initialSortBy[0];

  const initSortId = initialSortByValue
    ? initialSortByValue.id
    : TableConfig.defaultSort;
  const initSortDesc = initialSortByValue
    ? initialSortByValue.desc
    : TableConfig.defaultSortDesc;

  useEffect(() => {
    const newSortBy = [{ id: initSortId, desc: initSortDesc }];
    setSortBy(newSortBy);
  }, [initSortDesc, initSortId, setSortBy]);

  useEffect(() => {
    gotoPage(initialPageIndex);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [initialPageIndex]);

  useEffect(() => {
    setPageSize(initialPageSize);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [initialPageSize]);

  useEffect(() => {
    requestAnimationFrame(() => {
      ReactTooltip.rebuild();
    });
  }, [data]);

  /**
   * This is a fix for an edge case where the backend sends an incorrect
   * pagination total (total users count) and there are no rows (list items)
   * when the user navigates to a new page (eg. actual row count and pageSize
   * are both 10, whereas the pagination total shows 11, and the user navigates
   * to the 2nd page).
   * In cases like this, the user will be navigated to the previous page.
   */
  useEffect(() => {
    const count = page.length;
    if (count === 0) {
      if (pageIndex - 1 >= 0) {
        gotoPage(pageIndex - 1);
      } else {
        gotoPage(0);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [page]);

  /**
   * Handle sortBy change event
   *
   * @param event Column header click event
   */
  const handleChangeSortBy = (
    event: React.MouseEvent<HTMLSpanElement>
  ): void => {
    const dataSet = event.currentTarget.dataset;
    if (dataSet.sort) {
      const { id, canSort, isSorted, isSortedDesc, sortDescFirst } = JSON.parse(
        dataSet.sort
      );
      if (canSort && id !== null && id !== undefined) {
        updateSortBy([
          {
            id,
            desc: isSorted ? !isSortedDesc : sortDescFirst,
          },
        ]);
      }
    }
  };

  const isEmptyError =
    status !== Status.Loading && (status === Status.Error || data.length <= 0);

  return (
    <>
      <div className="insight-table-container pt-0">
        {status === Status.Loading ? (
          <SkeletonTheme color="#fafaf5" highlightColor="#ffffff">
            <Skeleton height={40} />
            <Skeleton height={62} count={10} />
          </SkeletonTheme>
        ) : (
          <PerfectScrollbar>
            {/* <ReactTooltip type="light" id="messageHistoryTooltip" html /> */}
            <table
              className={`insight-table ${styles.table}`}
              data-testid="message-history-list"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      if (column.id === 'selection') {
                        return (
                          <th key={column.id} {...column.getHeaderProps()} />
                        );
                      }
                      return (
                        <th
                          key={column.id}
                          {...column.getHeaderProps({
                            className:
                              column.headerClassName ?? column.className,
                          })}
                        >
                          <span
                            data-sort={JSON.stringify({
                              id: column.id,
                              canSort: column.canSort,
                              isSorted: column.isSorted,
                              isSortedDesc: column.isSortedDesc,
                              sortDescFirst: column.sortDescFirst,
                            })}
                            className="table-label text-12-bold text-uppercase"
                            {...column.getSortByToggleProps({
                              title: '',
                              onClick: handleChangeSortBy,
                            })}
                          >
                            {column.render('Header', { meta: pagination })}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length > 0 ? (
                  page.map((row, index) => (
                    <MessageHistoryRow
                      key={index}
                      details={messageDetails[row.id]}
                      prepareRow={prepareRow}
                      statusReloadRow={statusReloadRow}
                      row={row}
                    />
                  ))
                ) : (
                  <DummyRow
                    headers={headerGroups}
                    isError={status === Status.Error}
                  />
                )}
              </tbody>
            </table>
          </PerfectScrollbar>
        )}
        {isEmptyError && (
          <div className="no-data-message">
            <div className="title">{intl.get('ERR_CHART_EMPTY_TITLE')}</div>
            <div>{intl.get('ERR_GROUPS_EMPTY_MESSAGE')}</div>
          </div>
        )}
        <DataTableFooter
          pageSizeDisabled={isEmptyError}
          loading={status === Status.Loading}
          totalResults={pagination.total}
          gotoPage={updatePage}
          pageSize={initialPageSize}
          setPageSize={updatePageSize}
          pageCount={pageCount}
          pageIndex={initialPageIndex}
        />
      </div>
    </>
  );
};

export default MessageHistoryDataTable;

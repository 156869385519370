import { Component } from 'react';

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import * as intl from 'react-intl-universal';

import ResourceKeys from 'constants/permissions/ResourceKeys';
import PermissionUtil from 'helpers/PermissionUtil';
import setPageTitle from 'helpers/setPageTitle';
import ScrollToTopOnMount from 'shared/components/scroll-to-top-on-mount/ScrollToTopOnMount';
import PermissionsListView from 'shared/permissions/containers/permissions-list-view/PermissionsListView';

import PermissionsViewProps from './PermissionsViewProps';
import PermissionsViewState from './PermissionsViewState';

class PermissionsView extends Component<
  PermissionsViewProps,
  PermissionsViewState
> {
  constructor(props) {
    super(props);
    const { permissionsData, gettingStartedStates, getGettingStartedState } =
      props.appContext;
    const { claims } = permissionsData;

    const canAddPermissions = PermissionUtil.Can(
      claims,
      ResourceKeys.SettingsPermissionsAddNewLevel
    );
    const canEditPermissions = PermissionUtil.Can(
      claims,
      ResourceKeys.SettingsPermissionsItemEdit
    );

    const permissionsFirstTime = gettingStartedStates.data
      ? !gettingStartedStates.data.permissionCompleted
      : false;

    this.state = {
      canAddPermissions,
      canEditPermissions,
      permissionsFirstTime,
      getGettingStartedState,
    };
  }

  componentDidMount(): void {
    const { appContext } = this.props;
    appContext.hideErrorToast();
    setPageTitle(intl.get('BTN_SETTINGS'));
  }

  componentDidUpdate(prevProps: PermissionsViewProps): void {
    const { appContext } = this.props;
    const { gettingStartedStates } = appContext;
    const claims = get(this.props, 'appContext.permissionsData.claims');
    /** update {permissionsFirstTime} when getting started state updates */
    if (
      gettingStartedStates.status !==
      prevProps.appContext.gettingStartedStates.status
    ) {
      const permissionsFirstTime = gettingStartedStates.data
        ? !gettingStartedStates.data.permissionCompleted
        : false;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ permissionsFirstTime });
    }

    if (!isEqual(get(prevProps, 'appContext.permissionsData.claims'), claims)) {
      const canAddPermissions = PermissionUtil.Can(
        claims,
        ResourceKeys.SettingsPermissionsAddNewLevel
      );
      const canEditPermissions = PermissionUtil.Can(
        claims,
        ResourceKeys.SettingsPermissionsItemEdit
      );
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        canAddPermissions,
        canEditPermissions,
      });
    }
  }

  render(): JSX.Element {
    const {
      canAddPermissions,
      canEditPermissions,
      permissionsFirstTime,
      getGettingStartedState,
    } = this.state;

    const { appContext } = this.props;

    return (
      <div className="content-container">
        <ScrollToTopOnMount />
        <PermissionsListView
          canAddPermissions={canAddPermissions}
          canEditPermissions={canEditPermissions}
          isFirstTime={permissionsFirstTime}
          getGettingStartedState={getGettingStartedState}
          appContext={appContext}
        />
      </div>
    );
  }
}

export default PermissionsView;

import { ReactNode, useCallback, useRef, useState, FC as ReactFC } from 'react';

import { Field } from 'formik';
import find from 'lodash/find';
import * as intl from 'react-intl-universal';
import { Arrow, ToggleLayer } from 'react-laag';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';

import Constraints from 'constants/forms/Constraints';
import { formatDate } from 'helpers/DateFormat';
import { getLocalizedErrorString } from 'helpers/ErrorFormat';
import mergeRefs from 'helpers/mergeRefs';
import DatePicker from 'shared/components/date-picker/DatePicker';
import EnhancedFormikError from 'shared/components/enhanced-formik-error/EnhancedFormikError';
import ImageComponent from 'shared/components/image/ImageComponent';
import BlurCheckbox from 'shared/components/ins-form-fields/blur-checkbox/BlurCheckbox';
import BlurInput from 'shared/components/ins-form-fields/blur-input/BlurInput';
import FileInputField from 'shared/components/ins-form-fields/file-input-field/FileInputField';
import FormikSelect from 'shared/components/ins-form-fields/formik-select/FormikSelect';
import BlurPhoneNumberField from 'shared/components/ins-form-fields/phone-number-field/BlurPhoneNumberField';
import DateFormatType from 'shared/enums/DateFormatType';
import Status from 'shared/enums/Status';
import closeIcon from 'shared/static/img/close.svg';
import placeholderAvatar from 'shared/static/img/il_avatar.svg';

import InviteProjectFacilitatorsFormFieldError from '../../InviteProjectFacilitatorsFormFieldError';
import styles from './inviteFacilitatorsFormItem.module.scss';
import InviteFacilitatorsFormItemProps from './InviteFacilitatorsFormItemProps';

const InviteFacilitatorsFormItem: ReactFC<InviteFacilitatorsFormItemProps> = (
  props: InviteFacilitatorsFormItemProps
) => {
  const {
    length,
    values,
    status,
    handleRemove,
    index,
    validationSchema,
    supervisors,
    supervisorsFiltered,
    supervisorsStatus,
    setDatePickerValue,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = props;

  const maxLength = Constraints.MaxFieldLength;
  const supervisor = find(supervisors, { value: values.supervisorId }) ?? null;
  const startDateButtonRef = useRef<HTMLButtonElement>(null);

  const [startDatePickerRef, setStartDatePickerRef] =
    useState<HTMLDivElement | null>(null);

  const setCallbackRef = useCallback((element) => {
    setStartDatePickerRef(element);
  }, []);

  /**
   * Renders API errors specific to each form field
   *
   * @param fieldName The field under which the error should be rendered
   * @returns {string | null} Error message
   */
  const renderStatusError = (name): string | null => {
    const dotNameArray = name.split('.');
    const fieldName = dotNameArray[dotNameArray.length - 1];

    if (status && status.errors?.length > 0) {
      const fieldError = status.errors.find(
        (error: InviteProjectFacilitatorsFormFieldError) =>
          error.id === values.id && error.fieldName === fieldName
      );
      if (fieldError !== undefined) {
        const message = intl.get(getLocalizedErrorString(fieldError.errorCode));
        return message;
      }
    }
    return null;
  };

  /**
   * Validates duplicate emails and returns error strings for email field
   *
   * @param form Form props from @formik
   * @param value Value entered into email field
   * @returns {string | null} Error message
   */
  const getRenderError = ({ name }): string | null => {
    const statusError = renderStatusError(name);
    if (statusError) {
      return statusError;
    }
    return null;
  };

  /**
   * Remove selected image file from @formik state
   */
  const handleRemoveImage = (): void => {
    setFieldTouched(`facilitators.${index}.image`, false);
    setFieldValue(`facilitators.${index}.image`, null);
  };

  /**
   * Set selected file to @formik state
   *
   * @param files selected File | Files[]
   * @param event select file change event
   */
  const handleImageSelect = (
    files: File[] | null,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (files) {
      const selectedFile = files[0];
      const name = event.currentTarget.name ?? `facilitators.${index}.image`;
      try {
        const file = selectedFile;
        /* Does not preview image in the case of an unsupported extension */
        if (!Constraints.SupportedImageFormats.includes(file.type)) {
          throw new Error(intl.get('ERR_CP_UNSUPPORTED_FILE_FORMAT'));
        }
        const image = new Image();
        image.src = URL.createObjectURL(file);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        file.image = image;

        setFieldTouched(name, true);
        setFieldValue(name, file);
      } catch (error) {
        setFieldTouched(name, true, false);
        setFieldValue(name, null, false);
        if (error instanceof Error)
          setFieldError(
            name,
            error.message ?? intl.get('OOPS_SOMETHING_WENT_WRONG')
          );
      }
    }
  };

  /**
   * Renders date-picker wrapped in a toggle-able layer
   *
   * @returns {JSX.Element} JSX snippet containing the date-picker
   */
  const renderDatePicker = (): JSX.Element => {
    /* react laag focus loses track a it is elevated to a different level in dom;
      manual focus will be needed to get it back on track */
    const handleClose = (closeHandler: () => void) => (): void => {
      if (startDateButtonRef.current) {
        startDateButtonRef.current.focus();
      }
      closeHandler();
    };
    const container = startDatePickerRef;
    return (
      <ToggleLayer
        container={container || undefined}
        renderLayer={({
          isOpen,
          layerProps,
          arrowStyle,
          layerSide,
          close,
        }): ReactNode =>
          isOpen && (
            <div
              ref={layerProps.ref}
              className="layer insight-dropdown"
              style={{
                ...layerProps.style,
                left: '50%',
                transform: 'translateX(-50%)',
                top: '10px',
              }}
            >
              <Arrow
                style={arrowStyle}
                layerSide={layerSide}
                backgroundColor="#ffffff"
                borderWidth={1}
                borderColor="#dbdce1"
                roundness={0.7}
              />
              <DatePicker
                autoFocus={false}
                format={DateFormatType.DatePicker}
                className="insight-date-picker"
                name={`facilitators.${index}.startDate`}
                value={values.startDate}
                onChange={(name: string, date: Date): void =>
                  setDatePickerValue(name, date)
                }
                isOpen
                onCalendarClose={handleClose(close)}
              />
            </div>
          )
        }
        placement={{
          anchor: 'BOTTOM_CENTER',
          possibleAnchors: ['BOTTOM_CENTER'],
          triggerOffset: 10,
        }}
        closeOnOutsideClick
      >
        {({ triggerRef, toggle, isOpen }): ReactNode => (
          <>
            <Label>{intl.get('LBL_PROJECTS_FACILITATORS_START_DATE')}</Label>
            <button
              type="button"
              tabIndex={0}
              className={`calendar-button ${isOpen ? 'calendar-open' : ''}`}
              ref={mergeRefs([triggerRef, startDateButtonRef])}
              onClick={toggle}
            >
              <span className="text-12-medium text-gray">
                {values.startDate
                  ? formatDate(values.startDate, DateFormatType.ShortYear)
                  : intl.get('LBL_PROJECTS_FACILITATORS_SELECT_DATE')}
              </span>
            </button>
            <div ref={setCallbackRef} className="position-relative" />
          </>
        )}
      </ToggleLayer>
    );
  };

  return (
    <div id="inviteUserItem">
      {length > 1 && (
        <div className="d-flex justify-content-end mb-3">
          <button
            className="insight-close-button enlarge-icon"
            type="button"
            onClick={handleRemove(index)}
          >
            <img src={closeIcon} alt="Close" />
          </button>
        </div>
      )}
      <Row>
        <Col xs="12" md="auto" className={`pr-4 ${styles.pictureContainer}`}>
          <FormGroup>
            <div className={styles.frame}>
              <ImageComponent
                switcher
                className={styles.picture}
                fallbackClassName={styles.placeholder}
                fallbackSrc={placeholderAvatar}
                src={values.image?.image?.src}
                alt="Facilitator image"
              />
            </div>
            <EnhancedFormikError
              center
              name={`facilitators.${index}.image`}
              renderSecondaryError={renderStatusError}
            />
            <div className={styles.fileInput}>
              <Label className="btn btn-sm btn-secondary align-middle">
                <Field
                  className="form-control"
                  id={`facilitators.${index}.image`}
                  name={`facilitators.${index}.image`}
                  type="file"
                  accept={Constraints.SupportedImageFormats.join(', ')}
                  onChange={handleImageSelect}
                  component={FileInputField}
                />
                <i className="icon-export" />
                {intl.get('BTN_PROJECTS_FACILITATORS_UPLOAD_IMAGE')}
              </Label>
              <Button
                type="button"
                onClick={handleRemoveImage}
                disabled={values.image === null}
                className="btn btn-link btn-sm mx-auto shadow-none"
              >
                {intl.get('BTN_CREATE_PROFILE_REMOVE_IMAGE')}
              </Button>
            </div>
          </FormGroup>
        </Col>
        <Col>
          <Row>
            <Col xs="12" md="4">
              <FormGroup>
                <Field
                  autoComplete="true"
                  type="text"
                  name={`facilitators.${index}.firstName`}
                  maxLength={maxLength}
                  className="form-control"
                  component={BlurInput}
                  label={intl.get('LBL_PROJECTS_FACILITATORS_FIRST_NAME')}
                  validationSchema={validationSchema}
                  customError={getRenderError}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Field
                  autoComplete="true"
                  type="text"
                  name={`facilitators.${index}.lastName`}
                  maxLength={maxLength}
                  className="form-control"
                  component={BlurInput}
                  label={intl.get('LBL_PROJECTS_FACILITATORS_LAST_NAME')}
                  validationSchema={validationSchema}
                  customError={getRenderError}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4" className="pl-4">
              <FormGroup className="insight-select-group pb-3">
                <FormikSelect
                  // classNamePrefix="insight-select"
                  classNamePrefix="insight-select-no-border"
                  label={intl.get('LBL_PROJECTS_FACILITATORS_SUPERVISED_BY')}
                  value={supervisor}
                  options={supervisorsFiltered}
                  placeholder={intl.get(
                    'LBL_SETTINGS_USERS_SUPERVISED_BY_PLACEHOLDER'
                  )}
                  isLoading={supervisorsStatus === Status.Loading}
                  name={`facilitators.${index}.supervisorId`}
                  renderSecondaryError={renderStatusError}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Field
                  autoComplete="true"
                  type="text"
                  name={`facilitators.${index}.location`}
                  maxLength={maxLength}
                  className="form-control"
                  component={BlurInput}
                  label={intl.get('LBL_PROJECTS_FACILITATORS_LOCATION')}
                  validationSchema={validationSchema}
                  customError={getRenderError}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Field
                  type="tel"
                  autoComplete="tel"
                  name={`facilitators.${index}.phoneNumber`}
                  maxLength={maxLength}
                  label={intl.get('LBL_PROJECTS_FACILITATORS_PHONE_NUMBER')}
                  validationSchema={validationSchema}
                  className="form-control"
                  component={BlurPhoneNumberField}
                  customError={getRenderError}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4" className="pl-4">
              <FormGroup>{renderDatePicker()}</FormGroup>
            </Col>
            <Col xs="12" className="mb-3 pb-2">
              <div className="insight-checkbox-group">
                <Label className={styles.checkboxLabel}>
                  <span className="insight-checkbox mr-3" data-cy="terms-cb">
                    <Field
                      type="checkbox"
                      name={`facilitators.${index}.isImplementingPartner`}
                      component={BlurCheckbox}
                      validationSchema={validationSchema}
                      customError={getRenderError}
                    />
                    <i className="icon-check" />
                  </span>
                  <span className="text-14-medium">
                    {intl.get(
                      'LBL_SETTINGS_USERS_WORKS_FOR_IMPLEMENTING_PARTNER'
                    )}
                  </span>
                </Label>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {length > 1 && <hr className="divider" />}
    </div>
  );
};

export default InviteFacilitatorsFormItem;

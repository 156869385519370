import { useMemo, FC as ReactFC, memo } from 'react';

import { Field, FormikProps } from 'formik';
import find from 'lodash/find';
import * as intl from 'react-intl-universal';
import Select from 'react-select';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import Constraints from 'constants/forms/Constraints';
import { getLocalizedErrorString } from 'helpers/ErrorFormat';
import UserFormValue from 'modules/private/settings/containers/invite-users/UserFormValue';
import EnhancedFormikError from 'shared/components/enhanced-formik-error/EnhancedFormikError';
import BlurCheckbox from 'shared/components/ins-form-fields/blur-checkbox/BlurCheckbox';
import BlurInput from 'shared/components/ins-form-fields/blur-input/BlurInput';
import Status from 'shared/enums/Status';
import closeIcon from 'shared/static/img/close.svg';

import UsersFormFieldError from '../UsersFormFieldError';
import UsersFormValues from '../UsersFormValues';
import CountriesSelect from './countries-select/CountriesSelect';
import styles from './inviteUsersFormItem.module.scss';
import InviteUsersFormItemProps, {
  SelectOnChangeCurried,
} from './InviteUsersFormItemProps';
import JobRoleSelect from './job-role-async-select/JobRoleSelect';
import PermissionSelect from './permission-select/PermissionSelect';

export enum InviteUsersFormFields {
  EMAIL = 'email',
  JOB_ROLE = 'jobRole',
  SUPERVISOR = 'supervisorId',
  PERMISSION_LEVEL = 'permission',
  COUNTRY_DATA = 'countryDataAccess',
  IS_IMPLEMENTING_PARTNER = 'isImplementingPartner',
}

const InviteUsersFormItem: ReactFC<InviteUsersFormItemProps> = (
  props: InviteUsersFormItemProps
) => {
  const {
    values,
    status,
    index,
    length,
    handleRemove,
    jobRoles,
    jobRolesStatus,
    supervisors,
    supervisorsFiltered,
    supervisorsStatus,
    countries: countryOptions,
    countriesStatus,
    permissionLevels,
    permissionLevelsStatus,
    onCreateJobRole,
    createJobRoleStatus,
    createJobRoleError,
    setFieldTouched,
    setFieldValue: onFieldChange,
    validationSchema,
    togglePermissionsModal,
    clearJobRoleError,
  } = props;

  const setFieldValue = onFieldChange as SelectOnChangeCurried;
  const maxLength = Constraints.MaxFieldLength;

  const jobRole = find(jobRoles, { value: values.jobRole }) ?? null;
  const supervisor = find(supervisors, { value: values.supervisorId }) ?? null;
  // prettier-ignore
  const permission = find(permissionLevels, { value: values.permission }) ?? null;
  const country = countryOptions.filter((item) =>
    values.countryDataAccess.includes(item.value)
  );

  const countries = useMemo(
    () =>
      countryOptions.map((value) => {
        if (values.countryDataAccess.includes('*') && value.value !== '*') {
          return {
            ...value,
            isDisabled: true,
          };
        }
        return value;
      }),
    [values.countryDataAccess, countryOptions]
  );

  /**
   * Validates duplicate emails and returns error strings for email field
   *
   * @param form Form props from @formik
   * @param value Value entered into email field
   * @returns {string | null} Error message
   */
  const getRenderError = (
    _,
    form: FormikProps<UsersFormValues>,
    value: string
  ): string | null => {
    const statusError = renderStatusError(InviteUsersFormFields.EMAIL);
    if (statusError) return statusError;
    const duplicate = form.values.users.find(
      (user: UserFormValue, formIndex: number) =>
        value && user.email === value && index !== formIndex
    );
    if (duplicate !== undefined)
      return intl.get('ERR_INVITE_USERS_DUPLICATE_EMAIL');
    return null;
  };

  /**
   * Renders API errors specific to each form field
   *
   * @param fieldName The field under which the error should be rendered
   * @returns {string | null} Error message
   */
  const renderStatusError = (name): string | null => {
    const dotNameArray = name.split('.');
    const fieldName = dotNameArray[
      dotNameArray.length - 1
    ] as InviteUsersFormFields;

    if (status && status.errors?.length > 0) {
      const fieldError = status.errors.find(
        (error: UsersFormFieldError) =>
          error.id === values.id && error.fieldName === fieldName
      );
      if (fieldError !== undefined) {
        const message = intl.get(getLocalizedErrorString(fieldError.errorCode));
        return message;
      }
    }
    return null;
  };

  return (
    <div id="inviteUserItem">
      {length > 1 && (
        <div className="d-flex justify-content-end mb-3">
          <button
            className="insight-close-button enlarge-icon"
            type="button"
            onClick={handleRemove(index)}
          >
            <img src={closeIcon} alt="Close" />
          </button>
        </div>
      )}
      <Row>
        <Col xs="12" md="6" className="pr-5">
          <FormGroup>
            <Field
              autoComplete="new-password"
              type="email"
              name={`users.${index}.${InviteUsersFormFields.EMAIL}`}
              maxLength={maxLength}
              className="form-control"
              placeholder={intl.get('LBL_SETTINGS_USERS_EMAIL_PLACEHOLDER')}
              autoFocus
              label={intl.get('LBL_SETTINGS_USERS_EMAIL_2')}
              component={BlurInput}
              validationSchema={validationSchema}
              customError={getRenderError}
            />
            <div className="insight-checkbox-group">
              <Label className={styles.checkboxLabel}>
                <span className="insight-checkbox mr-3" data-cy="terms-cb">
                  <Field
                    type="checkbox"
                    name={`users.${index}.${InviteUsersFormFields.IS_IMPLEMENTING_PARTNER}`}
                    component={BlurCheckbox}
                    validationSchema={validationSchema}
                    customError={renderStatusError(
                      InviteUsersFormFields.IS_IMPLEMENTING_PARTNER
                    )}
                  />
                  <i className="icon-check" />
                </span>
                <span className="text-14-medium">
                  {intl.get(
                    'LBL_SETTINGS_USERS_WORKS_FOR_IMPLEMENTING_PARTNER'
                  )}
                </span>
              </Label>
            </div>
          </FormGroup>
        </Col>
        <Col xs="12" md="6" className="pl-4">
          <Row>
            <Col xs="12" md="6">
              <FormGroup className="insight-select-group pb-3">
                <Label htmlFor={InviteUsersFormFields.JOB_ROLE}>
                  {intl.get('LBL_SETTINGS_USERS_JOB_ROLE')}
                </Label>
                <JobRoleSelect
                  options={jobRoles}
                  isLoading={
                    jobRolesStatus === Status.Loading ||
                    createJobRoleStatus === Status.Loading
                  }
                  value={jobRole}
                  onCreateJobRole={onCreateJobRole}
                  createJobRoleStatus={createJobRoleStatus}
                  clearJobRoleError={clearJobRoleError}
                  createJobRoleError={createJobRoleError}
                  placeholder={intl.get(
                    'LBL_SETTINGS_USERS_JOB_ROLE_PLACEHOLDER'
                  )}
                  name={`users.${index}.${InviteUsersFormFields.JOB_ROLE}`}
                  tabSelectsValue={false}
                  onChange={setFieldValue(
                    `users.${index}.${InviteUsersFormFields.JOB_ROLE}`
                  )}
                  onBlur={setFieldTouched(
                    `users.${index}.${InviteUsersFormFields.JOB_ROLE}`
                  )}
                  classNamePrefix="insight-select-no-border"
                />
                <EnhancedFormikError
                  name={`users.${index}.${InviteUsersFormFields.JOB_ROLE}`}
                  renderSecondaryError={renderStatusError}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="6">
              <FormGroup className="insight-select-group pb-3">
                <Label htmlFor={InviteUsersFormFields.SUPERVISOR}>
                  {intl.get('LBL_SETTINGS_USERS_SUPERVISED_BY')}
                </Label>
                <Select
                  value={supervisor}
                  options={supervisorsFiltered}
                  placeholder={intl.get(
                    'LBL_SETTINGS_USERS_SUPERVISED_BY_PLACEHOLDER'
                  )}
                  isLoading={supervisorsStatus === Status.Loading}
                  name={`users.${index}.${InviteUsersFormFields.SUPERVISOR}`}
                  onChange={setFieldValue(
                    `users.${index}.${InviteUsersFormFields.SUPERVISOR}`
                  )}
                  onBlur={setFieldTouched(
                    `users.${index}.${InviteUsersFormFields.SUPERVISOR}`
                  )}
                  classNamePrefix="insight-select-no-border"
                />
                <EnhancedFormikError
                  name={`users.${index}.${InviteUsersFormFields.SUPERVISOR}`}
                  renderSecondaryError={renderStatusError}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="6">
              <FormGroup className="insight-select-group">
                <Label htmlFor={InviteUsersFormFields.PERMISSION_LEVEL}>
                  {intl.get('LBL_SETTINGS_USERS_PERMISSION_LEVEL')}
                </Label>
                <PermissionSelect
                  value={permission}
                  onChange={setFieldValue(
                    `users.${index}.${InviteUsersFormFields.PERMISSION_LEVEL}`
                  )}
                  onBlur={setFieldTouched(
                    `users.${index}.${InviteUsersFormFields.PERMISSION_LEVEL}`
                  )}
                  options={permissionLevels}
                  isLoading={permissionLevelsStatus === Status.Loading}
                  placeholder={intl.get(
                    'LBL_SETTINGS_USERS_PERMISSION_LEVEL_PLACEHOLDER'
                  )}
                  name={`users.${index}.${InviteUsersFormFields.PERMISSION_LEVEL}`}
                  classNamePrefix="insight-select-no-border"
                  togglePermissionsModal={togglePermissionsModal}
                />
                <EnhancedFormikError
                  name={`users.${index}.${InviteUsersFormFields.PERMISSION_LEVEL}`}
                  renderSecondaryError={renderStatusError}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="6">
              <FormGroup className="insight-select-group">
                <Label htmlFor={InviteUsersFormFields.COUNTRY_DATA}>
                  {intl.get('LBL_SETTINGS_USERS_COUNTRY_DATA')}
                </Label>
                <CountriesSelect
                  className="expand-height"
                  isMulti
                  value={country}
                  isClearable={false}
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  onChange={setFieldValue(
                    `users.${index}.${InviteUsersFormFields.COUNTRY_DATA}`,
                    true
                  )}
                  onBlur={setFieldTouched(
                    `users.${index}.${InviteUsersFormFields.COUNTRY_DATA}`
                  )}
                  options={countries}
                  isLoading={countriesStatus === Status.Loading}
                  placeholder={intl.get(
                    'LBL_SETTINGS_USERS_COUNTRY_DATA_PLACEHOLDER'
                  )}
                  name={`users.${index}.${InviteUsersFormFields.COUNTRY_DATA}`}
                  classNamePrefix="insight-select-no-border"
                />
                <EnhancedFormikError
                  name={`users.${index}.${InviteUsersFormFields.COUNTRY_DATA}`}
                  renderSecondaryError={renderStatusError}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr className="divider" />
    </div>
  );
};

export default memo(InviteUsersFormItem);

import { CancelTokenSource } from 'axios';
import queryString from 'query-string';

import ApiBase from 'api/common/ApiBase';
import ListFilters from 'api/common/ListFilters';
import ListResponse from 'api/common/ListResponse';
import GroupDetailMapItem from 'api/group/GroupDetailMapItem';
import GroupListResponse from 'api/group/GroupListResponse';
import GroupMetricValue from 'api/group/GroupMetricValue';
import { GroupApiTags } from 'constants/request-tags/RequestTags';
import GroupFinancialChartChartTypes from 'modules/private/group/components/group-financial-chart-container/GroupFinancialChartChartTypes';
import GroupFinancialChartFilter from 'modules/private/group/components/group-financial-chart-container/GroupFinancialChartFilter';
import GlobalFilters from 'shared/components/header-toolbar/GlobalFilters';
import Frequency from 'shared/enums/Frequency';

import SuccessResponse from '../common/SuccessResponse';
import BackupStatusSummary from './BackupStatusSummary';
import GroupDetailsBalanceSheet from './group-details-balance-sheet/GroupDetailsBalanceSheet';
import GroupDetails from './group-details/GroupDetails';
import GroupFinancialMetrics from './group-financial-metrics/GroupFinancialMetrics';
import GroupWidget from './group-widget/GroupWidget';
import GroupWidgetPreference from './group-widget/GroupWidgetPreference';
import GroupDetailListItem from './GroupDetailListItem';
import GroupNotificationResponse from './GroupNotificationResponse';
import GroupOverviewStatsResponse from './GroupOverviewStatsResponse';
import TablePreferences from './TablePreferences';

class GroupApi extends ApiBase {
  private static parseGlobalFilters(f: GlobalFilters): string {
    const defaultFilters = `backupStatus=${f.backupStatus}&groupAge=${f.groupAge}`;

    let dateFilters = '';

    if (f.fromDate && f.toDate) {
      dateFilters = `&dateFrom=${f.fromDate}&dateTo=${f.toDate}`;
    }

    let moreFilters = '';
    if (f.groupStatuses && f.groupStatuses.length > 0) {
      const groupStatusIds = f.groupStatuses;
      moreFilters = `&${queryString.stringify({
        groupStatus: groupStatusIds,
      })}`;
    }

    let projectFilters = '';
    if (f.projects && f.projects.length > 0) {
      const projectCodes = f.projects;
      projectFilters = `&${queryString.stringify({ projects: projectCodes })}`;
    }

    return `${defaultFilters}${dateFilters}${projectFilters}${moreFilters}`;
  }

  async GetGroupsExcel(
    globalFilters: GlobalFilters,
    reportTime: string,
    cancelToken: CancelTokenSource
  ): Promise<Blob> {
    const path = 'groups/excel-export';
    const action = `${path}?${GroupApi.parseGlobalFilters(
      globalFilters
    )}&reportTime=${reportTime}`;

    const excelData = this.GetAsyncBlob({
      action,
      tag: GroupApiTags.GetGroupsExcel,
      cancelSource: cancelToken,
    });

    return excelData;
  }

  async GetGroupListData(
    filters: GlobalFilters,
    listFilters: ListFilters,
    notificationFilters: string[],
    cancelToken: CancelTokenSource
  ): Promise<GroupListResponse<GroupDetailListItem>> {
    const path = 'groups/list';

    const { page, pageSize, sortBy, sort, search } = listFilters;

    const searchQuery =
      search && search.trim().length > 0 ? `&search=${search}` : '';
    const listFilterQuery = `page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sort=${sort}${searchQuery}`;

    const notificationFilterQuery =
      notificationFilters.length > 0
        ? `&${queryString.stringify({
            notificationFilters,
          })}`
        : '';

    const action = `${path}?${GroupApi.parseGlobalFilters(
      filters
    )}&${listFilterQuery}${notificationFilterQuery}`;

    const listData = this.GetAsync<GroupListResponse<GroupDetailListItem>>({
      action,
      tag: GroupApiTags.GetGroupListData,
      cancelSource: cancelToken,
    });

    return listData;
  }

  async GetGroupsAllIds(
    filters: GlobalFilters,
    search: string,
    notificationFilters: string[],
    cancelToken: CancelTokenSource
  ): Promise<{ items: string[] }> {
    const path = 'groups/list-all';

    const searchQuery =
      search && search.trim().length > 0 ? `&search=${search}` : '';

    const notificationFilterQuery =
      notificationFilters.length > 0
        ? `&${queryString.stringify({
            notificationFilters,
          })}`
        : '';

    const action = `${path}?${GroupApi.parseGlobalFilters(
      filters
    )}&${searchQuery}${notificationFilterQuery}`;

    const listData = this.GetAsync<{ items: string[] }>({
      action,
      tag: GroupApiTags.GetGroupsAllIds,
      cancelSource: cancelToken,
    });

    return listData;
  }

  async GetGroupNotifications(
    filters: GlobalFilters,
    cancelToken: CancelTokenSource,
    search?: string
  ): Promise<GroupNotificationResponse> {
    const path = 'groups/notifications';
    const searchParam =
      search && search.trim().length > 0 ? `&search=${search}` : '';

    const action = `${path}?${GroupApi.parseGlobalFilters(
      filters
    )}${searchParam}`;

    const listData = this.GetAsync<GroupNotificationResponse>({
      action,
      tag: GroupApiTags.GetGroupNotifications,
      cancelSource: cancelToken,
    });

    return listData;
  }

  async GetGroupOverviewStats(
    cancelToken: CancelTokenSource
  ): Promise<GroupOverviewStatsResponse> {
    const action = 'user/group_overview_stats';
    const result = await this.GetAsync<GroupOverviewStatsResponse>({
      action,
      tag: GroupApiTags.GetGroupOverviewStats,
      cancelSource: cancelToken,
    });

    return result;
  }

  async SetTablePreferences(
    preferences: TablePreferences[],
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const action = 'user/preferences/groups/group-table';
    const result = await this.PutAsync<SuccessResponse>({
      action,
      anonymous: false,
      includeAuthToken: true,
      body: preferences,
      tag: GroupApiTags.SetTablePreferences,
      cancelSource: cancelToken,
    });

    return result;
  }

  async GetBackupStatusSummaryData(
    filters: GlobalFilters,
    cancelToken?: CancelTokenSource
  ): Promise<BackupStatusSummary> {
    const path = 'groups/backupstatus-summery';

    const action = `${path}?${GroupApi.parseGlobalFilters(filters)}`;
    const data = this.GetAsync<BackupStatusSummary>({
      action,
      tag: GroupApiTags.GetBackupStatusSummaryData,
      cancelSource: cancelToken,
    });

    return data;
  }

  async GetGroupMetricData(
    filters: GlobalFilters,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<GroupMetricValue>> {
    const path = 'groups/cards';

    const action = `${path}?${GroupApi.parseGlobalFilters(filters)}`;
    const data = this.GetAsync<ListResponse<GroupMetricValue>>({
      action,
      tag: GroupApiTags.GetGroupMetricData,
      cancelSource: cancelToken,
    });

    return data;
  }

  async GetGroupMapData(
    globalFilters: GlobalFilters,
    searchTerm = '',
    notificationFilters: string[],
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<GroupDetailMapItem>> {
    const path = 'groups/locations';

    const globalFilterQuery = GroupApi.parseGlobalFilters(globalFilters);
    const search = `search=${searchTerm}`;

    const notificationFilterQuery =
      notificationFilters.length > 0
        ? `&${queryString.stringify({
            notificationFilters,
          })}`
        : '';

    const action = `${path}?${globalFilterQuery}&${search}${notificationFilterQuery}`;
    const data = this.GetAsync<ListResponse<GroupDetailMapItem>>({
      action,
      tag: GroupApiTags.GetGroupMapData,
      cancelSource: cancelToken,
    });

    return data;
  }

  /* group details api client methods */

  async GetGroupDetailsExcel(
    groupNumber: string,
    reportTime: string,
    cancelToken: CancelTokenSource
  ): Promise<Blob> {
    const path = `groups/details/${groupNumber}/excel-export`;
    const action = `${path}?reportTime=${reportTime}`;

    const excelData = this.GetAsyncBlob({
      action,
      tag: GroupApiTags.GetGroupDetailsExcel,
      cancelSource: cancelToken,
    });

    return excelData;
  }

  async GetGroupDetails(
    groupNumber: string,
    cancelToken: CancelTokenSource
  ): Promise<GroupDetails> {
    const path = `groups/details/${groupNumber}`;
    const action = `${path}`;
    const data = this.GetAsync<GroupDetails>({
      action,
      tag: GroupApiTags.GetGroupDetails,
      cancelSource: cancelToken,
    });

    return data;
  }

  async GetWidgetData(
    groupNumber: string,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<GroupWidget>> {
    const action = `groups/details/${groupNumber}/widgets`;
    const widgetData = this.GetAsync<ListResponse<GroupWidget>>({
      action,
      tag: GroupApiTags.GetWidgetData,
      cancelSource: cancelToken,
    });

    return widgetData;
  }

  async GetGroupFinancialMetrics(
    groupNumber: string,
    chartType: GroupFinancialChartChartTypes,
    filters: GroupFinancialChartFilter,
    cancelToken: CancelTokenSource
  ): Promise<GroupFinancialMetrics> {
    const path = `groups/details/${groupNumber}/metrics?type=${String(
      chartType
    )}&dateComparison=${String(filters.timeUnit.value)}&frequency=${
      Frequency.Monthly
    }`;
    const action = `${path}`;
    const data = this.GetAsync<GroupFinancialMetrics>({
      action,
      tag: GroupApiTags.GetGroupFinancialMetrics,
      cancelSource: cancelToken,
    });

    return data;
  }

  async GetGroupDetailsBalanceSheet(
    groupNumber: string,
    cancelToken: CancelTokenSource
  ): Promise<GroupDetailsBalanceSheet> {
    const path = `groups/details/${groupNumber}/balance-sheet`;
    const action = `${path}`;
    const data = this.GetAsync<GroupDetailsBalanceSheet>({
      action,
      tag: GroupApiTags.GetGroupDetailsBalanceSheet,
      cancelSource: cancelToken,
    });

    return data;
  }

  async AssignGroupStatusToGroups(
    statusId: string,
    groups: string[],
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const path = `groups/status/bulk`;
    const body = { groups, statusId };

    const response = this.PostAsync<Record<string, unknown>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: GroupApiTags.AssignGroupStatusToGroups,
      cancelSource: cancelToken,
    });

    return response;
  }

  async AssignFacilitatorsToProject(
    facilitator: string,
    groups: string[],
    cancelToken: CancelTokenSource
  ): Promise<Record<string, unknown>> {
    const path = `organizations/facilitators/${facilitator}/groups`;
    const body = { groups };

    const response = this.PostAsync<Record<string, unknown>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: GroupApiTags.AssignFacilitatorsToProject,
      cancelSource: cancelToken,
    });

    return response;
  }

  async SetWidgetPreferences(
    preferences: GroupWidgetPreference[],
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const action = 'user/preferences/group-details/widgets';
    const result = await this.PutAsync<SuccessResponse>({
      action,
      anonymous: false,
      includeAuthToken: true,
      body: preferences,
      tag: GroupApiTags.SetWidgetPreferences,
      cancelSource: cancelToken,
    });

    return result;
  }
}

const GroupApiInstance = new GroupApi();

export default GroupApiInstance;

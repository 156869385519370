/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useRef, useState, FC as ReactFC } from 'react';

import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import isEmpty from 'lodash/isEmpty';
import * as intl from 'react-intl-universal';
import { Button, Col, Row } from 'reactstrap';
import { ObjectSchema } from 'yup';

import ApiError from 'api/common/ApiError';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import mergeRefs from 'helpers/mergeRefs';
import PermissionUtil from 'helpers/PermissionUtil';
import InviteProjectFacilitatorsViewModel from 'modules/private/projects/containers/projects-view/InviteProjectFacilitatorsViewModel';
import { FileItem } from 'modules/private/projects/containers/projects-view/ProjectsViewState';
import EventKey from 'shared/enums/EventKey';
import HttpStatus from 'shared/enums/HttpStatus';
import { EventBus } from 'shared/events/EventBus';
import { CustomErrorArgs } from 'shared/types/eventTypes';

import InviteFacilitatorsFieldArray from './invite-facilitators-field-array/InviteFacilitatorsFieldArray';
import styles from './inviteProjectFacilitators.module.scss';
import InviteProjectFacilitatorsErrorStatus from './InviteProjectFacilitatorsErrorStatus';
import InviteProjectFacilitatorsFormValue from './InviteProjectFacilitatorsFormValue';
import InviteProjectFacilitatorsProps from './InviteProjectFacilitatorsProps';
import InviteProjectFacilitatorsValidations from './InviteProjectFacilitatorsValidations';

export type InviteProjectFacilitatorsFormValues = {
  facilitators: InviteProjectFacilitatorsFormValue[];
};

const getInitialValues = (): InviteProjectFacilitatorsFormValues => ({
  facilitators: [new InviteProjectFacilitatorsFormValue()],
});

const InviteProjectFacilitators: ReactFC<InviteProjectFacilitatorsProps> = (
  props: InviteProjectFacilitatorsProps
) => {
  const {
    innerFormikRef,
    isOpen,
    projectId,
    onToggle,
    onCreateFacilitators,
    onUploadImage,
    supervisors,
    supervisorsFiltered,
    supervisorsStatus,
    appContext,
    onShowSuccessState,
    onAddFacilitatorsSuccess,
  } = props;

  const formikRef =
    useRef<FormikProps<InviteProjectFacilitatorsFormValues> | null>(null);

  const [initialValue] = useState(getInitialValues);

  const { permissionsData } = appContext;
  const { claims } = permissionsData;
  const canInviteFacilitators = PermissionUtil.Can(
    claims,
    ResourceKeys.ProjectsItemAddFacilitators
  );

  /**
   * cleanup to reset for on unmount, this effect
   * will run on tab change; thereby run on isOpen:false
   * since it will reset tab
   */
  useEffect(
    () =>
      function cleanup(): void {
        if (formikRef.current) {
          formikRef.current.resetForm();
        }
      },
    []
  );

  useEffect(() => {
    if (isOpen === false && formikRef.current) {
      formikRef.current.resetForm();
    }
  }, [isOpen]);

  /**
   * Handles form submission to invite (a) new user/s
   *
   * @param values Values entered into the invite facilitators form
   * @param helpers Formik helpers for the invite facilitators form
   */
  const handleSubmit = async (
    values: InviteProjectFacilitatorsFormValues,
    helpers: FormikHelpers<InviteProjectFacilitatorsFormValues>
  ): Promise<void> => {
    helpers.setSubmitting(true);
    const uploadPromises: Array<FileItem> = [];
    values.facilitators.forEach((item) => {
      if (item.image && item.image.name) {
        uploadPromises.push({ id: item.id, file: item.image });
      }
    });
    try {
      if (!isEmpty(uploadPromises)) {
        await onUploadImage(uploadPromises);
      }

      const formatted = values.facilitators.map(
        (facilitator) =>
          new InviteProjectFacilitatorsViewModel(
            facilitator.id,
            facilitator.firstName,
            facilitator.lastName,
            facilitator.location,
            facilitator.phoneNumber,
            facilitator.supervisorId,
            facilitator.startDate,
            facilitator.isImplementingPartner,
            projectId,
            facilitator.image?.name
          )
      );

      try {
        await onCreateFacilitators(formatted);

        const initialValues: InviteProjectFacilitatorsFormValues =
          getInitialValues();
        helpers.resetForm({ values: initialValues });

        appContext.hideErrorToast();
        onAddFacilitatorsSuccess(false, true);
        onShowSuccessState(
          values.facilitators.length,
          values.facilitators.length === 1
            ? `${values.facilitators[0].firstName} ${values.facilitators[0].lastName}`
            : undefined
        );
      } catch (error) {
        if (error instanceof ApiError) {
          if (!isEmpty(error.response)) {
            helpers.setStatus({
              errorCode: error.response?.errorCode,
              errorMessage: error.response?.errorMessage,
              errors: error.response?.errors,
            } as InviteProjectFacilitatorsErrorStatus);
          }

          if (error.status === undefined) {
            appContext.setErrorToastText(
              intl.get('ERR_PROJECT_FACILITATORS_INVITE_FAILURE')
            );
          } else if (error.status !== HttpStatus.FORBIDDEN) {
            EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
              error,
              genericErrorString: 'ERR_TOAST_INVITE_USER_FORM_ERRORS',
              genericCallback: () => undefined,
              customCallback: () => undefined,
            } as CustomErrorArgs);
            if (error.status === HttpStatus.NOT_FOUND) {
              /* If the project has been deleted concurrently, redirect to 
              the projects page and re-load the projects list */
              onAddFacilitatorsSuccess(false, true);
              onToggle();
            }
          }
        }
      }
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HttpStatus.FORBIDDEN) {
          appContext.setErrorToastText(
            intl.get('ERR_PROJECT_FACILITATORS_INVITE_FAILURE')
          );
        }
      }
    } finally {
      helpers.setSubmitting(false);
    }
  };

  /**
   * handles addition of new form item
   */
  const handleAddNewItem = (): InviteProjectFacilitatorsFormValue =>
    new InviteProjectFacilitatorsFormValue();

  const validationSchema =
    InviteProjectFacilitatorsValidations.GetValidationSchema() as ObjectSchema;

  return (
    <Formik
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      innerRef={mergeRefs([innerFormikRef, formikRef])}
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors }): JSX.Element => (
        <Form noValidate>
          <FieldArray
            name="facilitators"
            render={(fieldArrayProps): JSX.Element => (
              <div className={styles.wrapper}>
                <InviteFacilitatorsFieldArray
                  errors={errors}
                  setCommonError={appContext.setErrorToastText}
                  isSubmitting={isSubmitting}
                  canInviteFacilitators={canInviteFacilitators}
                  supervisors={supervisors}
                  supervisorsFiltered={supervisorsFiltered}
                  supervisorsStatus={supervisorsStatus}
                  onAddNewItem={handleAddNewItem}
                  validationSchema={validationSchema}
                  fieldArrayProps={fieldArrayProps}
                />
              </div>
            )}
          />
          <Row className="mt-3 pt-3">
            <Col xs="auto">
              <Button onClick={onToggle}>{intl.get('BTN_CANCEL')}</Button>
            </Col>
            <Col className="d-flex align-items-center justify-content-center" />
            <Col xs="auto">
              <Button disabled={isSubmitting} color="primary" type="submit">
                {intl.get('BTN_PROJECT_FACILITATORS_SAVE_AND_CONTINUE')}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default InviteProjectFacilitators;

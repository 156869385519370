/* eslint-disable react/jsx-props-no-spreading */
import { FC as ReactFC } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import ModulePaths from 'constants/ModulePaths';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import PermissionUtil from 'helpers/PermissionUtil';
import ProtectedRoute from 'shared/components/hoc/protected-route/ProtectedRoute';

import ActivityFeedView from './containers/activity-feed-view/ActivityFeedView';
import InviteUsers from './containers/invite-users/InviteUsers';
import MyOrgView from './containers/my-org-view/MyOrgView';
import PermissionsView from './containers/permissions-view/PermissionsView';
import UsersView from './containers/users-view/UsersView';
import { ModalType as UsersModalType } from './containers/users-view/UsersViewProps';
import SettingsRoutesProps from './SettingsRoutesProps';

const SettingsRoutes: ReactFC<SettingsRoutesProps> = (
  props: SettingsRoutesProps
) => {
  const { match, appContext, location } = props;

  /**
   * determines if any of the links of setting page can be
   * viewed by the user; and returns the first possible
   * route checking permissions for redirection when
   * requested route is not authorized for the logged in user
   * @returns possibleRoute
   */
  const getFirstPossibleRoute = (): string => {
    const routes = {
      [ResourceKeys.SettingsMyOrgOrgName]: `${match.path}${ModulePaths.SettingsOrgPath}`,
      [ResourceKeys.SettingsUsers]: `${match.path}${ModulePaths.SettingsUsersPath}`,
      Permissions: `${match.path}${ModulePaths.SettingsPermissionsPath}`,
      [ResourceKeys.SettingsActivityFeed]: `${match.path}${ModulePaths.SettingsActivityPath}`,
    };

    let possibleRoute;
    Object.keys(routes).forEach((item) => {
      if (possibleRoute === undefined) {
        if (item === 'Permissions') {
          possibleRoute = routes[item];
        }
        if (PermissionUtil.Can(appContext.permissionsData.claims, item)) {
          possibleRoute = routes[item];
        }
      }
    });
    return possibleRoute ?? ModulePaths.DashboardPath;
  };

  const possibleRoute = getFirstPossibleRoute();

  return (
    <Switch>
      <ProtectedRoute
        resource={ResourceKeys.SettingsMyOrgOrgName}
        redirectTo={possibleRoute}
        exact
        path={`${match.path}${ModulePaths.SettingsOrgPath}`}
      >
        {(routeProps): JSX.Element => <MyOrgView {...props} {...routeProps} />}
      </ProtectedRoute>

      <ProtectedRoute
        resource={ResourceKeys.SettingsUsers}
        redirectTo={possibleRoute}
        exact
        path={`${match.path}${ModulePaths.SettingsUsersPath}`}
      >
        {(routeProps): JSX.Element => (
          <UsersView modal={UsersModalType.None} {...props} {...routeProps} />
        )}
      </ProtectedRoute>

      <ProtectedRoute
        resource={ResourceKeys.SettingsUsersInviteUsers}
        redirectTo={`${match.path}${ModulePaths.SettingsUsersPath}`}
        exact
        path={`${match.path}${ModulePaths.SettingsUsersPath}${ModulePaths.SettingsUsersInvitePath}`}
      >
        {(routeProps): JSX.Element => (
          <InviteUsers {...props} {...routeProps} />
        )}
      </ProtectedRoute>

      <Route exact path={`${match.path}${ModulePaths.SettingsPermissionsPath}`}>
        {(routeProps): JSX.Element => (
          <PermissionsView {...props} {...routeProps} />
        )}
      </Route>

      <ProtectedRoute
        exact
        resource={ResourceKeys.SettingsActivityFeed}
        path={`${match.path}${ModulePaths.SettingsActivityPath}`}
        redirectTo={possibleRoute}
      >
        {(routeProps): JSX.Element => (
          <ActivityFeedView {...props} {...routeProps} />
        )}
      </ProtectedRoute>

      <Route path="*">
        <Redirect
          to={{
            pathname: `${ModulePaths.SettingsPath}${ModulePaths.SettingsOrgPath}`,
            search: getGlobalFiltersQuery(location.search),
          }}
        />
        ;
      </Route>
    </Switch>
  );
};

export default SettingsRoutes;

import { useEffect, useState, FC as ReactFC } from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import Select from 'react-select';
import {
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import { getFormattedNumber } from 'helpers/NumberFormat';
import GroupMetricQuartilesTable from 'modules/private/dashboard/components/group-metrics/group-metric-quartile-table/GroupMetricQuartilesTable';
import GroupMetricsProps from 'modules/private/dashboard/components/group-metrics/group-metrics-container/GroupMetricsProps';
import GroupMetricDataPoint from 'modules/private/dashboard/components/group-metrics/GroupMetricDataPoint';
import GroupMetricQuartile from 'modules/private/dashboard/components/group-metrics/GroupMetricQuartile';
import GroupMetricMeta from 'modules/private/dashboard/components/group-metrics/GroupMetricsMeta';
import GroupMetricsType from 'modules/private/dashboard/components/group-metrics/GroupMetricsType';
import SelectFieldOption from 'shared/components/ins-form-fields/select-field/SelectFieldOption';
import CyclePhase from 'shared/enums/CyclePhase';

import GroupMetricsChart from '../../bar-chart/BarChart';

type ChartData = {
  [K in GroupMetricsType]: {
    data: GroupMetricDataPoint[];
    meta: Partial<GroupMetricMeta>;
  };
};

const tabItems = {
  [GroupMetricsType.CumulativeSavingsAnnualized]: {
    key: GroupMetricsType.CumulativeSavingsAnnualized,
    title: 'LBL_CUMULATIVE_SAVINGS_ANNUALIZED',
    hint: 'LBL_CUMULATIVE_SAVINGS_HELP_TEXT',
    quartileTableTitle: 'LBL_SAVINGS_AMOUNT',
    xAxisLabel: 'LBL_SAVINGS_AMOUNT',
    yAxisLabel: 'LBL_GROUP_COUNT',
    firstColumn: 'LBL_GROUP_COUNT',
    secondColumn: 'LBL_SAVINGS_AMOUNT',
  },

  [GroupMetricsType.AverageSavingsPerMember]: {
    key: GroupMetricsType.AverageSavingsPerMember,
    title: 'LBL_AVERAGE_SAVINGS_PER_MEMBER_ANNUALIZED',
    hint: 'LBL_AVERAGE_SAVINGS_HELP_TEXT',
    quartileTableTitle: 'LBL_SAVINGS_AMOUNT',
    xAxisLabel: 'LBL_SAVINGS_AMOUNT',
    yAxisLabel: 'LBL_MEMBER_COUNT',
    firstColumn: 'LBL_GROUP_COUNT',
    secondColumn: 'LBL_SAVINGS_AMOUNT',
  },

  [GroupMetricsType.EstimatedGroupShareout]: {
    key: GroupMetricsType.EstimatedGroupShareout,
    title: 'LBL_ESTIMATED_GROUP_SHAREOUT',
    hint: 'LBL_ESTIMATED_GROUP_SHAREOUT_HELP_TEXT',
    quartileTableTitle: 'LBL_ESTIMATED_GROUP_SHAREOUT',
    firstColumn: 'LBL_GROUP_COUNT',
    secondColumn: 'LBL_ESTIMATED_GROUP_SHAREOUT',
    xAxisLabel: 'LBL_ESTIMATED_GROUP_SHAREOUT',
    yAxisLabel: 'LBL_GROUP_COUNT',
  },
};

const GroupMetricsContainer: ReactFC<GroupMetricsProps> = (
  props: GroupMetricsProps
) => {
  const { onFilterChange, data, loading, error, localFilters } = props;

  const defaultChartData = Object.keys(GroupMetricsType).reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [GroupMetricsType[currentValue]]: {
        data: [],
        meta: {},
      },
    }),
    {} as ChartData
  );
  // prettier-ignore
  const [tabSelected, setTabSelected] = useState<GroupMetricsType>(GroupMetricsType.CumulativeSavingsAnnualized);
  const [cyclePhases, setCyclePhases] = useState<SelectFieldOption[]>([]);
  const [chartData, setChartData] = useState<ChartData>(defaultChartData);

  useEffect(() => {
    const cyclePhaseOptions = Object.values(CyclePhase).map((value) => ({
      value,
      label: intl.get(`LBL_CP_${value}`),
    }));
    setCyclePhases(cyclePhaseOptions);
  }, []);

  useEffect(() => {
    const classifiedChartData: ChartData = defaultChartData;
    data.forEach((dataItem) => {
      const meta = {
        ...dataItem.meta,
        xAxisLabel: intl.get(tabItems[dataItem.type].xAxisLabel),
        yAxisLabel: intl.get(tabItems[dataItem.type].yAxisLabel),
        xAxisGridValues: dataItem.dataPoints.map((dp) => dp.key),
      };

      const quartiles = dataItem.meta.quartiles?.map(
        ({ percentile, count, rangeFrom, rangeTo }) =>
          new GroupMetricQuartile(percentile, count, rangeFrom, rangeTo)
      );

      classifiedChartData[dataItem.type] = {
        data: dataItem.dataPoints,
        meta: { ...meta, quartiles },
      };
    });

    setChartData(classifiedChartData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  /**
   * Get classnames for active/inactive tabs
   *
   * @param tab Group metrics chart type
   * @returns {string} Classnames for active/inactive tabs
   */
  const isActive = (tab: GroupMetricsType): string => {
    const activeTabClasses = 'tab active';
    const idleTabClasses = 'tab idle';

    return tabSelected === tab ? activeTabClasses : idleTabClasses;
  };

  /**
   * Renders each nav tab based on supplied metric data
   *
   * @param metricData Data regarding the group metric tab
   * @returns {JSX.Element} JSX snippet containing the nav tab for the given metric
   */
  const renderTabs = (metricData): JSX.Element => (
    <Nav tabs className="content-box-tabs">
      {Object.values(tabItems).map((tab) => {
        const selectedTabData = metricData.find(
          (item) => item.type === tab.key
        );
        let tabData = selectedTabData ? selectedTabData.tabData : 0;
        if (typeof tabData === 'number') {
          tabData = getFormattedNumber(tabData);
        }
        return (
          <NavItem key={tab.key}>
            <NavLink
              className={isActive(tab.key)}
              onClick={(): void => setTabSelected(tab.key)}
            >
              <div className="chart-details">
                <span className="sub-title">
                  {intl.get(tab.title)}
                  <span className="help">
                    <i
                      className="icon-help"
                      data-for="insTooltip"
                      data-tip={intl.get(tab.hint)}
                    />
                  </span>
                </span>
                <span className="value">{tabData}</span>
              </div>
            </NavLink>
          </NavItem>
        );
      })}
    </Nav>
  );

  return (
    <BlockUi tag="div" blocking={loading} className="row dashboard-row">
      <div className="col">
        <div className="content-box">
          <div className="content-box-title has-dropdowns pb-4 pt-2">
            <h3 className="main-title">
              {intl.get('LBL_GROUP_METRICS')}
              <span className="help">
                <i
                  className="icon-help"
                  data-for="insTooltip"
                  data-tip="Group Metrics"
                />
              </span>
            </h3>
            <div className="inline-form">
              <FormGroup className="insight-select-group">
                <Label htmlFor="cyclePhase" className="chart-filter-label">
                  {intl.get('LBL_CHART_FILTER_CYCLE_PHASE')}
                </Label>
                <Select
                  placeholder="Cycle Phase"
                  className="basic-single"
                  name="cyclePhase"
                  options={cyclePhases}
                  value={cyclePhases.find(
                    (value) => localFilters.cyclePhase === value.value
                  )}
                  onChange={(option): void => {
                    const currentOption = option as SelectFieldOption;
                    onFilterChange({
                      cyclePhase:
                        currentOption?.value ?? CyclePhase.ActivelyLending,
                    });
                  }}
                  classNamePrefix="insight-select-no-border"
                />
              </FormGroup>
            </div>
          </div>
          {renderTabs(data)}
          <TabContent activeTab={tabSelected}>
            <TabPane tabId={GroupMetricsType.CumulativeSavingsAnnualized}>
              <div className="row ins-custom-chart-wrapper chart-container">
                <div className="col-lg-8 col-sm-12">
                  <GroupMetricsChart
                    data={chartData.CUMULATIVE_SAVINGS_ANNUALIZED.data}
                    meta={chartData.CUMULATIVE_SAVINGS_ANNUALIZED.meta}
                    error={error}
                    loading={loading}
                  />
                </div>
                <div className="col-lg-4 chart-table-column">
                  {chartData.CUMULATIVE_SAVINGS_ANNUALIZED && (
                    <GroupMetricQuartilesTable
                      title={tabItems[tabSelected].quartileTableTitle}
                      firstColumn={tabItems[tabSelected].firstColumn}
                      secondColumn={tabItems[tabSelected].secondColumn}
                      quartiles={
                        chartData.CUMULATIVE_SAVINGS_ANNUALIZED.meta.quartiles
                      }
                    />
                  )}
                </div>
              </div>
            </TabPane>
            <TabPane tabId={GroupMetricsType.AverageSavingsPerMember}>
              <div className="row ins-custom-chart-wrapper chart-container">
                <div className="col-lg-8 col-sm-12">
                  <GroupMetricsChart
                    data={chartData.AVERAGE_SAVINGS_PER_MEMBER.data}
                    meta={chartData.AVERAGE_SAVINGS_PER_MEMBER.meta}
                    error={error}
                    loading={loading}
                  />
                </div>

                <div className="col-lg-4 chart-table-column">
                  {chartData.AVERAGE_SAVINGS_PER_MEMBER && (
                    <GroupMetricQuartilesTable
                      title={tabItems[tabSelected].quartileTableTitle}
                      firstColumn={tabItems[tabSelected].firstColumn}
                      secondColumn={tabItems[tabSelected].secondColumn}
                      quartiles={
                        chartData.AVERAGE_SAVINGS_PER_MEMBER.meta.quartiles
                      }
                    />
                  )}
                </div>
              </div>
            </TabPane>
            <TabPane tabId={GroupMetricsType.EstimatedGroupShareout}>
              <div className="row ins-custom-chart-wrapper chart-container">
                <div className="col-lg-8 col-sm-12">
                  <GroupMetricsChart
                    data={chartData.ESTIMATED_GROUP_SHAREOUT.data}
                    meta={chartData.ESTIMATED_GROUP_SHAREOUT.meta}
                    error={error}
                    loading={loading}
                  />
                </div>

                <div className="col-lg-4 chart-table-column">
                  {chartData.ESTIMATED_GROUP_SHAREOUT && (
                    <GroupMetricQuartilesTable
                      title={tabItems[tabSelected].quartileTableTitle}
                      firstColumn={tabItems[tabSelected].firstColumn}
                      secondColumn={tabItems[tabSelected].secondColumn}
                      quartiles={
                        chartData.ESTIMATED_GROUP_SHAREOUT.meta.quartiles
                      }
                    />
                  )}
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </BlockUi>
  );
};

export default GroupMetricsContainer;

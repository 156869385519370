/* eslint-disable react/jsx-props-no-spreading */
import { MouseEvent, useEffect, FC as ReactFC } from 'react';

import { DebounceInput } from 'react-debounce-input';
import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';
import { Row, Col } from 'reactstrap';

import GroupList from 'modules/private/group/components/group-list/GroupList';
import GroupMap from 'modules/private/group/components/group-map/GroupMap';
import Status from 'shared/enums/Status';

import styles from './groupContainer.module.scss';
import GroupContainerProps from './GroupContainerProps';
import NotificationFilterOption from './notification-select/NotificationFilterOption';
import NotificationSelect from './notification-select/NotificationSelect';
import NotificationTag from './notification-tag/NotificationTag';

const GroupContainer: ReactFC<GroupContainerProps> = (
  props: GroupContainerProps
) => {
  const {
    searchTerm,
    filters,
    notifications,
    notificationsStatus,
    showGroupList,
    groupListProps,
    groupMapData,
    location,
    onChangeFilters,
    onSearchTermChange,
    onViewChange,
  } = props;

  /**
   * Handles remove filter event
   *
   * @param event Remove filter mouse event
   */
  const handleRemoveFilter = (event: MouseEvent<HTMLButtonElement>): void => {
    const removeFilter = event.currentTarget.value;
    onChangeFilters(filters.filter((item) => item !== removeFilter));
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [filters]);

  const filtersLoading =
    groupListProps.loading || notificationsStatus === Status.Loading;

  return (
    <>
      <Row className="dashboard-row">
        <Col md={4}>
          <NotificationSelect
            value={notifications.filter(
              (element) =>
                filters.includes(element.value) && element.count !== 0
            )}
            options={notifications}
            isLoading={filtersLoading}
            name="notificationFilter"
            onChange={onChangeFilters}
            placeholder={intl.get('LBL_NOTIFICATION_SELECT_PLACEHOLDER')}
            classNamePrefix="insight-select-no-border"
          />
        </Col>
        <Col className="btn-col-right d-flex align-items-center">
          {showGroupList && (
            <button
              className="btn"
              onClick={(): void => groupListProps.toggleColOrderModal(true)}
              disabled={false}
            >
              <i className="icon-plus" />
              {intl.get('BTN_MANAGE_COLUMNS')}
            </button>
          )}
          <div className="btn-toggle-group">
            <button
              onClick={(): void => onViewChange(true)}
              disabled={filtersLoading || groupMapData.loading}
              tabIndex={showGroupList ? -1 : 0}
              className={`btn ${showGroupList === true ? 'active' : ''}`}
            >
              <i className="icon-list" />
              <span>{intl.get('BTN_LIST')}</span>
            </button>
            <button
              onClick={(): void => onViewChange(false)}
              disabled={filtersLoading || groupMapData.loading}
              tabIndex={showGroupList ? 0 : -1}
              className={`btn ${showGroupList === false ? 'active' : ''}`}
            >
              <i className="icon-map-pin" />
              <span>{intl.get('BTN_MAP')}</span>
            </button>
          </div>
        </Col>
      </Row>
      <Row className="dashboard-row">
        <Col md={6}>
          <DebounceInput
            minLength={2}
            debounceTimeout={1000}
            value={searchTerm}
            disabled={groupListProps.loading || groupMapData.loading}
            onChange={(e): void => onSearchTermChange(e.target.value)}
            className={styles.search}
            placeholder={intl.get('LBL_GD_SEARCH_PLACEHOLDER')}
            type="search"
          />
        </Col>
      </Row>
      <Row className="dashboard-row">
        <Col className={styles.cloud}>
          {filters.map((notification) => {
            const current = notifications.find(
              (item) => item.value === notification && item.count !== 0
            ) as NotificationFilterOption;

            if (current) {
              return (
                <NotificationTag
                  key={current.value}
                  current={current}
                  filtersLoading={filtersLoading}
                  onRemoveFilter={handleRemoveFilter}
                />
              );
            }
            return null;
          })}
        </Col>
      </Row>
      {showGroupList && (
        <GroupList
          {...groupListProps}
          location={location}
          notificationStatus={notificationsStatus}
        />
      )}
      {!showGroupList && (
        <GroupMap data={groupMapData.data} loading={groupMapData.loading} />
      )}
    </>
  );
};

export default GroupContainer;

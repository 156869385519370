import { CancelTokenSource } from 'axios';
import queryString from 'query-string';

import ApiBase from 'api/common/ApiBase';
import ListFilters from 'api/common/ListFilters';
import ListResponse from 'api/common/ListResponse';
import PaginatedListResponse from 'api/common/PaginatedListResponse';
import SuccessResponse from 'api/common/SuccessResponse';
import ProjectDataMinimal from 'api/projects/ProjectDataMinimal';
import { MessageApiTags } from 'constants/request-tags/RequestTags';

import Country from './group-filters/Country';
import Facilitator from './group-filters/Facilitator';
import { GroupFiltersRequest } from './group-filters/GroupFiltersRequest';
import GroupFiltersResponse, {
  MessagingGroup,
} from './group-filters/GroupFiltersResponse';
import { MessageDetails } from './message/MessageDetails';
import MessageListItem from './message/MessageListItem';
import MessageProcessedStatus from './message/MessageProcessedStatus';
import { PostMessageRequestBody } from './message/PostMessageRequestBody';

class MessagingApi extends ApiBase {
  /**
   * Compose message
   */
  async CreateMessage(
    payload: PostMessageRequestBody,
    cancelToken: CancelTokenSource
  ): Promise<SuccessResponse> {
    const path = 'messages';
    const postMessageResponse = this.PostAsync<SuccessResponse>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body: payload,
      tag: MessageApiTags.CreateMessage,
      cancelSource: cancelToken,
    });
    return postMessageResponse;
  }

  /**
   * Get a list of messages
   *
   * @param listFilters Applied list filters
   */
  async GetMessageListData(
    listFilters: ListFilters,
    cancelToken: CancelTokenSource
  ): Promise<PaginatedListResponse<MessageListItem>> {
    const { page, pageSize, sortBy, sort, search } = listFilters;

    const searchQuery =
      search && search.trim().length > 0 ? `&search=${search}` : '';
    const listFilterQuery = `page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sort=${sort}${searchQuery}`;

    const path = `messages?${listFilterQuery}`;

    const messageListResponse = this.GetAsync<
      Promise<PaginatedListResponse<MessageListItem>>
    >({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: MessageApiTags.GetMessageListData,
      cancelSource: cancelToken,
    });

    return messageListResponse;
  }

  /**
   * Get single message
   *
   * @param id Message ID
   */
  async GetMessage(
    id: string,
    cancelToken: CancelTokenSource
  ): Promise<MessageDetails> {
    const path = `messages/details/${id}`;
    const messageDetailsResponse = this.GetAsync<Promise<MessageDetails>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: MessageApiTags.GetMessage,
      cancelSource: cancelToken,
    });
    return messageDetailsResponse;
  }

  /**
   * Get countries to populate filters
   */
  async GetCountries(
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<Country>> {
    const path = `groups/countries`;
    const countriesResponse = this.GetAsync<Promise<ListResponse<Country>>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: MessageApiTags.GetCountries,
      cancelSource: cancelToken,
    });
    return countriesResponse;
  }

  /**
   * Get projects to populate projects filter
   *
   * @param countryCodes Country codes to filter by
   */
  async GetProjectsForCountry(
    countryCodes: Array<string>,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<ProjectDataMinimal>> {
    const queryParams = `${queryString.stringify({
      countryCode: countryCodes,
    })}`;
    const path = `projects/all-projects?${queryParams}`;
    const projectsResponse = this.GetAsync<
      Promise<ListResponse<ProjectDataMinimal>>
    >({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: MessageApiTags.GetProjectsForCountry,
      cancelSource: cancelToken,
    });
    return projectsResponse;
  }

  /**
   * Get facilitator to populate filters
   *
   * @param projectIds Project Ids to filter by
   */
  async GetFacilitatorsForProjects(
    projectIds: Array<string>,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<Facilitator>> {
    const queryParams = `${queryString.stringify({
      projectId: projectIds,
    })}`;
    const path = `organizations/facilitators/lookup?${queryParams}`;
    const facilitatorsResponse = this.GetAsync<
      Promise<ListResponse<Facilitator>>
    >({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      tag: MessageApiTags.GetFacilitatorsForProjects,
      cancelSource: cancelToken,
    });
    return facilitatorsResponse;
  }

  /**
   * Post group filters
   *
   * @param groupFiltersRequest Selected group filters
   */
  async PostGroupFilters(
    groupFilters: GroupFiltersRequest,
    cancelToken: CancelTokenSource
  ): Promise<GroupFiltersResponse> {
    const path = 'messages/filtered-groups';
    const body = { ...groupFilters };
    const response = this.PostAsync<Promise<GroupFiltersResponse>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: MessageApiTags.PostGroupFilters,
      cancelSource: cancelToken,
    });

    return response;
  }

  /**
   * Get message details as excel
   *
   * @param id Message ID
   */
  async GetMessagingExcel(
    id: string,
    cancelToken: CancelTokenSource
  ): Promise<Blob> {
    const path = `messages/${id}/export`;

    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const action = `${path}?timezone=${clientTimezone}`;

    const excelData = this.GetAsyncBlob({
      action,
      tag: MessageApiTags.GetMessagingExcel,
      cancelSource: cancelToken,
    });

    return excelData;
  }

  /**
   * Get message processed status
   *
   * @param id Message ID
   */
  async GetMessageProcessedStatus(
    id: string,
    cancelToken: CancelTokenSource
  ): Promise<MessageProcessedStatus> {
    const path = `messages/${id}/processed-status`;
    const response = this.GetAsync<MessageProcessedStatus>({
      action: path,
      tag: MessageApiTags.GetMessageProcessedStatus,
      cancelSource: cancelToken,
    });
    return response;
  }

  /**
   * Check group message send history
   *
   * @param groups Selected groups
   */
  async CheckGroupStatus(
    groups: Array<string>,
    cancelToken: CancelTokenSource
  ): Promise<ListResponse<MessagingGroup>> {
    const path = 'messages/check-groups-status';
    const body = { groups };
    const response = this.PostAsync<Promise<ListResponse<MessagingGroup>>>({
      action: path,
      anonymous: false,
      includeAuthToken: true,
      body,
      tag: MessageApiTags.CheckGroupStatus,
      cancelSource: cancelToken,
    });

    return response;
  }
}

const MessagingApiInstance = new MessagingApi();
export default MessagingApiInstance;

/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { Component } from 'react';

import axios from 'axios';
import {
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
  FormikTouched,
} from 'formik';
import * as intl from 'react-intl-universal';
import { Modal } from 'reactstrap';

import AuthApiInstance from 'api/auth/AuthApi';
import ApiError from 'api/common/ApiError';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import LabelKeysGA from 'constants/ga/LabelKeysGA';
import TIMEOUTS from 'constants/Timeouts';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import setPageTitle from 'helpers/setPageTitle';
import RegisterEmailSent from 'modules/public/auth/components/register-email-sent/RegisterEmailSent';
import RegisterPartnerId from 'modules/public/auth/components/register-partner-id/RegisterPartnerId';
import RegisterPassword from 'modules/public/auth/components/register-password/RegisterPassword';
import RegisterWelcome from 'modules/public/auth/components/register-welcome/RegisterWelcome';
import ModalBackground from 'shared/components/hoc/dummy-background/ModalBackground';
import VerificationError from 'shared/enums/VerificationError';

import CreateAccountViewState from './CreateAccountViewState';
import RegisterFormValidation from './RegisterFormValidation';
import RegisterFormValues from './RegisterFormValues';

class CreateAccountView extends Component<
  Record<string, never>,
  CreateAccountViewState
> {
  constructor(props: Record<string, never>) {
    super(props);
    this.state = {
      wizardStep: 1,
      submitLoading: false,
      userEmail: null,
      resendEmailLoading: false,
      resendError: null,
      emailVerifying: false,
      emailError: null,
      emailExistRequestError: null,
      expiryTimeString: TIMEOUTS.AUTH_VERIFICATION_EXPIRY,
    };
  }

  componentDidMount(): void {
    setPageTitle();
  }

  componentWillUnmount(): void {
    this.source.cancel();
  }

  initialValues: RegisterFormValues = {
    firstName: '',
    lastName: '',
    jobTitle: '',
    organizationName: '',
    email: '',
    password: '',
    confirmedPassword: '',
    termsAccepted: false,
    partnerId: '',
  };

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  /**
   * Sets updated state to container state
   *
   * @param updateState Updated state object
   */
  setStateAttribute = (updateState: Partial<CreateAccountViewState>): void =>
    this.setState((state) => ({ ...state, ...updateState }));

  /**
   * Re-sends email for expired links
   */
  resendEmail = async (): Promise<void> => {
    const { userEmail } = this.state;
    if (userEmail) {
      this.setStateAttribute({ resendEmailLoading: true });
      try {
        await AuthApiInstance.ResendEmail(userEmail, this.source);

        sendEventGA(
          CategoryKeysGA.AccountCreateAccountComplete,
          ActionKeysGA.ResendEmail
        );
      } catch (error) {
        if (error instanceof ApiError && error.response) {
          const errorMessage = error.response.message;
          this.setStateAttribute({
            resendError: errorMessage
              ? (errorMessage as VerificationError)
              : VerificationError.GENERIC,
          });
        }
      } finally {
        this.setStateAttribute({ resendEmailLoading: false });
      }
    }
  };

  /**
   * Resets email error statuses
   */
  resetEmailStatus = (): void => {
    const { emailError, emailExistRequestError } = this.state;
    if (emailError) {
      this.setStateAttribute({ emailError: null });
    }
    if (emailExistRequestError) {
      this.setStateAttribute({ emailExistRequestError: null });
    }
  };

  /**
   * Handles wizard step submission
   *
   * @param values Values from form fields
   * @param actions Formik helpers
   */
  handleSubmit = (
    values: RegisterFormValues,
    actions: FormikHelpers<RegisterFormValues>
  ): void => {
    const { wizardStep } = this.state;
    const { setTouched } = actions;
    switch (wizardStep) {
      case 1:
        this.handleForwardClick(setTouched);
        break;
      case 2:
        this.handlePasswordContinue(setTouched, values);
        break;
      case 3:
        this.handleFormSubmit(values, actions);
        break;
      default:
        break;
    }
  };

  /**
   * Handles form submission
   *
   * @param values Values from form fields
   * @param actions Formik helpers
   */
  handleFormSubmit = async (
    values: RegisterFormValues,
    actions: FormikHelpers<RegisterFormValues>
  ): Promise<void> => {
    this.setStateAttribute({ submitLoading: true });
    try {
      const partnerIdExistsResponse =
        await AuthApiInstance.CheckPartnerIdExists(
          values.partnerId,
          this.source
        );
      if (partnerIdExistsResponse.exists) {
        if (partnerIdExistsResponse.organizationCreated) {
          actions.setStatus({
            partnerId: intl.get('ERR_CA_YOUR_ORG_HAS_OWNER_ACCOUNT'),
          });
        } else {
          actions.setStatus(null);
          const formData = {
            email: values.email,
            password: values.password,
            firstName: values.firstName,
            orgName: values.organizationName,
            lastName: values.lastName,
            jobRole: values.jobTitle,
            partnerID: values.partnerId,
          };
          const result = await AuthApiInstance.CreateAccount(
            formData,
            this.source
          );
          this.setStateAttribute({
            userEmail: values.email,
            expiryTimeString: result.expiryTime,
          });
          // eslint-disable-next-line @typescript-eslint/unbound-method
          this.handleForwardClick(actions.setTouched);
        }
      } else {
        actions.setStatus({
          partnerId: intl.get('ERR_CA_PARTNER_ID_DOES_NOT_EXIST'),
        });
      }
    } catch (error) {
      actions.setStatus({ submit: intl.get('OOPS_SOMETHING_WENT_WRONG') });
    } finally {
      this.setStateAttribute({ submitLoading: false });
      actions.setSubmitting(false);
    }
  };

  /**
   * Handles submission of the 2nd wizard step
   *
   * @param setTouched setTouched method from Formik helpers
   * @param values Values from form fields
   */
  handlePasswordContinue = async (
    setTouched: (
      touched: FormikTouched<RegisterFormValues>,
      shouldValidate?: boolean | undefined
    ) => void,
    values: RegisterFormValues
  ): Promise<void> => {
    this.setStateAttribute({ emailVerifying: true });
    try {
      const isExists = await AuthApiInstance.VerifyEmailExistence(
        values.email,
        this.source
      );
      this.setStateAttribute({ emailVerifying: false });
      if (isExists) {
        this.setStateAttribute({
          emailError: intl.get('ERR_STATUS_EXISTING_EMAIL'),
        });
      } else {
        this.resetEmailStatus();
        this.handleForwardClick(setTouched);
      }
    } catch (error) {
      this.setStateAttribute({
        emailVerifying: false,
        emailExistRequestError: intl.get(
          'ERR_STATUS_EMAIL_EXISTENCE_VERIFICATION_FAILED'
        ),
      });
    }
  };

  /**
   * Handles back click from 2nd wizard step
   */
  handlePasswordBackClick = (): void => {
    this.resetEmailStatus();
    this.handleBackClick();
  };

  /**
   * Handles back click on wizard
   */
  handleBackClick = (): void => {
    const { wizardStep } = this.state;
    const newStep = wizardStep - 1;
    if (wizardStep > 1) this.setStateAttribute({ wizardStep: newStep });
  };

  /**
   * Handles forward click on wizard
   *
   * @param setTouched setTouched method from Formik helpers
   */
  handleForwardClick = (
    setTouched: (
      touched: FormikTouched<RegisterFormValues>,
      shouldValidate?: boolean | undefined
    ) => void
  ): void => {
    const { wizardStep } = this.state;
    const newStep = wizardStep + 1;
    if (wizardStep < 4) {
      switch (wizardStep) {
        case 1:
          sendEventGA(
            CategoryKeysGA.AccountCreateAccountStep1,
            ActionKeysGA.Continue
          );
          break;
        case 2:
          sendEventGA(
            CategoryKeysGA.AccountCreateAccountStep2,
            ActionKeysGA.Continue
          );
          break;
        case 3:
          sendEventGA(
            CategoryKeysGA.AccountCreateAccountStep3,
            ActionKeysGA.CreateAccount,
            LabelKeysGA.Success
          );
          break;
        default:
          break;
      }
      this.setStateAttribute({ wizardStep: newStep });
    }
    // Form submit will set all the fields as touched. So here we remove other fields for next steps.
    const touched = Object.keys(this.initialValues).reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue]: false,
      }),
      {}
    );
    setTouched(touched);
  };

  /**
   * Renders correct wizard step as a child component
   *
   * @param formikProps Formik props
   * @returns {JSX.Element} JSX snippet containing appropriate wizard step
   */
  renderForm = (formikProps: FormikProps<RegisterFormValues>): JSX.Element => {
    const {
      wizardStep,
      submitLoading,
      emailVerifying,
      emailExistRequestError,
      emailError,
    } = this.state;
    const { setFieldValue, status, setStatus } = formikProps;
    const handleChangeEmail = (event): void => {
      this.resetEmailStatus();
      const { value } = event.target;
      setFieldValue('email', value);
    };
    let formStep;
    switch (wizardStep) {
      case 1:
        formStep = <RegisterWelcome step={wizardStep} />;
        break;
      case 2:
        formStep = (
          <RegisterPassword
            step={wizardStep}
            onBackClick={(): void => this.handlePasswordBackClick()}
            handleChangeEmail={handleChangeEmail}
            emailVerifying={emailVerifying}
            emailExistRequestError={emailExistRequestError}
            emailError={emailError}
          />
        );
        break;
      case 3:
        formStep = (
          <RegisterPartnerId
            step={wizardStep}
            status={status}
            setStatus={setStatus}
            loading={submitLoading}
            onBackClick={(): void => this.handleBackClick()}
            setFieldValue={setFieldValue}
          />
        );
        break;
      default:
        formStep = null;
        break;
    }

    return <Form noValidate>{formStep}</Form>;
  };

  render(): JSX.Element {
    const { wizardStep, resendEmailLoading, resendError, expiryTimeString } =
      this.state;

    return (
      <ModalBackground>
        <Modal size="lg" isOpen backdrop="static" centered>
          <Formik
            initialValues={this.initialValues}
            validationSchema={RegisterFormValidation.GetValidationSchema(
              wizardStep
            )}
            onSubmit={this.handleSubmit}
          >
            {this.renderForm}
          </Formik>
          {wizardStep === 4 && (
            <RegisterEmailSent
              loading={resendEmailLoading}
              resendEmail={this.resendEmail}
              error={resendError}
              expiryTimeString={expiryTimeString}
            />
          )}
        </Modal>
      </ModalBackground>
    );
  }
}

export default CreateAccountView;

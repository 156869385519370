/* eslint-disable no-nested-ternary */
import { useEffect, FC as ReactFC } from 'react';

import ReactTooltip from 'react-tooltip';

import { getFormattedNumber } from 'helpers/NumberFormat';

import styles from '../projectFacilitatorsDataTable.module.scss';
import ProjectFacilitatorsHeaderCellProps from './ProjectFacilitatorsHeaderCellProps';

const ProjectFacilitatorsHeaderCell: ReactFC<ProjectFacilitatorsHeaderCellProps> =
  ({ column, meta }: ProjectFacilitatorsHeaderCellProps) => {
    const { id, dataTitle, helpText } = column;

    useEffect(() => {
      ReactTooltip.rebuild();
    }, [column, meta]);

    return (
      <span className="table-label">
        {dataTitle}
        {helpText && (
          <span className="help">
            <i
              className="icon-help"
              data-for="insTooltip"
              data-tip={helpText}
            />
          </span>
        )}
        {id === 'name' && (
          <span className={`ml-1 ${styles.countColor}`}>{`(${getFormattedNumber(
            meta.total
          )})`}</span>
        )}
        {meta.total > 1 && (
          <span
            className={
              column.isSorted
                ? column.isSortedDesc
                  ? 'icon-chevron-down'
                  : 'icon-chevron-up'
                : ''
            }
          />
        )}
      </span>
    );
  };

export default ProjectFacilitatorsHeaderCell;

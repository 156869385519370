/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode, useEffect, useState, FC as ReactFC } from 'react';

import { FieldInputProps, FieldProps, FormikProps } from 'formik';
import PhoneInput from 'react-phone-number-input';
import { Label } from 'reactstrap';
import { ObjectSchema, reach, ValidationError } from 'yup';
import 'react-phone-number-input/style.css';

import usePrevious from 'shared/hooks/use-previous/UsePrevious';

import PhoneNumberCountrySelect from './phone-number-country-select/PhoneNumberCountrySelect';
import styles from './phoneNumberField.module.scss';

type BlurPhoneNumberFieldProps = React.InputHTMLAttributes<HTMLInputElement> &
  FieldProps<string> & {
    validationSchema: ObjectSchema;
    disabled: boolean;
    label: string;
    className?: string;
    customError?:
      | string
      | ((
          field: FieldInputProps<string>,
          form: FormikProps<any>,
          value: string
        ) => string | null);
  };

const BlurPhoneNumberField: ReactFC<BlurPhoneNumberFieldProps> = (
  props: BlurPhoneNumberFieldProps
) => {
  const { field, form, validationSchema, customError } = props;
  const { label, disabled } = props;

  const [value, setValue] = useState(field.value ?? '');
  const [error, setError] = useState('');
  const [touched, setTouched] = useState(false);

  const submitting = form.isSubmitting;
  const prevSubmitting = usePrevious(submitting);

  useEffect(() => {
    setValue(field.value ?? '');
  }, [field.value]);

  useEffect(() => {
    if (submitting && submitting !== prevSubmitting) {
      setTouched(true);
    }
  }, [submitting, prevSubmitting]);

  useEffect(() => {
    /**
     * runs yup validate on input value asynchronously and set
     * error to state
     * @param inputValue string input value
     */
    const validateField = async (inputValue: string): Promise<void> => {
      const schema = reach(validationSchema, field.name);
      if (!schema) {
        return;
      }
      try {
        await schema.validate(inputValue);
        setError('');
      } catch (err) {
        if (err instanceof ValidationError) {
          setError(err.message);
        }
      }
    };
    if (touched) {
      validateField(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.name, touched, JSON.stringify(validationSchema), value]);

  /**
   * Handle blur event handler which will set the value to
   * formik and calls formik blur triggering validation on
   * formik level
   *
   * @param event React.FocusEvent<HTMLInputElement>
   */
  const handleBlur = (event: any): void => {
    if (value) {
      form.setFieldValue(field.name, value, true);
    }
    field.onBlur(event);
    setTouched(true);
  };

  /**
   * Renders field error; local error gets priority over
   * optional customError passed as a prop
   */
  const renderError = (): ReactNode => {
    if (touched && error) {
      return <label className="form-error">{error}</label>;
    }
    if (customError) {
      if (typeof customError === 'function') {
        const custom = customError(field, form, value);
        if (custom) {
          return <label className="form-error">{custom}</label>;
        }
        return null;
      }
      return <label className="form-error">{customError}</label>;
    }
    return null;
  };

  const isError = !!renderError();

  // const HiddenCountrySelect: ReactFC = (): JSX.Element => <></>;

  return (
    <>
      {label && (
        <Label disabled={disabled} htmlFor={field.name}>
          {label}
        </Label>
      )}
      <PhoneInput
        {...field}
        value={value}
        onBlur={handleBlur}
        onChange={setValue}
        className={`${styles.phoneInputCustom}  ${isError ? styles.error : ''}`}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        countrySelectComponent={PhoneNumberCountrySelect}
      />
      {renderError()}
    </>
  );
};

BlurPhoneNumberField.defaultProps = {
  className: undefined,
  customError: undefined,
};

export default BlurPhoneNumberField;
